import React, { useEffect, useState, memo, FC } from "react";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material";
import { SwingWidthInput, SwingPanelInput } from "./SwingWidthInput";
import useSwingWidth from "../../hooks/useSwingWidth";
import Sidelites from "./Sidelites";
import { MenuItem } from "@mui/material";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import fractionToInt from "../../util/fractionToInt";
import findMinMaxInswing from "./findMaxInswing";
import findMinMaxOutswing from "./findMaxOutswing";
import { styled } from "@mui/material/styles";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 150,
  height: 20,
  position: "relative",
  top: -25,
}));

const StyledTypography = styled(Typography)({
  color: "#3f51b5",
  display: "inline",
  fontWeight: "bold",
});

interface SwingWidthProps {
  widthMeasureImg: string;
  editBtn: boolean | undefined;
  sideLites: string;
  swingType: string;
  direction: string;
  isPivot?: boolean;
}

interface UpdateObj {
  swingPanelWidth?: string;
  swingPanelFraction?: string;
  dropseal?: string;
}

const SwingWidthAccordian: FC<SwingWidthProps> = ({
  widthMeasureImg,
  editBtn,
  sideLites,
  swingType,
  direction,
  isPivot,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const equalPanels = useStore((state) => state.quote.equalPanels);

  const handleError = useStore((state) => state.handleError);
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);

  const material = useStore((state) => state.quote.material);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[2].expanded);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const overall = fractionToInt(unitSize, unitSizeFraction);

  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore(
    (state) => state.quote.swingPanelFraction
  );

  const overallPW = fractionToInt(swingPanelWidth || "", swingPanelFraction);

  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    if (direction !== "Inswing") return;
    var { min, max } = findMinMaxInswing(
      equalPanels || false,
      sideLites,
      swingType,
      isPivot || false
    );

    setMinWidth(min || 0);
    setMaxWidth(max || 0);
  }, [direction, sideLites, swingType, equalPanels]);

  useEffect(() => {
    if (direction !== "Outswing") return;
    var { min, max } = findMinMaxOutswing(
      equalPanels || false,
      sideLites,
      swingType
    );

    setMinWidth(min || 0);
    setMaxWidth(max || 0);
  }, [direction, sideLites, swingType, equalPanels]);

  const swingWidths = useSwingWidth(
    unitSize,
    unitSizeFraction || "",
    direction,
    swingType,
    sideLites,
    swingPanelWidth || "",
    swingPanelFraction || "",
    addToQuote,
    handleError,
    editBtn || false,
    equalPanels || false,
    material,
    isPivot || false,
    ""
  );

  const handleWidthChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    frameOrPanel: string
  ) => {
    const { value } = event.target;

    if (frameOrPanel === "frame") {
      addToQuote({ unitSize: value }, "unitSize");
    } else {
      let update: UpdateObj = { swingPanelWidth: value };

      const int = Number(value);
      if (int > 90 || (int === 90 && swingPanelFraction)) {
        update["dropseal"] = "";
      }

      addToQuote(update, "swingPanelWidth");
    }
  };

  const handleFractionChange = (
    event: SelectChangeEvent<string>,
    frameOrPanel: string
  ) => {
    const { value } = event.target;

    if (frameOrPanel === "frame") {
      if (!value || value === "0") {
        addToQuote({ unitSizeFraction: "" });
      } else {
        addToQuote({ unitSizeFraction: value });
      }
    } else {
      if (!value || value === "0") {
        addToQuote({ swingPanelFraction: "" });
      } else {
        let update: UpdateObj = { swingPanelFraction: value };

        if (swingPanelWidth) {
          const int = Number(swingPanelWidth);
          if (int === 90) {
            update["dropseal"] = "";
          }
        }

        addToQuote(update);
      }
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="unitSize"
      heading={sideLites === "none" ? "Net Frame Width" : "Widths"}
      secondaryHeading="Select a Width"
      required={false}
      required5={true}
    >
      <RowLayout>
        {direction && swingType ? (
          <ColLayout>
            <Typography style={{ paddingBottom: 20 }}>
              Note: We recommend Net Frame Width to be 1 inch less than the
              Rough Opening Width
            </Typography>

            <RowLayout>
              <SwingWidthInput
                equalPanels={equalPanels || false}
                overall={overall}
                minWidth={minWidth}
                maxWidth={maxWidth}
                inputTitle="Width in Inches"
                width={unitSize}
                handleChange={handleWidthChange}
                option="frame"
              />
              <RowLayout style={{ alignSelf: "center", paddingLeft: 40 }}>
                <StyledFormControl margin="dense" variant="outlined">
                  <InputLabel>fraction</InputLabel>
                  <Select
                    value={unitSizeFraction}
                    onChange={(event) => handleFractionChange(event, "frame")}
                    name="fraction"
                    label="fraction"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"1/16"}>1/16</MenuItem>
                    <MenuItem value={"1/8"}>1/8</MenuItem>
                    <MenuItem value={"3/16"}>3/16</MenuItem>
                    <MenuItem value={"1/4"}>1/4</MenuItem>
                    <MenuItem value={"5/16"}>5/16</MenuItem>
                    <MenuItem value={"3/8"}>3/8</MenuItem>
                    <MenuItem value={"7/16"}>7/16</MenuItem>
                    <MenuItem value={"1/2"}>1/2</MenuItem>
                    <MenuItem value={"9/16"}>9/16</MenuItem>
                    <MenuItem value={"5/8"}>5/8</MenuItem>
                    <MenuItem value={"11/16"}>11/16</MenuItem>
                    <MenuItem value={"3/4"}>3/4</MenuItem>
                    <MenuItem value={"13/16"}>13/16</MenuItem>
                    <MenuItem value={"7/8"}>7/8</MenuItem>
                    <MenuItem value={"15/16"}>15/16</MenuItem>
                  </Select>
                </StyledFormControl>
              </RowLayout>
            </RowLayout>

            {!sideLites || sideLites === "none" ? (
              <>
                {swingWidths.panelWidth && overall && (
                  <Typography>
                    <StyledTypography>
                      Approximate Per Panel Width(Y):{" "}
                    </StyledTypography>
                    {swingWidths.panelWidth} {swingWidths.panelFraction}"
                  </Typography>
                )}
              </>
            ) : sideLites !== "none" && equalPanels ? (
              <>
                {swingWidths.equalP && (
                  <>
                    <Typography>
                      <StyledTypography>
                        Approximate Per Panel and Per Sidelite Width:{" "}
                      </StyledTypography>
                      {swingWidths.equalP}"
                    </Typography>

                    <Typography style={{ color: "red" }}>
                      {sideliteWidthErr}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <RowLayout>
                <SwingPanelInput
                  overall={overallPW}
                  inputTitle="Panel Width"
                  panelWidth={swingPanelWidth || ""}
                  handleChange={handleWidthChange}
                  option="panel"
                  isPivot={isPivot || false}
                />
                <RowLayout style={{ alignSelf: "center", paddingLeft: 40 }}>
                  <StyledFormControl margin="dense" variant="outlined">
                    <InputLabel>fraction</InputLabel>
                    <Select
                      value={swingPanelFraction}
                      onChange={(event) => handleFractionChange(event, "panel")}
                      name="fraction"
                      label="fraction"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"1/16"}>1/16</MenuItem>
                      <MenuItem value={"1/8"}>1/8</MenuItem>
                      <MenuItem value={"3/16"}>3/16</MenuItem>
                      <MenuItem value={"1/4"}>1/4</MenuItem>
                      <MenuItem value={"5/16"}>5/16</MenuItem>
                      <MenuItem value={"3/8"}>3/8</MenuItem>
                      <MenuItem value={"7/16"}>7/16</MenuItem>
                      <MenuItem value={"1/2"}>1/2</MenuItem>
                      <MenuItem value={"9/16"}>9/16</MenuItem>
                      <MenuItem value={"5/8"}>5/8</MenuItem>
                      <MenuItem value={"11/16"}>11/16</MenuItem>
                      <MenuItem value={"3/4"}>3/4</MenuItem>
                      <MenuItem value={"13/16"}>13/16</MenuItem>
                      <MenuItem value={"7/8"}>7/8</MenuItem>
                      <MenuItem value={"15/16"}>15/16</MenuItem>
                    </Select>
                  </StyledFormControl>
                </RowLayout>
              </RowLayout>
            )}

            {!equalPanels && (
              <Sidelites
                swingWidths={swingWidths}
                doorPanelWidth={swingPanelWidth || ""}
                sideLites={sideLites}
              />
            )}
          </ColLayout>
        ) : (
          <Typography>
            Note: Select Swing Configuration to view Width options
          </Typography>
        )}

        <SwingMeasureImg
          widthMeasureImg={widthMeasureImg}
          equalPanels={equalPanels || false}
        />
      </RowLayout>
    </ReusableAccordian>
  );
};

interface SwingMeasureProps {
  widthMeasureImg: string;
  equalPanels: boolean;
}

const SwingMeasureImg: FC<SwingMeasureProps> = memo(
  ({ widthMeasureImg, equalPanels }) => {
    const exactConfig = useStore((state) => state.quote.exactConfig);

    return (
      <>
        {exactConfig && widthMeasureImg && !equalPanels && (
          <div style={{ paddingLeft: 40 }}>
            <img
              src={widthMeasureImg}
              style={{ width: "auto", height: "auto" }}
              alt=""
            />
          </div>
        )}
      </>
    );
  }
);

export default memo(SwingWidthAccordian);
