import React, { memo, FC } from "react";
import Selection from "./Selection";
import ReusableAccordian from "./ReusableAccordion";
import { useAccordion } from "../store/store";
import { RowLayout } from "../util/accordianStyles";

const QuantityAccordian: FC = () => {
  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[5].expanded);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="quantity"
      heading="Quantity"
      secondaryHeading="Select a quantity"
      required={false}
    >
      <RowLayout>
        <Selection typeRequired={"quantity"} />
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(QuantityAccordian);
