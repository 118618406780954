import React, { FC } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./Styles";
import litesCloudinaryImg from "../../Drawings/LitesLayout";
import { UnitObj } from "../../util/quoteCartStorage";

export function getBifoldKerfImage(screen: string, screenType: string) {
  const cloudinaryURL =
    "https://res.cloudinary.com/ag-millworks/image/upload/kerf/bifold";

  return !screen
    ? `${cloudinaryURL}/HEAD_JAMB_ZOOM_NOSCR.jpg`
    : screenType?.includes("Centor")
    ? `${cloudinaryURL}/HEAD_JAMB_ZOOM_CENTOR.jpg`
    : `${cloudinaryURL}/HEAD_JAMB_ZOOM_SCR.jpg`;
}

export function getSwingKerfImage(
  direction: string,
  screen: string,
  screenType: string
) {
  const cloudinaryURL =
    "https://res.cloudinary.com/ag-millworks/image/upload/kerf/swing";

  if (direction === "Outswing") {
    return !screen
      ? `${cloudinaryURL}/OS_NOSCR.jpg`
      : screenType?.includes("Centor")
      ? `${cloudinaryURL}/OS_CENTOR.jpg`
      : `${cloudinaryURL}/OS_SCR.jpg`;
  }

  return `${cloudinaryURL}/IS.jpg`;
}

export function getSliderKerfImage(screen: string) {
  const cloudinaryURL =
    "https://res.cloudinary.com/ag-millworks/image/upload/kerf/slider";

  return !screen
    ? `${cloudinaryURL}/HEAD_JAMB.jpg`
    : `${cloudinaryURL}/HEAD_JAMB_SCR.jpg`;
}

interface LiteProps {
  item: UnitObj;
}

const Lite: FC<LiteProps> = ({ item }) => {
  const withDividedLites = item.addDL && item.dlLayout ? true : false;
  const withSideliteDividedLites =
    (item.doorType === "Swing" || item.doorType === "Pivot") &&
    item.addDL &&
    item.diffSideliteSdl &&
    item.dlLayoutSidelite
      ? true
      : false;

  const liteImg =
    withDividedLites &&
    litesCloudinaryImg(item.dlLayout || "", item.addDL || "");

  const sideliteImg =
    withSideliteDividedLites &&
    litesCloudinaryImg(item.dlLayoutSidelite || "", item.addDL || "");

  const withSDL =
    withDividedLites && item.addDL === "Add SDL" && item.liteConfig !== '1 3/4"'
      ? true
      : false;

  const withTDL =
    withDividedLites && item.addDL === "Add TDL" && item.liteConfig === '1 3/4"'
      ? true
      : false;

  const withIRail =
    withDividedLites && item.addDL === "Add Intermediate Rail" ? true : false;

  const isCottageStyle =
    ["2W1H", "3W1H", "4W1H", "3 Lite Marginal", "6 Lite Marginal"].includes(
      item.dlLayout || ""
    ) &&
    withDividedLites &&
    item.daylightOpening
      ? true
      : false;

  const withSideliteDL =
    withSideliteDividedLites && item.liteConfig ? true : false;

  const isCottageStyleSidelite =
    ["2W1H", "3W1H", "4W1H"].includes(item.dlLayoutSidelite || "") &&
    withSideliteDividedLites &&
    item.daylightOpening
      ? true
      : false;

  return (
    <View style={[styles.column, { alignItems: "center", paddingTop: 10 }]}>
      {withDividedLites || withSideliteDividedLites ? (
        <>
          {withIRail ? (
            <Text style={[styles.textBold, { fontSize: 12 }]}>
              Intermediate Rail:
            </Text>
          ) : withSDL ? (
            <Text style={[styles.textBold, { fontSize: 12 }]}>
              SDL Configuration:
            </Text>
          ) : withTDL ? (
            <Text style={[styles.textBold, { fontSize: 12 }]}>
              TDL Configuration:
            </Text>
          ) : (
            <Text />
          )}

          {!withSideliteDividedLites ? (
            <View style={[styles.row, { alignItems: "center" }]}>
              {withDividedLites && liteImg ? (
                <Image
                  src={liteImg}
                  style={{
                    height: item.doorType === "Pivot" ? 108 : 130,
                    width: item.doorType === "Pivot" ? 50 : 60,
                  }}
                />
              ) : (
                <Text />
              )}

              <View style={[styles.column, { alignItems: "center" }]}>
                {withSDL || withTDL || withIRail ? (
                  <Text style={styles.text}>
                    {(item.doorType === "Pivot" && item.sideLite === "none") ||
                    (item.doorType === "Swing" &&
                      item.swingType === "Single Swing" &&
                      item.sideLite === "none")
                      ? "Panel"
                      : "All Panels"}{" "}
                    will be:
                  </Text>
                ) : (
                  <Text />
                )}
                {withSDL || withTDL || withIRail ? (
                  <Text style={styles.text}>{item.dlLayout}</Text>
                ) : (
                  <Text />
                )}
                {isCottageStyle ? (
                  <Text style={styles.text}>(DLO {item.daylightOpening}")</Text>
                ) : (
                  <Text />
                )}
              </View>
            </View>
          ) : (
            <View style={[styles.row, { alignItems: "center" }]}>
              <View style={[styles.column, { alignItems: "center" }]}>
                {withDividedLites && liteImg ? (
                  <Image src={liteImg} style={{ height: 110, width: 50 }} />
                ) : (
                  <Text />
                )}

                {withSDL || withTDL || withIRail ? (
                  <>
                    <Text style={styles.text}>Panel(s)</Text>
                    <Text style={styles.text}>
                      {item.dlLayout}{" "}
                      {isCottageStyle ? `(DLO ${item.daylightOpening})` : ""}
                    </Text>
                  </>
                ) : (
                  <Text />
                )}
              </View>

              <View style={[styles.column, { alignItems: "center" }]}>
                {withSideliteDividedLites && sideliteImg ? (
                  <Image src={sideliteImg} style={{ height: 110, width: 50 }} />
                ) : (
                  <Text />
                )}

                {withSideliteDL ? (
                  <>
                    <Text style={styles.text}>Sidelite(s)</Text>
                    <Text style={[styles.text, { paddingLeft: 5 }]}>
                      {item.dlLayoutSidelite}{" "}
                      {isCottageStyleSidelite
                        ? `(DLO ${item.daylightOpening})`
                        : ""}
                    </Text>
                  </>
                ) : (
                  <Text />
                )}
              </View>
            </View>
          )}
        </>
      ) : item.kerf ? (
        <View style={[styles.column, { alignItems: "center" }]}>
          <Text style={[styles.textBold, { fontSize: 12 }]}>Kerf Option:</Text>
          <Image
            src={
              item.doorType.includes("Bi-fold")
                ? getBifoldKerfImage(
                    item.addScreen || "",
                    item.screenType || ""
                  )
                : item.doorType === "Pivot"
                ? getSwingKerfImage("Inswing", "", "")
                : item.doorType === "Swing"
                ? getSwingKerfImage(
                    item.swingDirection || "",
                    item.addScreen || "",
                    item.screenType || ""
                  )
                : getSliderKerfImage(item.addScreen || "")
            }
            style={{ height: 150, width: 120 }}
          />
        </View>
      ) : (
        <Text />
      )}
    </View>
  );
};

export default Lite;
