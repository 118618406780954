import React, { memo } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import {
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { Image } from "cloudinary-react";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { kerfChoices, options } from "./defaults";
import KerfCustomOptions from "./KerfCustomOptions";
import {
  getSwingKerfImage,
  getBifoldKerfImage,
  getFPKerfImage,
  getSliderKerfImage,
  getPivotKerfImage,
} from "./kerfImages";
import { RowLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

interface UpdateObj {
  kerfConfig?: string;
  kerf?: string;
  jamb?: string;
  kerfSetback?: string;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 150,
  height: 20,
}));

const StyledDivSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
}));

const StyledDivLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledDivRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

function KerfAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const doorType = useStore((state) => state.quote.doorType);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const material = useStore((state) => state.quote.material);
  const swingDirection = useStore((state) => state.quote.swingDirection);
  const screenType = useStore((state) => state.quote.screenType);
  const addScreen = useStore((state) => state.quote.addScreen);

  const kerf = useStore((state) => state.quote.kerf);
  const kerfConfig = useStore((state) => state.quote.kerfConfig);
  const kerfSetback = useStore((state) => state.quote.kerfSetback);

  const jamb = useStore((state) => state.quote.jamb);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const accordion = useAccordion((state) => state.expanded[33]);

  const expanded = accordion?.expanded;
  const require = accordion?.require;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (!value) {
      addToQuote({ kerfSetback: "" });
    } else {
      addToQuote({ kerfSetback: value });
      if (findRequired("Kerf Custom Setback"))
        removeRequired("Kerf Custom Setback");
    }
  };

  const showSetbackInput =
    kerf && kerfConfig === "Custom Setback" ? true : false;

  const handleClickKerf = (label: string) => {
    const checked = label === kerf;

    if (checked) {
      let update: UpdateObj = {
        kerfConfig: "",
        kerf: "",
      };

      if (jamb) {
        update["jamb"] = "";
      }

      addToQuote(update);
    } else {
      if (findRequired("Kerf Custom Setback")) {
        removeRequired("Kerf Setback");
        removeRequired("Kerf Custom Setback");
      }

      let update: UpdateObj = {
        kerf: label,
        kerfConfig: 'Standard 3/8" Setback',
      };

      if (kerfSetback) update["kerfSetback"] = "";

      if (jamb) {
        update["jamb"] = "";
      }

      addToQuote(update);
    }
  };

  const handleClickOptions = (label: string) => {
    const checked = label === kerfConfig;

    let update: UpdateObj = { kerfSetback: "" };

    if (checked) {
      update["kerfConfig"] = "";
    } else {
      if (findRequired("Kerf Custom Setback")) {
        removeRequired("Kerf Setback");
      }
      update["kerfConfig"] = label;
    }

    addToQuote(update);
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="kerf"
      heading="Kerf"
      secondaryHeading="Add kerf?"
      colOrRow="col"
      required={require ? true : false}
    >
      <StyledDivRow>
        <StyledDivSpacing>
          <div onClick={() => handleClickKerf(kerfChoices.label)}>
            <Choices
              label={kerfChoices.label}
              variant={kerfChoices.label === kerf ? "filled" : "outlined"}
              checkMark={kerfChoices.label === kerf}
              wide={true}
            />
          </div>
        </StyledDivSpacing>
        {kerf && (
          <>
            <StyledDivLeftDivide>
              {options.map((state) => (
                <div
                  key={state.id}
                  onClick={() => handleClickOptions(state.label)}
                >
                  <Choices
                    label={state.label}
                    disabled={false}
                    variant={state.label === kerfConfig ? "filled" : "outlined"}
                    checkMark={state.label === kerfConfig}
                  />
                </div>
              ))}
            </StyledDivLeftDivide>

            {showSetbackInput && (
              <RowLayout style={{ marginBottom: 50 }}>
                <StyledFormControl>
                  <InputLabel>KF</InputLabel>
                  <Select
                    value={kerfSetback}
                    onChange={handleChange}
                    label="KF"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <KerfCustomOptions
                      door={
                        doorType === "Swing" && swingDirection
                          ? swingDirection
                          : doorType === "Fixed Panel"
                          ? exactConfig
                          : doorType
                      }
                      material={
                        doorType === "Pivot"
                          ? material
                          : doorType === "Swing"
                          ? material
                          : doorType === "Fixed Panel"
                          ? material
                          : null
                      }
                    />
                  </Select>
                </StyledFormControl>
              </RowLayout>
            )}
          </>
        )}
      </StyledDivRow>

      {kerf && (
        <RowLayout>
          <div>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId={
                doorType === "Fixed Panel"
                  ? getFPKerfImage(material, exactConfig)
                  : doorType.includes("Bi-fold")
                  ? getBifoldKerfImage(
                      addScreen || "",
                      screenType || "",
                      material
                    )
                  : doorType === "Slider"
                  ? getSliderKerfImage(addScreen || "", material)
                  : doorType === "Pivot"
                  ? getPivotKerfImage(material)
                  : getSwingKerfImage(
                      swingDirection || "",
                      addScreen || "",
                      screenType || "",
                      material
                    )
              }
            />
          </div>
        </RowLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(KerfAccordian);
