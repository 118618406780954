import React, { useState, useEffect, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import getModalStyle from "../../../../util/getModalStyle";
import { StyledModalDiv } from "../../../../util/accordianStyles";

interface ConfirmationModalProps {
  handleClose: () => void
  loading?: boolean
  open: boolean
  mutation: any
  showTab: string
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  handleClose,
  loading,
  open,
  mutation,
  showTab,
}) => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [status, setStatus] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => setStatus(event.target.value);

  useEffect(() => {
    setStatus("");
  }, [open]);

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3 id="simple-modal-description">Change Status</h3>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          paddingBottom: 20,
        }}
      >
        <p>Select a New Status:</p>

        <select onChange={handleChange} value={status} style={{ padding: 5 }}>
          <option value="" />
          {showTab === "Service Repair" ? (
            <>
              <option value="Submitted">Submitted</option>
              <option value="Scheduled">Scheduled</option>
              <option value="Completed">Completed</option>
            </>
          ) : (
            <>
              {role === "Admin" && showTab === "Orders" && (
                <option value="Quote">Quote</option>
              )}
              <option value="Pending Review">Pending Review</option>
              {showTab === "Orders" && (
                <>
                  <option value="Awaiting Deposit">Awaiting Deposit</option>
                  <option value="Order">Order / Deposit Received</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Completed">Completed</option>
                </>
              )}
            </>
          )}
        </select>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        {mutation.isLoading || loading ? (
          <Button variant="contained" color="primary">
            <CircularProgress color="inherit" size={30} />
          </Button>
        ) : (
          <Button
            onClick={() => status && mutation.mutate(status)}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        )}
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ConfirmationModal;
