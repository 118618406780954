import React, { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { calcWithAdminCharge } from "../../../../util/calcWithAdminCharge";
import StatusTooltip from "./StatusTooltip";
import QuotePdf from "./pdf/QuotePdf";
import OrderPdf from "./pdf/OrderPdf";
import { StyledCheckbox } from "./StyledCheckbox";
import { TableCellsProps } from "../RoleTableCells";
import { styled } from "@mui/material/styles";

const StyledViewQuote = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
});

const StyledTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 200,
});

const OutsideSalesCells: FC<TableCellsProps> = ({
  row,
  tab,
  handleViewClick,
  viewPdfId,
  dealer,
  handleViewPdf,
  numSelected,
  hoverId,
  handleCheck,
  isItemSelected,
}) => {
  const isOrder =
    row.status !== "Quote" && row.status !== "Archived" ? true : false;

  return (
    <>
      {tab === "Insights" ? (
        <TableCell />
      ) : (
        (row.status === "Quote" || row.status === "Archived") && (
          <TableCell padding="checkbox">
            {(numSelected && numSelected > 0) || hoverId === row._id ? (
              <StyledCheckbox
                color="primary"
                onClick={(event: React.MouseEvent<any>) =>
                  handleCheck(event, row)
                }
                checked={isItemSelected}
              />
            ) : (
              <div style={{ width: 16, height: 16 }} />
            )}
          </TableCell>
        )
      )}

      <TableCell onClick={() => handleViewClick && handleViewClick(row)} padding="checkbox">
        <StyledViewQuote variant="caption">View</StyledViewQuote>
      </TableCell>

      {tab === "Quotes" && (
        <QuotePdf
          row={row}
          viewPdfId={viewPdfId || ""}
          dealer={dealer || null}
          handleViewPdf={handleViewPdf || null}
        />
      )}

      {tab === "Insights" ? (
        <>
          <TableCell align="center" scope="row" padding="none">
            {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}
          </TableCell>

          <TableCell align="center" scope="row" padding="none">
            {row.dateOrdered
              ? new Date(row.dateOrdered).toLocaleDateString("en-US")
              : "N/A"}
          </TableCell>
        </>
      ) : tab === "Quotes" ? (
        <>
          <TableCell align="center" scope="row" padding="none">
            {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}
          </TableCell>
          <TableCell align="center" scope="row" padding="none">
            {row.updatedAt
              ? new Date(row.updatedAt).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "N/A"}
          </TableCell>
        </>
      ) : (
        <TableCell align="center" scope="row" padding="none">
          {row.dateOrdered
            ? new Date(row.dateOrdered).toLocaleDateString("en-US")
            : "N/A"}
        </TableCell>
      )}

      {tab === "Insights" && isOrder ? (
        <OrderPdf
          handleViewPdf={handleViewPdf || null}
          row={row}
          dealer={dealer || null}
          viewPdfId={viewPdfId || ""}
        />
      ) : tab === "Insights" && !isOrder ? (
        <QuotePdf
          row={row}
          viewPdfId={viewPdfId || ""}
          dealer={dealer || null}
          handleViewPdf={handleViewPdf || null}
        />
      ) : (
        tab === "Orders" && (
          <OrderPdf
            handleViewPdf={handleViewPdf || null}
            row={row}
            dealer={dealer || null}
            viewPdfId={viewPdfId || ""}
          />
        )
      )}

      <StyledTableCell align="center">
        {row.customerName && row.customerName.trim()
          ? row.customerName
          : "None"}
      </StyledTableCell>
      <TableCell align="center">{row.quoteNumber}</TableCell>

      <TableCell align="center">{row.dealer}</TableCell>

      <TableCell align="center">{row.salesperson}</TableCell>
      <TableCell align="center">{row.poNumber || "None"}</TableCell>

      <TableCell align="center">
        {row.grandTotal && !row.adminCharge
          ? row.grandTotal
          : row.grandTotal && row.adminCharge
          ? calcWithAdminCharge(row.grandTotal, row.adminCharge)
          : "N/A"}
      </TableCell>

      <TableCell align="center">{row.totalUnits || "None"}</TableCell>

      {tab === "Insights" && (
        <TableCell align="center">
          {row.daysToClose ? row.daysToClose + " days" : "N/A"}
        </TableCell>
      )}

      <StatusTooltip role="Outside Sales" row={row} tab={tab} />
    </>
  );
};

export default OutsideSalesCells;
