import React from "react";
import { Button } from "@mui/material";
import api from "../../../api/api";
import { useQueryClient } from "react-query";
import { styled } from "@mui/material/styles";

const StyledColDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "center",
  padding: 40,
});

const StyledImg = styled("img")({
  width: 150,
  height: 80,
  marginTop: 30,
});

interface Result {
  event: string;
  info: {
    secure_url: string;
  };
}

interface Data {
  email: string;
  dealerLogo?: string;
}

interface UploadWidgetProps {
  data: Data;
  createAcct: boolean;
  setUser?: React.Dispatch<React.SetStateAction<any>>;
}

async function uploadResult(
  result: Result,
  isCreateAcct: boolean
): Promise<string | boolean> {
  if (result.event === "success") {
    if (!isCreateAcct) {
      const res = await api.put("/api/addDealerLogoURL", {
        logoURL: result.info.secure_url,
      });
      if (res.data) return true;
      return false;
    } else {
      return result.info.secure_url;
    }
  }
  return false;
}

const getSignature = async (params_to_sign: any) => {
  return await api.post(`/api/cloudinaryGetSign`, { sign: params_to_sign });
};

const generateSignature = async (
  callback: (signature: any) => void,
  params_to_sign: any
) => {
  try {
    const signature = await getSignature(params_to_sign);
    callback(signature.data);
  } catch (err) {
    console.log(err);
  }
};

const UploadWidget: React.FC<UploadWidgetProps> = ({
  data,
  createAcct,
  setUser,
}) => {
  const queryClient = useQueryClient();

  const checkUploadResult = async (result: Result) => {
    const uResult = await uploadResult(result, createAcct || false);

    if (uResult && !createAcct)
      return queryClient.invalidateQueries("UserAccount");

    if (uResult && createAcct && setUser && typeof setUser === "function") {
      setUser((prev: any) => ({ ...prev, dealerLogo: uResult }));
    }
  };

  let widget = (window as any).cloudinary.createUploadWidget(
    {
      folder: "DealerLogos",
      uploadPreset: "pbeo7xvi",
      cloudName: "ag-millworks",
      sources: ["local", "url", "image_search"],
      apiKey: "762425355781477",
      clientAllowedFormats: ["webp", "jpg", "png", "jpeg"],
      maxFileSize: 2500000,
      publicId: data.email,
      uploadSignature: generateSignature,
    },
    (error: any, result: Result) => {
      if (error) console.log(error);
      checkUploadResult(result);
    }
  );

  const showWidget = () => {
    if (data.email) widget.open();
  };

  return (
    <StyledColDiv>
      <Button variant="contained" color="primary" onClick={showWidget}>
        Upload Logo
      </Button>

      {data.dealerLogo && <StyledImg src={data.dealerLogo} alt="" />}
    </StyledColDiv>
  );
};

export default UploadWidget;
