import React, { useState, FC } from "react";
import { Link } from "react-scroll";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import { useRequiredMsgs } from "../store/requiredMsgs";
import { styled } from "@mui/material/styles";

const StyledPointerDiv = styled("div")({
  position: "fixed",
  right: 0,
  top: 200,
  backgroundColor: "#4b4b4b",
  zIndex: 1000,
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledLinkDiv = styled("div")({
  width: 0,
  height: "100%",
  borderTop: "22px solid transparent",
  borderBottom: "22px solid transparent",
  borderRight: "20px solid #4b4b4b",
  position: "absolute",
  left: -19,
});

const StyledErrDiv = styled("div")({
  position: "fixed",
  bottom: 140,
  right: 10,
  zIndex: 50,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const StyledTooltipDiv = styled("div")({
  backgroundColor: "#f5f5f9",
  color: "rgba(0, 0, 0, 0.87)",
  maxWidth: 220,
  fontSize: 12,
  border: "1px solid #dadde9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

interface ErrIconFixedProps {
  requiredMsgs: string[]
}

export const ErrIconFixed: FC<ErrIconFixedProps> = ({ requiredMsgs }) => {
  return (
    <StyledErrDiv>
      <StyledTooltipDiv>
        <Typography style={{ color: "red", fontWeight: "bold" }}>
          Selections Required:
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {requiredMsgs.map((msg, index) => (
            <Typography key={index} style={{ color: "red" }}>
              - {msg}
            </Typography>
          ))}
        </div>
      </StyledTooltipDiv>

      <IconButton size="large">
        <ErrorRoundedIcon style={{ color: "red", fontSize: 60 }} />
      </IconButton>
    </StyledErrDiv>
  );
};

const RequiredScroller: FC<ErrIconFixedProps> = ({ requiredMsgs }) => {
  const [previousScroll, setPreviousScroll] = useState("");

  const handleSetActive = (to: string) => setPreviousScroll(to);

  return (
    <StyledPointerDiv>
      <StyledLinkDiv />
      <Link
        style={{ padding: 10, lineHeight: 2.85, color: "white" }}
        activeClass="active"
        to={requiredMsgs[requiredMsgs.indexOf(previousScroll) + 1]}
        spy
        smooth
        isDynamic
        offset={-200}
        duration={500}
        onSetActive={handleSetActive}
      >
        Scroll To Required
      </Link>
    </StyledPointerDiv>
  );
};

const RequiredMsgLayout = () => {
  const requireds = useRequiredMsgs((state) => state.required);

  return (
    <>
      {requireds && requireds.length ? (
        <>
          <ErrIconFixed requiredMsgs={requireds} />
          <RequiredScroller requiredMsgs={requireds} />
        </>
      ) : null}
    </>
  );
};

export default RequiredMsgLayout;
