import axios from "axios";
import { JwtPayload } from "jsonwebtoken";
import {jwtDecode} from 'jwt-decode';

export function checkForExpiredToken(tokenArg?: string){
    const token = tokenArg || sessionStorage.getItem('auth-token');
    if(!token) return false;
    
    const decode = jwtDecode<JwtPayload>(token);
    const expirationTime = 
      decode && 
      decode.exp 
        ? decode.exp * 1000 
        : 0

    if(Date.now() >= expirationTime){
        localStorage.clear();
        localStorage.setItem("session", JSON.stringify("expired"))
        return false;
    }

    return true
};

export const AttemptSignInOnMount = async () => {
  let token = sessionStorage.getItem("auth-token");

  if (token === null) {
    sessionStorage.setItem("auth-token", "");
    token = "";
    console.log("no token found");
    return false;
  } else {
    const res = await axios.post(
      "/tokenIsValid", 
      null, 
      { headers: { "x-auth-token": token } }
    );

    if(res.data){
      return true
    }

    return false
  }
};

export const SignIn = async (email: string, password: string) => {
  try {
    const dateString = new Date().toLocaleString();
    const dateFormat = dateString.replace(",", " at");

    const response = await axios.post(
      "/signin", 
      { email, password, date: dateFormat }
    );

    const { token, factor, id, region, dealer, dealerRep, role } =
      response.data;

    const userEmail = response.data.email;

    sessionStorage.setItem("user", JSON.stringify(id));
    sessionStorage.setItem("role", JSON.stringify(role));
    sessionStorage.setItem("email", JSON.stringify(userEmail));
    sessionStorage.setItem("auth-token", token);
    !response.data.hideTips &&
      sessionStorage.setItem("showTips", JSON.stringify(true));
    localStorage.removeItem("session");

    return { factor, id, region, dealer, dealerRep, userEmail, role, token };
  } catch (err) {
    console.log("issue with sign in");
    return { error: "User not found" };
  }
};
