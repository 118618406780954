import React, { useState, FC } from "react";
import {
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { postDeliveryMarkup } from "../../../api/SettingsApi";
import { Done as DoneIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledColDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 20,
  margin: 20,
});

const StyledAcctText = styled(Typography)({
  textDecoration: "underline",
  paddingBottom: 20,
  fontWeight: "bold",
  alignSelf: "center",
});

const StyledCircularProgress = styled(CircularProgress)({
  color: green[500],
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
});

interface DeliveryMarkupProps {
  deliveryMarkup: string 
  changeDeliveryMarkup: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const DeliveryMarkup: FC<DeliveryMarkupProps> = ({ deliveryMarkup, changeDeliveryMarkup }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const handleButtonClick = async () => {
    setLoading(true);
    setTimeout(async () => {
      const bool = deliveryMarkup === "Yes" ? true : false;
      const res = await postDeliveryMarkup(bool);

      if (res.data === "Settings Updated") {
        setLoading(false);
        return setSuccess(true);
      }

      return setLoading(false);
    }, 800);
  };

  return (
    <StyledColDiv style={{ height: 350, justifyContent: "space-around" }}>
      <StyledAcctText variant="h4">Account Settings</StyledAcctText>

      <Typography>
        Do you want to add mark-up/margin to jobsite delivery charge?
      </Typography>

      <FormControl style={{ marginTop: 10 }}>
        <RadioGroup value={deliveryMarkup} onChange={changeDeliveryMarkup}>
          <FormControlLabel value="No" control={<Radio />} label="No" />
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>

      <Box sx={{ m: 1, position: "relative" }}>
        <Button
          variant="contained"
          sx={buttonSx}
          disabled={loading}
          onClick={handleButtonClick}
          startIcon={success && <DoneIcon />}
        >
          Apply
        </Button>
        {loading && <StyledCircularProgress size={24} />}
      </Box>
    </StyledColDiv>
  );
};

export default DeliveryMarkup;
