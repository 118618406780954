export var savedQuote: UnitObj = {
  doorType: "",
  price: "",
  unitSize: "",
  unitSizeFraction: "",
  configuration: "",
  exactConfig: "",
  quantity: 1,
  woodSpecies: "",
  material: "",
  height: "",
  heightFraction: "",
  exteriorCladColor: "",
  jamb: "",
  paintAndPrime: "",
  prime: "",
  headTrackColor: "",
  glassType: "LoE 366",
  i89coating: "",
  stilesAndRails: "",
  lockRail: "",
  stileCategory: "",
  shootBoltColor: "",
  weatherStripColor: "",
  spacerColor: "",
  stain: "",
  stickingStops: "",
  stickingStopsExterior: "",
  stickingStopsInterior: "",
  hingeColor: "",
  flashingFin: "",
  flashingFinConfig: "",
  finSetback: "",
  liteConfig: "",
  numberOfLites: 0,
  numberOfLitesSidelite: 0,
  exactConfigImg: "",
  exactConfigFull: "",
  withKey: "",
  handleStyle: "",
  handleColor: "",
  handleHeight: "",
  sillStyle: "",
  sillColor: "",
  swingDirection: "",
  screenColor: "",
  addScreen: "",
  screenLayout: "",
  screenPocket: "",
  location: "",
  customPaintOrStain: "",
  stainColor: "",
  paintColor: "",
  notes: "",
  ufactor: "",
  shgc: "",
  roWidth: "",
  roHeight: "",
  panelWidth: "",
  panelHeight: "",
  panelThickness: "",
  frameWeight: "",
  perPanelWeight: "",
  glassWidth: "",
  glassWidthDD: "",
  glassHeight: "",
  breatherTubes: "None",
  numberOfPanels: "",
  netFrameWidthWithPocket: "",
  woodAbbrev: "",
  title24Comp: false,
  DLW: "",
  customerUnitPrice: "",
  CUPforEach: "",
  DUPforEach: "",
  dlLayout: "",
  dlLayoutSidelite: "",
  daylightOpening: "",
  daylightOpeningSidelite: "",
  addDL: "",
  swingType: "",
  sideLite: "none",
  sideLiteWidth: "",
  swingPanelWidth: "",
  swingPanelFraction: "",
  primaryDoor: "",
  sideLiteDLO: "",
  unfactoredPrice: 0,
  withHandle: "",
  screenType: "",
  sliderPocket: "",
  addSlidingScreen: "",
  sliderPocketSize: "",
  automation: "",
  pocketingSliderNetWidth: "",
  equalPanels: false,
  diffSideliteSdl: null,
  customCladColor: "",
  kerf: "",
  kerfConfig: "",
  kerfSetback: "",
  cornerType: "",
  cornerWidthRight: "",
  cornerWidthFractionRight: "",
  cornerConfigRight: "",
  customPaintColor: "",
  sillFinish: "",
  shadeMeshColor: ""
};

export interface AddonObj {
  addon: string;
  price: number;
  _id?: string;
}

export interface AccordionObj {
  _id?: string;
  name: string;
  expanded?: boolean;
  require?: boolean;
}

export interface RequiredObj {
  type: string;
  _id?: string;
}

export interface UnitObj {
  _id?: string;
  shadeMeshColor?: string;
  localStorageState?: string;
  itemizedAddonsUnfactored?: AddonObj[];
  itemizedAddons?: AddonObj[];
  orderIndex?: number;
  doorType: string;
  quantity: number;
  material: string;
  price: string;
  unitSize: string;
  unitSizeFraction?: string;
  configuration?: string;
  exactConfig: string;
  height: string;
  heightFraction?: string;
  woodSpecies: string;
  exteriorCladColor?: string;
  jamb?: string;
  paintAndPrime?: string;
  prime?: string;
  headTrackColor?: string;
  glassType?: string;
  i89coating?: string;
  stilesAndRails?: string;
  lockRail?: string;
  stileCategory?: string;
  shootBoltColor?: string;
  weatherStripColor?: string;
  spacerColor?: string;
  stain?: string;
  stickingStops?: string;
  stickingStopsExterior?: string;
  stickingStopsInterior?: string;
  hingeColor?: string;
  pivotHardware?: string;
  pivotAction?: string;
  pivotBackPlate?: string;
  pivotBackPlateColor?: string;
  pivotMagnet?: string;
  pivotMagnetColor?: string;
  pivotPoint?: string;
  flashingFin?: string;
  flashingFinConfig?: string;
  finSetback?: string;
  kerf?: string;
  kerfConfig?: string;
  kerfSetback?: string;
  liteConfig?: string;
  numberOfLites?: number;
  numberOfLitesSidelite?: number;
  exactConfigImg?: string;
  exactConfigFull?: string;
  withKey?: string;
  handleStyle?: string;
  handleColor?: string;
  handleHeight?: string;
  sillStyle?: string;
  sillColor?: string;
  swingDirection?: string;
  screenColor?: string;
  addScreen?: string;
  screenLayout?: string;
  screenPocket?: string;
  location?: string;
  customPaintOrStain?: string;
  stainColor?: string;
  paintColor?: string;
  notes?: string;
  ufactor?: string;
  shgc?: string;
  cpd?: string;
  roWidth?: string;
  roHeight?: string;
  panelWidth?: string;
  panelHeight?: string;
  panelThickness?: string;
  frameWeight?: string;
  perPanelWeight?: string;
  glassWidth?: string;
  glassWidthDD?: string;
  glassHeight?: string;
  breatherTubes?: string;
  numberOfPanels?: string;
  netFrameWidthWithPocket?: string;
  woodAbbrev?: string;
  title24Comp?: boolean;
  DLW?: string;
  customerUnitPrice?: string;
  CUPforEach?: string;
  DUPforEach?: string;
  dlLayout?: string;
  daylightOpening?: string;
  dlLayoutSidelite?: string;
  daylightOpeningSidelite?: string;
  addDL?: string;
  swingType?: string;
  sideLite?: string;
  sideLiteWidth?: string;
  swingPanelWidth?: string;
  swingPanelFraction?: string;
  primaryDoor?: string;
  sideLiteDLO?: string;
  withHandle?: string;
  unfactoredPrice?: number;
  screenType?: string;
  sliderPocket?: string;
  addSlidingScreen?: string;
  sliderPocketSize?: string;
  automation?: string;
  dropseal?: string;
  dropsealCode?: string;
  equalPanels?: boolean;
  diffSideliteSdl?: boolean | null;
  sliderPanelSqft?: number;
  pocketingSliderNetWidth?: string;
  customCladColor?: string;
  customPaintColor?: string;
  customStainColor?: string;
  sillFinish?: string;
  customGlass?: boolean;
  glassAddon?: string;
  glassAddonSide?: string;
  ignoreUnitCheck?: boolean;
  kd?: boolean;
  cornerType?: string;
  cornerFrameWidthRight?: number;
  cornerFrameWidthLeft?: number;
  cornerWidthRight?: string;
  cornerWidthFractionRight?: string;
  cornerConfigRight?: string;
  panelWidthRightCorner?: string;
  glassWidthRightCorner?: string;
  glassWidthDDRightCorner?: string;
  roWidthRightCorner?: string;
  exactConfigImgRightCorner?: string;
  exactConfigFullRightCorner?: string;
  DLWRightCorner?: string;
  netFrameWidthWithPocketRightCorner?: string;
  perPanelWeightRightCorner?: string;
  numberOfPanelsRightCorner?: string;
  numberOfPanelsLeftCorner?: string;
  required?: RequiredObj[];
  accordionControl?: AccordionObj[];
}
