const backPlate = [
    { id: 1, type: "pivotBackPlate", label: "Square Back Plate" },
    { id: 2, type: "pivotBackPlate", label: "Round Back Plate" }
]

const backPlateColors = [
    { id: 1, type: "pivotBackPlateColor", label: "Black" },
    { id: 2, type: "pivotBackPlateColor", label: "Stainless Steel" }
]

export { backPlate, backPlateColors }