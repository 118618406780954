import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Typography, CircularProgress } from "@mui/material";
import getModalStyle from "../../../../util/getModalStyle";
import { StyledModalDiv } from "../../../../util/accordianStyles";

interface DuplicateQuoteModalProps {
  handleClose: () => void
  open: boolean
  duplicateQuote: (quoteNumber: number) => void
  quoteNumber: number
  error: boolean
  loading: boolean
}

const DuplicateQuoteModal: FC<DuplicateQuoteModalProps> = ({
  handleClose,
  open,
  duplicateQuote,
  quoteNumber,
  error,
  loading,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", paddingBottom: 40 }}
      >
        Would you like to duplicate Quote PC-{quoteNumber}?
      </Typography>

      {error && (
        <Typography style={{ color: "red", fontWeight: "bold", padding: 10 }}>
          Could not duplicate Quote
        </Typography>
      )}

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} color="inherit" variant="contained">
          Cancel
        </Button>

        {!loading ? (
          <Button
            onClick={() => duplicateQuote(quoteNumber)}
            variant="contained"
            color="primary"
          >
            <FileCopyIcon style={{ fontSize: 14 }} />
            Duplicate
          </Button>
        ) : (
          <Button variant="contained" color="primary">
            <CircularProgress style={{ color: "white" }} />
          </Button>
        )}
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default DuplicateQuoteModal;
