import React, { FC } from "react";
import Grow from "@mui/material/Grow";
import { Typography, Paper } from "@mui/material";
import {
  taggedSwingConfigs,
  equalPanelsSwing,
} from "../../taggedImages/taggedSwingConfigImgs";
import {
  taggedPivotConfigs,
  equalPanelsPivot,
} from "../../taggedImages/taggedPivotConfigImgs";
import { useStore } from "../../store/store";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  "&:hover": { cursor: "pointer" },
  padding: 10,
  margin: 10,
});


interface SwingConfigsProps {
  setWidthMeasureImg: (img: string | undefined) => void;
  swingDir: string;
  sType: string;
  sLite: string;
  isPivot?: boolean;
}

interface SwingImgConfigObj {
  swingDirection: string;
  SwingType: string;
  Sidelites: string;
  label: string;
  img1: string;
  img2: string;
  img3?: string;
  img4?: string;
  img1Lever?: string;
  img2Lever?: string;
  img3Lever?: string;
  img4Lever?: string;
  img1M?: string;
  img2M?: string;
  img3M?: string;
  img4M?: string;
}

const SwingConfigs: FC<SwingConfigsProps> = ({
  setWidthMeasureImg,
  swingDir,
  sType,
  sLite,
  isPivot,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const exactConfig = useStore((state) => state.quote.exactConfig);
  const exactConfigImg = useStore((state) => state.quote.exactConfigImg);

  const handleStyle = useStore((state) => state.quote.handleStyle);

  const isSingleWithSidelite =
    sType === "Single Swing" && sLite === "Add Single Sidelite";
  const isLock = exactConfigImg?.includes("LOCK");

  const hinge = exactConfig?.slice(-2);

  const selectedSide =
    hinge === "LH" && !isLock && !isSingleWithSidelite
      ? "Left Hinged"
      : hinge === "RH" && !isLock && !isSingleWithSidelite
      ? "Right Hinged"
      : hinge === "LH" && !isLock && isSingleWithSidelite
      ? "Left Hinged Handle Opposite Sidelite"
      : hinge === "RH" && !isLock && isSingleWithSidelite
      ? "Right Hinged Handle Opposite Sidelite"
      : hinge === "LH" && isLock && isSingleWithSidelite
      ? "Left Hinged Handle Facing Sidelite"
      : hinge === "RH" && isLock && isSingleWithSidelite
      ? "Right Hinged Handle Facing Sidelite"
      : "";

  const equalPanels = useStore((state) => state.quote.equalPanels);

  const configImgs =
    isPivot && equalPanels
      ? equalPanelsPivot
      : isPivot
      ? taggedPivotConfigs
      : equalPanels
      ? equalPanelsSwing
      : taggedSwingConfigs;

  const swingConfigImgs: SwingImgConfigObj | undefined = configImgs.find(
    (item) =>
      item.swingDirection === swingDir &&
      item.SwingType === sType &&
      item.Sidelites === sLite
  );

  const handleClick = (side: string) => {
    if(!swingConfigImgs) return;

    if (side === "left") {
      const configIMG =
        isPivot && handleStyle === "Dallas Lever"
          ? swingConfigImgs?.img1Lever
          : swingConfigImgs?.img1;

      addToQuote({
        exactConfig: `${swingConfigImgs.label} LH`,
        exactConfigImg: configIMG,
      });

      setWidthMeasureImg(swingConfigImgs.img1M);
    }
    if (side === "right") {
      const configIMG =
        isPivot && handleStyle === "Dallas Lever"
          ? swingConfigImgs?.img2Lever
          : swingConfigImgs?.img2;

      addToQuote({
        exactConfig: `${swingConfigImgs.label} RH`,
        exactConfigImg: configIMG,
      });

      setWidthMeasureImg(swingConfigImgs.img2M);
    }
  };

  const handleClickSingleSidelite = (side: string) => {
    if(!swingConfigImgs) return;

    if (side === "left") {
      const configIMG =
        isPivot && handleStyle === "Dallas Lever"
          ? swingConfigImgs?.img3Lever
          : swingConfigImgs?.img3;

      addToQuote({
        exactConfig: `${swingConfigImgs.label} LH`,
        exactConfigImg: configIMG,
      });

      setWidthMeasureImg(swingConfigImgs.img3M);
    }
    if (side === "right") {
      const configIMG =
        isPivot && handleStyle === "Dallas Lever"
          ? swingConfigImgs?.img4Lever
          : swingConfigImgs?.img4;

      addToQuote({
        exactConfig: `${swingConfigImgs.label} RH`,
        exactConfigImg: configIMG,
      });

      setWidthMeasureImg(swingConfigImgs.img4M);
    }
  };

  return (
    <ColLayout style={{ paddingLeft: 5, paddingRight: 5 }}>
      {sType ? (
        <>
          <ColLayout style={{ width: "100%" }}>
            {selectedSide ? (
              <Typography variant="h5" style={{ color: "#57d5f2" }}>
                You Selected: {selectedSide}
              </Typography>
            ) : (
              <Typography style={{ fontWeight: "bold" }} variant="h6">
                Select a Configuration Option
              </Typography>
            )}
            <Typography variant="caption">* As Viewed from Exterior</Typography>
          </ColLayout>
          {swingConfigImgs?.img4 && (
            <Typography style={{ fontWeight: "bold" }} variant="h6">
              Jamb Leg Side
            </Typography>
          )}
          <RowLayout>
            <Grow in={true}>
              <ColLayout>
                <StyledPaper
                  onClick={() => handleClick("left")}
                  elevation={3}
                  style={{
                    marginRight: 40,
                    border:
                      (selectedSide === "Left Hinged" ||
                        selectedSide ===
                          "Left Hinged Handle Opposite Sidelite") ?
                      "4px solid #57d5f2" : "",
                  }}
                >
                  <img
                    style={{ height: "auto", width: 250 }}
                    src={
                      isPivot && handleStyle === "Dallas Lever"
                        ? swingConfigImgs?.img1Lever
                        : swingConfigImgs?.img1
                    }
                    alt="swing configuration"
                  />
                </StyledPaper>
                <Typography style={{ padding: 10 }}>
                  {sType === "Pair Swing" && "Primary"} Left Hinged
                </Typography>
              </ColLayout>
            </Grow>
            {swingConfigImgs?.img2 && (
              <Grow in={true}>
                <ColLayout>
                  <StyledPaper
                    onClick={() => handleClick("right")}
                    elevation={3}
                    style={{
                      border:
                        (selectedSide === "Right Hinged" ||
                          selectedSide ===
                            "Right Hinged Handle Opposite Sidelite") ?
                        "4px solid #57d5f2" : "",
                    }}
                  >
                    <img
                      style={{ height: "auto", width: 250 }}
                      src={
                        isPivot && handleStyle === "Dallas Lever"
                          ? swingConfigImgs?.img2Lever
                          : swingConfigImgs?.img2
                      }
                      alt="swing 2 configuration"
                    />
                  </StyledPaper>
                  <Typography style={{ padding: 10 }}>
                    {sType === "Pair Swing" && "Primary"} Right Hinged
                  </Typography>
                </ColLayout>
              </Grow>
            )}
          </RowLayout>
          {swingConfigImgs?.img4 && (
            <>
              <Typography style={{ fontWeight: "bold" }} variant="h6">
                Sidelite Side
              </Typography>

              <RowLayout
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                {swingConfigImgs.img3 && (
                  <Grow in={true}>
                    <ColLayout>
                      <StyledPaper
                        onClick={() => handleClickSingleSidelite("left")}
                        elevation={3}
                        style={{
                          border:
                            selectedSide ===
                              "Left Hinged Handle Facing Sidelite" ?
                            "4px solid #57d5f2" : "",
                        }}
                      >
                        <img
                          style={{ height: "auto", width: 250 }}
                          src={
                            isPivot && handleStyle === "Dallas Lever"
                              ? swingConfigImgs?.img3Lever
                              : swingConfigImgs?.img3
                          }
                          alt="swing 3 configuration"
                        />
                      </StyledPaper>
                      <Typography style={{ padding: 10 }}>
                        {sType === "Pair Swing" && "Primary"} Left Hinged
                      </Typography>
                    </ColLayout>
                  </Grow>
                )}
                {swingConfigImgs?.img4 && (
                  <Grow in={true}>
                    <ColLayout>
                      <StyledPaper
                        onClick={() => handleClickSingleSidelite("right")}
                        elevation={3}
                        style={{
                          border:
                            selectedSide ===
                              "Right Hinged Handle Facing Sidelite" ?
                            "4px solid #57d5f2" : "",
                        }}
                      >
                        <img
                          style={{ height: "auto", width: 250 }}
                          src={
                            isPivot && handleStyle === "Dallas Lever"
                              ? swingConfigImgs?.img4Lever
                              : swingConfigImgs?.img4
                          }
                          alt="swing 4 configuration"
                        />
                      </StyledPaper>
                      <Typography style={{ padding: 10 }}>
                        {sType === "Pair Swing" && "Primary"} Right Hinged
                      </Typography>
                    </ColLayout>
                  </Grow>
                )}
              </RowLayout>
            </>
          )}
        </>
      ) : (
        <Typography variant="h5">Select a Swing Type</Typography>
      )}
    </ColLayout>
  );
};

export default SwingConfigs;
