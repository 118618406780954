import React from "react";
import { Select, Typography, MenuItem, SelectChangeEvent } from "@mui/material";
import { useStore } from "../store/store";
import { useRequiredMsgs } from "../store/requiredMsgs";
import {styled} from "@mui/material/styles"
import { RowLayout } from "../util/accordianStyles";

const Layout = styled("div")(({theme}) => ({
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
  marginBottom: 10,
  marginTop: 20,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: "100%"
}));

const SillFinish: React.FC = () => {
  const sillFinish = useStore((state) => state.quote.sillFinish);
  const addToQuote = useStore((state) => state.addToQuote);

  const findRequired = useRequiredMsgs(state => state.findRequired);
  const removeRequired = useRequiredMsgs(state => state.removeRequired);

  const handleChange = (e: SelectChangeEvent<string>) => {
    addToQuote({ sillFinish: e.target.value })
    if(findRequired('Sill Dam Finish')) removeRequired("Sill Dam Finish");
  }

  return (
    <Layout>
      <Typography style={{ margin: "auto" }} variant="subtitle1">
        Sill Dam Finish
        <span style={{ color: "red", fontSize: 20 }}>*</span>
      </Typography>
      <RowLayout>
        <Select
          value={sillFinish}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
          label="finish"
          variant="outlined"
          margin="dense"
          size="small"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="No Finish">No Finish</MenuItem>
          <MenuItem value="Match Interor Finish">
            Match Interior Finish
          </MenuItem>
        </Select>
      </RowLayout>
    </Layout>
  );
};

export default SillFinish;
