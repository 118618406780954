import React, { FC } from "react";
import { TableCell, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import GenerateDealer from "../../../../../quotePDF/GenerateDealer";
import { HtmlTooltip } from "../StatusTooltip";
import { QuoteObj } from "../../../../../GlobalTypes";
import { DealerState } from "../../QuotesTable";
import { CutsheetDataObj } from "../../../../../GlobalTypes";

interface ProductionPdfProps {
  row: QuoteObj
  handleViewPdf: ((id: string, userId: string, quoteStatus: string) => void) | null;
  dealer: DealerState | null
  viewPdfId: string
  loadingCutsheet: boolean
  cutsheetData: CutsheetDataObj[]
}

const ProductionPdf: FC<ProductionPdfProps> = ({
  row,
  handleViewPdf,
  dealer,
  viewPdfId,
  loadingCutsheet,
  cutsheetData,
}) => {
  return (
    <TableCell padding="checkbox" align="center" style={{ width: 100 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {row.units && row.units.length > 0 && viewPdfId !== `${row._id}prod` ? (
          <HtmlTooltip title={"Download Production PDF"}>
            <IconButton
              onClick={() => handleViewPdf && handleViewPdf(row._id, row.userId, "prod")}
              aria-label="download"
              size="large">
              <GetAppIcon color={"primary"} />
            </IconButton>
          </HtmlTooltip>
        ) : row.units && row.units.length > 0 &&
          viewPdfId === `${row._id}prod` &&
          loadingCutsheet ? (
          <div>Fetching...</div>
        ) : row.units && row.units.length > 0 &&
          viewPdfId === `${row._id}prod` &&
          !loadingCutsheet ? (
          <GenerateDealer
            data={row}
            series={row.seriesOverride || "Default"}
            isTable={true}
            isOrder={false}
            codOrTerms={dealer?.terms || ""}
            poNumber={row.poNumber || ""}
            region={dealer?.region || ""}
            dealerTax={dealer?.dealerTax || 0}
            factor={row.factor}
            grandTotal={row.grandTotal || "N/A"}
            suggestedRetail={row.suggestedRetailPrice || "N/A"}
            isProduction={true}
            salesOrder={row.salesOrder || ""}
            cutsheetData={cutsheetData}
          />
        ) : !row.units || !row.units.length ? (
          <HtmlTooltip title={"No Units in Quote"}>
            <IconButton aria-label="download" size="large">
              <GetAppIcon />
            </IconButton>
          </HtmlTooltip>
        ) : null}
      </div>
    </TableCell>
  );
};

export default ProductionPdf;
