import React, { useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../../Choices";
import ReusableAccordian from "../../ReusableAccordion";
import { useStore, useAccordion } from "../../../store/store";
import { useRequiredMsgs } from "../../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import {
  screenColors,
  slidingScreenDbl,
  slidingScreenLeft,
  slidingScreenRight,
} from "./defaults";
import { RowLayout, ColLayout } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const slidingScreenConfigs = ["OA-XO", "OX-AO", "OX", "XO"];

export default function SlidingScreenAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const chosenConfig = useStore((state) => state.quote.exactConfig);
  const slidingScreenChoice = useStore((state) => state.quote.addSlidingScreen);

  const screenColorChoice = useStore((state) => state.quote.screenColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[25].expanded);

  const slidingScreenImgShown =
    slidingScreenChoice === "Add Double Screen"
      ? "Screens/DURANGO_DOUBLE"
      : slidingScreenChoice === "Add Screen Open Left"
      ? "Screens/DURANGO_LEFT"
      : slidingScreenChoice === "Add Screen Open Right"
      ? "Screens/DURANGO_RIGHT"
      : "";

  const findScreenDirection = useCallback(() => {
    if (!slidingScreenConfigs.includes(chosenConfig)) return;

    if (chosenConfig === "OA-XO" || chosenConfig === "OX-AO") {
      return addToQuote({ addSlidingScreen: "Add Double Screen" });
    }
    if (chosenConfig === "OX") {
      return addToQuote({ addSlidingScreen: "Add Screen Open Left" });
    }

    if (chosenConfig === "XO") {
      return addToQuote({ addSlidingScreen: "Add Screen Open Right" });
    }
  }, [chosenConfig]);

  useEffect(() => {
    if (!chosenConfig) return;
    findScreenDirection();
  }, [chosenConfig]);

  const handleClick = (label: string) => {
    const checked = label === screenColorChoice;

    if (checked) {
      addToQuote({ screenColor: "" });
    } else {
      addToQuote({ screenColor: label });
      if (findRequired("Screen Extrusion Color"))
        removeRequired("Screen Extrusion Color");
    }
  };

  return (
    <>
      <ReusableAccordian
        expanded={expanded}
        handleAccordClick={handleAccordClick}
        accordionName="screen"
        heading="Sliding Screen"
        secondaryHeading="Select Screen Option"
        colOrRow="col"
      >
        <RowLayout>
          <ColLayoutSpacing>
            <Typography variant="subtitle1">
              Screen Layout
              <span style={{ color: "red", fontSize: 20 }}>*</span>
            </Typography>
            <RowLayout>
              {chosenConfig === "OX"
                ? slidingScreenLeft.map((state) => (
                    <div key={state.id}>
                      <Choices
                        label={state.label}
                        variant="filled"
                        checkMark={true}
                        disabled={true}
                      />
                    </div>
                  ))
                : chosenConfig === "XO"
                ? slidingScreenRight.map((state) => (
                    <div key={state.id}>
                      <Choices
                        label={state.label}
                        variant="filled"
                        checkMark={true}
                        disabled={true}
                      />
                    </div>
                  ))
                : chosenConfig === "OX-AO" || chosenConfig === "OA-XO"
                ? slidingScreenDbl.map((state) => (
                    <div key={state.id}>
                      <Choices
                        label={state.label}
                        variant="filled"
                        checkMark={true}
                        disabled={true}
                      />
                    </div>
                  ))
                : null}
            </RowLayout>
          </ColLayoutSpacing>

          <ColLayoutLeftDivider>
            <Typography variant="subtitle1">
              Select an Extrusion Color{" "}
              <span style={{ color: "red", fontSize: 20 }}>*</span>
            </Typography>
            <RowLayout>
              {screenColors.map((state) => (
                <div key={state.id} onClick={() => handleClick(state.label)}>
                  <Choices
                    label={state.label}
                    variant={
                      state.label === screenColorChoice ? "filled" : "outlined"
                    }
                    checkMark={state.label === screenColorChoice}
                  />
                </div>
              ))}
            </RowLayout>
          </ColLayoutLeftDivider>
        </RowLayout>

        {slidingScreenImgShown && (
          <ColLayout>
            <Image
              secure="true"
              cloudName="ag-millworks"
              publicId={slidingScreenImgShown}
            />
            <Typography variant="caption">*As Viewed from Exterior</Typography>
          </ColLayout>
        )}
      </ReusableAccordian>
    </>
  );
}
