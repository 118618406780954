import React, { FC, Dispatch, SetStateAction } from "react";
import {
  FormControl,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledColStartDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  paddingTop: 60,
  paddingBottom: 20,
});

const StyledRowDiv = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
});

const StyledRowCenterDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
  marginLeft: 50,
});

const StyledOtsm = styled(Typography)({
  paddingRight: 20,
  fontWeight: "bold",
});

interface OTSMProps {
  otsm: boolean;
  setOtsm: Dispatch<SetStateAction<boolean>>;
  otsmDefault: boolean;
  setOtsmDefault: Dispatch<SetStateAction<boolean>>;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const OTSM: FC<OTSMProps> = ({ otsm, setOtsm, otsmDefault, setOtsmDefault, setSuccess }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtsm(event.target.checked);
    setSuccess((previous) => previous && "");
  };

  const handleChangeDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtsmDefault(event.target.checked);
  };

  return (
    <StyledColStartDiv>
      <StyledRowDiv>
        <StyledOtsm>Over The Shoulder Mode (OTSM):</StyledOtsm>

        <FormControl>
          <FormControlLabel
            control={
              <Switch color="primary" checked={otsm} onChange={handleChange} />
            }
            label={otsm ? "On" : "Off"}
          />
        </FormControl>
      </StyledRowDiv>

      <StyledRowCenterDiv>
        <Typography style={{ paddingRight: 20 }}>Default:</Typography>

        <FormControl>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={otsmDefault}
                disabled={!otsm ? true : false}
                onChange={handleChangeDefault}
              />
            }
            label={otsmDefault ? "Fixed Markup" : "Fixed Margin"}
          />
        </FormControl>
      </StyledRowCenterDiv>

      <Typography variant="subtitle2" style={{ marginLeft: 50 }}>
        Turning on OTSM will hide Dealer Margin/Markup and all pricing shown on
        the Configurator and Order Summary page.
      </Typography>
      <Typography variant="subtitle2" style={{ marginLeft: 50 }}>
        <span style={{ fontWeight: "bold" }}>Note: </span> Pricing will still be
        displayed on the Customer and Dealer PDF details
      </Typography>
    </StyledColStartDiv>
  );
};

export default OTSM;
