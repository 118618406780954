import React, { memo } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { headtrack } from "./defaults";
import { RowLayout } from "../../util/accordianStyles";

function HeadTrackColorAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const headTrackColor = useStore((state) => state.quote.headTrackColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[12].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    addToQuote({ headTrackColor: !checked ? label : "" });

    if (!checked && findRequired("Head Track Color"))
      removeRequired("Head Track Color");
  };

  const variant = (label: string) =>
    label === headTrackColor ||
    label.replace("Anodized", "").trim() === headTrackColor
      ? "filled"
      : "outlined";

  const checkMark = (label: string) =>
    label === headTrackColor ||
    label.replace("Anodized", "").trim() === headTrackColor;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="headTrack"
      heading="Head Track Color"
      secondaryHeading="Select head track color"
    >
      <RowLayout>
        {headtrack.map((state) => (
          <div key={state.id} onClick={() => handleClick(state.label)}>
            <Choices
              label={state.label}
              variant={variant(state.label)}
              checkMark={checkMark(state.label)}
              wide={true}
            />
          </div>
        ))}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(HeadTrackColorAccordian);
