import React, { useEffect, useRef, FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grow from "@mui/material/Grow";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import litesCloudinaryImg from "../../Drawings/LitesLayout";
import { useStore } from "../../store/store";
import { dlOptions } from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledImg = styled("img")({
  height: 300,
  width: 150,
});

const StyledFormControl = styled(FormControl)({
  width: 270,
  paddingBottom: 10,
});


interface DLselectionProps {
  panelLayout: string 
  layout?: string 
  dlo?: string
  isSDL?: boolean 
  swingSidelite?: boolean 
  doorType: string
}

interface UpdateObj {
  dlLayout?: string
  dlLayoutSidelite?: string
  numberOfLites?: string | number
  numberOfLitesSidelite?: string | number
  diffSideliteSdl?: boolean
}

const DLselection: FC<DLselectionProps> = ({ panelLayout, layout, dlo, isSDL, swingSidelite, doorType }) => {
  const editBtn = useStore((state) => state.isEdit);

  const addDL = useStore((state) => state.quote.addDL);

  const addToQuote = useStore((state) => state.addToQuote);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const text =
    doorType === "Pivot" && !isSDL
      ? "Intermediate Rail"
      : isSDL
      ? "SDL"
      : "TDL";

  const cottage = ["2W1H", "3W1H", "4W1H"];

  const calculateNumOfLites = (dlLayout: string, isCottage: boolean, isSidelite: boolean | undefined) => {
    const removeletters = dlLayout.includes("Marginal")
      ? dlLayout.slice(0, 1)
      : dlLayout.replace("W", "").replace("H", "").split("");

    var num = isCottage
      ? parseInt(removeletters[0]) + parseInt(removeletters[1])
      : dlLayout.includes("Marginal")
      ? parseInt(dlLayout.slice(0, 1))
      : parseInt(removeletters[0]) * parseInt(removeletters[1]);

    return !isSidelite
      ? { numberOfLites: num }
      : { numberOfLitesSidelite: num };
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    let update: UpdateObj = {}

    if (!swingSidelite) {
      update["dlLayout"] = value;
    } else {
      update["dlLayoutSidelite"] = value;
    }

    if (!value) {
      if (!swingSidelite) {
        update["numberOfLites"] = "";
      } else {
        update["numberOfLitesSidelite"] = "";
      }

      return addToQuote(update);
    }

    if (!swingSidelite && value === "9 Lite Marginal")
      update["diffSideliteSdl"] = true;

    if (
      findRequired("Divided Lites Configuration") ||
      findRequired("Sidelite Divided Lites Configuration")
    ) {
      value && !swingSidelite && removeRequired("Divided Lites Configuration");
      value &&
        swingSidelite &&
        removeRequired("Sidelite Divided Lites Configuration");
    }

    var isCottage = cottage.includes(value);
    const litesobj = calculateNumOfLites(value, isCottage, swingSidelite);

    update = { ...update, ...litesobj };
    addToQuote(update);
  };

  const handleDOChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (!value) {
      addToQuote({ daylightOpening: "" });
    } else {
      addToQuote({ daylightOpening: value });

      if (findRequired("Daylight Opening")) removeRequired("Daylight Opening");
    }
  };

  const editRef = useRef(true);

  useEffect(() => {
    if (editBtn) return;
    editRef.current = true;
  }, [editBtn]);

  useEffect(() => {
    if (!swingSidelite) return;
    if (editBtn && editRef.current) {
      editRef.current = false;
    } else {
      addToQuote({ dlLayoutSidelite: "" });
    }
  }, [swingSidelite, panelLayout]);

  const filterSwingSideliteOptions = (panelLite: string) => {
    if (panelLite === "9 Lite Marginal")
      return [
        { value: "3 Lite Marginal", label: "3 Lite Marginal" },
        { value: "6 Lite Marginal", label: "6 Lite Marginal" },
      ];

    const panelNumHigh = panelLite.slice(-2);
    const matchNumHigh = dlOptions.filter((item) => {
      if (item.value.slice(-2) === panelNumHigh && item.value !== panelLite)
        return item;
    });

    return matchNumHigh;
  };

  const isCottage = !layout ? false : cottage.includes(layout);

  const liteImage = litesCloudinaryImg(layout || "", addDL);

  const options = isSDL
    ? [...dlOptions, { value: "9 Lite Marginal", label: "9 Lite Marginal" }]
    : text === "Intermediate Rail"
    ? dlOptions.filter((itm) => !itm.label.includes("Cottage Style"))
    : dlOptions;

  return (
    <RowLayout style={{ paddingRight: 20 }}>
      <ColLayout style={{ paddingRight: 20 }}>
        <StyledFormControl required>
          <InputLabel>
            {text} {swingSidelite && "Sidelite"} Configuration
          </InputLabel>
          {!swingSidelite && (
            <Select
              onChange={handleChange}
              value={layout}
              label={`${text} ${swingSidelite && "Sidelite"} Configuration`}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options.map((item) => (
                <MenuItem key={item.label} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}

          {swingSidelite && (
            <Select
              onChange={handleChange}
              value={layout}
              label={`${text} ${swingSidelite && "Sidelite"} Configuration`}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {filterSwingSideliteOptions(panelLayout).map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Required</FormHelperText>
        </StyledFormControl>
        {isCottage && !swingSidelite && (
          <StyledFormControl required>
            <InputLabel>Daylight Opening (Enter value for X)</InputLabel>
            {isSDL ? (
              <Select onChange={handleDOChange} value={dlo}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'5"'}>5 Inches</MenuItem>
                <MenuItem value={'6"'}>6 Inches</MenuItem>
                <MenuItem value={'7"'}>7 Inches</MenuItem>
                <MenuItem value={'8"'}>8 Inches</MenuItem>
                <MenuItem value={'9"'}>9 Inches</MenuItem>
                <MenuItem value={'10"'}>10 Inches</MenuItem>
                <MenuItem value={'11"'}>11 Inches</MenuItem>
                <MenuItem value={'12"'}>12 Inches</MenuItem>
                <MenuItem value={'13"'}>13 Inches</MenuItem>
                <MenuItem value={'14"'}>14 Inches</MenuItem>
                <MenuItem value={'15"'}>15 Inches</MenuItem>
                <MenuItem value={'16"'}>16 Inches</MenuItem>
                <MenuItem value={'17"'}>17 Inches</MenuItem>
                <MenuItem value={'18"'}>18 Inches</MenuItem>
                <MenuItem value={'19"'}>19 Inches</MenuItem>
                <MenuItem value={'20"'}>20 Inches</MenuItem>
                <MenuItem value={'21"'}>21 Inches</MenuItem>
                <MenuItem value={'22"'}>22 Inches</MenuItem>
                <MenuItem value={'23"'}>23 Inches</MenuItem>
                <MenuItem value={'24"'}>24 Inches</MenuItem>
              </Select>
            ) : (
              <Select onChange={handleDOChange} value={dlo}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'11"'}>11 Inches</MenuItem>
                <MenuItem value={'12"'}>12 Inches</MenuItem>
                <MenuItem value={'13"'}>13 Inches</MenuItem>
                <MenuItem value={'14"'}>14 Inches</MenuItem>
                <MenuItem value={'15"'}>15 Inches</MenuItem>
                <MenuItem value={'16"'}>16 Inches</MenuItem>
                <MenuItem value={'17"'}>17 Inches</MenuItem>
                <MenuItem value={'18"'}>18 Inches</MenuItem>
                <MenuItem value={'19"'}>19 Inches</MenuItem>
                <MenuItem value={'20"'}>20 Inches</MenuItem>
                <MenuItem value={'21"'}>21 Inches</MenuItem>
                <MenuItem value={'22"'}>22 Inches</MenuItem>
                <MenuItem value={'23"'}>23 Inches</MenuItem>
                <MenuItem value={'24"'}>24 Inches</MenuItem>
              </Select>
            )}
            <FormHelperText>Required</FormHelperText>
          </StyledFormControl>
        )}
      </ColLayout>

      {layout && (
        <Grow in={true}>
          <StyledImg src={liteImage} alt="Lites Layout" />
        </Grow>
      )}
    </RowLayout>
  );
};

DLselection.defaultProps = {
  panelLayout: "",
};

export default DLselection;
