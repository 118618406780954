import React, { useEffect, useState, useMemo } from "react";
import "./required.css";
import { useStore } from "../../store/store";
import { WrapperOver240, WrapperUnder240 } from "./Wrappers";

function RequiredDisplayBifoldCorner() {
  const material = useStore(state => state.quote.material);
  const woodSpecies = useStore(state => state.quote.woodSpecies);
  const leftWidth = useStore(state => state.quote.unitSize);
  const leftFraction = useStore(state => state.quote.unitSizeFraction);
  const rightWidth = useStore(state => state.quote.cornerWidthRight);
  const rightFraction = useStore(state => state.quote.cornerWidthFractionRight);
  const height = useStore(state => state.quote.height);
  const heightFraction = useStore(state => state.quote.heightFraction);
  const cornerType = useStore(state => state.quote.cornerType);
  const configLeft = useStore(state => state.quote.exactConfig);
  const configRight = useStore(state => state.quote.cornerConfigRight);

  const [scrollPosition, setScrollPosition] = useState<null | number>(null);

  const listenScrollEvent = () => setScrollPosition(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  });


  const header = useMemo(() => {
    return (
      <div className="header">
        <p>
          {material ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Material - </span>
              {material}
            </>
          ) : (
            "Material"
          )}
        </p>
        <p>
          {woodSpecies ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Wood - </span>
              {woodSpecies}
            </>
          ) : (
            "Wood Species"
          )}
        </p>
       <p>
          {leftWidth || rightWidth ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Width - </span>{" "}
              {leftWidth && leftFraction ? `L: ${leftWidth} ${leftFraction} inches` : leftWidth ? `L: ${leftWidth} inches` : '' }
              {rightWidth && rightFraction ? ` R: ${rightWidth} ${rightFraction} inches` : rightWidth ? ` R: ${rightWidth} inches` : '' }
            </>
          ) : (
            "Width"
          )}
        </p>
        <p>
          {height ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Height - </span> {height}{" "}
              {heightFraction} inches
            </>
          ) : (
            "Height"
          )}
        </p>
        <p>
          {configLeft || configRight ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Configuration -</span>{" "}
              {cornerType} {configLeft} {configRight}
            </>
          ) : (
            "Configuration"
          )}
        </p>
      </div>
    );
  }, [
    material, 
    woodSpecies, 
    leftWidth, 
    leftFraction,
    rightWidth, 
    rightFraction, 
    height, 
    heightFraction, 
    configLeft, 
    configRight,
    cornerType
]);

  return (
    <>
      {scrollPosition && scrollPosition >= 240 ? (
        <WrapperOver240>{header}</WrapperOver240>
      ) : (
        <WrapperUnder240>{header}</WrapperUnder240>
      )}
    </>
  );
}


export default RequiredDisplayBifoldCorner;
