const pivotPoints = [
    {
      id: 1,
      type: "pivotPoint",
      label: '4-3/4" Pivot Axis Point',
    },
    {
      id: 2,
      type: "pivotPoint",
      label: '8" Pivot Axis Point',
    },
    {
      id: 3,
      type: "pivotPoint",
      label: '12" Pivot Axis Point',
    },
    {
      id: 4,
      type: "pivotPoint",
      label: '16" Pivot Axis Point',
    },
    {
        id: 5,
        type: "pivotPoint",
        label: 'Middle Pivot Axis Point',
    },
  ];

  const pivotPoints2 = [
    {
      id: 1,
      type: "pivotPoint",
      label: '4-3/4" Pivot Axis Point',
    },
    {
      id: 2,
      type: "pivotPoint",
      label: '8" Pivot Axis Point',
    },
    {
      id: 3,
      type: "pivotPoint",
      label: '12" Pivot Axis Point',
    },
    {
      id: 4,
      type: "pivotPoint",
      label: '16" Pivot Axis Point',
    },
  ];
  

export { pivotPoints, pivotPoints2 }