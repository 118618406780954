const baseURL = 'https://res.cloudinary.com/ag-millworks/image/upload/dlPanels';

const cottage = ["2W1H", "3W1H", "4W1H"];

function litesCloudinaryImg(layout: string, dl?: string){
  if(!layout) return '';

  const label = !layout.includes('Marginal') ? layout 
    : layout === '3 Lite Marginal' ? '3_LITE_MARGINAL_NO_DIM_500_2'
    : layout === '6 Lite Marginal' ? '6_LITE_MARGINAL_NO_DIM_500_2'
    : layout === '9 Lite Marginal' ? '9_LITE_MARGINAL'
    : '';

  const addLabel = cottage.includes(layout) ? '_COTTAGE'
    : '';

  const img = 
   dl !== "Add Intermediate Rail" 
    ? `${baseURL}/${label}${addLabel}.jpg`
    : `${baseURL}/intermediate_rail/${label}.jpg`

  return img;
};

export default litesCloudinaryImg;