import React, { FC } from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import { bifoldCornerPVImg } from "../../../Drawings/EPVCorner";
import { styles } from "../Styles";
import { UnitObj } from "../../../util/quoteCartStorage";

export interface Props {
  item: UnitObj;
}

function getConfigTxtPosition(
  cornerType: string,
  screen: string,
  side: string
) {
  if (cornerType === "Inside") {
    if (!screen) {
      return side === "left"
        ? { top: "120px", left: "125px", transform: "rotate(-90deg)" }
        : { top: "75px", left: "165px" };
    } else {
      return side === "left"
        ? { top: "150px", left: "160px", transform: "rotate(-90deg)" }
        : { top: "115px", left: "190px" };
    }
  } else {
    if (!screen) {
      return side === "left"
        ? { top: "200px", left: "200px" }
        : { top: "150px", left: "250px", transform: "rotate(-90deg)" };
    } else {
      return side === "left"
        ? { top: "135px", left: "150px" }
        : { top: "105px", left: "205px", transform: "rotate(-90deg)" };
    }
  }
}

function getNoAccessTxtPosition(cornerType: string, screen: string) {
  if (cornerType === "Inside") {
    if (!screen) {
      return { top: "60%", left: "35%", fontSize: 12 };
    } else {
      return { top: "65%", left: "45%", fontSize: 12 };
    }
  } else {
    if (!screen) {
      return { top: "35%", left: "25%", fontSize: 12 };
    } else {
      return { top: "20%", left: "20%", fontSize: 12 };
    }
  }
}

const PVCorner: FC<Props> = ({ item }) => {
  const pv = bifoldCornerPVImg(
    item.cornerType || "",
    item.screenType || "",
    item.addScreen || ""
  );

  const full = `${item.exactConfigFull} ${item.exactConfigFullRightCorner}`;

  const evens = ["2", "4", "6", "8"];

  const noAccess =
    evens.includes(item.exactConfig.split("")[0]) &&
    evens.includes(item.cornerConfigRight?.split("")[0] || "");

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      <Text style={[styles.textBold, { fontSize: 11, zIndex: 20 }]}>
        Elevation View: Qty. {item.quantity} Bi-fold {item.cornerType} Corner{" "}
        {full} ({`Primary Door: ${item.primaryDoor}`})
      </Text>

      <View>
        <Text style={styles.text}>As Viewed from Exterior</Text>
      </View>

      <Text style={[styles.textBold, { fontSize: 11, paddingTop: 5 }]}>
        Plan View:
      </Text>

      <View style={styles.planViewCornerWrap}>
        <Text
          style={[
            styles.planViewConfigTxt,
            getConfigTxtPosition(
              item.cornerType || "",
              item.addScreen || "",
              "left"
            ),
          ]}
        >
          {item.exactConfig}
        </Text>
        <Text
          style={[
            styles.planViewConfigTxt,
            getConfigTxtPosition(
              item.cornerType || "",
              item.addScreen || "",
              "right"
            ),
          ]}
        >
          {item.cornerConfigRight}
        </Text>
        {noAccess ? (
          <Text
            style={[
              styles.planViewConfigTxt,
              getNoAccessTxtPosition(
                item.cornerType || "",
                item.addScreen || ""
              ),
            ]}
          >
            NO EXTERIOR ACCESS
          </Text>
        ) : (
          <Text />
        )}
        <Image src={pv} style={[styles.planViewCorner, { zIndex: 0 }]} />
      </View>
    </>
  );
};

export default PVCorner;
