import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { usePDF } from "@react-pdf/renderer";
import {
  Backdrop,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomerCost from "./CustomerCost";
import CustomerEmailModal from "../components/orderSummary/modals/CustomerEmailModal";
import EmailIcon from "@mui/icons-material/Email";
import EmailWarningModal from "../components/orderSummary/modals/EmailWarningModal";
import { AddCostObj } from "../GlobalTypes";
import { QuoteObj } from "../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledBackdrop = styled(Backdrop)({
  zIndex: 20,
  color: "#fff",
});

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StyledText = styled(Typography)({
  color: "white",
  paddingTop: 10,
});

const StyledSpan = styled("span")({
  fontWeight: "bold",
  paddingLeft: 5,
  paddingRight: 5,
});

const StyledAnchor = styled("a")({
  textDecoration: "none",
});

const StyledButton = styled(Button)({
  color: "black",
  marginBottom: 5,
});

const StyledDivColStart = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  position: "relative",
});

const StyledDivMail = styled("div")(({ theme }) => ({
  position: "absolute",
  top: -35,
  right: -10,
  zIndex: 1000,
  [theme.breakpoints.down("lg")]: {
    top: 0,
    right: -50,
  },
}));

export interface CustomerPDFProps {
  hidePricing: string;
  setPdfErr: Dispatch<SetStateAction<boolean>>;
  data: QuoteObj;
  dealerLogo: string;
  isOrder?: boolean;
  salesTax: number;
  poNumber: string;
  margin: number;
  withMarkup: boolean;
  addCosts: AddCostObj[];
  grandTotal: string;
  suggestedRetail: string;
  deliveryMarkup: number;
  dealerTax: number | null;
}

const GenerateCustomer: FC<CustomerPDFProps> = ({
  hidePricing,
  setPdfErr,
  data,
  dealerLogo,
  isOrder,
  salesTax,
  poNumber,
  margin,
  withMarkup,
  addCosts,
  grandTotal,
  suggestedRetail,
  deliveryMarkup,
  dealerTax,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <CustomerCost
        data={data}
        hidePricing={hidePricing}
        isOrder={isOrder}
        salesTax={salesTax}
        poNumber={poNumber}
        margin={margin}
        withMarkup={withMarkup}
        addCosts={addCosts}
        grandTotal={grandTotal}
        suggestedRetail={suggestedRetail}
        dealerLogo={dealerLogo}
        deliveryMarkup={deliveryMarkup}
        dealerTax={dealerTax}
      />
    ),
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpenWarning(false);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const [openWarning, setOpenWarning] = useState(false);

  const handleOpenWarning = () => setOpenWarning(true);

  const handleCloseWarning = () => setOpenWarning(false);

  if (instance.loading && !instance.error)
    return (
      <StyledBackdrop open={true}>
        <StyledDivCol>
          <img
            src="https://res.cloudinary.com/ag-millworks/image/upload/Calculating_doorlogo.png"
            alt="door logo"
          />
          <StyledText>Preparing Your Quote...</StyledText>
        </StyledDivCol>
      </StyledBackdrop>
    );

  if (instance.error) {
    setPdfErr && setPdfErr(true);
    return null;
  }

  const fileName = `AGMillworks(PC-${data.quoteNumber})Customer.pdf`;

  return (
    <>
      <CustomerEmailModal
        customerEmail={data.customerEmail || ""}
        dealerEmail={data.email || ""}
        fileName={fileName}
        open={open}
        handleClose={handleClose}
        blob={instance?.blob}
        url={instance.url}
      />

      <EmailWarningModal
        open={openWarning}
        handleClose={handleCloseWarning}
        handleOpenEmailModal={handleOpen}
      />

      <StyledDivColStart>
        <StyledDivMail>
          <Tooltip title="Email to Customer" placement="top">
            <IconButton
              onClick={!margin ? handleOpenWarning : handleOpen}
              style={{ alignSelf: "flex-end" }}
            >
              <EmailIcon style={{ fontSize: 18 }} color="primary" />
            </IconButton>
          </Tooltip>
        </StyledDivMail>

        {instance.url && (
          <>
            <StyledAnchor href={instance.url} download={fileName}>
              <StyledButton
                variant="contained"
                style={{ backgroundColor: "#21c7f5" }}
              >
                <StyledSpan> Customer </StyledSpan> Details
              </StyledButton>
            </StyledAnchor>
          </>
        )}
      </StyledDivColStart>
    </>
  );
};

export default GenerateCustomer;
