import React, { useState, useRef, useEffect, FC, MutableRefObject } from "react";
import { Typography, Paper, IconButton, SelectChangeEvent } from "@mui/material";
import {
  Info as InfoIcon,
  ArrowBackIos,
  ArrowForwardIos,
  Circle as CircleIcon,
  CircleOutlined as CircleOutlineIcon,
} from "@mui/icons-material";
import BifoldTypeSelect from "./BifoldTypeSelect";
import { useStore } from "../store/store";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./RemoveEditDuplicate/EditBtn.css";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  "&:hover": { cursor: "pointer" },
  padding: 10,
  margin: 10,
});

const StyledDivCol = styled("div")({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivTitle = styled("div")({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "groove",
  borderRadius: 5,
  borderColor: "#4b4b4b",
  paddingBottom: 0,
  boxShadow: "0px 2px 3px gray",
  position: "relative",
  overflow: "hidden",
});

const StyledArrowBoxLeft = styled("div")({
  width: 50,
  height: "100%",
  position: "absolute",
  left: 0,
  zIndex: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#c3cbdc",
  // backgroundColor: "rgb(195, 203, 220, 0.3)",
  backgroundImage: "linear-gradient(to right, #c3cbdc 0%, #ffffff 100%)",
  cursor: "pointer",
});

const StyledArrowBoxRight = styled("div")({
  width: 40,
  height: "100%",
  position: "absolute",
  right: 0,
  zIndex: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#c3cbdc",
  backgroundImage: "linear-gradient(to left, #c3cbdc 0%, #ffffff 100%)",
  cursor: "pointer",
});

const StyledBifoldImg = styled("img")(({ theme }) => ({
  width: 250,
  height: 220,
  objectFit: "contain",
  [theme.breakpoints.down("md")]: {
    width: 200,
    height: 200,
  },
  [theme.breakpoints.down("sm")]: {
    width: 150,
    height: 150,
  },
}));

const StyledSliderImg = styled("img")(({ theme }) => ({
  width: 300,
  height: 220,
  objectFit: "contain",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: 200,
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: 150,
  },
}));

const StyledPivotImg = styled("img")(({ theme }) => ({
  width: 150,
  height: 220,
  objectFit: "contain",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: 200,
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: 150,
  },
}));

const StyledSwingImg = styled("img")(({ theme }) => ({
  width: 200,
  height: 220,
  objectFit: "contain",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: 200,
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: 150,
  },
}));

const StyledFixedImg = styled("img")(({ theme }) => ({
  width: 100,
  height: 220,
  objectFit: "contain",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: 200,
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: 150,
  },
}));

const StyledContentDiv = styled("div")({
  backgroundColor: "#fafafa",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const StyledCarouselDiv = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  transition: "transform 0.3s ease-in-out",
  flexWrap: "nowrap",
  padding: 20,
  [theme.breakpoints.down("lg")]: {
    padding: 0,
    paddingTop: 40,
    paddingBottom: 40,
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StyledCarouselItmDiv = styled("div")(({ theme }) => ({
  width: "calc(100% - 70px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transition: "1s cubic-bezier(0.39, 0.575, 0.565, 1)",
  padding: 40,
  paddingTop: 10,
  [theme.breakpoints.down("md")]: {
    paddingTop: 10,
    paddingBottom: 30,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
  },
}));

const StyledPagesDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginTop: -50,
  paddingBottom: 20,
  alignSelf: "center",
});

const StyledArrowIconBack = styled(ArrowBackIos)({
  color: "black",
  fontSize: 50,
  "&:hover": {
    fontSize: 60,
  },
  marginLeft: 20,
});

const StyledArrowIconFront = styled(ArrowForwardIos)({
  color: "black",
  fontSize: 50,
  "&:hover": {
    fontSize: 60,
  },
});

const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/UnitGifs";
const AG_URL = "https://www.agmillworks.com";

const data = [
  {
    img: `${CLOUDINARY_URL}/bifoldGifPic.jpg`,
    jif: `${CLOUDINARY_URL}/bifold_gif.gif`,
    val: "bifold",
    title: "Bi-Fold Door",
    href: `${AG_URL}/folding_door_systems`,
  },
  {
    img: `${CLOUDINARY_URL}/sliderGifPic.jpg`,
    jif: `${CLOUDINARY_URL}/slider_gif.gif`,
    val: "slider",
    title: "Sliding Door",
    href: `${AG_URL}/sliding_door_systems`,
  },
  {
    img: `${CLOUDINARY_URL}/swingGifPic.jpg`,
    jif: `${CLOUDINARY_URL}/swing_gif.gif`,
    val: "swing",
    title: "French Swing Door",
    href: `${AG_URL}/french_swing_door_systems`,
  },
  {
    img: `${CLOUDINARY_URL}/pivotGifPic_v3.jpg`,
    jif: `${CLOUDINARY_URL}/pivot_gif_v3.gif`,
    val: "pivot",
    title: "Pivot Door",
    href: `${AG_URL}/pivot-door-system`,
  },
  {
    img: `${CLOUDINARY_URL}/fullbound3.jpg`,
    jif: `${CLOUDINARY_URL}/fullbound3.jpg`,
    val: "fixed",
    title: "Fixed Panel",
    href: ``,
  },
];

interface CarouselProps {
  reset: () => void
  bifold: string
  handleBifoldChange:(event: SelectChangeEvent<string>) => void
  windowOrDoor: string
}

interface RefObj extends HTMLDivElement {
  current: unknown
}

const Carousel: FC<CarouselProps> = ({ reset, bifold, handleBifoldChange, windowOrDoor }) => {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.up("md"));
  const mediaXL = useMediaQuery(theme.breakpoints.up("xl"));

  const screenCutoff1 = useMediaQuery("(min-width:1655px)", { noSsr: true });
  const screenCutoff2 = useMediaQuery("(min-width:1400px)", { noSsr: true });

  const largeScreen =
    !screenCutoff1 && screenCutoff2 ? false : media && mediaXL ? true : false;
  const mediumScreen =
    (!screenCutoff1 && screenCutoff2) || (media && !mediaXL) ? true : false;

  const addToQuote = useStore((state) => state.addToQuote);

  const [hover, setHover] = useState("");

  const [startX, setStartX] = useState<number | null>(null);

  const [numPages, setNumPages] = useState(2);

  const handleHover = (unit: string) => setHover(unit);

  const handleClick = (tab: string) => {
    reset();

    switch (tab) {
      case "bifold":
        addToQuote({ doorType: bifold?.replace("F", "f") });
        break;
      case "slider":
        addToQuote({ doorType: "Slider" });
        break;
      case "swing":
        addToQuote({ doorType: "Swing" });
        break;
      case "fixed":
        addToQuote({ doorType: "Fixed Panel" });
        break;
      case "pivot":
        addToQuote({ doorType: "Pivot" });
        break;
      default:
        return;
    }
  };

  const boxRef: MutableRefObject<RefObj | null> = useRef(null);
  const carouselRef: MutableRefObject<RefObj | null> = useRef(null);

  const [index, setIndex] = useState(1);

  useEffect(() => {
    const initialIndex = largeScreen ? 1 : mediumScreen && !largeScreen ? 0 : 1;

    setIndex(initialIndex);
  }, [media, mediaXL, screenCutoff1, screenCutoff2]);

  useEffect(() => {
    if(!boxRef?.current) return

    if(!carouselRef?.current) return

    const boxWidth: unknown = "offsetWidth" in boxRef.current ? boxRef.current.offsetWidth : 0;
    const translateX = typeof boxWidth === "number" ? -(index - 1) * boxWidth : 0
    const mult = largeScreen ? 1.6 : mediumScreen ? 1.4 : 1;

    if("style" in carouselRef.current){
      carouselRef.current.style.width = typeof boxWidth === "number" ? `${boxWidth * 5}px` : ""
      carouselRef.current.style.transform = `translateX(${translateX * mult}px)`;
    }

    const pageNum = largeScreen ? 2 : mediumScreen ? 4 : 5;
    setNumPages(pageNum);
  }, [index, media, mediaXL, screenCutoff1, screenCutoff2]);

  const shiftLeft = () => {
    if (largeScreen && index === 1) {
      return setIndex(2);
    }

    if (!largeScreen && mediumScreen && index === 0) {
      return setIndex(3);
    }

    if (!largeScreen && !mediumScreen && index === 1) {
      return setIndex(5);
    }

    const newIndex = (index - 1 + 5) % 5;
    setIndex(newIndex);
  };

  const shiftRight = () => {
    if (largeScreen && index === 2) {
      return setIndex(1);
    }

    if (!largeScreen && mediumScreen && index === 3) {
      return setIndex(0);
    }

    if (!largeScreen && !mediumScreen) {
      if (index === 4) {
        return setIndex(5);
      }
      if (index === 5) {
        return setIndex(1);
      }
    }

    const newIndex = (index + 1) % 5;
    setIndex(newIndex);
  };

  const pageClick = (dotNum: number) => {
    setIndex(dotNum);
  };

  const handleTouchStart = (event: React.TouchEvent<unknown>) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: React.TouchEvent<unknown>) => {
    if (startX === null) {
      return;
    }

    const diffX = event.touches[0].clientX - startX;
    const boxWidth = 
      boxRef?.current && 
      "offsetWidth" in boxRef.current 
        ? boxRef.current.offsetWidth 
        : 0
    const moveRatio = typeof boxWidth === "number" ? diffX / boxWidth : 0

    if (moveRatio > 0.2) {
      shiftLeft();
      setStartX(null);
    } else if (moveRatio < -0.2) {
      shiftRight();
      setStartX(null);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  return (
    <StyledContentDiv>
      <StyledDivTitle
        style={{
          width: largeScreen ? 1300 : mediumScreen ? 780 : 360,
          alignItems: largeScreen
            ? "flex-start"
            : mediumScreen
            ? "flex-end"
            : "flex-start",
        }}
      >
        {largeScreen || mediumScreen ? (
          <Typography
            style={{ alignSelf: "center", marginTop: 10 }}
            variant="h4"
          >
            Select a Unit Type to Build
          </Typography>
        ) : (
          <Typography style={{ alignSelf: "center" }} variant="h6">
            Select a Unit Type
          </Typography>
        )}

        <StyledArrowBoxLeft onClick={shiftLeft}>
          <StyledArrowIconBack />
        </StyledArrowBoxLeft>
        <StyledArrowBoxRight onClick={shiftRight}>
          <StyledArrowIconFront />
        </StyledArrowBoxRight>

        <StyledCarouselDiv
          ref={carouselRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {data.map((item, idx) => (
            <StyledCarouselItmDiv
              key={idx}
              style={{
                alignSelf:
                  item.title === "Fixed Panel" ? "flex-start" : "center",
                // paddingLeft: !largeScreen && !mediumScreen && index === 1 ? 80 : 40
              }}
              ref={boxRef}
            >
              <StyledPaper
                onClick={() => handleClick(item.val)}
                onMouseEnter={() => handleHover(item.title)}
                onMouseLeave={() => handleHover("")}
                elevation={3}
                style={{ border: hover === item.title ? "4px solid #57d5f2" : "" }}
              >
                {item.val === "bifold" ? (
                  <StyledBifoldImg
                    src={hover === item.title ? item.jif : item.img}
                    alt={item.title}
                  />
                ) : item.val === "slider" ? (
                  <StyledSliderImg
                    src={hover === item.title ? item.jif : item.img}
                    alt={item.title}
                  />
                ) : item.val === "swing" ? (
                  <StyledSwingImg
                    src={hover === item.title ? item.jif : item.img}
                    alt={item.title}
                  />
                ) : item.val === "pivot" ? (
                  <StyledPivotImg
                    src={hover === item.title ? item.jif : item.img}
                    alt={item.title}
                  />
                ) : (
                  <StyledFixedImg
                    src={hover === item.title ? item.jif : item.img}
                    alt={item.title}
                  />
                )}
              </StyledPaper>

              <StyledDivCol>
                {item.title === "Fixed Panel" &&
                !largeScreen &&
                !mediumScreen ? (
                  <>
                    <Typography
                      style={{ color: hover === item.title ? "#57d5f2" : "" }}
                      variant="h6"
                    >
                      Fixed
                    </Typography>
                    <Typography
                      style={{ color: hover === item.title ? "#57d5f2" : "" }}
                      variant="h6"
                    >
                      Panel
                    </Typography>
                  </>
                ) : item.title !== "Bi-Fold Door" ? (
                  <Typography
                    style={{ color: hover === item.title ? "#57d5f2" : "" }}
                    variant="h6"
                  >
                    {item.title}
                  </Typography>
                ) : (
                  <BifoldTypeSelect
                    hover={hover}
                    fontSize="1.25rem"
                    handleChange={handleBifoldChange}
                    windowOrDoor={windowOrDoor}
                  />
                )}

                {item.title !== "Fixed Panel" ? (
                  <Typography variant="caption" style={{ paddingLeft: 5 }}>
                    Learn More
                    <a href={item.href} target="_blank" rel="noreferrer">
                      <IconButton color="primary" size="large">
                        <InfoIcon />
                      </IconButton>
                    </a>
                  </Typography>
                ) : (
                  <div />
                )}
              </StyledDivCol>
            </StyledCarouselItmDiv>
          ))}
        </StyledCarouselDiv>

        <StyledPagesDiv>
          {Array.from(Array(numPages).keys()).map((num) => (
            <IconButton
              size="small"
              key={num}
              onClick={() =>
                pageClick(largeScreen ? num + 1 : mediumScreen ? num : num + 1)
              }
            >
              {(largeScreen && index === num + 1) ||
              (!largeScreen && mediumScreen && index === num) ||
              (!largeScreen && !mediumScreen && index === num + 1) ? (
                <CircleIcon style={{ fontSize: 10 }} />
              ) : (
                <CircleOutlineIcon style={{ fontSize: 10 }} />
              )}
            </IconButton>
          ))}
        </StyledPagesDiv>
      </StyledDivTitle>
    </StyledContentDiv>
  );
};

export default Carousel;
