import React, { memo, FC } from "react";
import { Chip, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)({
  backgroundColor: "white",
  fontWeight: "bold",
  borderRadius: 10,
});

interface QuoteNumberProps {
  quoteNumber: number
}

const QuoteNumber: FC<QuoteNumberProps> = ({ quoteNumber }) => {
  return (
    <Hidden mdDown>
      <StyledChip variant="outlined" label={`Quote #${quoteNumber}`} />
    </Hidden>
  );
};

export default memo(QuoteNumber);
