import React, { useState, useEffect, FC, Dispatch, SetStateAction } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ReusableAccordian from "../ReusableAccordion";
import SizeLayout from "../SizeLayout";
import fractionToInt from "../../util/fractionToInt";
import SliderPocket from "./SliderPocket/SliderPocket";
import { useStore, useAccordion } from "../../store/store";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { SelectChangeEvent } from "@mui/material";

interface SliderWidthProps {
  setSliderConfig: Dispatch<SetStateAction<string>>
}

interface UpdateObj {
  unitSize?: string 
  exactConfig?: string
  unitSizeFraction?: string
  addScreen?: string
  screenType?: string
  screenPocket?: string
  screenLayout?: string
  screenMeshColor?: string
  screenColor?: string
  jamb?: string
}

const SliderWidthAccordian: FC<SliderWidthProps> = ({
  setSliderConfig
}) => {
  const addToQuote = useStore(state => state.addToQuote);

  const sliderP = useStore((state) => state.quote.sliderPocket);
  const isPocketing = sliderP === "With Pocket";

  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[29].expanded);

  const width = useStore((state) => state.quote.unitSize);
  const fraction = useStore((state) => state.quote.unitSizeFraction);

  const screenType = useStore((state) => state.quote.screenType);
  const screen = useStore((state) => state.quote.addScreen);

  const [min, setMin] = useState(48);
  const [max, setMax] = useState({ num: 576, string: "576" });

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const overallWidth = fractionToInt(unitSize, unitSizeFraction);

  useEffect(() => {
    if (!isPocketing) {
      if (min === 48 && max.num === 576) return;
      setMin(48);
      setMax({ num: 576, string: "576" });
      (overallWidth >= 48 && overallWidth <= 576) || !overallWidth
        ? handleError("widthErr", "")
        : handleError("widthErr", 'Enter width between 48 - 576"');
    }
    if (isPocketing) {
      if (min === 24 && max.num === 452) return;
      setMin(24);
      setMax({ num: 452, string: "452" });
      (overallWidth >= 24 && overallWidth <= 452) || !overallWidth
        ? handleError("widthErr", "")
        : handleError("widthErr", 'Enter width between 24 - 452"');
    }
  }, [isPocketing]);

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    let update: UpdateObj = { unitSize: value, exactConfig: "" };

    const overall = fractionToInt(value, fraction);

    if (screenType === "Centor Flat Screen/Shade") {
      if (
        overall > 348.8125 ||
        (screen === "Add Double Shade" && overall > 316.8125) ||
        (screen === "Add Shade" && overall > 159.8125) ||
        (screen === "Add Combo Screen/Shade" && overall > 161.8125) ||
        (screen === "Add Screen" && overall > 175.8125)
      ) {
        update = {
          ...update,
          addScreen: "",
          screenType: "",
          screenPocket: "",
          screenLayout: "",
          screenMeshColor: "",
          screenColor: "",
          jamb: "",
        };
      }
    }

    if (overall > max.num) {
      handleError("widthErr", `Width can not exceed ${max.string}"`);
    } else if (overall <= max.num && overall >= min) {
      handleError("widthErr", "");
      // addToQuote({unitSize: value}, "unitSize")
    } else if (overall < min) {
      handleError("widthErr", `Width must exceed ${min}"`);
    }

    addToQuote(update);
    setSliderConfig("")
    handleError("primaryDoorErr", "");
  };

  const handleFractionChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    let update: UpdateObj = { exactConfig: "" };

    const overall = fractionToInt(width, value);

    if (overall > max.num) {
      handleError("widthErr", `Width can not exceed ${max.string}"`);
    } else if (overall <= max.num && overall >= min) {
      handleError("widthErr", "");
    }

    if (value === "") {
      update = { unitSizeFraction: "" };
    } else {
      update = { unitSizeFraction: value };
    }

    if (screenType === "Centor Flat Screen/Shade") {
      if (
        overall > 348.8125 ||
        (screen === "Add Double Shade" && overall > 316.8125) ||
        (screen === "Add Shade" && overall > 159.8125) ||
        (screen === "Add Combo Screen/Shade" && overall > 161.8125) ||
        (screen === "Add Screen" && overall > 175.8125)
      ) {
        update = {
          ...update,
          addScreen: "",
          screenType: "",
          screenPocket: "",
          screenLayout: "",
          screenMeshColor: "",
          screenColor: "",
          jamb: "",
        };
      }
    }

    addToQuote(update);
    setSliderConfig("")
    handleError("primaryDoorErr", "");
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sliderWidth"
      heading={isPocketing ? "Daylight Frame Width" : "Net Frame Width"}
      secondaryHeading="Select a Width"
      colOrRow="col"
      required={false}
      required5={true}
    >
      <Typography style={{ paddingBottom: 20 }}>
        Note: For single-sided pocketing units, we recommend a Net Frame Width
        1-1/2" less than the Rough Opening Width, and for double-sided units, 2"
        less than the Rough Opening Width.
      </Typography>

      <ColLayout style={{ width: "100%", justifyContent: "center" }}>
        <RowLayout>
          <Typography style={{ fontWeight: "bold", paddingRight: 60 }}>
            Pocketing:
          </Typography>

          <SliderPocket setSliderConfig={setSliderConfig} />
        </RowLayout>

        {sliderP && (
          <ColLayout style={{ width: "100%" }}>
            <Divider style={{ width: "50%" }} />

            <RowLayout style={{ position: "relative" }}>
              <Typography style={{ fontWeight: "bold", paddingRight: 40 }}>
                {isPocketing ? "Daylight Frame Width:" : "Net Frame Width:"}
              </Typography>

              <SizeLayout
                size={width}
                handleChange={handleWidthChange}
                sizeErr={widthErr}
                name="sliderWidth"
                fractionValue={fraction || ""}
                handleFractionChange={handleFractionChange}
              />
            </RowLayout>
          </ColLayout>
        )}
      </ColLayout>
    </ReusableAccordian>
  );
}

export default SliderWidthAccordian