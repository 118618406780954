import React, { useState, FC } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Edit as EditIcon,
  HighlightOff as DeleteIcon,
  AddCircle as AddIcon,
} from "@mui/icons-material";
import { deleteAddCost } from "../../api/OrderSummaryApi";
import AddCostsModal from "./modals/AddCostsModal";
import { useQueryClient } from "react-query";
import useSessionState from "../../hooks/useSessionState";
import { AddCostObj } from "../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledCol = styled("div")({
  dislay: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  paddingBottom: 20,
});

const StyledRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledAccordion = styled(Accordion)({
  width: "100%",
  boxShadow: "none",
  border: 0,
});

const StyledExpandMoreIcon = styled(ExpandMore)({
  position: "absolute",
  top: 0,
  left: 130,
});

const StyledExpandLessIcon = styled(ExpandLess)({
  position: "absolute",
  top: 0,
  left: 130,
});

function displayPrice(price: number) {
  if (!price) return;

  if (String(price).includes(".")) {
    const dec = String(price).split(".")[1];
    if (dec && dec.length === 1) {
      return `${price}0`;
    }
  }

  return price;
}

interface AddCostsProps {
  costsArray: AddCostObj[]
  quoteId: string
  disableChanges: boolean
  margin: string
  withMarkup: boolean
  hasDealerTax: boolean
}

const AdditionalCosts: FC<AddCostsProps> = ({
  costsArray,
  quoteId,
  disableChanges,
  margin,
  withMarkup,
  hasDealerTax,
}) => {
  const queryClient = useQueryClient();

  const [expandAddCost, setExpand] = useSessionState("expandAddCost", false);

  const [open, setOpen] = useState(false);

  const [cost, setCost] = useState<AddCostObj>({
    _id: "",
    title: "",
    price: 0,
    taxable: false
  });

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => setExpand(expanded);

  const handleOpen = (itm: AddCostObj) => {
    if (!itm._id) {
      itm.taxable && !hasDealerTax
        ? setCost({ ...itm, _id: "tax" })
        : setCost({ ...itm, _id: "notax" });
      return setOpen(true);
    }

    setCost(itm);
    setOpen(true);
  };

  const handleOpenNewCost = () => {
    if (disableChanges) return;
    setCost({
      _id: "",
    title: "",
    price: 0,
    taxable: false
    });
    setOpen(true);
  };

  const handleClose = () => {
    setCost({
      _id: "",
      title: "",
      price: 0,
      taxable: false
    });
    setOpen(false);
  };

  const deleteCost = async (cId: string | null, tax: boolean) => {
    if (disableChanges) return;

    const id = cId ? cId : tax ? "tax" : "notax";

    const response = await deleteAddCost(quoteId, id, margin, withMarkup);

    if (response.data.matchedCount === 1) {
      return queryClient.invalidateQueries("Cart");
    }
  };

  return (
    <StyledCol>
      <AddCostsModal
        open={open}
        handleClose={handleClose}
        editCost={cost}
        quoteId={quoteId}
        margin={margin}
        withMarkup={withMarkup}
        hasDealerTax={hasDealerTax}
      />

      {costsArray.length ? (
        <StyledAccordion expanded={expandAddCost} onChange={handleChange}>
          <AccordionSummary
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ maxHeight: 20, minHeight: 20 }}
          >
            <Typography
              style={{
                position: "relative",
                color: "#3f51b5",
                textDecoration: "underline",
              }}
            >
              Additional Costs{" "}
              {!expandAddCost ? (
                <StyledExpandMoreIcon />
              ) : (
                <StyledExpandLessIcon />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: "100%" }}>
            {costsArray.map((itm, idx) => (
              <StyledRow
                key={idx}
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                {!hasDealerTax ? (
                  <Typography variant="subtitle2">
                    -{" "}
                    {`${itm.title || "Additional Cost"} ${
                      itm.taxable ? "(Tax)" : "(No-Tax)"
                    }`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    - {`${itm.title || "Additional Cost"}`}
                  </Typography>
                )}

                <StyledRow>
                  <Typography variant="subtitle2" style={{ paddingRight: 10 }}>
                    ${displayPrice(itm.price)}
                  </Typography>

                  {!disableChanges && (
                    <>
                      <Tooltip placement="top" title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            deleteCost(itm._id || null, itm.taxable)
                          }
                          disabled={disableChanges}
                        >
                          <DeleteIcon style={{ color: "crimson" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip placement="top" title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleOpen(itm)}
                          disabled={disableChanges}
                        >
                          <EditIcon color={"cyan" as "primary"} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </StyledRow>
              </StyledRow>
            ))}
            {costsArray.length < 3 && !disableChanges ? (
              <StyledRow
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <AddNewCost handleOpen={handleOpenNewCost} />
              </StyledRow>
            ) : null}
          </AccordionDetails>
        </StyledAccordion>
      ) : !disableChanges ? (
        <AddNewCost handleOpen={handleOpenNewCost} />
      ) : null}
    </StyledCol>
  );
};

interface AddNewCostProps {
  handleOpen: () => void
}

const AddNewCost: FC<AddNewCostProps> = ({ handleOpen }) => {
  return (
    <Button
      onClick={handleOpen}
      variant="outlined"
      startIcon={<AddIcon />}
      style={{ border: "none" }}
      size="small"
    >
      Add Cost
    </Button>
  );
};

export default AdditionalCosts;
