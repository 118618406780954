import React, { FC } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Grow } from "@mui/material";
import usePersistedState from "../hooks/usePersistedState";
import debounce from "../util/debounce";
import { useStore } from "../store/store";

const FlexRowDiv = styled("div")(({
  display: "flex", 
  justifyContent: "center"
}));

const FlexColDiv = styled("div")(({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

interface AddNotesProps {
  editQuote: boolean,
  submitPressed: boolean
}

const AddNotes: FC<AddNotesProps> = ({ editQuote, submitPressed }) => {
  const addToQuote = useStore(state => state.addToQuote);

  const [showNotes, setShowNotes] = usePersistedState(
    "showNotes",
    false,
    submitPressed,
    editQuote
  );

  const [notesText, setNotesText] = usePersistedState(
    "notesText",
    "",
    submitPressed,
    editQuote
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setNotesText(value);
    debounce(value, addToQuote, 'notes', 300);
  };

  const handleClick = () => setShowNotes(!showNotes);

  return <>
    <FlexRowDiv>
      <Button
        onClick={handleClick}
        style={{ width: "100%" }}
        variant="contained"
        color="cyan"
      >
        Add Notes
      </Button>
    </FlexRowDiv>
    <FlexColDiv>
      {showNotes && (
        <>
          <Grow in={showNotes}>
            <Typography style={{ padding: 5 }}>
              Notes are subject for review and may affect final price and lead
              time.
            </Typography>
          </Grow>

          <Grow in={showNotes} timeout={1000}>
            <TextareaAutosize
              maxLength={120}
              value={notesText}
              onChange={handleChange}
              minRows={3}
              style={{ width: "100%" }}
              placeholder="Add Notes..."
            />
          </Grow>
        </>
      )}
    </FlexColDiv>
  </>;
};

export default AddNotes;
