import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../Styles";
import { convertDecimalToFraction } from "../../../util/pdfFormulas/convertDecimalToFraction";
import { getKvalue, getNvalue } from "../RefKey";
import { Props } from "./PVCorner";

const refKeyLetters = ["A.L", "A.R", "B", "I", "J", "K", "L"];

const RefKeyCorner2: FC<Props> = ({ item }) => {
  const jamb = item.jamb ?
    item.jamb
    .replace("Standard", "")
    .replace('" Overall Jamb Width', "")
    .replace("-", " ")
    .trim()
    :
    ""

  const NL = getNvalue(
    item.addScreen || "",
    item.screenPocket || "",
    item.netFrameWidthWithPocket || "",
    item.unitSize,
    item.unitSizeFraction || ""
  );

  const NR = getNvalue(
    item.addScreen || "",
    item.screenPocket || "",
    item.netFrameWidthWithPocketRightCorner || "",
    item.cornerWidthRight || "",
    item.cornerWidthFractionRight || ""
  );

  const K =
    item.flashingFin &&
    getKvalue(item.flashingFinConfig || "", jamb, item.finSetback || "");

  const borderBottom = { borderBottom: "1px solid black" };

  return (
    <View style={[styles.column, { alignItems: "center", paddingTop: 10 }]}>
      <Text style={[styles.textBold, { fontSize: 12 }]}>Reference Key</Text>

      <View style={[styles.row, { border: "1px solid black" }]}>
        <View style={[styles.column, { borderRight: "1px solid black" }]}>
          {refKeyLetters.map((letter) => (
            <Text
              key={letter}
              style={[styles.refKeyText, borderBottom]}
            >
              {letter}
            </Text>
          ))}

          {item.addScreen && item.screenPocket ? (
            <>
              <Text style={[styles.refKeyText, borderBottom]}>
                M.L
              </Text>
              <Text style={[styles.refKeyText, borderBottom]}>
                M.R
              </Text>
              <Text style={[styles.refKeyText, borderBottom]}>
                N.L
              </Text>
              <Text style={[styles.refKeyText, borderBottom]}>
                N.R
              </Text>
            </>
          ) : (
            <Text />
          )}

          {item.kerf ? (
            <Text style={[styles.refKeyText, borderBottom]}>
              KF
            </Text>
          ) : (
            <Text />
          )}
        </View>
        <View style={[styles.column, { alignItems: "center" }]}>
          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.unitSize} {item.unitSizeFraction || ""}" Net Frame Width
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.cornerWidthRight} {item.cornerWidthFractionRight || ""}" Net
              Frame Width
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.height} {item.heightFraction || ""}" Net Frame Height
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>{jamb}" Overall Jamb Width</Text>
          </View>

          <View style={styles.refKeyCol}>
            {item.sillStyle === "Ultra Guide" ||
            item.sillStyle === "ADA Ultra Guide" ? (
              <Text style={styles.refKeyText}>N/A</Text>
            ) : (
              <Text style={styles.refKeyText}>{jamb}" Sill Width</Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            {K ? (
              <Text style={styles.refKeyText}>
                {convertDecimalToFraction(K)}" Behind Flashing Fin
              </Text>
            ) : (
              <Text style={styles.refKeyText}>N/A (No Flashing Fin)</Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            {item.flashingFin && item.flashingFinConfig !== "Custom Setback" ? (
              <Text style={styles.refKeyText}>
                {item.flashingFinConfig?.replace("Standard", "").includes("7/8")
                  ? '1" Setback'
                  : item.flashingFinConfig?.replace("Standard", "")}
              </Text>
            ) : item.flashingFin &&
              item.flashingFinConfig === "Custom Setback" ? (
              <Text style={styles.refKeyText}>{item.finSetback}" Setback</Text>
            ) : (
              <Text style={styles.refKeyText}>N/A (No Flashing Fin)</Text>
            )}
          </View>

          {item.addScreen && item.screenPocket ? (
            <>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {item.netFrameWidthWithPocket}" Width w/Pocket
                </Text>
              </View>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {item.netFrameWidthWithPocketRightCorner}" Width w/Pocket
                </Text>
              </View>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {convertDecimalToFraction(NL)}" Screen Pocket
                </Text>
              </View>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {convertDecimalToFraction(NR)}" Screen Pocket
                </Text>
              </View>
            </>
          ) : (
            <Text />
          )}

          {item.kerf ? (
            <View style={styles.refKeyCol}>
              {item.kerfConfig !== "Custom Setback" ? (
                <Text style={styles.refKeyText}>
                  {item.kerfConfig?.replace("Standard", "")}
                </Text>
              ) : item.kerfConfig === "Custom Setback" ? (
                <Text style={styles.refKeyText}>
                  {item.kerfSetback}" Setback
                </Text>
              ) : (
                <Text style={styles.refKeyText}>N/A (No Kerf)</Text>
              )}
            </View>
          ) : (
            <Text />
          )}
        </View>
      </View>
    </View>
  );
};

export default RefKeyCorner2;
