import React, { useState, useEffect, FC } from "react";
import {
  Switch,
  FormControl,
  FormControlLabel,
  Modal,
  Typography,
  Button,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import getModalStyle from "../../../util/getModalStyle";
import { useQueryClient } from "react-query";
import round from "../../../util/round";
import { postAddCost, updateAddCost } from "../../../api/OrderSummaryApi";
import { AddCostObj } from "../../../GlobalTypes";
import { RowLayout, ColLayout, StyledModalDiv } from "../../../util/accordianStyles";

interface AddCostsModalProps {
  open: boolean;
  handleClose: () => void;
  editCost: AddCostObj;
  quoteId: string;
  margin: string;
  withMarkup: boolean;
  hasDealerTax: boolean;
}

const AddCostsModal: FC<AddCostsModalProps> = ({
  open,
  handleClose,
  editCost,
  quoteId,
  margin,
  withMarkup,
  hasDealerTax,
}) => {
  const queryClient = useQueryClient();

  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [cost, setCost] = useState({ amount: "", name: "" });

  const [err, setErr] = useState("");

  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!editCost?.title && !editCost?.price) return;

    setCost({
      amount: `${editCost.price}`,
      name: editCost.title,
    });
    setChecked(editCost.taxable);
  }, [editCost]);

  const handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value.includes("-")) return;

    if (value.includes(".")) {
      const dec = value.split(".")[1];
      if (dec && dec.length > 2) return;
    }

    if (Number(value) > 1000000) return;

    setCost({ ...cost, [name]: value });
  };

  const handleCancel = () => {
    setCost({ amount: "", name: "" });
    setChecked(true);
    setLoading(false);
    setErr("");
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(event.target.checked);

  const addCost = async () => {
    if (!cost.amount || !cost.name) {
      return setErr("Please Fill Out Both Fields");
    }

    setErr("");
    setLoading(true);

    const response = await postAddCost(
      quoteId,
      cost.name,
      round(Number(cost.amount)),
      hasDealerTax ? false : checked,
      margin,
      withMarkup
    );

    if (response.data.matchedCount === 1) {
      queryClient.invalidateQueries("Cart");
      handleCancel();
    } else {
      setLoading(false);
      setErr("Error: A Problem Occurred");
    }
  };

  const updateCost = async () => {
    if (!cost.amount || !cost.name) {
      return setErr("Please Fill Out Both Fields");
    }

    setErr("");
    setLoading(true);

    const response = await updateAddCost(
      quoteId,
      editCost._id || "",
      cost.name,
      round(Number(cost.amount)),
      hasDealerTax ? false : checked,
      margin,
      withMarkup
    );

    if (response.data.matchedCount === 1) {
      queryClient.invalidateQueries("Cart");
      handleCancel();
    } else {
      setLoading(false);
      setErr("Error: A Problem Occurred");
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle}>
      <ColLayout>
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            borderBottom: "1px solid black",
            width: "100%",
            paddingBottom: 10,
          }}
        >
          Additional Cost
        </Typography>

        <RowLayout style={{ paddingTop: 10 }}>
          <Typography style={{ paddingRight: 20 }}>Name:</Typography>

          <FormControl size="small">
            <OutlinedInput
              value={cost.name}
              name="name"
              onChange={handleCostChange}
              style={{ maxWidth: 150 }}
              autoComplete="false"
            />
          </FormControl>
        </RowLayout>

        <RowLayout style={{ paddingBottom: 10 }}>
          <Typography style={{ paddingRight: 20 }}>Amount:</Typography>

          <FormControl size="small">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              value={cost.amount}
              name="amount"
              onChange={handleCostChange}
              style={{ maxWidth: 150 }}
              type="number"
              // step=".01"
            />
          </FormControl>
        </RowLayout>

        {!hasDealerTax && (
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label="Taxable"
            />
          </FormControl>
        )}

        {err && <Typography style={{ color: "red" }}>{err}</Typography>}

        <RowLayout style={{ paddingTop: 20 }}>
          <Button
            onClick={handleCancel}
            variant="contained"
            style={{ marginRight: 10 }}
            color="inherit"
          >
            Cancel
          </Button>

          {!loading ? (
            <Button
              onClick={editCost?.price ? updateCost : addCost}
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              Apply
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              <CircularProgress size={24} style={{ color: "white" }} />
            </Button>
          )}
        </RowLayout>
      </ColLayout>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleCancel}>
      {body}
    </Modal>
  );
};

export default AddCostsModal;
