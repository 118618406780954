import React, { FC } from "react";
import { TableCell, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import GenerateDealer from "../../../../../quotePDF/GenerateDealer";
import { HtmlTooltip } from "../StatusTooltip";
import { QuoteObj } from "../../../../../GlobalTypes";
import { DealerState } from "../../QuotesTable";

interface QuotePdfProps {
  row: QuoteObj;
  handleViewPdf: null | ((id: string, userId: string, quoteStatus: string) => void);
  dealer: DealerState | null;
  viewPdfId: string;
}

const QuotePdf: FC<QuotePdfProps> = ({
  row,
  viewPdfId,
  dealer,
  handleViewPdf,
}) => {
  const missingRequiredInfo = row.units
    ? row.units.some((unit) => unit.required && unit.required.length)
    : [];

  return (
    <TableCell padding="checkbox" align="center" style={{ width: 100 }}>
      {row.units && row.units.length > 0 && missingRequiredInfo ? (
        <HtmlTooltip title={"Missing Required Info"}>
          <IconButton aria-label="download" size="large">
            <GetAppIcon />
          </IconButton>
        </HtmlTooltip>
      ) : row.units &&
        row.units.length > 0 &&
        viewPdfId !== `${row._id}quote` ? (
        <HtmlTooltip title={"Download PDF"}>
          <IconButton
            onClick={() => handleViewPdf && handleViewPdf(row._id, row.userId, "quote")}
            aria-label="download"
            size="large"
          >
            <GetAppIcon color={"primary"} />
          </IconButton>
        </HtmlTooltip>
      ) : row.units &&
        row.units.length > 0 &&
        viewPdfId === `${row._id}quote` ? (
        <GenerateDealer
          data={row}
          series={row.seriesOverride || "Default"}
          isTable={true}
          isOrder={false}
          codOrTerms={dealer?.terms || ""}
          poNumber={row.poNumber || ""}
          region={dealer?.region || ""}
          dealerTax={dealer?.dealerTax || 0}
          factor={row.factor}
          grandTotal={row.grandTotal || "N/A"}
          suggestedRetail={row.suggestedRetailPrice || "N/A"}
        />
      ) : !row.units || !row.units.length ? (
        <HtmlTooltip title={"No Units in Quote"}>
          <IconButton aria-label="download" size="large">
            <GetAppIcon />
          </IconButton>
        </HtmlTooltip>
      ) : null}
    </TableCell>
  );
};

export default QuotePdf;
