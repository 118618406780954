import React, {FC} from "react";
import { View, Image } from "@react-pdf/renderer";
import { bifoldCornerJambLegImg } from "../../../Drawings/CAD";
import { UnitObj } from "../../../util/quoteCartStorage";

function isOddConfig(config: string) {
  return Number(config.split("")[0]) % 2 !== 0;
}

interface JambLegProps {
  item: UnitObj
}

const JambLegCorner: FC<JambLegProps> = ({ item }) => {
  const left = isOddConfig(item.exactConfig) && item.primaryDoor === "Left";
  const right = isOddConfig(item.cornerConfigRight || "") && item.primaryDoor === "Right";

  const oneSideOdd = left || right;

  const cornerImg = bifoldCornerJambLegImg(
    item.cornerType || "",
    item.material,
    item.screenType || "",
    item.addScreen || "",
    item.screenPocket || "",
    item.primaryDoor || "",
    oneSideOdd
  );

  const bifoldStyle = { width: 280, height: 224 };

  return (
    <View style={{ width: 280, height: 250 }}>
      <Image src={cornerImg} style={bifoldStyle} />
    </View>
  );
};

export default JambLegCorner;
