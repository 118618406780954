function resetModalTextHandler(
  prevQuote: any,
  newQuote: any,
  ignoreKey?: string
): string[] {
  var modalText: string[] = [];

  for (const key in newQuote) {
    if (prevQuote[key] && !newQuote[key]) {
      if (key !== ignoreKey) {
        if (key === "woodSpecies") modalText.push("Wood Species");
        if (key === "swingType") modalText.push("Swing Type");
        if (key === "unitSize") modalText.push("Unit Size");
        if (key === "swingPanelWidth") modalText.push("Panel Width");
        if (key === "pivotHardware") modalText.push("Pivot Hardware");
        if (key === "pivotAction") modalText.push("Pivot Movement");
        if (key === "stilesAndRails") modalText.push("Stiles");
        if (key === "handleStyle") modalText.push("Handle");
        if (key === "handleColor") modalText.push("Handle Finish");
        if (key === "addDL") modalText.push("Divided Lites");
        if (key === "addSlidingScreen") modalText.push("Screen");
        if (key === "addScreen") modalText.push("Screen");
        if (key === "pivotPoint") modalText.push("Pivot Point");
        if (key === "dropseal") modalText.push("Drop Seal");
        if (key === "pivotMagnet") modalText.push("Ghostop Magnet");
        if (
          key === "paintAndPrime" ||
          key === "stain" ||
          key === "prime" ||
          key === "customPaintOrStain"
        )
          modalText.push("Paint/Stain");
        if (key === "jamb") modalText.push("Jamb");
      }
    }
  }

  return modalText;
}

export default resetModalTextHandler;
