import React, { FC } from "react";
import { Text, Image, View } from "@react-pdf/renderer";

import { screenImg } from "../../Drawings/CAD";

import bifoldCloudinaryImg from "../../Drawings/ElevationPlanViewBifold";
import {
  swingCloudinaryImg,
  swingProdCloudinaryImg,
} from "../../Drawings/ElevationPlanViewSwing";
import sliderCloudinaryImg from "../../Drawings/ElevationPlanViewSlider";
import bifoldWindowCloudinaryImg from "../../Drawings/EPVwindow";
import { getPivotEVImage, getPivotPVImage } from "../../Drawings/EPVPivot";
import { fixedEVPV } from "../../Drawings/EPVFixedPanel";
import { styles } from "./Styles";
import { UnitObj } from "../../util/quoteCartStorage";

interface AsViewedFromExtProps {
  addDL: string;
  liteConfig: string;
}

const AsViewedFromExt: FC<AsViewedFromExtProps> = ({ addDL, liteConfig }) => {
  const text =
    addDL === "Add Intermediate Rail"
      ? "As Viewed from Exterior (Intermediate Rail not shown)"
      : addDL && liteConfig !== '1 3/4"'
      ? "As Viewed from Exterior (SDL not shown)"
      : addDL && liteConfig === '1 3/4"'
      ? "As Viewed from Exterior (TDL not shown)"
      : "As Viewed from Exterior";

  return (
    <View style={{ padding: 5 }}>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

interface Props {
  item: UnitObj;
  isProduction?: boolean;
}

const EVandPV: FC<Props> = ({ item, isProduction }) => {
  if (item.doorType === "Swing") {
    return <SwingDetails item={item} isProduction={isProduction} />;
  }

  if (item.doorType === "Slider") {
    return <SliderDetails item={item} />;
  }

  if (item.doorType.includes("Bi-fold")) {
    return <BifoldDetails item={item} isProduction={isProduction} />;
  }

  if (item.doorType === "Fixed Panel") {
    return <FixedPanelDetails item={item} isProduction={isProduction} />;
  }

  if (item.doorType === "Pivot") {
    return <PivotDetails item={item} isProduction={isProduction} />;
  }

  return <Text />;
};

const PivotDetails: FC<Props> = ({ item, isProduction }) => {
  const ev = getPivotEVImage(
    item.sideLite || "",
    item.handleStyle || "",
    item.exactConfig.slice(-2),
    item.exactConfigImg?.includes("LOCK") ? "LOCK" : "",
    item.equalPanels || false
  );

  const pv = getPivotPVImage(
    item.sideLite || "",
    item.exactConfig.slice(-2),
    item.exactConfigImg?.includes("LOCK") ? "LOCK" : "",
    item.equalPanels || false
  );

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
        Elevation View: Qty. {item.quantity} {item.exactConfig}{" "}
        {item.equalPanels ? "Equal" : ""}
      </Text>

      <AsViewedFromExt
        addDL={item.addDL || ""}
        liteConfig={item.liteConfig || ""}
      />

      <Image
        src={ev}
        style={{
          width:
            item.sideLite && item.sideLite !== "none" && item.equalPanels
              ? 280
              : 210,
          height: 300,
          zIndex: 10,
        }}
      />

      <Text
        style={[
          styles.textBold,
          {
            fontSize: 12,
            paddingBottom: 5,
          },
        ]}
      >
        Plan View:
      </Text>

      <Image src={pv} style={{ width: 414, height: 100 }} />
    </>
  );
};

const SwingDetails: FC<Props> = ({ item, isProduction }) => {
  const numberOfPanels = parseInt(item.numberOfPanels || "");

  const screenPlanStyles =
    numberOfPanels === 2 && item.sideLite !== "none"
      ? styles.screenImgSwing
      : styles.screenImgSwingSmall;

  const { ev, pv } = !isProduction
    ? swingCloudinaryImg(
        item.swingDirection || "",
        item.swingType || "",
        item.sideLite || "",
        item.equalPanels || false,
        item.exactConfig.slice(-2),
        item.exactConfigImg?.replace(".jpg", "").slice(-4) || ""
      )
    : swingProdCloudinaryImg(
        item.swingDirection || "",
        item.swingType || "",
        item.sideLite || "",
        item.equalPanels || false,
        item.exactConfig.slice(-2),
        item.exactConfigImg?.replace(".jpg", "").slice(-4) || "",
        item.stilesAndRails || ""
      );

  const screenImgSrc =
    item.addScreen && item.swingDirection !== "Inswing"
      ? screenImg(
          item.addSlidingScreen || "",
          item.addScreen,
          item.screenLayout || "",
          item.swingDirection || "",
          item.screenType || "",
          item.doorType
        )
      : "";

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
        Elevation View: Qty. {item.quantity}{" "}
        {item.swingDirection === "Outswing" ? "Swings Out" : "Swings In"}{" "}
        {item.exactConfig.split(" ").splice(1).join(" ")}{" "}
        {item.equalPanels ? "Equal" : ""}
      </Text>

      <AsViewedFromExt
        addDL={item.addDL || ""}
        liteConfig={item.liteConfig || ""}
      />

      <Image src={ev} style={{ width: 350, height: 300, zIndex: 10 }} />

      <Text
        style={[
          styles.textBold,
          {
            fontSize: 12,
            // paddingBottom: item.swingDirection === "Inswing" ? 25 : 5,
            paddingBottom: 25,
          },
        ]}
      >
        Plan View:
      </Text>

      {item.addScreen && item.swingDirection !== "Inswing" ? (
        <Image src={screenImgSrc} style={screenPlanStyles} />
      ) : (
        <Text />
      )}

      <Image src={pv} style={styles.planViewSwing} />
    </>
  );
};

const SliderDetails: FC<{ item: UnitObj }> = ({ item }) => {
  const numberOfPanels = parseInt(item.numberOfPanels || "");

  const screenPlanStyles =
    numberOfPanels <= 3 ? styles.sliderScreenSmall : styles.sliderScreen;

  const { ev, pv } = sliderCloudinaryImg(item.exactConfig, item.sliderPocket || "");

  const screenImgSrc =
    item.addScreen || item.addSlidingScreen
      ? screenImg(
          item.addSlidingScreen || "",
          item.addScreen || "",
          item.screenLayout || "",
          item.swingDirection || "",
          item.screenType || "",
          item.doorType
        )
      : "";

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
        Elevation View: Qty. {item.quantity} {item.doorType} {item.exactConfig}
      </Text>

      <AsViewedFromExt
        addDL={item.addDL || ""}
        liteConfig={item.liteConfig || ""}
      />

      <Image src={ev} style={styles.elevationView} />

      <Text style={[styles.textBold, { fontSize: 12, paddingBottom: 15 }]}>
        Plan View:
      </Text>

      {item.addScreen && !item.addSlidingScreen ? (
        <Image src={screenImgSrc} style={screenPlanStyles} />
      ) : (
        <Text />
      )}

      <Image src={pv} style={styles.planViewSwing} />

      {item.addSlidingScreen && item.screenType === "Sliding Screen" ? (
        <Image src={screenImgSrc} style={screenPlanStyles} />
      ) : (
        <Text />
      )}
    </>
  );
};

const BifoldDetails: FC<Props> = ({ item, isProduction }) => {
  const numberOfPanels = parseInt(item.numberOfPanels || "");

  const screenPlanStyles =
    numberOfPanels >= 5
      ? styles.screenImg
      : numberOfPanels < 5 && numberOfPanels > 2
      ? styles.screenImgSmall
      : styles.screenImgXSmall;

  const planViewStyles =
    numberOfPanels <= 4 ? styles.planViewBfoldSmall : styles.planViewBfold;

  const { ev, pv } =
    item.doorType === "Bi-fold"
      ? bifoldCloudinaryImg(item.exactConfig, item.primaryDoor || "", isProduction)
      : bifoldWindowCloudinaryImg(
          item.exactConfig,
          item.primaryDoor || "",
          isProduction || false
        );

  const screenImgSrc = item.addScreen
    ? screenImg(
        item.addSlidingScreen || "",
        item.addScreen,
        item.screenLayout || "",
        item.swingDirection || "",
        item.screenType || "",
        item.doorType
      )
    : "";

  const specifyPrimary = [
    "3L1R",
    "1L3R",
    "5L1R",
    "1L5R",
    "3L3R",
    "7L1R",
    "1L7R",
    "5L3R",
    "3L5R",
  ];

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      {specifyPrimary.includes(item.exactConfig) && item.primaryDoor ? (
        <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
          Elevation View: Qty. {item.quantity} {item.doorType}{" "}
          {item.exactConfigFull} ({`Primary Door: ${item.primaryDoor}`})
        </Text>
      ) : (
        <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
          Elevation View: Qty. {item.quantity} {item.doorType}{" "}
          {item.exactConfigFull}
        </Text>
      )}

      <AsViewedFromExt
        addDL={item.addDL || ""}
        liteConfig={item.liteConfig || ""}
      />

      <Image src={ev} style={styles.elevationView} />

      <Text style={[styles.textBold, { fontSize: 12, paddingBottom: 5 }]}>
        Plan View:
      </Text>

      {item.addScreen ? (
        <Image src={screenImgSrc} style={screenPlanStyles} />
      ) : (
        <Text />
      )}

      <Image src={pv} style={planViewStyles} />
    </>
  );
};

const FixedPanelDetails: FC<Props> = ({ item, isProduction }) => {
  const { ev, pv } = fixedEVPV(item.exactConfig, isProduction);

  return (
    <>
      <Text
        style={[
          styles.text,
          { textDecoration: "underline", fontSize: 10, zIndex: 20 },
        ]}
      >
        All Images Not Drawn to Scale or Proportion
      </Text>

      <Text style={[styles.textBold, { fontSize: 12, zIndex: 20 }]}>
        Elevation View: Qty. {item.quantity} {item.doorType} {item.exactConfig}
      </Text>

      <AsViewedFromExt
        addDL={item.addDL || ""}
        liteConfig={item.liteConfig || ""}
      />

      <Image src={ev} style={styles.elevationViewFixedPanel} />

      <Text style={[styles.textBold, { fontSize: 12, paddingBottom: 5 }]}>
        Plan View:
      </Text>

      <Image src={pv} style={styles.planViewFixedPanel} />
    </>
  );
};

export default EVandPV;
