import React, { memo, FC } from "react";
import { Image } from "cloudinary-react";
import { useStore, CreateStore } from "../store/store";

const bifoldImgs: string[] = [
  "/ImageBanner/bifolds/bifold_img1",
  "/ImageBanner/bifolds/bifold_img2",
  "/ImageBanner/bifolds/bifold_img3",
  "/ImageBanner/bifolds/bifold_img4",
  "/ImageBanner/bifolds/bifold_img5",
];

const sliderImgs: string[] = [
  "/ImageBanner/sliders/slider1",
  "/ImageBanner/sliders/slider2",
  "/ImageBanner/sliders/slider3",
  "/ImageBanner/sliders/slider4",
  "/ImageBanner/sliders/slider5",
  "/ImageBanner/sliders/slider6",
];

const swingImgs: string[] = [
  "/ImageBanner/swings/swing1",
  "/ImageBanner/swings/swing2",
  "/ImageBanner/swings/swing3",
];

const fixedImgs: string[] = ["/ImageBanner/fixed_panels/fixed2"];

const pivotImgs: string[] = ["/ImageBanner/pivots/pivot1"];

const showRandomImg = (imageArray: string[]): string => {
  const randomImage = Math.floor(Math.random() * imageArray.length);
  return imageArray[randomImage];
};

const ImageBanner: FC = () => {
  const doorType = useStore((state: CreateStore) => state.quote.doorType);

  const imgType = doorType.includes("Bi-fold")
    ? bifoldImgs
    : doorType === "Slider"
    ? sliderImgs
    : doorType === "Swing"
    ? swingImgs
    : doorType === "Fixed Panel"
    ? fixedImgs
    : doorType === "Pivot"
    ? pivotImgs
    : null;

  const displayedBanner = imgType && showRandomImg(imgType);

  return (
    <>
      {displayedBanner && (
        <Image
          style={{ marginBottom: 10, marginTop: 60, borderRadius: 5 }}
          cloudName="ag-millworks"
          secure={true}
          publicId={displayedBanner}
        />
      )}
    </>
  );
};

export default memo(ImageBanner);
