import api from "./api";
import { QuoteFormState } from "../pages/QuoteForm";

async function getUser(id: string) {
  return await api.get("/userInfo", {
    params: {
      id,
      page: "Quote Form",
    },
  });
}

async function getQuoteFormData(userId: string) {
  return await api.get("/api/history/getQuoteForm", {
    params: {
      userId,
      quoteNumber: JSON.parse(sessionStorage.getItem("quoteNumber") || JSON.stringify("")),
    },
  });
}

async function getUserFromEmail(email: string) {
  return await api.get("/userInfo", {
    params: {
      email,
    },
  });
}

async function postCreateQuote(
  userId: string,
  quoteForm: QuoteFormState,
  salesTax: number | null
) {
  return await api.post("/api/history", {
    userId,
    quoteForm,
    salesTax,
  });
}

async function postEditForm(
  quoteId: string,
  updatedForm: QuoteFormState,
  salesTax: number | null
) {
  return await api.put("/api/editQuoteForm", {
    quoteId,
    updatedForm,
    salesTax,
  });
}

async function getSalesTax(zip: string, city: string | null) {
  return await api.post("/api/zipTax", {
    zip,
    city,
  });
}

export {
  getUser,
  getUserFromEmail,
  getQuoteFormData,
  postCreateQuote,
  getSalesTax,
  postEditForm,
};
