import React, { useState, FC } from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { styled } from "@mui/material/styles";

const StyledFooter = styled("footer")({
  position: "absolute",
  bottom: 0,
  height: "2rem",
});

const StyledSpan = styled("span")({
  fontSize: 10,
  textDecoration: "underline",
  "&&:hover": {
    cursor: "pointer",
  },
});

const StyledP = styled("p")({
  fontSize: 10,
  opacity: 0.5,
  textAlign: "center",
});

interface CopyrightProps {
  position: string
}

const Copyright: FC<CopyrightProps> = ({ position }) => {
  const year = new Date().getFullYear();

  const [show, setShow] = useState(false);

  return (
    <>
      <StyledFooter
        style={{
          right:
            position === "right" ? 10 : position === "center1" ? "35%" : "50%",
        }}
      >
        <StyledP>
          <span style={{ fontSize: 12 }}>©</span> {year} AG Millworks |{" "}
          <StyledSpan onClick={() => setShow(true)}>Privacy</StyledSpan>
        </StyledP>
      </StyledFooter>

      <PrivacyPolicy show={show} setShow={setShow} />
    </>
  );
};

export default Copyright;
