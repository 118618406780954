import React, { memo, useEffect } from "react";
import { Typography } from "@mui/material";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import showOptions from "./showOptions";
import fractionToInt from "../../util/fractionToInt";
import { Image, Transformation } from "cloudinary-react";
import findImages from "./findImages";
import { Paper } from "@mui/material";
import { RowLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutSpace = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const ColLayoutLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

interface UpdateObj {
  pivotHardware?: string
  pivotAction?: string
  pivotPoint?: string
  pivotMagnet?: string
  stilesAndRails?: string
  stileCategory?: string
  hingeColor?: string
}

function PivotHardwareAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);
  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore(
    (state) => state.quote.swingPanelFraction
  );
  const dropseal = useStore((state) => state.quote.dropseal);
  const stilesAndRails = useStore((state) => state.quote.stilesAndRails);
  const pivotPoint = useStore((state) => state.quote.pivotPoint);

  const width = fractionToInt(swingPanelWidth || "", swingPanelFraction || "");

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[36].expanded);

  const handleClickHardware = (label: string) => {
    const checked = label === pivotHardware;
    if (checked) return;
    let update: UpdateObj = { pivotHardware: label };

    if (label === "Dorma" && pivotAction === "Self Closing") {
      update["pivotAction"] = "";
    }

    if (label === "Fritsjurgens") {
      if (pivotPoint && pivotPoint.includes('4-3/4"')) {
        update["pivotPoint"] = "";
      }
      if (pivotAction === "Self Closing") {
        // update['dropseal'] = ""
        update["pivotMagnet"] = "";
        if (stilesAndRails?.includes("Slimline")) {
          update["stilesAndRails"] = "";
          update["stileCategory"] = "";
        }
      } else if (dropseal) {
        update["hingeColor"] = "Stainless Steel";
      }
    }

    addToQuote(update);
  };

  const handleClickAction = (label: string) => {
    const checked = label === pivotAction;
    if (checked) return;

    let update: UpdateObj = { pivotAction: label };
    
    if(label === "Self Closing" && pivotHardware === "Fritsjurgens"){
      // update['dropseal'] = ""
      update["pivotMagnet"] = "";

      if (stilesAndRails?.includes("Slimline")) {
        update["stilesAndRails"] = "";
        update["stileCategory"] = "";
      }
    }

    addToQuote(update);
  };

  const hideDorma = width > 60;

  useEffect(() => {
    if (!pivotHardware || pivotHardware === "Fritsjurgens") return;

    if (hideDorma) {
      addToQuote({ pivotHardware: "" });
    }
  }, [hideDorma]);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="pivotHardware"
      heading="Pivot Hardware"
      secondaryHeading="Select Pivot Hardware/Movement"
      required={true}
      required5={true}
      colOrRow="col"
    >
      <RowLayout>
        <ColLayoutSpace>
          <Typography style={{ paddingBottom: 20 }}>Pivot Hardware</Typography>
          {showOptions(
            "hardware",
            handleClickHardware,
            pivotHardware || "",
            "",
            hideDorma
          )}
        </ColLayoutSpace>

        <ColLayoutLeftDivide>
          <Typography style={{ paddingBottom: 20 }}>Pivot Movement</Typography>
          {showOptions(
            "movement",
            handleClickAction,
            pivotAction || "",
            pivotHardware || ""
          )}
        </ColLayoutLeftDivide>
      </RowLayout>

      {pivotAction && pivotHardware && (
        <RowLayout style={{ paddingTop: 20 }}>
          <Paper elevation={3}>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId={findImages(pivotHardware, pivotAction)}
            >
              <Transformation width="300" crop="scale" />
            </Image>
          </Paper>
        </RowLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotHardwareAccordian);
