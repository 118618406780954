const cladRails = [
  {
    id: 1,
    type: "stilesAndRails",
    label: "Slimline | Ultra-Modern",
    stileCategory: "Slimline",
  },
  {
    id: 2,
    type: "stilesAndRails",
    label: "Euro | Contemporary",
    stileCategory: "Euro",
  },
  {
    id: 3,
    type: "stilesAndRails",
    label: "Traditional | Classic",
    stileCategory: "Traditional",
  },
];

const windowRails = [
  {
    id: 1,
    type: "stilesAndRails",
    label: "Slimline | Ultra-Modern",
    stileCategory: "Slimline",
  },
  {
    id: 2,
    type: "stilesAndRails",
    label: "Euro | Contemporary",
    stileCategory: "Euro",
  },
];

const slimline = [
  {
    id: 1,
    type: "stilesAndRails",
    label: "Slimline",
  },
  {
    id: 2,
    type: "stilesAndRails",
    label: "Slimline Traditional",
  },
];

const woodRails = [
  {
    id: 1,
    type: "stilesAndRails",
    label: "Euro | Contemporary",
    stileCategory: "Euro",
  },
  {
    id: 2,
    type: "stilesAndRails",
    label: "Traditional | Classic",
    stileCategory: "Traditional",
  },
];

const cladTradBtm = [
  {
    id: 1,
    type: "stilesAndRails",
    label: 'Bottom rail 7"',
  },
  {
    id: 2,
    type: "stilesAndRails",
    label: 'Bottom rail 4-3/4"',
  },
];

const lockRailFritsSelfClosing = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles with 3-7/16" Lock Stile and Top Rails and 4-3/4" Bottom Rails',
};

const lockRailFritsSwing = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Bottom Rails with 3-7/16" Lock Stile and Top Rails',
};

const lockRail = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Rails with 3-7/16" Lock Stile',
};

const lockRailSlider = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Top Rails with 3-7/16" Lock Stile and Bottom Rails',
};

const lockrailEven = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Rails with 2-1/2" Lock Stile',
};

const lockrailFixedPanel = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Rails',
};

const lockRailFPSlider = {
  id: 1,
  type: "lockRail",
  label: '2-1/2" Stiles and Top Rails with 3-7/16" and Bottom Rails',
};

export {
  lockRailFPSlider,
  lockrailFixedPanel,
  lockrailEven,
  lockRail,
  cladTradBtm,
  woodRails,
  slimline,
  windowRails,
  cladRails,
  lockRailSlider,
  lockRailFritsSelfClosing,
  lockRailFritsSwing
};
