import React, { FC } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const StyledInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 0,
});

const StyledShipDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingBottom: 30,
  width: "80%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const StyledShipLeftDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: 20,
  [theme.breakpoints.down("lg")]: {
    paddingBottom: 10,
    paddingRight: 0,
  },
}));

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

interface DeliveryInfoProps {
  handleAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  address: string;
  city: string;
  zip: string;
  dealerDelivery: boolean;
}

const DeliveryInfo: FC<DeliveryInfoProps> = ({
  handleAllChange,
  address,
  city,
  zip,
  dealerDelivery,
}) => {
  return (
    <>
      <StyledShipDiv>
        <StyledShipLeftDiv>
          <Typography style={{ flexBasis: "30%" }} variant="body2">
            Street: <span style={{ color: "red", fontSize: 22 }}>* </span>
          </Typography>
          <StyledInput
            maxLength={50}
            onChange={handleAllChange}
            name="address"
            value={address}
            placeholder="Street"
            disabled={dealerDelivery}
          />
        </StyledShipLeftDiv>

        <StyledInputDiv>
          <Typography style={{ flexBasis: "30%" }} variant="body2">
            City: <span style={{ color: "red", fontSize: 22 }}>* </span>
          </Typography>
          <StyledInput
            maxLength={50}
            onChange={handleAllChange}
            name="city"
            value={city}
            placeholder="City"
            disabled={dealerDelivery}
          />
        </StyledInputDiv>
      </StyledShipDiv>

      <StyledShipDiv>
        <StyledShipLeftDiv>
          <Typography style={{ flexBasis: "30%" }} variant="body2">
            State:
          </Typography>
          <StyledInput maxLength={30} disabled name="state" placeholder="CA" />
        </StyledShipLeftDiv>

        <StyledInputDiv>
          <Typography style={{ flexBasis: "30%" }} variant="body2">
            Zip Code: <span style={{ color: "red", fontSize: 22 }}>* </span>
          </Typography>
          <StyledInput
            maxLength={20}
            onChange={handleAllChange}
            name="zip"
            value={zip}
            placeholder="Zip Code"
            disabled={dealerDelivery}
          />
        </StyledInputDiv>
      </StyledShipDiv>
    </>
  );
};

export default DeliveryInfo;
