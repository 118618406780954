const baseURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

const specifyPrimary = [
  "3L1R",
  "1L3R",
  "5L1R",
  "1L5R",
  "3L3R",
  "7L1R",
  "1L7R",
  "5L3R",
  "3L5R",
];

function bifoldCloudinaryImg(
  config: string,
  primaryDoor: string,
  isProd?: boolean
) {
  const primaryAbbrev =
    primaryDoor === "Left" ? "_LH" : primaryDoor === "Right" ? "_RH" : "";

  const primary = specifyPrimary.includes(config) ? primaryAbbrev : "";

  const ev = `${baseURL}/elevationView/bifolds/${config}${primary}_EV.jpg`;
  const pv = !isProd
    ? `${baseURL}/planView/bifolds/${config}${primary}_PLAN.png`
    : `${baseURL}/planView/bifolds/production/${config}${primary}_PLAN.png`;

  return {
    ev,
    pv,
  };
}

export default bifoldCloudinaryImg;
