import React, { FC } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface CustomerHeardProps {
  heardAbout: string;
  handleChange: (
    e:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  heardAboutOther: string;
}

const CustomerHeard: FC<CustomerHeardProps> = ({
  heardAbout,
  handleChange,
  heardAboutOther,
}) => {
  return (
    <StyledDivCol>
      <Typography style={{ padding: 20 }}>
        Why are you quoting AG Millworks for this project?
      </Typography>

      <FormControl style={{ width: 355 }} size="small">
        <InputLabel>Select Option</InputLabel>
        <Select
          native
          name="heardAbout"
          value={heardAbout}
          onChange={handleChange}
          size="small"
          label="Select Option"
        >
          <option value="" />
          <option value="Customer discovered AG from Web Search">
            Customer discovered AG from Web Search
          </option>
          <option value="Customer discovered AG from Instagram">
            Customer discovered AG from Instagram
          </option>
          <option value="Customer discovered AG from Facebook">
            Customer discovered AG from Facebook
          </option>
          <option value="Customer discovered AG from Pinterest">
            Customer discovered AG from Pinterest
          </option>
          <option value="Customer discovered AG from magazine">
            Customer discovered AG from magazine
          </option>
          <option value="Salesperson guided customer to AG">
            Salesperson guided customer to AG
          </option>
          <option value="Leadtime">Leadtime</option>
          <option value="Quality and customer service">
            Quality and customer service
          </option>
          <option value="Specified by architect on plans">
            Specified by architect on plans
          </option>
          <option value="Other">Other</option>
        </Select>
      </FormControl>

      {heardAbout === "Other" && (
        <StyledDivRow style={{ width: 355, paddingTop: 10 }}>
          <Typography variant="subtitle2" style={{ paddingRight: 10 }}>
            Please Specify:{" "}
          </Typography>
          <TextField
            value={heardAboutOther}
            onChange={handleChange}
            name="heardAboutOther"
            size="small"
          />
        </StyledDivRow>
      )}
    </StyledDivCol>
  );
};

export default CustomerHeard;
