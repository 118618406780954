import { roundTo4 } from "../../quotePDF/CutSheets/StoryPole";

export function openingWidthFormula(
  stiles: string,
  panelWidth: number,
  doorType: string,
  swingDirection?: string,
  isSidelite?: boolean,
  exactConfig?: string
) {
  let opening;
  let openingDD;

  if (isSidelite) {
    opening = roundTo4(panelWidth - 3.75);
    return { opening, openingDD };
  }
  //add an extra 1/8 to lock stile on pivots per Antonio and Brian
  if (stiles.includes("Slimline")) {
    const sliderSlimEuroLock = [
      "XX Left Primary",
      "XX Right Primary",
      "PXX",
      "XXP",
      "PXA-XXP",
      "PXX-AXP",
    ];
    opening =
      doorType === "Slider" &&
      sliderSlimEuroLock.includes(exactConfig || "") &&
      stiles.includes("Slimline")
        ? roundTo4(panelWidth - 4.6875)
        : doorType !== "Swing" && doorType !== "Pivot"
        ? roundTo4(panelWidth - 3.75)
        : roundTo4(panelWidth - 4.6875);

    openingDD =
      doorType === "Swing" && swingDirection === "Inswing"
        ? roundTo4(panelWidth - 4.8125)
        : roundTo4(panelWidth - 4.6875);
  } else if (stiles.includes("Euro")) {
    const sub = 5.625;
    const subInswing = 5.75;

    opening = roundTo4(panelWidth - sub);

    openingDD =
      doorType === "Swing" && swingDirection === "Inswing"
        ? roundTo4(panelWidth - subInswing)
        : null;
  } else if (stiles.includes("Bottom")) {
    opening = roundTo4(panelWidth - 8.25);

    openingDD =
      doorType === "Swing" && swingDirection === "Inswing"
        ? roundTo4(panelWidth - 8.375)
        : null;
  }

  return { opening, openingDD };
}
