function findImages(hardware: string, action: string): string{
    if(!hardware) return "";

    if(hardware === "Dorma"){
        return "/PivotHardware/dorma_freeswing"
    }

    if(hardware === "Fritsjurgens"){
        return action === "Free Swing"
            ? "/PivotHardware/fritsjurgen_freeswing"
            : "/PivotHardware/fritsjurgen_selfclosing"
    }

    return ""
}

export default findImages