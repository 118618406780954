import React, { FC } from "react";
import { View, Text, Link } from "@react-pdf/renderer";
import { styles } from "./Styles";

interface DisclosuresProps {
  isDealer?: boolean
}

const Disclosures: FC<DisclosuresProps> = ({ isDealer }) => {
  return (
    <>
      <View  style={styles.rowCenterPad}>
        <Text style={styles.disclosuresHeading}>
          DISCLOSURES
        </Text>
      </View>

      <View style={styles.colStartPad}>
        {isDealer ? (
          <Text style={styles.disclosuresBold}>
            - Prices good for acceptance within 14 days unless extended in
            writing.
          </Text>
        ) : (
          <Text />
        )}
        <Text style={styles.disclosures}>
          - Lead Times subject to change at any time.
        </Text>
        <Text style={styles.disclosures}>
          - Any changes after order has been placed will result in
          extra charges, which will be to be determined and a delay in lead
          time.
        </Text>
        <Text style={styles.disclosures}>
          - Minimum of 2 people is required for hand unloading for jobsite deliveries, and only curbside delivery is available.
        </Text>
        <Text style={styles.disclosures}>
          - Please be advised that if the agreed upon jobsite delivery is not
          able to accept delivery when product is complete, the material will be
          shipped to the dealers shop and invoiced accordingly.
        </Text>
        {isDealer ? (
          <Text style={styles.disclosures}>
            - All prices are dealer's net cost and include freight to dealer.
          </Text>
        ) : (
          <Text />
        )}
        <Text style={styles.disclosures}>
          - AG Millworks reserves the right to change specifications without
          notice.
        </Text>
        <Text style={styles.disclosures}>
          - Please Note: Sill Pans, Flashing Fins, and other Waterproofing
          measures are required for proper installation of all products.
        </Text>
        <Text style={styles.disclosures}>
          - Units with a net frame larger than 16 feet wide or taller than 9 feet tall may be shipped knocked down (K.D.) for easier handling. 
        </Text>
        <Text style={styles.disclosures}>
          - All weights and measures shown are best approximations.
        </Text>
        <Text style={styles.disclosures}>
          - The dealer is responsible to deliver the applicable warranty to
          their customer.
        </Text>
        <Text style={styles.disclosures}>
          - Warranty Does Not cover Water, Wind Penetration, or Airborne
          Particles on Ultra Guide Channel, ADA, or Stainless-Steel Recessed
          Track.
        </Text>
        <Text style={styles.disclosures}>
          - It is the sole responsibility of the customer to provide an adequate
          weep system for the Ultra-Guide and ADA channel.{" "}
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            src="https://agmillworks.com/ultra-guide-drain/"
          >
            https://agmillworks.com/ultra-guide-drain/
          </Link>
        </Text>
        <Text style={styles.disclosures}>
          - A “seacoast” or “saltwater environment” is within three (3) miles of
          the coastline, lake, river or other body of water which contains
          saltwater, and we recommend S.S. Hardware on units near the coastal
          areas. Protective coatings, such as Boeshield T-9
          (www.boeshield.com/homeapplications/) should be applied to hinges,
          hangers and all moving parts as often as needed to maintain protection
          with coastal applications. (See Care and Handling Instructions, which
          can be found at www.agmillworks.com/door_care)
        </Text>
        <Text style={styles.disclosures}>
          - For the purpose of the all wood limited warranty, misuse is defined
          as, but not limited to, installing a product on any side of a
          structure without adequate overhang.
        </Text>
        <Text style={styles.disclosures}>
          - Special Notice regarding Accoya Wood (Acetylated Wood): Fasteners
          (screws, nails, etc.) made of lesser grade metals may corrode and
          cause discoloration if used to penetrate Accoya Wood, due to acetic
          acid contained in the wood as a result of the preservation process.
          Therefore, corrosion-resistant stainless-steel fasteners and fixtures
          should be exclusively used when screwing or nailing into Products made
          with Accoya Wood.
        </Text>
        <Text style={styles.disclosures}>
          - For full warranty details please visit the following link:{" "}
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            src="https://www.agmillworks.com/warranty"
          >
            https://www.agmillworks.com/warranty
          </Link>
        </Text>
      </View>
    </>
  );
};

export default Disclosures;
