const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/CutSheets";

function sillImagesPivot(doorMaterial: string, sidelite: string, sill: string) {
  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const sideliteLabel = sidelite !== "none" ? "_FIXED_SL" : "";

  const sillLbl = sill.includes("Aluminum") ? "_CLAD" : "_WOOD";

  const cloudinaryID = `${material}${sideliteLabel}${sillLbl}`.trim();

  return `${CLOUDINARY_URL}/sill/Pivot/${cloudinaryID}.jpg`;
}

function jambLegImagesPivot(
  doorMaterial: string,
  hinge: string,
  sidelite: string,
  sideliteSide: string
) {
  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const hingeDir = `_${hinge}`;

  let sideliteLabel = "";

  if (sidelite === "Add Single Sidelite") {
    if (sideliteSide === "LOCK") {
      sideliteLabel = hinge === "LH" ? "_RIGHT_SL" : "_LEFT_SL";
    } else {
      sideliteLabel = hinge === "LH" ? "_LEFT_SL" : "_RIGHT_SL";
    }
  } else if (sidelite === "Add Double Sidelite") {
    sideliteLabel = "_2SL";
  }

  const cloudinaryID = `${material}${hingeDir}${sideliteLabel}`.trim();

  return `${CLOUDINARY_URL}/jamb/Pivot/${cloudinaryID}_v3.jpg`;
}

export { sillImagesPivot, jambLegImagesPivot };
