const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/CutSheets";

function sillImagesSwing(
  doorMaterial: string,
  screenType: string,
  screen: string,
  swingDirection: string,
  jamb: string
) {
  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const sillLabel = swingDirection === "Outswing" ? "_OS" : "_IS";

  const screenLabel = !screen
    ? ""
    : screenType?.includes("Centor")
    ? "_CENTOR"
    : "_SCREEN";

  const jambLabel =
    swingDirection === "Outswing" || doorMaterial === "All Wood"
      ? ""
      : jamb.includes("5-1/8")
      ? "_5.125"
      : jamb.includes("6-1/16")
      ? "_6.0625"
      : jamb.includes("7")
      ? "_7"
      : jamb.includes("7-15/16")
      ? "_7.9375"
      : "";

  const cloudinaryID =
    `${material}${sillLabel}${screenLabel}${jambLabel}`.trim();

  return `${CLOUDINARY_URL}/sill/Swing/${cloudinaryID}.jpg`;
}

function sillImagesSidelite(doorMaterial: string, screenType: string, screen: string, swingDirection: string, sidelite: string) {
  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const sillLabel = swingDirection === "Outswing" ? "_OS" : "_IS";

  const screenLabel = 
    !screen
      ? ""
      : screenType?.includes("Centor")
      ? "_CENTOR"
      : "_SCREEN";
      
  const sideliteLabel = sidelite !== "none" ? "_FIXED_SL" : "";

  const cloudinaryID =
    `${material}${sillLabel}${screenLabel}${sideliteLabel}`.trim();

  return `${CLOUDINARY_URL}/sill/Swing/${cloudinaryID}.jpg`;
}

function jambLegImagesSwing(
  doorMaterial: string,
  screenType: string,
  screen: string,
  screenLayout: string,
  screenPocket: string,
  swingDirection: string,
  hinge: string,
  swingType: string,
  sidelite: string,
  jamb: string,
  sideliteSide: string
) {
  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const direction =
    swingDirection === "Outswing"
      ? "_OS"
      : swingDirection === "Inswing"
      ? "_IS"
      : "";

  const hingeDir = `_${hinge}`;

  const swing = `_${swingType.split(" ")[0].toUpperCase()}`;

  const screenLabel = !screen
    ? ""
    : screenType?.includes("Centor")
    ? "_CENTOR"
    : screen.includes("Double") &&
      screenPocket &&
      screenPocket.includes("Double")
    ? "_SCREEN_PKT_DBL"
    : screen && !screenPocket
    ? "_SCREEN"
    : screen && screenLayout && screenLayout.includes("Left") && screenPocket
    ? "_SCREEN_PKT_LEFT"
    : screen && screenLayout && screenLayout.includes("Right") && screenPocket
    ? "_SCREEN_PKT_RIGHT"
    : "";

  const sideliteLabel =
    sidelite === "Add Single Sidelite"
      ? "_FIXED_SL"
      : sidelite === "Add Double Sidelite"
      ? "_2X_FIXED_SL"
      : "";

  const jambLabel =
    swingDirection === "Outswing" || doorMaterial === "All Wood"
      ? ""
      : jamb.includes("5-1/8")
      ? "_5.125"
      : jamb.includes("6-1/16")
      ? "_6.0625"
      : jamb.includes("7")
      ? "_7"
      : jamb.includes("7-15/16")
      ? "_7.9375"
      : "";

  const singleWithSingleSidelite =
    swingType === "Single Swing" &&
    sidelite === "Add Single Sidelite" &&
    sideliteSide === "LOCK"
      ? true
      : false;

  let lockside = "";

  if (singleWithSingleSidelite) {
    lockside = "_LOCK";
  }

  const cloudinaryID =
    `${material}${screenLabel}${direction}${hingeDir}${swing}${sideliteLabel}${jambLabel}${lockside}`.trim();

  return `${CLOUDINARY_URL}/jamb/Swing/${cloudinaryID}.jpg`;
}

export { sillImagesSwing, sillImagesSidelite, jambLegImagesSwing };
