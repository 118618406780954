import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { findGlassQuantity } from "../../util/glass/findGlassQuantity";
import LockStileTable from "./LockStileTable";
import { roundTo4 } from "./StoryPole";
import fractionToInt from "../../util/fractionToInt";
import { calculatePanelHeight } from "../../util/pdfFormulas/calculatePanelHeight";
import { calculatePanelWidth } from "../../util/pdfFormulas/calculatePanelWidth";
import { calculateCornerPanelWidth } from "../../util/pdfFormulas/calculateCornerPanelWidth";
import calcSwingWidths from "../../util/pdfFormulas/calculateSwingWidths";
import iRailGlassHeight from "../../util/glass/iRailGlassHeight";
import iRailGlassWidth from "../../util/glass/iRailGlassWidth"
import { openingHeightFormula } from "../../util/glass/openingHeightFormula";
import { openingWidthFormula } from "../../util/glass/openingWidthFormula";
import { UnitObj } from "../../util/quoteCartStorage";

const styles = StyleSheet.create({
  columnEnd: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  columnCenter: {
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  borders: {
    borderBottom: "2px solid black",
    borderLeft: "2px solid black",
    borderTop: "2px solid black",
  },
  align: {
    alignSelf: "flex-start",
    paddingTop: 10,
  },
  headerFont: {
    fontSize: 8,
  },
});

export function round16th(size: number) {
  return Math.floor(roundTo4(size) * 16) / 16;
}

const PANEL_HEIGHT_IS_SL_ADD = 0.2187;
const PANEL_HEIGHT_OS_SL_ADD = 0.25;
const PANEL_HEIGHT_PIVOT_ADD = .5;

interface CutSheetLockStileProps {
  data: UnitObj
  cornerSide?: string
}

const CutSheetLockStile: FC<CutSheetLockStileProps> = ({ data, cornerSide }) => {
  var netWidth =
    data.doorType.includes("Corner") && cornerSide === "right"
      ? fractionToInt(
          data.cornerWidthRight || "",
          data.cornerWidthFractionRight || ""
        )
      : fractionToInt(data.unitSize, data.unitSizeFraction || "");

  var netHeight = fractionToInt(data.height, data.heightFraction || "");

  const panelWidth =
    (data.doorType === "Swing" || data.doorType === "Pivot") && data.sideLite === "none"
      ? calcSwingWidths(
          data.unitSize,
          data.unitSizeFraction || "",
          data.swingDirection || "",
          data.swingType || "",
          "none",
          "",
          "",
          false,
          data.material,
          data.doorType === "Pivot",
          data.addScreen && data.screenType ? data.screenType : "",
        ).panelW
      : data.doorType.includes("Corner")
      ? calculateCornerPanelWidth(
          cornerSide === "left"
            ? Number(data.numberOfPanelsLeftCorner)
            : Number(data.numberOfPanelsRightCorner),
          data.material,
          cornerSide === "left"
            ? Number(data.cornerFrameWidthLeft)
            : Number(data.cornerFrameWidthRight),
          (cornerSide === "left" && data.primaryDoor === "Left") ||
            (cornerSide === "right" && data.primaryDoor === "Right")
        )
      : calculatePanelWidth(
          data.doorType,
          netWidth,
          Number(data.numberOfPanels),
          data.material,
          data.exactConfig,
          data.stilesAndRails || "",
          data.addScreen && data.screenType ? data.screenType : "",
          data.swingPanelWidth || "",
          data.swingPanelFraction || "",
          data.sliderPocket || "",
          data.handleStyle || ""
        );

  const panelHeight = calculatePanelHeight(
    data.exactConfig,
    data.doorType,
    data.sillStyle || "",
    netHeight,
    data.addScreen && data.screenType ? data.screenType : "",
    data.material,
    data.swingDirection || ""
  );

  const roundPanelWidth = roundTo4(panelWidth || 0);
  const roundPanelHeight = roundTo4(panelHeight);

  const sidelitePanelHeight =
    data.doorType === "Swing" &&
    data.sideLite !== "none" &&
    data.swingDirection === "Outswing"
      ? roundTo4(roundPanelHeight + PANEL_HEIGHT_OS_SL_ADD)
      : data.doorType === "Swing" &&
        data.sideLite !== "none" &&
        data.swingDirection === "Inswing"
      ? roundTo4(roundPanelHeight + PANEL_HEIGHT_IS_SL_ADD)
      : data.doorType === "Pivot"
      ? roundTo4(roundPanelHeight + PANEL_HEIGHT_PIVOT_ADD)
      : 0;

  const { opening, openingDD } = openingWidthFormula(
    data.stilesAndRails || "",
    roundPanelWidth,
    data.doorType,
    data.swingDirection || "",
    false,
    data.exactConfig
  );

  let glassOpening = opening;
  let glassOpeningDD = openingDD;
  let glassOpeningSidelite;

  let openingHeight = 0;

  if(data.doorType === "Pivot" && data.addDL === "Add Intermediate Rail"){
    const irailHeight = iRailGlassHeight(
      panelHeight,
      data.stilesAndRails || "",
      data.pivotHardware || "",
      data.pivotAction || "",
      data.liteConfig || "",
      data.dlLayout || "",
      false
    );

    const irailWidth = iRailGlassWidth(
      panelWidth || 0,
      data.stilesAndRails || "",
      data.liteConfig || "",
      data.dlLayout || "",
      false
    );

    glassOpening = irailWidth.openingWidth
    openingHeight = irailHeight?.openingHeight || 0;
  }else{
    const open = openingHeightFormula(
      data.doorType,
      data.stilesAndRails || "",
      roundPanelHeight,
      data.doorType === "Pivot"
        ? `${data.pivotHardware} ${data.pivotAction}`
        : "",
      data.sliderPanelSqft || 0
    );
    openingHeight = open?.openingHeight || 0;
  }
  
  let glassOpeningHeight = openingHeight;

  let sideliteGlassHeight;
  let sideliteGlassHeightTopBtm;

  if(data.doorType === "Pivot" && data.addDL === "Add Intermediate Rail" && data.sideLite !== "none"){
    const irailSidelite = iRailGlassHeight(
      panelHeight,
      data.stilesAndRails || "",
      data.pivotHardware || "",
      data.pivotAction || "",
      data.liteConfig || "",
      data.dlLayout || "",
      true
    );
    sideliteGlassHeight = irailSidelite.openingHeight
    sideliteGlassHeightTopBtm = irailSidelite.openingHeightTopBtm || irailSidelite.openingHeight
  }else if((data.doorType === "Swing" || data.doorType === "Pivot") && data.sideLite !== "none"){
    sideliteGlassHeight =
    ( data.doorType === "Swing" || data.doorType === "Pivot") &&
     data.sideLite !== "none" &&
     openingHeightFormula(
       data.doorType,
       data.stilesAndRails || "",
       sidelitePanelHeight
     ).openingHeight;
  }

  let isCornerSideEven = false;

  if (data.doorType.includes("Corner")) {
    isCornerSideEven =
      cornerSide === "left" &&
      Number(data.exactConfig.split("")[0]) % 2 === 0 &&
      Number(data.cornerConfigRight?.split("")[0]) % 2 !== 0
        ? true
        : cornerSide === "right" &&
          Number(data.exactConfig.split("")[0]) % 2 !== 0 &&
          Number(data.cornerConfigRight?.split("")[0]) % 2 === 0
        ? true
        : false;
  } else {
    isCornerSideEven = false;
  }

  const glassQty = findGlassQuantity(
    data.quantity,
    data.stilesAndRails || "",
    !data.doorType.includes("Corner")
      ? Number(data.numberOfPanels)
      : cornerSide === "left"
      ? Number(data.numberOfPanelsLeftCorner)
      : Number(data.numberOfPanelsRightCorner),
    data.doorType,
    data.doorType.includes("Corner") && cornerSide === "right" && data.cornerConfigRight
      ? data.cornerConfigRight
      : data.exactConfig,
    data.swingType || "",
    data.swingDirection || "",
    data.sideLite || "",
    data.sliderPocket || "",
    isCornerSideEven,
    data.addDL,
    data.dlLayout
  );

  const sideliteWidth =
    (data.doorType === "Swing" || data.doorType === "Pivot") &&
    data.sideLite !== "none" &&
    round16th(
      data.equalPanels
        ? calcSwingWidths(
            data.unitSize,
            data.unitSizeFraction || "",
            data.swingDirection || "",
            data.swingType || "",
            data.sideLite || "",
            data.swingPanelWidth || "",
            data.swingPanelFraction || "",
            data.equalPanels || "",
            data.material,
            data.doorType === "Pivot",
            data.addScreen && data.screenType ? data.screenType : ""
          ).equalized
        : calcSwingWidths(
            data.unitSize,
            data.unitSizeFraction || "",
            data.swingDirection || "",
            data.swingType || "",
            data.sideLite || "",
            data.swingPanelWidth || "",
            data.swingPanelFraction || "",
            data.equalPanels || false,
            data.material,
            data.doorType === "Pivot",
            data.addScreen && data.screenType ? data.screenType : ""
          ).slw
    );

  if ((data.doorType === "Swing" || data.doorType === "Pivot") && data.sideLite !== "none") {
    if(data.doorType === "Pivot" && data.addDL === "Add Intermediate Rail"){
      const irailWidth = iRailGlassWidth(
        sideliteWidth || 0,
        data.stilesAndRails || "",
        data.liteConfig || "",
        data.dlLayout || "",
        true
      );
      glassOpeningSidelite = irailWidth.openingWidth
    }else{
      glassOpeningSidelite = openingWidthFormula(
        data.stilesAndRails || "",
        sideliteWidth || 0,
        data.doorType,
        data.swingDirection,
        true
      ).opening;
    }
  }

  return (
    <View style={styles.columnEnd}>
      {glassQty.qty && 
       data.doorType === "Pivot" && 
       data.addDL === "Add Intermediate Rail" ? (
        <>
          <LockStileTable
            qty={1}
            panelWidth={roundPanelWidth}
            panelHeight={roundPanelHeight}
            glassOpening={openingWidthFormula(
              data.stilesAndRails || "",
              roundPanelWidth,
              data.doorType,
              data.swingDirection || "",
              false,
              data.exactConfig
            )?.opening || 0}
            glassOpeningHeight={
              openingHeightFormula(
                data.doorType,
                data.stilesAndRails || "",
                roundPanelHeight,
                `${data.pivotHardware} ${data.pivotAction}`
              )?.openingHeight || 0
            }
            isIRailFrame={true}
          />

        <View style={styles.rowCenter}>
          <View style={styles.align}>
            <View style={styles.borders}>
              <View style={styles.columnCenter}>
                <Text style={styles.headerFont}>INT.</Text>
                <Text style={styles.headerFont}>RAIL</Text>
              </View>
            </View>
          </View>
          <LockStileTable
            qty={glassQty.qty}
            glassOpening={glassOpening || 0}
            glassOpeningHeight={glassOpeningHeight}
            isIRail={true}
          />
        </View>
        </>
      )
      : glassQty.qty ? (
        <LockStileTable
          qty={glassQty.qty}
          panelWidth={roundPanelWidth}
          panelHeight={roundPanelHeight}
          glassOpening={
            data.doorType === "Swing" &&
            data.swingDirection === "Inswing" &&
            data.swingType === "Single Swing" &&
            glassOpeningDD
              ? glassOpeningDD
              : glassOpening
              ? glassOpening
              : 0
          }
          glassOpeningHeight={glassOpeningHeight}
          isBifold={data.doorType.includes("Bi-fold")}
        />
      ) : (
        <Text></Text>
      )}

      {glassQty.qtyTopBtm && 
       data.doorType === "Pivot" && 
       data.addDL === "Add Intermediate Rail" ? (
        <>
          <View style={styles.rowCenter}>
            <View style={styles.align}>
              <View style={styles.borders}>
                <View style={styles.columnCenter}>
                  <Text style={styles.headerFont}>SIDE</Text>
                  <Text style={styles.headerFont}>LITES</Text>
                </View>
              </View>
            </View>
            <LockStileTable
              qty={data.sideLite === "Add Double Sidelite" ? 2 : 1}
              panelWidth={sideliteWidth || 0}
              panelHeight={sidelitePanelHeight}
              glassOpening={openingWidthFormula(
                data.stilesAndRails || "",
                sideliteWidth || 0,
                data.doorType,
                data.swingDirection,
                true
              )?.opening || 0}
              glassOpeningHeight={
                openingHeightFormula(
                  data.doorType,
                  data.stilesAndRails || "",
                  sidelitePanelHeight,
                  `${data.pivotHardware} ${data.pivotAction}`
                )?.openingHeight || 0
              }
              isIRailFrame={true}
            />
          </View>

          <View style={styles.rowCenter}>
            <View style={styles.align}>
              <View style={styles.borders}>
                <View style={styles.columnCenter}>
                {glassQty.qtyMid ?
                  <Text style={styles.headerFont}>TOP/BTM INT.</Text>
                :
                  <Text style={styles.headerFont}>INT.</Text>
                }
                  <Text style={styles.headerFont}>RAIL</Text>
                </View>
              </View>
            </View>
            <LockStileTable
              qty={glassQty.qtyTopBtm}
              glassOpening={glassOpeningSidelite || 0}
              glassOpeningHeight={sideliteGlassHeightTopBtm || 0}
              isIRail={true}
            />
          </View>

          {glassQty.qtyMid ? 
            <View style={styles.rowCenter}>
              <View style={styles.align}>
                <View style={styles.borders}>
                  <View style={styles.columnCenter}>
                    <Text style={styles.headerFont}>MID INT.</Text>
                    <Text style={styles.headerFont}>RAIL</Text>
                  </View>
                </View>
              </View>
              <LockStileTable
                qty={glassQty.qtyMid}
                glassOpening={glassOpeningSidelite || 0}
                glassOpeningHeight={sideliteGlassHeight || 0}
                isIRail={true}
              />
            </View>
          :
          <Text />
          }
        </>
      )
      : glassQty.qtyDD ? (
        <View style={styles.rowCenter}>
          <View style={styles.align}>
            <View style={styles.borders}>
              <View style={styles.columnCenter}>
                {data.doorType.includes("Bi-fold") ||
                data.doorType === "Slider" ||
                (data.doorType === "Swing" &&
                  data.swingDirection === "Inswing" &&
                  data.swingType === "Pair Swing") ? (
                  <>
                    <Text style={styles.headerFont}>LOCK</Text>
                    <Text style={styles.headerFont}>STILES</Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.headerFont}>SIDE</Text>
                    <Text style={styles.headerFont}>LITES</Text>
                  </>
                )}
              </View>
            </View>
          </View>
          <LockStileTable
            qty={glassQty.qtyDD}
            panelWidth={
              data.doorType === "Swing" &&
              data.sideLite !== "none" &&
              data.swingDirection !== "Inswing" &&
              sideliteWidth
                ? sideliteWidth
                : data.doorType === "Swing" &&
                  data.swingType === "Single Swing" &&
                  data.sideLite !== "none" &&
                  data.swingDirection === "Inswing" &&
                  sideliteWidth
                ? sideliteWidth
                : data.doorType === "Pivot" && 
                  sideliteWidth
                ? sideliteWidth
                : roundPanelWidth
            }
            panelHeight={
              data.sideLite !== "none" && data.swingDirection !== "Inswing"
                ? sidelitePanelHeight
                : data.doorType === "Swing" &&
                  data.swingType === "Single Swing" &&
                  data.sideLite !== "none" &&
                  data.swingDirection === "Inswing"
                ? sidelitePanelHeight
                : data.doorType === "Swing" &&
                  data.swingDirection === "Outswing"
                ? roundTo4(roundPanelHeight + 0.25)
                : data.doorType === "Pivot"
                ? sidelitePanelHeight
                : roundPanelHeight
            }
            glassOpening={
              data.doorType === "Swing" &&
              data.sideLite !== "none" &&
              data.swingDirection !== "Inswing" &&
              glassOpeningSidelite
                ? glassOpeningSidelite
                : data.doorType === "Swing" &&
                  data.swingType === "Single Swing" &&
                  data.sideLite !== "none" &&
                  data.swingDirection === "Inswing" &&
                  glassOpeningSidelite
                ? glassOpeningSidelite
                : data.doorType === "Pivot" && 
                  glassOpeningSidelite
                ? glassOpeningSidelite
                : glassOpeningDD
                ? glassOpeningDD
                : 0
            }
            glassOpeningHeight={
              data.sideLite !== "none" && 
              data.swingDirection !== "Inswing" &&
              sideliteGlassHeight
                ? sideliteGlassHeight
                : data.doorType === "Swing" &&
                  data.swingType === "Single Swing" &&
                  data.sideLite !== "none" &&
                  data.swingDirection === "Inswing" &&
                  sideliteGlassHeight
                ? sideliteGlassHeight
                : data.doorType === "Swing" &&
                  data.swingDirection === "Outswing" &&
                  glassOpeningHeight
                ? roundTo4(glassOpeningHeight + 0.25)
                : data.doorType === "Pivot" &&
                sideliteGlassHeight
                ? sideliteGlassHeight
                : glassOpeningHeight
            }
            isBifold={data.doorType.includes("Bi-fold")}
          />
        </View>
      ) : (
        <Text></Text>
      )}

      {glassQty.qtyFixed ? (
        <View style={styles.rowCenter}>
          <View style={styles.align}>
            <View style={styles.borders}>
              <View style={styles.columnCenter}>
                {data.doorType === "Swing" &&
                data.swingDirection === "Inswing" ? (
                  <>
                    <Text style={styles.headerFont}>SIDE</Text>
                    <Text style={styles.headerFont}>LITES</Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.headerFont}>FIXED</Text>
                    <Text style={styles.headerFont}>PANEL</Text>
                  </>
                )}
              </View>
            </View>
          </View>
          <LockStileTable
            qty={glassQty.qtyFixed}
            panelWidth={
              sideliteWidth
                ? sideliteWidth
                : data.doorType === "Slider" &&
                  data.exactConfig.includes("O") &&
                  data.exactConfig.split("-")[0].length === 2 &&
                  !data.handleStyle?.includes("Truth")
                ? roundPanelWidth
                : panelWidth
                ? roundTo4(panelWidth + 0.5)
                : 0
            }
            panelHeight={
              data.doorType === "Slider" && (data.addSlidingScreen || data.sillStyle?.includes("Steel"))
                ? roundTo4(panelHeight + 0.3125)
                : data.doorType === "Slider"
                ? roundTo4(panelHeight + 0.375)
                : data.doorType === "Swing" && data.swingDirection === "Inswing"
                ? sidelitePanelHeight
                : roundPanelHeight
            }
            glassOpening={
              data.doorType === "Slider" && 
              glassOpening 
              ? glassOpening 
              : glassOpeningSidelite
              ? glassOpeningSidelite
              : 0
            }
            glassOpeningHeight={
              data.doorType === "Slider"
                ? (openingHeightFormula(
                    data.doorType,
                    data.stilesAndRails || "",
                    (data.addSlidingScreen || data.sillStyle?.includes("Steel"))
                      ? roundTo4(panelHeight + 0.3125)
                      : roundTo4(panelHeight + 0.375),
                    "",
                    data.sliderPanelSqft || 0
                  )?.openingHeight || 0)
                : data.doorType === "Swing" && 
                  data.swingDirection === "Inswing" &&
                  sideliteGlassHeight
                ? sideliteGlassHeight
                : glassOpeningHeight
            }
          />
        </View>
      ) : (
        <Text></Text>
      )}
    </View>
  );
};

export default CutSheetLockStile;
