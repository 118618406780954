
function pivotLimits(
  pw: number,
  setPanelErr: (err: { slimline: string, euro: string } | {}) => void,
  resetStiles: () => void,
  hardware: string,
  movement: string,
  stiles: string
) {
  let panelErr = {};
  let reset = false;
  
  if (pw > 72) {
    panelErr = {
      slimline: "Slimline Stiles not available for this Width",
      euro: "Euro Stiles not available for this Width",
    };

    if(stiles?.includes("Slimline") || stiles?.includes("Euro")){
      reset = true
    }
  } else if (pw > 60) {
    panelErr = {
      slimline: "Slimline Stiles not available for this Width",
    };

    if(stiles?.includes("Slimline")){
      reset = true
    }
  }

  if(hardware === "Fritsjurgens" && movement === "Self Closing"){
    panelErr = {
      ...panelErr,
      slimline: "Slimline Stiles not available with Fritsjurgens Self Closing units"
    };
  } 

  setPanelErr(panelErr);

  if(reset) resetStiles()
}

export { pivotLimits };
