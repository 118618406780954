import React from "react";
import { Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .MuiTooltip-tooltip`]: {
//     fontSize: theme.typography.pxToRem(14),
//     border: "1px solid #dadde9",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   }
// }));

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}))

const SalesTaxTooltip = () => {
  return (
    <>
      <Typography variant="body2">Sales Tax:</Typography>
      <HtmlTooltip title="*Sales Tax is calculated based on Jobsite Address and may be changed with the edit button">
        <InfoIcon style={{ color: "#57d5f2" }} />
      </HtmlTooltip>
    </>
  );
};

export default SalesTaxTooltip;
