import React, { memo, FC } from "react";
import {
  Paper,
  Grow,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  useMediaQuery,
} from "@mui/material";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { Image } from "cloudinary-react";
import { tier1, tier2, tier3, tier4, tier5, woodImgs } from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const baseWoods = [
  "Paint-Grade Bass",
  "Stain-Grade Bass",
  "Paint-Grade Douglas Fir",
  "Paint-Grade Pine",
  "Stain-Grade Pine",
  "Paint-Grade Vertical Douglas Fir",
];

interface UpchargeWoodsProps {
  label: string;
}

interface TierItm {
  id: number;
  type: string;
  label: string;
  abbrev: string;
  material: string;
}

interface RenderTierProps {
  tier: TierItm[];
  handleClick: (label: string, abbrev: string) => void;
  chipMaterial: string;
  variant: (label: string) => "filled" | "outlined";
  checkMark: (label: string) => boolean;
}

const UpchargeWoods: FC<UpchargeWoodsProps> = ({ label }) => {
  return (
    <Typography variant="body2" style={{ fontSize: "0.8125rem" }}>
      {label} <span>*</span>
    </Typography>
  );
};

const RenderTier: FC<RenderTierProps> = ({
  tier,
  handleClick,
  chipMaterial,
  variant,
  checkMark,
}) => {
  return (
    <>
      {tier
        .filter(
          (wood) => wood.material === chipMaterial || wood.material === "both"
        )
        .map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.abbrev)}
          >
            <Choices
              label={
                baseWoods?.includes(state.label) ? (
                  state.label
                ) : (
                  <UpchargeWoods label={state.label} />
                )
              }
              variant={variant(state.label)}
              checkMark={checkMark(state.label)}
            />
          </div>
        ))}
    </>
  );
};

const StyledSpan = styled("span")({
  position: "absolute",
  borderRight: "2px solid black",
  right: 0,
  top: "20%",
  width: 5,
  height: "60%",
});

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    borderBottom: "none",
    margin: 5,
    position: "relative",
    fontSize: 18,
  },
});

const StyledTableCell2 = styled(TableCell)({
  "&.MuiTableCell-root": {
    borderBottom: "none",
    margin: 5,
    position: "relative",
    fontSize: 18,
    marginBottom: 10,
    marginTop: 20,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
});

interface WoodProps {
  doorType: string;
}

interface ClickUpdate {
  woodSpecies: string;
  woodAbbrev: string;
  stainColor?: string;
  stain?: string;
  customPaintOrStain?: string;
  paintColor?: string;
  paintAndPrime?: string;
  prime?: string;
}

const WoodAccordian: FC<WoodProps> = ({ doorType }) => {
  const largeScreen = useMediaQuery("(min-width:1300px)", { noSsr: true });

  const addToQuote = useStore((state) => state.addToQuote);

  const materialChoice = useStore((state) => state.quote.material);

  const woodSpecies = useStore((state) => state.quote.woodSpecies);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[1].expanded);

  const woodObj = !woodSpecies
    ? { imgLabel: "" }
    : woodImgs.find((obj) => obj.label === woodSpecies);

  const handleClick = (label: string, woodAbbrev: string) => {
    const checked = checkMark(label);

    if (checked) return;

    let update: ClickUpdate = {
      woodSpecies: label,
      woodAbbrev: woodAbbrev,
    };

    if (label.includes("Paint-Grade")) {
      update["stainColor"] = "";
      update["stain"] = "";
      update["customPaintOrStain"] = "";
    } else if (!label.includes("Paint-Grade")) {
      update["paintColor"] = "";
      update["paintAndPrime"] = "";
      update["prime"] = "";
      update["customPaintOrStain"] = "";
    }

    addToQuote(update);
  };

  const variant = (label: string) =>
    label === woodSpecies ? "filled" : "outlined";

  const checkMark = (label: string) => label === woodSpecies;

  const chipMaterial = materialChoice === "All Wood" ? "wood" : "clad";

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="wood"
      heading="Wood Species"
      secondaryHeading="Select wood finish"
      colOrRow="col"
      required={false}
      required5={true}
    >
      {!materialChoice ? (
        <Typography>Select a Material to view Wood Species Options</Typography>
      ) : materialChoice === "All Wood" && doorType === "Pivot" ? (
        <>
          <Typography style={{ padding: 5 }} variant="subtitle1">
            PLEASE NOTE: Wood grains may vary; picture may not be exact to wood
            species
          </Typography>

          <Typography style={{ padding: 5 }} variant="subtitle2">
            * Upcharge applies
          </Typography>

          <ColLayout style={{ flexWrap: "wrap" }}>
            {woodSpecies && woodObj?.imgLabel ? (
              <Grow in={true} timeout={500}>
                <RowLayout style={{ width: 300 }}>
                  <Paper style={{ width: 150, height: 150 }} elevation={3}>
                    <Image
                      cloudName="ag-millworks"
                      secure="true"
                      publicId={`WoodSpecies/${woodObj.imgLabel}`}
                    />
                  </Paper>
                </RowLayout>
              </Grow>
            ) : (
              <div style={{ width: 300 }} />
            )}

            <RowLayout>
              {tier5
                .filter((itm) => itm.label?.includes("Accoya"))
                .map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClick(state.label, state.abbrev)}
                  >
                    <Choices
                      label={
                        baseWoods?.includes(state.label) ? (
                          state.label
                        ) : (
                          <UpchargeWoods label={state.label} />
                        )
                      }
                      variant={variant(state.label)}
                      checkMark={checkMark(state.label)}
                    />
                  </div>
                ))}
            </RowLayout>
          </ColLayout>
        </>
      ) : (
        <>
          <Typography style={{ padding: 5 }} variant="subtitle1">
            PLEASE NOTE: Wood grains may vary; picture may not be exact to wood
            species
          </Typography>

          {materialChoice?.includes("Clad") && (
            <Typography style={{ padding: 5 }} variant="subtitle1">
              If Paint Grade is required, we strongly recommend PG-Basswood
            </Typography>
          )}

          <Typography style={{ padding: 5 }} variant="subtitle2">
            * Upcharge increases per category
          </Typography>

          <div
            style={
              largeScreen
                ? {
                    marginBottom: 10,
                    marginTop: 20,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }
                : {
                    marginBottom: 10,
                    marginTop: 20,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                  }
            }
          >
            {woodSpecies && woodObj?.imgLabel ? (
              <Grow in={true} timeout={500}>
                <RowLayout style={{ width: 300 }}>
                  <Paper style={{ width: 150, height: 150 }} elevation={3}>
                    <Image
                      cloudName="ag-millworks"
                      secure="true"
                      publicId={`WoodSpecies/${woodObj.imgLabel}`}
                    />
                  </Paper>
                </RowLayout>
              </Grow>
            ) : (
              <div style={{ width: 300 }} />
            )}

            <TableContainer style={{ flexBasis: "50%" }}>
              <Table size="small">
                <TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="none"
                        style={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          paddingLeft: 10,
                        }}
                        align="center"
                      >
                        Category
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      1
                      <StyledSpan />
                    </StyledTableCell>
                    <StyledTableCell2 align="right">
                      <RenderTier
                        tier={tier1}
                        handleClick={handleClick}
                        chipMaterial={chipMaterial}
                        variant={variant}
                        checkMark={checkMark}
                      />
                    </StyledTableCell2>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      2
                      <StyledSpan />
                    </StyledTableCell>
                    <StyledTableCell2 align="right">
                      <RenderTier
                        tier={tier2}
                        handleClick={handleClick}
                        chipMaterial={chipMaterial}
                        variant={variant}
                        checkMark={checkMark}
                      />
                    </StyledTableCell2>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      3
                      <StyledSpan />
                    </StyledTableCell>
                    <StyledTableCell2 align="right">
                      <RenderTier
                        tier={tier3}
                        handleClick={handleClick}
                        chipMaterial={chipMaterial}
                        variant={variant}
                        checkMark={checkMark}
                      />
                    </StyledTableCell2>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      4
                      <StyledSpan />
                    </StyledTableCell>
                    <StyledTableCell2 align="right">
                      <RenderTier
                        tier={tier4}
                        handleClick={handleClick}
                        chipMaterial={chipMaterial}
                        variant={variant}
                        checkMark={checkMark}
                      />
                    </StyledTableCell2>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      5
                      <StyledSpan />
                    </StyledTableCell>
                    <StyledTableCell2 align="right">
                      <RenderTier
                        tier={tier5}
                        handleClick={handleClick}
                        chipMaterial={chipMaterial}
                        variant={variant}
                        checkMark={checkMark}
                      />
                    </StyledTableCell2>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </ReusableAccordian>
  );
};

export default memo(WoodAccordian);
