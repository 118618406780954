const jambInswing = [
  {
    id: 1,
    type: "jamb",
    label: '5-1/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: '6-1/16" Overall Jamb Width',
  },
  {
    id: 3,
    type: "jamb",
    label: '7" Overall Jamb Width',
  },
  {
    id: 4,
    type: "jamb",
    label: '7-15/16" Overall Jamb Width',
  },
];

const jambNoScreen = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 5-5/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambNoScreenKerf = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 6" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambWithScreenSwing = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 6-7/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambWithScreen = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 8-1/2" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambWithScreenKerf = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 8-7/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambFixedSlider = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 6-5/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambCentorScreen = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 9 7/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambCentorScreenKerf = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard  10 1/4" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];

const jambSwingCentorScreen = [
  {
    id: 1,
    type: "jamb",
    label: 'Standard 7 7/8" Overall Jamb Width',
  },
  {
    id: 2,
    type: "jamb",
    label: "Custom Overall Jamb Width",
  },
];


export {
    jambWithScreen,
    jambWithScreenKerf,
    jambWithScreenSwing,
    jambInswing,
    jambNoScreen,
    jambNoScreenKerf,
    jambFixedSlider,
    jambCentorScreen,
    jambCentorScreenKerf,
    jambSwingCentorScreen,
}