import fractionToInt from "./fractionToInt";
import { UnitObj } from "./quoteCartStorage";

export interface LineItem {
  configImg?: string,
  configImgRight?: string,
  exactConfigRight?: string,
  exactConfig?: string,
  doorType?: string,
  material?: string,
  swingDirection?: string,
  dl?: string,
  id?: string,
  handle?: string,
  glass?: string,
  handleHeight?: string,
  doorHeight?: number,
  spacer?: string,
  wood?: string,
  screenColor?: string,
  breatherTubes?: string,
  kerf?: string,
  cladColor?: string,
  customCladColor?: string
}

const noHandle: string[] = [
  "2L",
  "2R",
  "2L2R",
  "4L",
  "4R",
  "6L",
  "6R",
  "4L2R",
  "2L4R",
  "8L",
  "8R",
  "6L2R",
  "2L6R",
  "4L4R",
];

function addLineItem(cart: UnitObj[], addedFields: string[]): LineItem[] {
  let arr: LineItem[] = [];

  cart.forEach((unit) => {
    let obj = {
      configImg: unit.exactConfigImg,
      configImgRight: unit.doorType.includes("Corner")
        ? unit.exactConfigImgRightCorner
        : "",
      exactConfigRight: unit.doorType.includes("Corner")
        ? unit.cornerConfigRight
        : "",
      exactConfig: unit.exactConfig,
      doorType: unit.doorType,
      material: unit.material,
      swingDirection: unit.swingDirection || "",
      dl: unit.addDL || "",
      id: unit._id,
    };

    addedFields.forEach((field) => {
      let secondaryObj = {};

      switch (field) {
        case "Glass":
          secondaryObj = {
            ...secondaryObj,
            glass: unit.glassType || "None",
          };
          break;
        case "Handle":
          secondaryObj = {
            ...secondaryObj,
            handle:
              unit.doorType.includes("Corner") &&
              noHandle.includes(unit.exactConfig) &&
              noHandle.includes(unit.cornerConfigRight || "")
                ? "N/A"
                : !unit.doorType.includes("Corner") &&
                  (noHandle.includes(unit.exactConfig) ||
                    unit.doorType === "Bi-fold Window")
                ? "N/A"
                : unit.doorType === "Pivot" && unit.handleStyle
                ? unit.handleStyle
                : unit.doorType !== "Slider" &&
                  unit.withHandle === "Add Handle Set"
                ? unit.handleStyle
                : unit.handleStyle && unit.doorType === "Slider"
                ? unit.handleStyle
                : "None",
          };
          break;
        case "Handle Height":          
          const handleHeight = unit.handleHeight || "36\"";

          secondaryObj = {
            ...secondaryObj,
            handleHeight:
              unit.doorType.includes("Corner") &&
              noHandle.includes(unit.exactConfig) &&
              noHandle.includes(unit.cornerConfigRight || "")
                ? "N/A"
                : !unit.doorType.includes("Corner") &&
                  (noHandle.includes(unit.exactConfig) ||
                  unit.doorType === "Bi-fold Window")
                ? "N/A"
                : unit.doorType === "Pivot" 
                ? handleHeight
                : unit.doorType !== "Slider" &&
                  unit.withHandle === "Add Handle Set"
                ? handleHeight
                : unit.handleStyle && 
                  unit.doorType === "Slider"
                ? handleHeight
                : "None",
            doorHeight: fractionToInt(unit.height, unit.heightFraction)
          };
          break;
        case "Spacer":
          secondaryObj = {
            ...secondaryObj,
            spacer: unit.spacerColor || "None",
          };
          break;
        case "Wood":
          secondaryObj = {
            ...secondaryObj,
            wood: unit.woodAbbrev || "None",
          };
          break;
        case "Screen Color":
          secondaryObj = {
            ...secondaryObj,
            screenColor:
              !unit.addScreen && !unit.addSlidingScreen
                ? "None"
                : unit.screenColor,
          };
          break;
        case "Breather Tubes":
          secondaryObj = {
            ...secondaryObj,
            breatherTubes: unit.breatherTubes || "None",
          };
          break;
        case "Kerf":
          secondaryObj = {
            ...secondaryObj,
            kerf: !unit.kerf
              ? "None"
              : unit.kerf && unit.kerfConfig?.includes("Custom")
              ? unit.kerfSetback
              : "3/8",
          };
          break;
        case "Clad Color":
          secondaryObj = {
            ...secondaryObj,
            cladColor:
              unit.exteriorCladColor && unit.material.includes("Clad")
                ? unit.exteriorCladColor
                : "None",
          };
          break;
        case "Custom Clad Color":
          secondaryObj = {
            ...secondaryObj,
            customCladColor:
              unit.exteriorCladColor &&
              unit.exteriorCladColor.includes("Custom") &&
              unit.material.includes("Clad") &&
              unit.customCladColor
                ? unit.customCladColor
                : "",
          };
          break;
        default:
          console.log("No Matching Field");
      }

      obj = {
        ...obj,
        ...secondaryObj,
      };
    });

    arr.push(obj);
  });

  return arr;
}

export default function checkCart(cart: UnitObj[]) {
  let handle: string = "";
  let glass: string = cart[0].glassType || "";
  let spacer: string = cart[0].spacerColor || "";
  let wood: string = cart[0].woodSpecies;
  let screenColor: string = "";
  let breatherTubes: string = cart[0].breatherTubes || "None";
  let cladColor: string =
    cart[0].material === "Clad Exterior/Wood Interior" &&
    cart[0].exteriorCladColor
      ? cart[0].exteriorCladColor
      : "";
  let handleHeight: string = cart[0].handleHeight || "36\"";
  let kerf: string = "";
  let kerfLoop: boolean = false;

  interface KerfSelecObj {
    kerf: string,
  }
  let kerfSelections: KerfSelecObj[] = [];

  let allUnitCladColors: string[] = [];
  let allCustomCladColors: string[] = [];

  let fields: string[] = [];

  for (let i = 0; i < cart.length; i++) {
    const unit: UnitObj = cart[i];

    if (unit.glassType !== glass) {
      !fields.includes("Glass") && fields.push("Glass");
    }

    if (
      (unit.doorType === "Slider" && unit.sliderPocket !== "With Pocket") ||
      (unit.doorType === "Bi-fold Zero Corner" &&
        unit.withHandle === "Add Handle Set" &&
        (!noHandle.includes(unit.exactConfig) ||
          !noHandle.includes(unit.cornerConfigRight || ""))) ||
      (unit.withHandle === "Add Handle Set" &&
        !noHandle.includes(unit.exactConfig) &&
        unit.doorType !== "Bi-fold Window" &&
        unit.doorType !== "Bi-fold Zero Corner")
    ) {
      if (!handle) handle = unit.handleStyle || "";

      if (unit.handleStyle !== handle) {
        !fields.includes("Handle") && fields.push("Handle");
      }

      const unitHandleHeight: string = unit.handleHeight || "36\"";
      
      if(unitHandleHeight !== handleHeight){
        !fields.includes("Handle Height") && fields.push("Handle Height")
      }
    }

    if(unit.doorType === "Pivot" || unit.sliderPocket === "With Pocket"){
      const unitHandleHeight: string = unit.handleHeight || "36\"";
      
      if(unitHandleHeight !== handleHeight){
        !fields.includes("Handle Height") && fields.push("Handle Height")
      }
    }

    if (unit.spacerColor !== spacer) {
      !fields.includes("Spacer") && fields.push("Spacer");
    }

    if (unit.woodSpecies !== wood) {
      !fields.includes("Wood") && fields.push("Wood");
    }

    if (unit.addScreen || unit.addSlidingScreen) {
      if (!screenColor) screenColor = unit.screenColor || "";

      if (unit.screenColor !== screenColor) {
        !fields.includes("Screen Color") && fields.push("Screen Color");
      }
    }

    if (unit.kerf && unit.kerfConfig) {
      if (!unit.kerfConfig.includes("Custom") && !kerf) {
        kerf = "3/8";
      } else if (!kerf) {
        kerf = unit.kerfSetback || "";
      }

      if (!kerfLoop) {
        if (
          (!unit.kerfConfig.includes("Custom") && kerf !== "3/8") ||
          (unit.kerfSetback && kerf !== unit.kerfSetback)
        ) {
          kerfLoop = true;

          for (let q = 0; q < cart.length; q++) {
            const itm = cart[q];

            const hasKerf = itm.kerf && itm.kerfConfig;

            kerfSelections.push({
              kerf:
                hasKerf && !itm.kerfConfig?.includes("Custom")
                  ? "3/8"
                  : hasKerf && itm.kerfSetback
                  ? itm.kerfSetback
                  : "3/8",
            });
          }
        }
      }
    }

    if (
      i + 1 === cart.length &&
      kerfSelections.length &&
      !kerfSelections.every((k) => k.kerf === kerfSelections[0].kerf)
    ) {
      !fields.includes("Kerf") && fields.push("Kerf");
    }

    const bt: string = unit.breatherTubes || "None";

    if (bt !== breatherTubes) {
      !fields.includes("Breather Tubes") && fields.push("Breather Tubes");
    }

    if (unit.exteriorCladColor && unit.material.includes("Clad")) {
      allUnitCladColors.push(unit.exteriorCladColor);
      if (unit.exteriorCladColor.includes("Custom") && unit.customCladColor)
        allCustomCladColors.push(unit.customCladColor);

      if (!cladColor) cladColor = unit.exteriorCladColor;

      if (
        i + 1 === cart.length &&
        allUnitCladColors.every((color) => color && color.includes("Custom"))
      ) {
        if (!compareCustomClad(allCustomCladColors)) {
          !fields.includes("Clad Color") && fields.push("Clad Color");
          !fields.includes("Custom Clad Color") &&
            fields.push("Custom Clad Color");
        }
      } else if (
        i + 1 === cart.length &&
        allUnitCladColors.includes("Custom")
      ) {
        !fields.includes("Clad Color") && fields.push("Clad Color");
        !fields.includes("Custom Clad Color") &&
          fields.push("Custom Clad Color");
      } else {
        if (unit.exteriorCladColor !== cladColor) {
          !fields.includes("Clad Color") && fields.push("Clad Color");
          !fields.includes("Custom Clad Color") &&
            fields.push("Custom Clad Color");
        }
      }
    }
  }

  const newArr = addLineItem(cart, fields);

  return newArr;
}

function parseCladColor(clad: string): string {
  return clad?.replace(/-/g, "").replace(/ /g, "").toLowerCase().trim();
}

function compareCustomClad(cladArray: string[]): boolean {
  const areEqual = cladArray.every(
    (color) => parseCladColor(color) === parseCladColor(cladArray[0])
  );
  return areEqual;
}
