import React, {
  useEffect,
  lazy,
  Suspense,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";

const Login = lazy(() => import("../pages/Login"));

interface LoginWrapperProps {
  user?: string;
  handleAuthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignIn: (
    event: React.KeyboardEvent<any> | React.MouseEvent<HTMLButtonElement>
  ) => void;
  email: string;
  password: string;
  errorMessage: string;
  setUser: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const LoginWrapper: FC<LoginWrapperProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.user) {
      navigate(`/dealerZone/${props.user}`);
    }
  }, [props.user, navigate]);

  return (
    <Suspense fallback={<Backdrop open={true}></Backdrop>}>
      <Login
        handleAuthChange={props.handleAuthChange}
        handleSignIn={props.handleSignIn}
        email={props.email}
        password={props.password}
        errorMessage={props.errorMessage}
        setUser={props.setUser}
        loading={props.loading}
      />
    </Suspense>
  );
};

export default LoginWrapper;
