import React, { FC } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { screenType } from "./defaults";
import ScreenAccordian from "../ScreenAccordian";
import CentorScreenAccordian from "../CentorScreenAccordian";
import fractionToInt from "../../util/fractionToInt";
import { Typography, Badge } from "@mui/material";
import { Image } from "cloudinary-react";
import { Element } from "react-scroll";
import { styled } from "@mui/material/styles";
import LearnMore from "../LearnMore";

const StyledDiv70 = styled("div")({
  width: "70%",
  paddingBottom: 20,
  textAlign: "center",
  paddingTop: 20,
});

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingTop: 10
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: "#f0be07"
  },
});

const fivePanel = ["5L OR 5R", "3L2R OR 2L3R", "4L1R OR 1L4R"];

function centorSectionViewURLs(material: string, doorType: string, sill: string, cornerType?: string) {
  const parentFolder = "CENTOR_SECTION_VIEW";

  if (doorType?.includes("Bi-fold")) {
    const isCorner = doorType.includes("Corner");
    const folder = isCorner ? "bifold_corners" : "bifolds";
    const materialLbl = material.includes("Clad") ? "CLAD" : "WOOD";
    const sillLbl =
      sill === "ADA Ultra Guide"
        ? "ADA_UG"
        : sill === "Ultra Guide"
        ? "UG"
        : sill === "Rounded Dam"
        ? "ROUND"
        : "SQUARE";

    return {
      headSill: `${parentFolder}/bifolds/${materialLbl}_HEAD_SILL_${sillLbl}`,
      jambLeg: isCorner
        ? `${parentFolder}/${folder}/${cornerType?.toUpperCase()}_CORNER_${materialLbl}_LEG_JAMB`
        : `${parentFolder}/${folder}/${materialLbl}_LEG_JAMB`,
    };
  }

  if (doorType === "Swing") {
    const folder = "swings";
    const materialLbl = material.includes("Clad") ? "CLAD" : "WOOD";

    return {
      headSill: `${parentFolder}/${folder}/${materialLbl}_SILL`,
      jambLeg: `${parentFolder}/${folder}/${materialLbl}`,
    };
  }
}

interface ScreenTypeProps {
  doorType: string
  editQuote: boolean,
  submitPressed: boolean
}

const BrioOrCentorAccordian: FC<ScreenTypeProps> = ({ doorType, editQuote, submitPressed }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const isCornerUnit = doorType?.includes("Corner");

  const material = useStore((state) => state.quote.material);
  const sillStyle = useStore((state) => state.quote.sillStyle);

  const configuration = useStore((state) => state.quote.configuration);
  const direction = useStore((state) => state.quote.swingDirection);
  const cornerType = useStore((state) => state.quote.cornerType);
  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const heightInt = fractionToInt(height, heightFraction);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const rightWidth = useStore((state) => state.quote.cornerWidthRight);
  const rightFraction = useStore(
    (state) => state.quote.cornerWidthFractionRight
  );

  const width = fractionToInt(unitSize, unitSizeFraction);
  const widthRight = fractionToInt(rightWidth || "", rightFraction);

  const screenMax = cornerType === "Outside" ? 199 : 185;
  const screenMin = cornerType === "Outside" ? 193 : 179;

  const isFivePanel =
    (doorType === "Bi-fold" || doorType === "Bi-fold Window") &&
    fivePanel.includes(configuration || "")
      ? true
      : false;

  const isWeirdSixteenRange = width >= 186.0625 && width <= 192 ? true : false;

  const isPocketAllowed =
    isCornerUnit && (width > screenMax || widthRight > screenMax)
      ? true
      : isCornerUnit &&
        ((width > screenMin && width <= screenMax) ||
          (widthRight > screenMin && widthRight <= screenMax))
      ? false
      : !isCornerUnit && isWeirdSixteenRange && isFivePanel
      ? false
      : true;

  const widerThan16 =
    isCornerUnit && (width > screenMax || widthRight > screenMax)
      ? true
      : isCornerUnit &&
        ((width > screenMin && width <= screenMax) ||
          (widthRight > screenMin && widthRight <= screenMax))
      ? false
      : !isCornerUnit && width > 192
      ? true
      : false;

  const screenTypeChoice = useStore((state) => state.quote.screenType);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[31].expanded);

  const centorSectionImgs = centorSectionViewURLs(
    material,
    doorType,
    sillStyle || "",
    cornerType
  );

  const handleClick = (label: string) => {
    const checked = label === screenTypeChoice;

    if (checked) {
      addToQuote({
        screenType: "",
        addScreen: "",
        screenLayout: "",
        screenPocket: "",
        screenColor: "",
        jamb: "",
      });
    } else {
      addToQuote({
        screenType: label,
        addScreen: "",
        screenLayout: "",
        screenPocket: "",
        screenColor: "",
        jamb: "",
      });
    }
  };

  const isCentor = screenTypeChoice?.includes("Centor");

  const isBrio = screenTypeChoice?.includes("Brio");

  const hideScreens =
    (doorType === "Swing" && direction === "Inswing") || heightInt < 32
      ? true
      : false;

  const centorWidth = width - 1;

  const screenTypeChoices =
    centorWidth > 354 ||
    heightInt < 32.125 ||
    (isCornerUnit && cornerType === "Inside")
      ? screenType.filter((itm) => itm.id === 1)
      : screenType;

  const brioOnlyOption = screenTypeChoices.length === 1 ? true : false;

  if (hideScreens) return null;

  return (
    <>
      {!brioOnlyOption && (
        <ReusableAccordian
          expanded={expanded}
          handleAccordClick={handleAccordClick}
          accordionName="screenType"
          heading="Screen Type"
          secondaryHeading="Select a Screen Type"
          required={false}
          colOrRow="col"
        >
          <>
            {isCentor && (
              <Typography>
                Featuring a flat material that rolls up and provides the option
                for either screen mesh or shade.
              </Typography>
            )}

            {isBrio && (
              <Typography>
                Featuring an accordion-style material that folds up
              </Typography>
            )}
            {screenTypeChoice && (
              <LearnMore href="https://agmillworks.com/retractable-screens/" />
            )}

            <StyledDiv>
              {screenTypeChoices.map((item) => (
                <div key={item.id} onClick={() => handleClick(item.label)}>
                  {item.id === 2 ?
                  <StyledBadge badgeContent={"New!"}>
                  <Choices
                    label={item.label}
                    variant={
                      item.label === screenTypeChoice ? "filled" : "outlined"
                    }
                    checkMark={item.label === screenTypeChoice}
                  />
                  </StyledBadge>
                  :
                  <Choices
                    label={item.label}
                    variant={
                      item.label === screenTypeChoice ? "filled" : "outlined"
                    }
                    checkMark={item.label === screenTypeChoice}
                  />
                  }
                </div>
              ))}
            </StyledDiv>

            {(isCentor && centorSectionImgs) && (
              <StyledDiv style={{ paddingTop: 40 }}>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={centorSectionImgs.jambLeg}
                  style={{ paddingRight: 30 }}
                />
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={centorSectionImgs.headSill}
                  style={{ paddingLeft: 30 }}
                />
              </StyledDiv>
            )}

            {isCentor && (
              <StyledDiv70>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>Important Note:</span> To
                  simplify installation, we have integrated the Centor Screen
                  framing into our frame and added wood blocking to fill any
                  gaps. This design requires only one rough opening through the
                  jamb. The Net Frame Width (NFW) and Net Frame Height (NFH)
                  measurements include the screen frame.
                </Typography>
              </StyledDiv70>
            )}
          </>
        </ReusableAccordian>
      )}

      {brioOnlyOption || (!brioOnlyOption && screenTypeChoice && !isCentor) ? (
        <>
          <Element name="Screen Layout" />
          <Element name="Screen Extrusion Color" />

          <ScreenAccordian
            doorType={doorType}
            isWeirdSixteenRange={isWeirdSixteenRange}
            fivePanel16={isFivePanel}
            isPocketAllowed={isPocketAllowed}
            editQuote={editQuote}
            submitPressed={submitPressed}
            widerThan16={widerThan16}
          />
        </>
      ) : !screenTypeChoice ? null : isCentor ? (
        <>
          <Element name="Shade Mesh Color" />
          <Element name="Screen Layout" />
          <Element name="Screen Extrusion Color" />

          <CentorScreenAccordian
            doorType={doorType}
            width={width}
            widthRight={widthRight}
          />
        </>
      ) : null}
    </>
  );
};

export default BrioOrCentorAccordian;
