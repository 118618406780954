const baseURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

function primaryLabel(config: string, primaryDoor: string, side: string) {
  const isOdd = Number(config.split("")[0]) % 2 !== 0;
  return primaryDoor === side && isOdd ? "_HANDLE" : "";
}

function bifoldCornerEVLeftImg(config: string, primaryDoor: string) {
  const primary = primaryLabel(config, primaryDoor, "Left");
  return `${baseURL}/elevationView/bifoldCorners/${config}${primary}.jpg`;
}

function bifoldCornerEVRightImg(config: string, primaryDoor: string) {
  const primary = primaryLabel(config, primaryDoor, "Right");
  return `${baseURL}/elevationView/bifoldCorners/${config}${primary}.jpg`;
}

function bifoldCornerPVImg(cornerType: string, screenType: string, screen: string) {
  const withScreen = screen && screenType?.includes("Centor") ? "_centor" : screen ? "_screen" : "";
  return `${baseURL}/planView/bifoldCorners/${cornerType}${withScreen}.png`
}

function cornerStileLetters(config: string, isPrimary: boolean) {
  const primary = isPrimary ? "PLAN" : "Secondary_PLAN";
  const pv = `${baseURL}/planView/bifoldCorners/production/${config}_${primary}.png`;
  return pv;
}

export {
  bifoldCornerEVLeftImg,
  bifoldCornerEVRightImg,
  bifoldCornerPVImg,
  cornerStileLetters,
};
