import React, { useEffect, Dispatch, SetStateAction, FC } from "react";
import Login from "../pages/Login";
import { useNavigate } from "react-router-dom";

interface LogoutWrapperProps {
  user?: string;
  handleAuthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignIn: (
    event: React.KeyboardEvent | React.MouseEvent<HTMLButtonElement>
  ) => void;
  email: string;
  password: string;
  errorMessage: string;
  setUser: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const LogoutWrapper: FC<LogoutWrapperProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.setUser("");
    navigate("/");
  }, []);

  return (
    <Login
      handleAuthChange={props.handleAuthChange}
      handleSignIn={props.handleSignIn}
      email={props.email}
      password={props.password}
      errorMessage={props.errorMessage}
      setUser={props.setUser}
      loading={props.loading}
    />
  );
};

export default LogoutWrapper;
