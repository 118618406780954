import React, { FC } from "react";
import { styled } from "@mui/material/styles";

const StyledImg = styled("img")({
  width: 250,
  height: 200,
});

const StyledEmptyDiv = styled("div")({
  width: 250,
  height: 200,
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingTop: 20,
});

interface ServiceImagesProps {
  urls: {
    u1?: string
    u2?: string
    u3?: string
  }
}

const ServiceImages: FC<ServiceImagesProps> = ({ urls }) => {
  return (
    <StyledDivRow>
      {urls.u1 ? (
        <StyledImg src={urls.u1} alt="upload 1" />
      ) : (
        <StyledEmptyDiv />
      )}
      {urls.u2 ? (
        <StyledImg src={urls.u2} alt="upload 2" />
      ) : (
        <StyledEmptyDiv />
      )}
      {urls.u3 ? (
        <StyledImg src={urls.u3} alt="upload 3" />
      ) : (
        <StyledEmptyDiv />
      )}
    </StyledDivRow>
  );
};

export default ServiceImages;
