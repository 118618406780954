import React, { FC } from "react";
import { RowLayout } from "../util/accordianStyles";
import { Info as InfoIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledAnchor = styled("a")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
  fontSize: 14,
  color: "black"
});

interface LearnMoreProps {
  href: string
}

const LearnMore: FC<LearnMoreProps> = ({ href }) => {
  return (
    <RowLayout style={{ marginTop: 5 }}>
      <StyledAnchor
        target="_blank"
        rel="noreferrer"
        href={href}
      >
        Learn More
        <InfoIcon style={{ marginLeft: 5 }} color="primary" />
      </StyledAnchor>
    </RowLayout>
  );
};

export default LearnMore;
