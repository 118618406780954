import React, { FC, Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import { assignTeam } from "../../../api/ServiceApi";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
});

interface ServiceTeamsProps {
  team: number;
  setTeam: Dispatch<SetStateAction<number>>;
  isEdit: boolean;
  snNumber: number;
}

const ServiceTeamsBtns: FC<ServiceTeamsProps> = ({ team, setTeam, isEdit, snNumber }) => {
  const handleClick = async (num: number) => {
    if (!isEdit) return setTeam(num);

    setTeam(num);
    return await assignTeam(snNumber, num);
  };

  return (
    <StyledDiv>
      <Button
        variant={team === 1 ? "contained" : "outlined"}
        color="primary"
        size="small"
        onClick={() => handleClick(1)}
        style={{ marginRight: 10 }}
      >
        Team 1
      </Button>
      <Button
        variant={team === 2 ? "contained" : "outlined"}
        color="primary"
        size="small"
        onClick={() => handleClick(2)}
      >
        Team 2
      </Button>
    </StyledDiv>
  );
};

export default ServiceTeamsBtns;
