import React, { FC } from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";

const StyledList = styled(List)({
  overflow: "auto",
  height: 0,
  flex: "1 0 auto",
});

const StyledListItemText = styled(ListItemText)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  display: "inline",
  fontFamily: "Arial",
});

interface SkeletonProps {
  salesperson: string
  fetchingUser: boolean
  isFetching: boolean
  loadingQuantity: boolean
}

const SidebarSkeleton: FC<SkeletonProps> = ({
  salesperson,
  fetchingUser,
  isFetching,
  loadingQuantity,
}) => {
  return (
    <StyledList>
      <ListItem>
        {fetchingUser || isFetching || loadingQuantity ? (
          <ListItemText
            primary={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((skelNum) => (
              <Skeleton key={skelNum} animation="wave" />
            ))}
          />
        ) : (
          <StyledListItemText
            primary={`Welcome ${salesperson}!`}
            secondary={
              <StyledTypography
                variant="body2"
                color="textPrimary"
              >
                Units Will Display Here
              </StyledTypography>
            }
          />
        )}
      </ListItem>
    </StyledList>
  );
};

export default SidebarSkeleton;
