import React, { useEffect, FC } from "react";
import { Button } from "@mui/material";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import { useAccordion, useStore } from "../store/store";
import { styled } from "@mui/material/styles";

const StyledCancelButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
  position: "fixed",
  bottom: 60,
  right: -25,
  zIndex: 50,
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
}));

const StyledEditButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
  position: "fixed",
  bottom: 0,
  right: -25,
  zIndex: 50,
}));

const StyledMinimizeButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "20%",
  color: "white",
}));

interface ConfigBtnsProps {
  resetValues: () => void;
  addDoorToSidebar: ((withCheck?: boolean, ignoreUnitCheck?: boolean) => void)
}

function isBtnDisabled(
  errorArray: (string | undefined)[],
  requiredArray: (string | undefined)[]
) {
  var withErr = false;

  const errorArrayHasValue = errorArray.some((err) => Boolean(err) === true);

  if (errorArrayHasValue) return true;

  requiredArray.every((addon) => addon) ? (withErr = false) : (withErr = true);

  return withErr;
}

const ConfiguratorBtns: FC<ConfigBtnsProps> = ({
  resetValues,
  addDoorToSidebar,
}) => {
  const editBtn = useStore((state) => state.isEdit);

  const handleMinimize = useAccordion((state) => state.handleMinimize);
  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);
  const widthErrCornerRight = useStore((state) => state.widthErrCornerRight);
  const heightErr = useStore((state) => state.heightErr);
  const jambErr = useStore((state) => state.jambErr);
  const configErr = useStore((state) => state.configErr);
  const primaryDoorErr = useStore((state) => state.primaryDoorErr);
  const swingWidthErr = useStore((state) => state.swingWidthErr);
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);
  const pivotPointErr = useStore((state) => state.pivotPointErr);

  const doorType = useStore((state) => state.quote.doorType);
  const cornerType = useStore((state) => state.quote.cornerType);
  const cornerConfigRight = useStore((state) => state.quote.cornerConfigRight);
  const rightWidth = useStore((state) => state.quote.cornerWidthRight);
  const material = useStore((state) => state.quote.material);
  const unitSize = useStore((state) => state.quote.unitSize);
  const height = useStore((state) => state.quote.height);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const woodSpecies = useStore((state) => state.quote.woodSpecies);
  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingDirection = useStore((state) => state.quote.swingDirection);
  const configuration = useStore((state) => state.quote.configuration);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);

  useEffect(() => {
    if (doorType === "Slider") return;
    const errKey = "configErr";

    if (doorType === "Swing") {
      if (swingDirection && !exactConfig) {
        return handleError(errKey, "Please Select an Exact Unit Configuration");
      }
      return handleError(errKey, "");
    }

    if (doorType.includes("Corner")) {
      if (!cornerType) {
        return handleError(errKey, "Please Select a Corner Type");
      }

      if (!exactConfig || !cornerConfigRight) {
        return handleError(errKey, "Please Select a Unit Configuration");
      }
    } else {
      if (configuration && !exactConfig) {
        return handleError(errKey, "Please Select an Exact Unit Configuration");
      }
    }

    handleError(errKey, "");
  }, [
    doorType,
    swingDirection,
    configuration,
    exactConfig,
    cornerType,
    cornerConfigRight,
  ]);

  const errorArray =
    doorType === "Swing"
      ? [
          widthErr,
          heightErr,
          jambErr,
          configErr,
          swingWidthErr,
          sideliteWidthErr,
          primaryDoorErr,
        ]
      : doorType === "Bi-fold Zero Corner"
      ? [
          widthErr,
          heightErr,
          swingWidthErr,
          jambErr,
          configErr,
          primaryDoorErr,
          widthErrCornerRight,
        ]
      : doorType === "Pivot"
      ? [pivotPointErr, widthErr, heightErr, jambErr, configErr]
      : [
          widthErr,
          heightErr,
          jambErr,
          configErr,
          primaryDoorErr,
          swingWidthErr,
          sideliteWidthErr,
        ];

  const requiredArray =
    doorType === "Swing"
      ? [material, height, unitSize, exactConfig, woodSpecies, swingPanelWidth]
      : doorType === "Pivot"
      ? [
          material,
          height,
          unitSize,
          exactConfig,
          woodSpecies,
          swingPanelWidth,
          pivotHardware,
          pivotAction,
        ]
      : doorType === "Bi-fold Zero Corner"
      ? [
          material,
          height,
          unitSize,
          rightWidth,
          exactConfig,
          cornerConfigRight,
          woodSpecies,
        ]
      : doorType === "Pivot"
      ? [
          material,
          height,
          unitSize,
          exactConfig,
          woodSpecies,
          swingPanelWidth,
          pivotHardware,
          pivotAction,
        ]
      : [material, height, unitSize, exactConfig, woodSpecies];

  const btnDisabled = isBtnDisabled(errorArray, requiredArray);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {editBtn && (
        <StyledCancelButton
          variant="contained"
          size="large"
          color="customGrey"
          onClick={resetValues}
        >
          Cancel Update
        </StyledCancelButton>
      )}

      {editBtn ? (
        <StyledEditButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddShoppingCart />}
          onClick={() => addDoorToSidebar()}
          disabled={btnDisabled}
        >
          Update Unit
        </StyledEditButton>
      ) : (
        <StyledSubmitButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddShoppingCart />}
          onClick={() => addDoorToSidebar()}
          disabled={btnDisabled}
        >
          Add to Quote
        </StyledSubmitButton>
      )}

      <StyledMinimizeButton
        variant="contained"
        size="large"
        color="customGrey"
        onClick={handleMinimize}
      >
        Minimize Tabs
      </StyledMinimizeButton>
    </div>
  );
};

export default ConfiguratorBtns;
