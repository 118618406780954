import React, { FC } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";

import JambLegCorner from "./JambLegCorner";
import HeadSillSection from "../HeadSillSection";
import { getBifoldKerfImage } from "../Lite";
import RefKeyCorner2 from "./RefKeyCorner2";
import PVCorner from "./PVCorner";
import { styles } from "../Styles";
import { Header } from "../DealerDrawings";
import { DrawingsProps } from "./DrawingsCorner";

const DrawingsCorner2: FC<DrawingsProps> = ({
  isDealer,
  form,
  item,
  unit,
  salesOrder,
  isProduction,
}) => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed).toLocaleDateString();
  const time = new Date(timeElapsed).toLocaleTimeString();

  const signline = "${signfield:1:y:______}";
  const dateSigned = "${datefield:1:y:Date_Signed}";

  const fontSize = { fontSize: 11 };

  return (
    <Page style={{ position: "relative" }}>
      <Header
        form={form}
        salesOrder={salesOrder}
        today={today}
        time={time}
        isDealer={isDealer}
        isProduction={isProduction}
      />

      <View style={[styles.column, { width: "100%" }]}>
        <View style={[styles.rowStart2, { alignItems: "center" }]}>
          <View style={styles.unitNum}>
            <Text style={styles.unitNumTxt}>Unit #: {unit}</Text>
          </View>

          <View style={[styles.evpvCol, { alignSelf: "flex-start" }]}>
            <PVCorner item={item} />
          </View>

          <View style={[styles.column, { width: "30%" }]}>
            <RefKeyCorner2 item={item} />

            {item.kerf ?
            <View
              style={[styles.column, { alignItems: "center", paddingTop: 20 }]}
            >
              <Text style={[styles.textBold, fontSize]}>
                Kerf Option:
              </Text>

              {item.kerfConfig !== "Custom Setback" ? (
                <Text style={[styles.textBold, fontSize]}>
                  KF = {item.kerfConfig?.replace("Standard", "")}
                </Text>
              ) : item.kerfConfig === "Custom Setback" ? (
                <Text style={[styles.textBold, fontSize]}>
                  KF = {item.kerfSetback}" Setback
                </Text>
              ) : (
                <Text style={[styles.textBold, fontSize]}>
                  N/A (No Kerf)
                </Text>
              )}

              <Image
                src={getBifoldKerfImage(item.addScreen || "", item.screenType || "")}
                style={{ height: 110, width: 88 }}
              />
            </View>
            :
            <Text />}
          </View>
        </View>

        <View style={[styles.rowSpacing, { paddingTop: 40 }]}>
          <View style={[styles.column, { alignItems: "center" }]}>
            <Text style={[styles.textBold, fontSize]}>
              Jamb Leg Section View:
            </Text>

            <JambLegCorner item={item} />
          </View>
          <View style={[styles.column, { alignItems: "center" }]}>
            <Text style={[styles.textBold, fontSize]}>
              Head/Sill Section View:
            </Text>

            <HeadSillSection item={item} />
          </View>
        </View>
      </View>

      {isDealer ? (
        <View style={styles.fixedBox}>
          <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
          <View style={{ position: "relative" }}>
            <Text style={styles.dateSigned}>{dateSigned}</Text>
          </View>
        </View>
      ) : (
        <View style={styles.initialsBox}>
          <Text style={styles.textBold}>Initials: __________</Text>
        </View>
      )}
    </Page>
  );
};

export default DrawingsCorner2;
