const addSdlChip = {
  id: 1,
  label: "Add SDL",
  type: "addDL",
};

const addTdlChip = {
  id: 1,
  label: "Add TDL",
  type: "addDL",
};

const addIRailChip = {
  id: 1,
  label: "Add Intermediate Rail",
  type: "addDL",
};

const lites = [
  {
    id: 1,
    type: "liteConfig",
    label: '1 1/8"',
    disabled: false,
  },
  {
    id: 2,
    type: "liteConfig",
    label: '7/8"',
    disabled: false,
  },
  {
    id: 3,
    type: "liteConfig",
    label: '5/8"',
    disabled: false,
  },
];

const lites2 = { id: 1, type: "liteConfig", label: '1 3/4"', disabled: true };

const litesIRail = [
  {
    id: 1,
    type: "liteConfig",
    label: '5 1/4"',
    disabled: false,
  },
  {
    id: 2,
    type: "liteConfig",
    label: '2 1/2"',
    disabled: false,
  }
];

const dlOptions = [
  { value: "1W2H", label: "1 Wide 2 High" },
  { value: "1W3H", label: "1 Wide 3 High" },
  { value: "1W4H", label: "1 Wide 4 High" },
  { value: "1W5H", label: "1 Wide 5 High" },
  { value: "1W6H", label: "1 Wide 6 High" },
  { value: "1W7H", label: "1 Wide 7 High" },
  { value: "2W1H", label: "2 Wide 1 High (Cottage Style)" },
  { value: "2W2H", label: "2 Wide 2 High" },
  { value: "2W3H", label: "2 Wide 3 High" },
  { value: "2W4H", label: "2 Wide 4 High" },
  { value: "2W5H", label: "2 Wide 5 High" },
  { value: "2W6H", label: "2 Wide 6 High" },
  { value: "3W1H", label: "3 Wide 1 High (Cottage Style)" },
  { value: "3W3H", label: "3 Wide 3 High" },
  { value: "3W4H", label: "3 Wide 4 High" },
  { value: "4W1H", label: "4 Wide 1 High (Cottage Style)" },
];

export {
  dlOptions,
  lites2,
  lites,
  addTdlChip,
  addSdlChip,
  addIRailChip,
  litesIRail
};
