import React, { useState, useCallback, FC } from "react";
import {
  Divider,
  Drawer,
  Button,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  CssBaseline,
  AppBar,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import ImageBanner from "./ImageBanner";
import DoorTabs from "./DoorTabs";
import RequiredOptionsDisplay from "./requiredDisplayed/RequiredOptionsDisplay";
import RequiredDisplayBifoldCorner from "./requiredDisplayed/RequiredDisplayBifoldCorner";
import RequiredDisplayPivot from "./requiredDisplayed/RequiredDisplayPivot";
import findRequired from "../util/findRequired";
import Spinner from "./Spinner";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import usePersistedState from "../hooks/usePersistedState";
import AccountDropdown from "./AccountDropdown";
import ExpiredModal from "./ExpiredModal";
import InfoAlert from "./InfoAlert";
import Skeleton from "@mui/material/Skeleton";
import CollapsePriceInfo from "./CollapsePriceInfo";
import CartBadge from "./CartBadge";
import QuoteNumber from "./QuoteNumber";
import OtsmIcon from "./OtsmIcon";
import SidebarTotals from "./SidebarTotals";
import QuoteSummaryBtn from "./QuoteSummaryBtn";
import { useQuery, useQueryClient } from "react-query";
import DragDropList from "./DragDropList";
import SidebarSkeleton from "./SidebarSkeleton";
import { useStore, useAccordion } from "../store/store";
import sortUnits from "../util/sortUnits";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getPricing,
  postAdminFactor,
  postQtyUpdate,
  postUnitUpdate,
  postDuplicate,
  postUnit,
  getQuote,
  getUser,
  postIgnoreCartCheck,
  getIgnoreCartCheck,
  getIgnoreUnitCheck,
} from "../api/SidebarApi";
import UnitCheckModal from "./UnitCheckModal";

import CartCheckModal from "./CartCheck/CartCheckModal";
import checkForCommonHardware from "../util/checkForCommonHardware";
import checkCart from "../util/checkCart";
import Copyright from "./Copyright";
import { blueGrey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { UnitObj, RequiredObj, AddonObj, AccordionObj } from "../util/quoteCartStorage";
import { FixesObj } from "../util/checkForCommonHardware";
import { LineItem } from "../util/checkCart";

const StyledDrawerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "200vh",
  overflow: "hidden",
});

const StyledButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  // top: 10,
  // right: 80,
  color: "black",
  width: 143,
  height: 32,
  marginTop: 10,
}));

const StyledDivRoot = styled("div")({
  display: "flex",
  minHeight: "100vh",
  paddingBottom: "2rem",
  position: "relative",
  backgroundColor: "#fafafa",
  maxWidth: "100%",
});

const drawerWidthSidebar = 310;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${drawerWidthSidebar}px)`,
    marginLeft: drawerWidthSidebar,
  },
  display: "flex",
}));

const StyledNav = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: drawerWidthSidebar,
    flexShrink: 0,
  },
}));

const StyledToolbar = styled(Toolbar)({
  backgroundColor: "#4b4b4b",
  display: "flex",
  justifyContent: "space-between",
});

const StyledFlexStartDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "50%",
});

const StyledFlexEndDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "50%",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
  position: "absolute",
  left: 20,
}));

const StyledImgLogoSmall = styled("img")({
  height: 75,
  width: 300,
});

const StyledImgLogo = styled("img")({
  height: 80,
  width: 60,
  marginLeft: 50,
});

const StyledMain = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "100%",
  paddingTop: "2rem",
  borderBottom: "1px solid #eeeeee",
}));

const StyledDiv = styled("div")({
  position: "relative",
  height: 75,
  borderBottom: "1px solid #cdd1d3",
});

const StyledDiv2 = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
});

const StyledList = styled(List)({
  zIndex: 10,
  backgroundColor: blueGrey[50],
});

const StyledDrawer = styled(Drawer)({
  width: drawerWidthSidebar,
  backgroundColor: blueGrey[50],
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidthSidebar,
    backgroundColor: blueGrey[50],
  },
});

const SideBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state;

  const quoteNumber = JSON.parse(sessionStorage.getItem("quoteNumber") || JSON.stringify(""));

  const theme = useTheme();

  const [fetch, setFetch] = useState(false);

  const queryClient = useQueryClient();

  const { data: userData, isFetching: fetchingUser } = useQuery(
    "Dealer",
    async () => {
      const response = await getUser(userId);
      if (response.data.signInRole === "Production") {
        const user = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
        navigate(`/dealerZone/${user}`);
        return;
      }
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setFetch(true),
    }
  );

  const { data, isFetching } = useQuery(
    (fetch || userData) && "QuoteCart",
    async () => {
      const response = await getQuote(userId, quoteNumber);

      const { quote, expiredUpdate } = response.data;

      if (quote.status !== "Quote" && quote.status !== "Archived") {
        if (
          userData.signInRole === "Dealer" ||
          userData.signInRole === "Manager" ||
          userData.signInRole === "Company Manager" ||
          userData.signInRole === "Outside Sales"
        ) {
          return navigate("/logout");
        }
      }

      expiredUpdate && handleOpen(expiredUpdate);

      quote.factor && setAdminFactor(parseFloat(quote.factor));

    if(quote.units.length){
      setOrder(sortUnits(quote.units) || [])
    }

      setLoadingQU(false);

      return response.data;
    },
    { refetchOnWindowFocus: false }
  );

  const [orderedUnits, setOrder] = useState<UnitObj[]>([]);

  const getStore = useStore((state) => state.getStore);
  const doorType = useStore((state) => state.quote.doorType);

  const isEdit = useStore((state) => state.isEdit);

  const resetQuote = useStore((state) => state.resetQuote);

  const handleMinimize = useAccordion((state) => state.handleMinimize);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [editQuote, setEditQuote] = useState(false);
  const [isSubmitBtnPressed, setIsSubmitBtnPressed] = usePersistedState(
    "isSubmitBtnPressed",
    false
  );

  const [nonMatches, setNonMatches] = useState<FixesObj[]>([]);

  const [openCommon, setOpenCommon] = useState(false); //used for unit check modal

  const handleCloseCommon = () => {
    setOpenCommon(false);
    setNonMatches([]);
  }; //used for unit check modal


  const [cartCheck, setCartCheck] = useState<LineItem[]>([]);

  const [openCCM, setOpenCCM] = useState(false); //used for cart check modal

  const handleCloseCCM = () => {
    setOpenCCM(false);
    setCartCheck([]);
  }; //used for cart check modal

  const [modalText, setModalText] = useState("");

  const [open, setOpen] = useState(false); //used for expired price modal

  const handleClose = () => setOpen(false); //used for expired price modal

  const handleOpen = (text: string) => {
    setModalText(text);
    setOpen(true);
  };

  const [loadingQuantity, setLoadingQU] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [adminFactor, setAdminFactor] = usePersistedState(
    "adminFactor",
    "None"
  );

  const addDoorToSidebar = async (withCheck?: boolean, ignoreUnitCheck?: boolean) => {
    if (
      (userData.role === "Inside Sales" ||
        userData.role === "Outside Sales" ||
        userData.role === "Admin") &&
      adminFactor === "None"
    ) {
      return alert("Select a Factor to Continue");
    }

    if (data.quote.units.length === 26) {
      handleOpen("Can not exceed 26 items in your cart");
      return;
    }

    if (isEdit && withCheck) {
      const unitId = JSON.parse(sessionStorage.getItem("unitId") || JSON.stringify(""));

      const response = await getIgnoreUnitCheck(data.quote._id, unitId);

      if (!response.data) {
        const store = getStore();
        const badMatch = checkForCommonHardware(store);

        if (badMatch.length) {
          setNonMatches(badMatch);
          setOpenCommon(true);
          return;
        }
      }
    } else if (!isEdit && withCheck) {
      const store = getStore();
      const badMatch = checkForCommonHardware(store);

      if (badMatch.length) {
        setNonMatches(badMatch);
        setOpenCommon(true);
        return;
      }
    }

    setOpenCommon(false);
    setNonMatches([]);
    setIsLoading(true);

    const endpoint =
      doorType === "Fixed Panel"
        ? "calcFixedPanel"
        : doorType.includes("Bi-fold")
        ? "calc"
        : `calc${doorType}`;

    unitCalculation(endpoint, ignoreUnitCheck);
  };

  const unitCalculation = async (postURL: string, ignoreUnitCheck?: boolean) => {
    const store = getStore();
    const require = findRequired(store);

    const res = await getPricing(postURL, store, data.quote._id);

    if (res.data.error) {
      handleOpen(
        "An Error Occurred for this Unit. Please Contact Customer Service at (805) 644-4494 for Technical Assistance."
      );
      setIsLoading(false);
      return;
    }

    if (isEdit) {
      editUnit(
        res.data.quote,
        require.required,
        require.expanded,
        res.data.itemized,
        res.data.itemizedUnfactored,
        ignoreUnitCheck
      );
    } else {
      let promises: Promise<void>[] & void[] = [];

      promises.push(
        addToQuoteHistory(
          res.data.quote,
          data.quote._id,
          require.required,
          require.expanded,
          res.data.itemized,
          res.data.itemizedUnfactored,
          ignoreUnitCheck
        )
      );

      promises.push(resetAllValues());

      Promise.all(promises)
        .then(() => {
          setIsLoading(false);
          setWindowOrDoor("Bi-Fold");
        })
        .catch((err) => {
          console.log(err);
          handleOpen(
            "An Error Occurred for this Unit. Please Contact Customer Service at (805) 644-4494 for Technical Assistance."
          );
          setIsLoading(false);
        });
    }
  };

  const addToQuoteHistory = async (
    saved: UnitObj,
    id: string,
    required: RequiredObj[] | string[],
    accordionControl: AccordionObj[],
    itemizedAddons: AddonObj[],
    itemizedAddonsUnfactored: AddonObj[],
    ignoreUnitCheck?: boolean
  ) => {
    localStorage.removeItem("accordionControl");
    localStorage.removeItem("requiredMsgs");
    !saved.doorType.includes("Bi-fold") &&
      localStorage.removeItem("displayedConfigOptions");
    const localStorageState = JSON.stringify(localStorage);

    const post = await postUnit(
      saved,
      id,
      localStorageState,
      required,
      accordionControl,
      itemizedAddons,
      itemizedAddonsUnfactored,
      ignoreUnitCheck
    );

    if (post.data.quote === "Unit Added") {
      return queryClient.invalidateQueries("QuoteCart");
    }
  };

  const duplicateUnit = async (unitId: string) => {
    if(data.quote.units.length === 26){
      handleOpen('Can not exceed 26 items in your cart');
      return;
    }

    setLD(true);

    const response = await postDuplicate(
      unitId,
      data.quote._id,
      data.quote.units.length || 0
    );

    if (response.data.error) {
      handleOpen(
        "An Error Occurred for this Unit. Please Contact Customer Service at (805)644-4494 for Technical Assistance."
      );
      setLD(false);
      return;
    }

    setLD(false);
    return queryClient.invalidateQueries("QuoteCart");
  };

  const editUnit = async (
    saved: UnitObj,
    required: RequiredObj[] | string[],
    accordionControl: AccordionObj[],
    itemizedAddons: AddonObj[],
    itemizedAddonsUnfactored: AddonObj[],
    ignoreUnitCheck?: boolean
  ) => {
    if (isEdit) {
      const unitId = JSON.parse(sessionStorage.getItem("unitId") || JSON.stringify(""));
      const localStorageState = JSON.stringify(localStorage);

      const orderIndex = orderedUnits.findIndex(item => item._id && item._id.toString() === unitId)

      const response = await postUnitUpdate(
        saved,
        data.quote._id,
        unitId,
        itemizedAddons,
        itemizedAddonsUnfactored,
        localStorageState,
        required,
        accordionControl,
        orderIndex,
        ignoreUnitCheck
      );

      if (response.data.error) {
        handleOpen(
          "An Error Occurred for this Unit. Please Contact Customer Service at (805) 644-4494 for Technical Assistance."
        );
        setIsLoading(false);
        return;
      }

      queryClient.invalidateQueries("QuoteCart");
      resetAllValues();

      setIsLoading(false);
      setWindowOrDoor("Bi-Fold");
    }
  };

  const resetAllValues = useCallback(() => {
    localStorage.clear();

    setIsSubmitBtnPressed(true);

    setTimeout(() => {
      setIsSubmitBtnPressed(false);
    }, 1000);

    resetQuote();
    handleMinimize();
  }, []);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const backToFormPage = () => {
    navigate(`/form/${userData.id}`, {
      state: { isQuoteCreated: true, userId },
    });
  };

  const handleResumeLater = () => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
    resetAllValues();
    navigate(`/dealerZone/${loggedInUser}`);
  };

  const changeQuantity = useCallback(
    async (unitId: string, newQuantity: string | number) => {
      setLoadingQU(true);

      const response = await postQtyUpdate(data.quote._id, unitId, Number(newQuantity));

      if (response.data.error) {
        handleOpen(
          "An Error Occurred for this Unit. Please Contact Customer Service at (805)644-4494 for Technical Assistance."
        );
        setLoadingQU(false);
        return;
      }

      return queryClient.invalidateQueries("QuoteCart");
    },
    [adminFactor, userData, data]
  );

  const handleAdminFactor = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      if (data.quote.status !== "Quote" && data.quote.status !== "Archived") {
        return alert("Can not change factor on Order");
      }
      setAdminFactor(value);

      if (value === "None") return;

      if (!data.quote.units.length) {
        const res = await postAdminFactor(data.quote._id, value);
        if (res.data.error) handleOpen("Could not add factor");
        return;
      }

      setLoadingQU(true);

      const res = await postAdminFactor(data.quote._id, value);

      if (res.data.error) {
        handleOpen("An Error Occurred for this Unit.");
        return;
      }

      return queryClient.invalidateQueries("QuoteCart");
    },
    [data]
  );

  const routeToOrderSummary = useCallback(
    async (route: boolean, ignoreCheck?: boolean) => {
      if (
        userData.factor === 1 &&
        (!adminFactor || adminFactor === "None") &&
        (userData.role === "Admin" ||
          userData.role === "Inside Sales" ||
          userData.role === "Outside Sales") &&
        !data.quote.factor
      ) {
        handleOpen("Enter a Factor to Continue");
        return;
      }

      function changePage() {
        sessionStorage.setItem("refresh", JSON.stringify(true));
        navigate(`/orderSummary/${userData.id}`, {
          state: {
            isQuoteCreated: true,
            region: userData.region,
            userId,
          },
        });
      }

      if (data.quote.status !== "Quote" && data.quote.status !== "Archived") {
        return changePage();
      }

      const response = await getIgnoreCartCheck(data.quote._id);

      if (route || response.data) {
        if (ignoreCheck) {
          await postIgnoreCartCheck(data.quote._id);
        }
        return changePage();
      }

      const check = checkCart(orderedUnits);

      if (Object.keys(check[0]).length > 9) {
        setCartCheck(check);
        setOpenCCM(true);
      } else {
        changePage();
      }
    },
    [userData, data, orderedUnits]
  );

  const [checkedPD, setCheckedPD] = usePersistedState("checkedPD", false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => setCheckedPD(event.target.checked);

  const [windowOrDoor, setWindowOrDoor] = usePersistedState(
    "windowOrDoor",
    "Bi-Fold"
  );

  const [loadDuplicate, setLD] = useState(false);

  const changeLogo = useMediaQuery("(min-width:500px)", { noSsr: true });

  const sidebarTotals =
    !isFetching &&
    !fetchingUser &&
    userData &&
    userData.dealerTax &&
    !data.quote.adminCharge
      ? [
          "Total",
          "Custom Clad Color: ",
          "Sales Tax: ",
          "Delivery Charge: ",
          "Dealer Net Cost: ",
        ]
      : !isFetching &&
        !fetchingUser &&
        userData &&
        userData.dealerTax &&
        data.quote.adminCharge
      ? [
          "Total",
          "Custom Clad Color: ",
          `${data.quote.adminChargeLabel || "Admin Charge"}: `,
          "Sales Tax: ",
          "Delivery Charge: ",
          "Dealer Net Cost: ",
        ]
      : [
          "Total",
          "Custom Clad Color: ",
          "Delivery Charge: ",
          "Dealer Net Cost: ",
        ];

  const drawer = (
    <StyledDrawerDiv>
      <StyledDiv>
        <StyledDiv2>
          <StyledButton
            onClick={backToFormPage}
            variant="contained"
            size="small"
            color="cyan"
          >
            <NavigateBeforeIcon style={{ display: "inline" }} />
            Back to Form
          </StyledButton>

          {!fetchingUser && !isFetching && data?.quote.units.length ? (
            <CartBadge numOfItems={data.quote.totalUnits} />
          ) : null}
        </StyledDiv2>
      </StyledDiv>

      {!fetchingUser &&
      !isFetching &&
      !loadingQuantity &&
      data?.quote.units.length ? (
        <DragDropList
          quoteId={data.quote._id}
          status={data.quote.status}
          units={orderedUnits}
          setUnits={setOrder}
          loadDuplicate={loadDuplicate}
          duplicateUnit={duplicateUnit}
          setWindowOrDoor={setWindowOrDoor}
          resetAllValues={resetAllValues}
          setEditQuote={setEditQuote}
          checkedPD={checkedPD}
          changeQuantity={changeQuantity}
        />
      ) : (
        <SidebarSkeleton
          salesperson={data?.quote.salesperson}
          fetchingUser={fetchingUser}
          isFetching={isFetching}
          loadingQuantity={loadingQuantity}
        />
      )}

      <StyledList>
        <Divider />

        {!fetchingUser &&
        !loadingQuantity &&
        (!userData.otsm ||
          (userData.signInRole !== "Dealer" &&
            userData.signInRole !== "Manager" &&
            userData.signInRole !== "Company Manager")) ? (
          <CollapsePriceInfo
            handleChange={handleSwitchChange}
            checked={checkedPD}
          >
            {!isFetching &&
              sidebarTotals.map((text, index) => (
                <div key={index}>
                  <SidebarTotals
                    text={text}
                    customClad={data?.quote.customCladPrice || 0}
                    factor={data.quote.factor}
                    canEditFactor={
                      userData.factor === 1 || userData.signInRole === "Admin"
                    }
                    dealerTax={userData.dealerTax || null}
                    handleAdminFactor={handleAdminFactor}
                    adminFactor={adminFactor}
                    suggRetailPrice={data?.quote.suggestedRetailPrice}
                    grandTotal={data?.quote.grandTotal}
                    deliveryCharge={data?.quote.deliveryCharge}
                    adminCharge={
                      userData.dealerTax && data?.quote.adminCharge
                        ? data.quote.adminCharge
                        : null
                    }
                  />
                </div>
              ))}
          </CollapsePriceInfo>
        ) : !fetchingUser && userData.otsm ? null : (
          <ListItem>
            <ListItemText
              primary={<Skeleton variant="rectangular" height={50} />}
            />
          </ListItem>
        )}

        {!fetchingUser && !isFetching && !loadingQuantity ? (
          <QuoteSummaryBtn
            grandTotal={data?.quote.grandTotal}
            disableQSum={data?.disableQSum}
            editBtn={isEdit || false}
            routeToOrderSummary={routeToOrderSummary}
          />
        ) : (
          <ListItem>
            <ListItemText
              primary={<Skeleton variant="rectangular" height={50} />}
            />
          </ListItem>
        )}
      </StyledList>
    </StyledDrawerDiv>
  );

  return (
    <StyledDivRoot>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <StyledFlexStartDiv>
            <StyledIconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </StyledIconButton>

            {changeLogo ? (
              <StyledImgLogoSmall
                src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoSmall.png"
                alt="Company Logo"
              />
            ) : (
              <StyledImgLogo
                src="https://res.cloudinary.com/ag-millworks/image/upload/doorlogo.png"
                alt=""
              />
            )}
          </StyledFlexStartDiv>

          <StyledFlexEndDiv>
            {!fetchingUser &&
              userData.otsm &&
              (userData.signInRole === "Dealer" ||
                userData.signInRole === "Manager" ||
                userData.signInRole === "Company Manager") && <OtsmIcon />}

            {!isFetching && data && (
              <div style={{ paddingRight: 10 }}>
                <QuoteNumber quoteNumber={data.quote.quoteNumber} />
              </div>
            )}

            <Button
              variant="contained"
              size="small"
              color="cyan"
              style={{ color: "white" }}
              onClick={handleResumeLater}
            >
              Resume Later
            </Button>

            <AccountDropdown
              color="white"
              resumeLaterBtn={true}
              returnedPath="config"
            />
          </StyledFlexEndDiv>
        </StyledToolbar>
      </StyledAppBar>
      <StyledNav>
        <Hidden lgUp implementation="css">
          <StyledDrawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </StyledDrawer>
        </Hidden>
        <Hidden lgDown implementation="css">
          <StyledDrawer variant="permanent" open>
            {drawer}
          </StyledDrawer>
        </Hidden>
      </StyledNav>

      <StyledMain style={{ maxWidth: !doorType ? "100%" : "" }}>
        <Spinner isLoading={isLoading} />

        <ExpiredModal
          open={open}
          handleClose={handleClose}
          modalText={modalText}
          changeDoor={false}
        />

        {!isFetching && data && cartCheck.length ? (
          <CartCheckModal
            cartCheck={cartCheck}
            handleClose={handleCloseCCM}
            open={openCCM}
            routeToOrderSummary={routeToOrderSummary}
            quoteId={data.quote._id}
            userId={userId}
          />
        ) : null}

        {!isFetching && data && (
          <UnitCheckModal
            open={openCommon}
            handleClose={handleCloseCommon}
            nonMatches={nonMatches}
            addToCart={addDoorToSidebar}
          />
        )}

        {doorType && (
          <>
            {!isEdit && <InfoAlert />}

            <ImageBanner />

            {doorType === "Bi-fold Zero Corner" ? (
              <RequiredDisplayBifoldCorner />
            ) : doorType === "Pivot" ? (
              <RequiredDisplayPivot />
            ) : (
              <RequiredOptionsDisplay />
            )}
          </>
        )}
        <DoorTabs
          windowOrDoor={windowOrDoor}
          setWindowOrDoor={setWindowOrDoor}
          resetAllValues={resetAllValues}
          editQuote={editQuote}
          submitPressed={isSubmitBtnPressed}
          addDoorToSidebar={addDoorToSidebar}
        />
      </StyledMain>

      <Copyright position="center1" />
    </StyledDivRoot>
  );
};

export default SideBar;
