import React, { FC } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Select,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Typography,
  InputBase,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  borderRadius: 4,
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "lightgrey",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Arial"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const StyledSearchDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const StyledSearchWrapDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 20,
  paddingBottom: 20,
  backgroundColor: "#fafafa",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const StyledResetButton = styled("button")({
  "&:hover": {
    cursor: "pointer",
  },
  marginLeft: 10,
  borderRadius: 5,
});

const StyledButton = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 20,
  color: "black",
});

const StyledDivRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: 10,
    justifyContent: "space-around",
  },
}));

const StyledRowDivCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
});

function getOptions(tab: string) {
  if (tab === "Glass Details") {
    return (
      <>
        <option value={"salesOrder"}>Sales Order</option>
        <option value={"invoiceNumber"}>Invoice</option>
      </>
    );
  }

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  if (tab === "Sales Report") {
    const email = JSON.parse(sessionStorage.getItem("email") || JSON.stringify(""));

    if (role === "Outside Sales" && email !== "george@agmillworks.com") {
      return (
        <>
          <option value={"date"}>Date</option>
          <option value={"dealer"}>Dealer</option>
          <option value={"comments"}>Comments</option>
        </>
      );
    }

    return (
      <>
        <option value={"date"}>Date</option>
        <option value={"agRep"}>AG Rep</option>
        <option value={"dealer"}>Dealer</option>
        <option value={"comments"}>Comments</option>
      </>
    );
  }

  if (tab === "Customer List") {
    const email = JSON.parse(sessionStorage.getItem("email") || JSON.stringify(""));

    if (role === "Outside Sales" && email !== "george@agmillworks.com") {
      return (
        <>
          <option value={"dealer"}>Dealer</option>
          <option value={"dealerRep"}>Dealer Rep</option>
          <option value={"email"}>Email</option>
        </>
      );
    }

    return (
      <>
        <option value={"agSalesRep"}>AG Rep</option>
        <option value={"dealer"}>Dealer</option>
        <option value={"dealerRep"}>Dealer Rep</option>
        <option value={"email"}>Email</option>
      </>
    );
  }

  if (tab === "Service Repair") {
    const admins = ["Admin", "Inside Sales", "Outside Sales"];

    if (admins.includes(role)) {
      return (
        <>
          <option value={"createdAt"}>Date Created</option>
          <option value={"customerName"}>Customer Name</option>
          <option value={"snNumber"}>Service Number</option>
          <option value={"poNumber"}>PO Number</option>
          <option value={"dealer"}>Dealer</option>
          <option value={"status"}>Status</option>
        </>
      );
    }

    return (
      <>
        <option value={"createdAt"}>Date Created</option>
        <option value={"customerName"}>Customer Name</option>
        <option value={"snNumber"}>Service Number</option>
        <option value={"poNumber"}>PO Number</option>
        <option value={"status"}>Status</option>
      </>
    );
  }

  if (tab === "Calendar") {
    return (
      <>
        <option value={"salesOrder"}>Sales Order</option>
        <option value={"snNumber"}>Service Number</option>
      </>
    );
  }

  if (role === "Production") {
    return (
      <>
        <option value={"quoteNumber"}>Quote Number</option>
        <option value={"jobName"}>Project Name</option>
        <option value={"poNumber"}>PO Number</option>
        <option value={"dealer"}>Dealer</option>
        <option value={"salesperson"}>Dealer Rep</option>
        <option value={"salesOrder"}>Sales Order Number</option>
      </>
    );
  }

  if (role === "Admin" || role === "Inside Sales" || role === "Outside Sales") {
    return (
      <>
        <option value={"createdAt"}>Date Created</option>
        <option value={"customerName"}>Customer Name</option>
        <option value={"quoteNumber"}>Quote Number</option>
        <option value={"jobName"}>Project Name</option>
        <option value={"poNumber"}>PO Number</option>
        {role !== "Outside Sales" && (
          <option value={"agSalesperson"}>AG Rep</option>
        )}
        <option value={"dealer"}>Dealer</option>
        <option value={"salesperson"}>Dealer Rep</option>
        {tab === "Orders" && (
          <>
            <option value={"salesOrder"}>Sales Order Number</option>
            <option value={"invoiceNumber"}>Invoice Number</option>
            <option value={"status"}>Status</option>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <option value={"createdAt"}>Date Created</option>
      <option value={"customerName"}>Customer Name</option>
      <option value={"quoteNumber"}>Quote Number</option>
      <option value={"jobName"}>Project Name</option>
      <option value={"poNumber"}>PO Number</option>
      {(role === "Manager" || role === "Company Manager") && (
        <option value={"salesperson"}>Dealer Rep</option>
      )}
      {tab === "Orders" && <option value={"status"}>Status</option>}
    </>
  );
}

interface SearchProps {
  filterBy: string;
  filterSearch?: () => void;
  handleFilterByChange: (e: SelectChangeEvent<string>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
  handleReset?: () => void;
  showTab: string;
  searchTerm: string;
  handleSearchBtn?: () => void;
  showArchive?: string;
  handleArchive?: (e: SelectChangeEvent<string>) => void;
  showActive?: string;
  handleActive?: (e: SelectChangeEvent<string>) => void;
}

const SearchBar: FC<SearchProps> = ({
  filterBy,
  filterSearch,
  handleFilterByChange,
  handleChange,
  onKeyDown,
  handleReset,
  showTab,
  searchTerm,
  handleSearchBtn,
  showArchive,
  handleArchive,
  showActive,
  handleActive,
}) => {
  return (
    <StyledSearchWrapDiv>
      <StyledSearchDiv>
        <StyledDivRow>
          <FormControl
            variant="outlined"
            margin="dense"
            style={{ paddingRight: 10, width: "100%", borderRadius: 10 }}
            disabled={showTab === "Calendar"}
          >
            <InputLabel>Filter Search</InputLabel>
            <Select
              style={{ backgroundColor: "lightgrey" }}
              native
              value={filterBy}
              onChange={handleFilterByChange}
              label="Filter Search"
              input={<BootstrapInput />}
            >
              {getOptions(showTab)}
            </Select>
          </FormControl>

          <TextField
            id="combo-box-demo"
            onChange={handleChange}
            onKeyPress={onKeyDown}
            size="small"
            style={{ width: 380, marginTop: 10 }}
            value={searchTerm}
            label="Search by Keyword..."
            variant="outlined"
          />
        </StyledDivRow>

        <StyledDivRow style={{ marginTop: 10 }}>
          <StyledButton
            onClick={() =>
              showTab === "Service Repair" && handleSearchBtn
                ? handleSearchBtn()
                : filterSearch
                ? filterSearch()
                : null
            }
            size="large"
            variant="contained"
            color={showTab === "Glass Details" ? "customGrey" : "cyan"}
            style={{ color: showTab === "Glass Details" ? "white" : "black" }}
          >
            <SearchIcon />
            <Typography style={{ fontWeight: "bold" }} variant="body2">
              Search
            </Typography>
          </StyledButton>
          {showTab !== "Calendar" && showTab !== "Glass Details" && (
            <StyledResetButton onClick={handleReset}>
              Reset
            </StyledResetButton>
          )}
        </StyledDivRow>
      </StyledSearchDiv>

      {showTab === "Insights" && (
        <StyledRowDivCenter>
          <Typography style={{ paddingRight: 10 }}>Show:</Typography>

          <Select size="small" value={showArchive} onChange={handleArchive}>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Quotes">Quotes</MenuItem>
            <MenuItem value="Archived">Archived</MenuItem>
            <MenuItem value="Quotes/Archived">Quotes/Archived</MenuItem>
            <MenuItem value="Orders">Orders</MenuItem>
          </Select>
        </StyledRowDivCenter>
      )}

      {showTab === "Quotes" && (
        <StyledRowDivCenter>
          <Typography style={{ paddingRight: 10 }}>Show:</Typography>

          <Select size="small" value={showArchive} onChange={handleArchive}>
            <MenuItem value="Quotes">Quotes</MenuItem>
            <MenuItem value="Archived">Archived</MenuItem>
            <MenuItem value="Quotes/Archived">Quotes/Archived</MenuItem>
          </Select>
        </StyledRowDivCenter>
      )}

      {showTab === "Customer List" && (
        <StyledRowDivCenter>
          <Typography style={{ paddingRight: 10 }}>Show:</Typography>

          <Select size="small" value={showActive} onChange={handleActive}>
            <MenuItem value="active">Active Accounts</MenuItem>
            <MenuItem value="inactive">Inactive Accounts</MenuItem>
          </Select>
        </StyledRowDivCenter>
      )}
    </StyledSearchWrapDiv>
  );
};

export default SearchBar;
