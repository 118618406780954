import React, { useEffect, memo, FC } from "react";
import Typography from "@mui/material/Typography";
import { convertDecimalToFraction } from "../util/pdfFormulas/convertDecimalToFraction";
import ReusableAccordian from "./ReusableAccordion";
import fractionToInt from "../util/fractionToInt";
import { useStore, useAccordion } from "../store/store";
import SizeLayout from "./SizeLayout";
import { calculatePanelHeight } from "../util/pdfFormulas/calculatePanelHeight";
import { RowLayout, ColLayout } from "../util/accordianStyles";
import { SelectChangeEvent } from "@mui/material";

interface HeightProps {
  doorType: string
  showHandleHeight: (show: boolean) => void
}

interface UpdateObj {
  height: string
  handleHeight?: string
}

const HeightAccordian: FC<HeightProps> = ({
  doorType,
  showHandleHeight,
}) => {
  const addToQuote = useStore(state => state.addToQuote);

  const direction = useStore((state) => state.quote.swingDirection);

  const handleHeight = useStore((state) => state.quote.handleHeight);

  const material = useStore((state) => state.quote.material);
  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const handleErrMsgChange = useStore((state) => state.handleError);
  const heightErr = useStore((state) => state.heightErr);

  const screenType = useStore((state) => state.quote.screenType);
  const addScreen = useStore((state) => state.quote.addScreen);
  const sillStyle = useStore((state) => state.quote.sillStyle);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[3].expanded);

  const overall = fractionToInt(height, heightFraction || "");

  let swingPanelHeight = calculatePanelHeight(
    "",
    "Swing",
    sillStyle || "",
    overall,
    addScreen && screenType ? screenType : "",
    material,
    direction
  );

  useEffect(() => {
    if (!height) return;

    const withCentor =
      addScreen && screenType?.includes("Centor") ? true : false;

    const range =
      doorType === "Bi-fold Window"
        ? { min: 26, max: 72, minString: '26"', maxString: '72"' }
        : doorType === "Fixed Panel"
        ? { min: 26, max: 122.5, minString: '26"', maxString: '122 1/2"' }
        : doorType === "Pivot"
        ? { min: 72, max: 121.875, minString: '72"', maxString: '121 7/8"' }
        : { min: 72, max: 122.5, minString: '72"', maxString: '122 1/2"' };

    if (overall <= range.max && overall >= range.min) {
      handleErrMsgChange("heightErr", "");
    } else if (overall < range.min) {
      handleErrMsgChange("heightErr", `${errMessageLow} ${range.minString}`);
    } else if (overall > range.max) {
      handleErrMsgChange("heightErr", `${errMessageHigh} ${range.maxString}`);
    }

    const resetObj = {
      addScreen: "",
      screenLayout: "",
      screenPocket: "",
      screenType: "",
    };

    if (withCentor) {
      let centorHeight = 0;

      if (doorType === "Slider") {
        centorHeight = sillStyle?.includes("Steel")
          ? overall + 1.1792
          : overall - 0.5625;
      } else {
        centorHeight = sillStyle?.includes("Ultra")
          ? // ? overall + 1.2417
            overall + 1.1875
          : overall - 0.5625
      }

      if (
        doorType?.includes("Window") &&
        centorHeight >= 25 &&
        centorHeight < 32.125
      ) {
        addToQuote(resetObj);
      }

      if (
        (addScreen?.includes("Combo") || addScreen?.includes("Shade")) &&
        centorHeight > 114
      ) {
        addToQuote(resetObj);
      }
    } else {
      if (doorType?.includes("Window") && overall >= 26 && overall < 32) {
        addToQuote(resetObj);
      }
    }
  }, [height, heightFraction, doorType]);

  const handleFractionChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({ heightFraction: value });
  };

  const errMessageHigh = "Height can not exceed";
  const errMessageLow = "Height must be at least";

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let update: UpdateObj = { height: value }
   
    const show = 
     doorType === "Pivot" && parseInt(value) >= 96 
     ? true 
     : parseInt(value) >= 108 
     ? true 
     : false;

    showHandleHeight(show);

    if (!show && handleHeight === '42"') {
      update["handleHeight"] = "";
    }

    addToQuote(update);
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="height"
      heading="Net Frame Height"
      secondaryHeading="Select a Height"
      required={false}
      required5={true}
    >
      <ColLayout style={{ maxWidth: 600 }}>
        {doorType.includes("Bi-fold") ? (
          <ColLayout style={{ alignItems: "flex-start" }}>
            <Typography style={{ marginTop: -20, fontSize: 14 }}>
              <span style={{ fontWeight: "bold" }}>Note:</span> Recommended Net
              Frame Height clearance is as follows:
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              1/2 inch less than the Rough Opening Height for Raised Sills
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              1-3/8 inches less than the RO Height for Ultra Guide tracks
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              1-5/8 inches less than the RO Height for Ultra Guide tracks with a
              Brio accordion Screen
            </Typography>
            <Typography style={{ paddingBottom: 20, fontSize: 14 }}>
              2-1/4 inches less than the RO Height for Ultra Guide tracks with a
              Centor Flat Screen/Shade
            </Typography>
          </ColLayout>
        ) : doorType === "Slider" ? (
          <Typography
            style={{ paddingBottom: 20, marginTop: -20, fontSize: 14 }}
          >
            <span style={{ fontWeight: "bold" }}>Note:</span> Recommended Net
            Frame Height clearance is as follows: 1/2 inch less than the Rough
            Opening Height for Raised Sills, and 1-3/4 inches for Stainless
            Steel tracks.
          </Typography>
        ) : (
          <Typography
            style={{ paddingBottom: 20, marginTop: -20, fontSize: 14 }}
          >
            <span style={{ fontWeight: "bold" }}>Note:</span> We recommend Net
            Frame Height to be 1/2 inch less than the Rough Opening Height
          </Typography>
        )}

        <RowLayout>
          <SizeLayout
            size={height}
            handleChange={handleHeightChange}
            sizeErr={heightErr}
            name="height"
            fractionValue={heightFraction || ""}
            handleFractionChange={handleFractionChange}
          />
        </RowLayout>

        {height && swingPanelHeight && doorType === "Swing" ? (
          <RowLayout>
            <Typography
              style={{
                color: "#3f51b5",
                fontWeight: "bold",
                paddingRight: 10,
              }}
            >
              Panel Height:{" "}
            </Typography>
            <Typography>
              {convertDecimalToFraction(swingPanelHeight)} inches
            </Typography>
          </RowLayout>
        ) : null}
      </ColLayout>
    </ReusableAccordian>
  );
}

export default memo(HeightAccordian);
