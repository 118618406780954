const handleColors = [
  { label: "Rustic Umber", img: "Handles/colors/Rustic_Umber" },
  {
    label: "Satin Nickel(Resista Finish)",
    img: "Handles/colors/Satin_Nickle",
  },
  { label: "Matte Black", img: "Handles/colors/Matte_Black" },
  { label: "White", img: "Handles/colors/White" },
  { label: "Oil Rubbed Bronze *", img: "Handles/colors/Oil_Rubbed_Bronze" },
  { label: "Polished Brass", img: "Handles/colors/Polished_Brass" },
  { label: "Satin PVD", img: "Handles/colors/Satin_PVD" },
  { label: "Satin Nickel PVD", img: "Handles/colors/Satin_PVD" },
  { label: "Bronze PVD", img: "Handles/colors/Bronze_PVD" },
];

const normalHandleColor = [
  "Rustic Umber",
  "Satin Nickel(Resista Finish)",
  "Matte Black",
  "White",
  "Oil Rubbed Bronze *",
]; //white is verona only

const veronaSliderColor = [
  "Rustic Umber",
  "Satin Nickel(Resista Finish)",
  "Matte Black",
  "White",
  "Polished Brass",
  "Oil Rubbed Bronze *",
];

const sliderTruthMountColors = ["Satin Nickel PVD", "Bronze PVD", "Matte Black"];

const normalHandleColorDallas = [
  "Rustic Umber",
  "Satin Nickel(Resista Finish)",
  "Matte Black",
];

const special = ["Matte Black", "Satin Nickel(Resista Finish)", "Rustic Umber"];

const specialAtlanta = ["Matte Black", "Satin Nickel(Resista Finish)"];

const handleImages = [
  { label: "Verona", imgLabel: "Handles/verona" },
  { label: "Dallas", imgLabel: "Handles/dallas" },
  { label: "Atlanta *", imgLabel: "Handles/atlanta" },
  { label: "Rodos *", imgLabel: "Handles/rodos" },
  { label: "Munchen *", imgLabel: "Handles/munchen" },
  { label: "New Orleans *", imgLabel: "Handles/neworleans" },
  { label: "Toronto *", imgLabel: "Handles/toronto" },
];

const sliderImgs = [
  { label: "Verona", imgLabel: "Handles/VeronaSlider" },
  { label: "Dallas", imgLabel: "Handles/dallasSlider" },
  { label: "One Piece Truth (Flush Mount)", imgLabel: "Handles/TruthFlush" },
];

const withHandle = [
  {
    id: 1,
    type: "withHandle",
    label: "Add Handle Set",
  },
  {
    id: 2,
    type: "withHandle",
    label: "No Handle Set",
  },
];

const handles = [
  {
    id: 1,
    type: "handleStyle",
    label: "Verona",
  },
  {
    id: 2,
    type: "handleStyle",
    label: "Dallas",
  },
];

const nonPocketHandles = [
  {
    id: 1,
    type: "handleStyle",
    label: "Verona",
  },
  {
    id: 2,
    type: "handleStyle",
    label: "Dallas",
  },
  {
    id: 3,
    type: "handleStyle",
    label: "One Piece Truth (Flush Mount)",
  },
];

const specialOrder = [
  {
    id: 1,
    type: "handleStyle",
    label: "Atlanta *",
  },
  {
    id: 2,
    type: "handleStyle",
    label: "Rodos *",
  },
  {
    id: 3,
    type: "handleStyle",
    label: "Munchen *",
  },
  {
    id: 4,
    type: "handleStyle",
    label: "New Orleans *",
  },
  {
    id: 5,
    type: "handleStyle",
    label: "Toronto *",
  },
];

const withKey = [
  {
    id: 1,
    type: "withKey",
    label: "Exterior Key",
  },
  {
    id: 2,
    type: "withKey",
    label: "No Key",
  },
];


export {
    withKey,
    specialOrder,
    nonPocketHandles,
    handles,
    withHandle,
    sliderImgs,
    handleImages,
    specialAtlanta,
    special,
    normalHandleColorDallas,
    sliderTruthMountColors,
    veronaSliderColor,
    normalHandleColor,
    handleColors
}