import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import Grow from "@mui/material/Grow";
import { Image, Transformation } from "cloudinary-react";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { cladColor, cladColorImgs } from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

function ExteriorCladAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[6].expanded);

  const exteriorCladColor = useStore((state) => state.quote.exteriorCladColor);
  const customCladColor = useStore((state) => state.quote.customCladColor);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 20) {
      addToQuote({ customCladColor: value }, "customCladColor");

      if (findRequired("Custom Clad Color"))
        removeRequired("Custom Clad Color");
    }
  };

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    addToQuote(
      { exteriorCladColor: !checked ? label : "" },
      "exteriorCladColor"
    );

    if (!checked && findRequired("Exterior Clad Color"))
      removeRequired("Exterior Clad Color");
  };

  const customCladChosen = exteriorCladColor === 'Custom Color (upcharge applies)';

  const variant = (label: string) => label === exteriorCladColor ? 'filled' : 'outlined';

  const checkMark = (label: string) => label === exteriorCladColor;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="exteriorClad"
      heading="Exterior Clad Color"
      secondaryHeading="Select exterior clad color"
      colOrRow="col"
    >
      {customCladChosen && (
        <>
          <Typography>
            *A shade variation in custom clad color is expected in production
            line spraying.
          </Typography>
          <Typography>
            **Please send color sample to our office with your quote number
            included. Production will not start until sample is recieved. Send
            to: 3111 Golf Course Dr. Ventura, CA 91301
          </Typography>
        </>
      )}

      <ColLayout>
        {!customCladChosen && (
          <Typography>* Colors on screen may vary from end product</Typography>
        )}
        <RowLayout>
          {cladColor.map((state) => (
            <div key={state.id} onClick={() => handleClick(state.label)}>
              <Choices
                label={state.label}
                variant={variant(state.label)}
                checkMark={checkMark(state.label)}
              />
            </div>
          ))}
        </RowLayout>
      </ColLayout>
      {customCladChosen ? (
        <TextField
          error={customCladChosen && !customCladColor ? true : false}
          helperText={
            !customCladColor ? "Please Enter a Custom Clad Color" : ""
          }
          variant="outlined"
          margin="dense"
          onChange={handleChange}
          value={customCladColor}
        />
      ) : exteriorCladColor ? (
        cladColorImgs
          .filter((img) => img.label === exteriorCladColor)
          .map((imageShown) => (
            <RowLayout key={imageShown.label}>
              <Grow in={true}>
                <div>
                  <Image
                    cloudName="ag-millworks"
                    secure="true"
                    publicId={imageShown.imgLabel}
                  >
                    <Transformation
                      width="200"
                      height="200"
                      radius="35"
                      crop="scale"
                    />
                  </Image>
                </div>
              </Grow>
            </RowLayout>
          ))
      ) : null}
    </ReusableAccordian>
  );
}

export default memo(ExteriorCladAccordian);
