const CLOUDINARY_URL = 'https://res.cloudinary.com/ag-millworks/image/upload/Slider_Configs';

export const taggedSliderConfigs = [
    {
        config: "OX OR XO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OX.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XO.jpg`
    },
    {
        config: "XX",
        img1: `${CLOUDINARY_URL}/nonpocketing/XX_LH.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XX_RH.jpg`
    },
    {
        config: "OXX OR XXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXX.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XXO.jpg`
    },
    {
        config: "XXX",
        img1: `${CLOUDINARY_URL}/nonpocketing/XXX_LH.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XXX_RH.jpg`
    },
    {
        config: "XXXX",
        img1: `${CLOUDINARY_URL}/nonpocketing/XXXX_LH.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XXXX_RH.jpg`
    },
    {
        config: "OXXX OR XXXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXXX.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XXXO.jpg`
    },
    {
        config: "OXXXX OR XXXXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXXXX.jpg`,
        img2: `${CLOUDINARY_URL}/nonpocketing/XXXXO.jpg`
    },
    {
        config: "OX-XO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OX-XO.jpg`,
        img2: ``
    },
    {
        config: "OXX-XXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXX-XXO.jpg`,
        img2: ``
    },
    {
        config: "OXXX-XXXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXXX-XXXO.jpg`,
        img2: ``
    },
    {
        config: "OXXXX-XXXXO",
        img1: `${CLOUDINARY_URL}/nonpocketing/OXXXX-XXXXO.jpg`,
        img2: ``
    },
    {
        config: "PX-XP",
        img1: `${CLOUDINARY_URL}/pocketing/PX-XP.jpg`,
        img2: ``
    },
    {
        config: "PX OR XP",
        img1: `${CLOUDINARY_URL}/pocketing/PX.jpg`,
        img2: `${CLOUDINARY_URL}/pocketing/XP.jpg`
    },
    {
        config: "PXX-XXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXX-XXP.jpg`,
        img2: ``
    },
    {
        config: "PXX OR XXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXX.jpg`,
        img2: `${CLOUDINARY_URL}/pocketing/XXP.jpg`
    },
    {
        config: "PXXX-XXXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXXX-XXXP.jpg`,
        img2: ``
    },
    {
        config: "PXXX OR XXXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXXX.jpg`,
        img2: `${CLOUDINARY_URL}/pocketing/XXXP.jpg`
    },
    {
        config: "PXXXX-XXXXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXXXX-XXXXP.jpg`,
        img2: ``
    },
    {
        config: "PXXXX OR XXXXP",
        img1: `${CLOUDINARY_URL}/pocketing/PXXXX.jpg`,
        img2: `${CLOUDINARY_URL}/pocketing/XXXXP.jpg`
    },
];