import React, { FC } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableCell, Tooltip } from "@mui/material";
import { QuoteObj } from "../../../../GlobalTypes";
import { styled } from "@mui/material/styles";

// export const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .MuiTooltip-tooltip`]: {
//     backgroundColor: "grey",
//     color: "white",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: "column",
//     alignItems: "center",
//   }
// }));

export const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "grey",
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  }
}));

function isAdmin(role: string) {
  return (
    role === "Admin" || role === "Inside Sales" || role === "Outside Sales"
  );
}

interface StatusTooltipProps {
  row: QuoteObj
  role: string
  tab: string
}

const StatusTooltip: FC<StatusTooltipProps> = ({ row, role, tab }) => {
  const admin = isAdmin(role);

  return (
    <TableCell align="center">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {row.status}
        {tab === "Orders" && (
          <HtmlTooltip
            placement="top"
            title={
              <>
                <p>
                  Created: {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}{" "}
                  at{" "}
                  {new Date(String(row.updatedAt)).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <p>
                  Sign-Off:{" "}
                  {new Date(String(row.dateOrdered)).toLocaleDateString("en-US")} at{" "}
                  {new Date(String(row.dateOrdered)).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                {row.depositReceivedDate && (
                  <p>
                    Deposit Received:{" "}
                    {new Date(row.depositReceivedDate).toLocaleDateString(
                      "en-US",
                      { timeZone: "UTC" }
                    )}
                  </p>
                )}
                {admin && row.glassOrderDate ? (
                  <p>
                    Glass Ordered:{" "}
                    {new Date(row.glassOrderDate).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
                  </p>
                ) : null}
                {admin && row.glassDeliveredDate ? (
                  <p>
                    Glass Delivered:{" "}
                    {new Date(row.glassDeliveredDate).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
                  </p>
                ) : null}
                {admin && row.scheduledDeliveryDate && (row.status === 'Scheduled' || row.status === 'Delivered' || row.status === 'Completed') ? (
                  <p>
                    {row.status === 'Scheduled' ? 'Scheduled:' : 'Delivered:'}{" "}
                    {new Date(row.scheduledDeliveryDate).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: "UTC",
                      }
                    )}{" "}
                    at {row.scheduledDeliveryTime}
                  </p>
                ) : null}
                 {row.invoiceDate && (
                  <p>
                    Invoiced:{" "}
                    {new Date(row.invoiceDate).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
                  </p>
                )}
              </>
            }
          >
            <MoreVertIcon />
          </HtmlTooltip>
        )}
      </div>
    </TableCell>
  );
};

export default StatusTooltip;
