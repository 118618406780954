import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getModalStyle from "../../../../util/getModalStyle";
import { StyledModalDiv } from "../../../../util/accordianStyles";

interface ConfimrationModalProps {
  handleClose: () => void;
  open: boolean;
  nextModal: (next: string) => void;
}

const ConfirmationModal: FC<ConfimrationModalProps> = ({
  handleClose,
  open,
  nextModal,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3>Would you like to overwrite this Order's Sales Order Number?</h3>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          onClick={handleClose}
          size="small"
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          onClick={() => nextModal("Sales")}
          size="small"
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default ConfirmationModal;
