import { roundTo4 } from "../../quotePDF/CutSheets/StoryPole";

function iRailGlassWidth(
  panelWidth: number,
  stiles: string,
  iRailSize: string,
  dlLayout: string,
  isSidelite: boolean
) {
  const slim = 1.875;
  const euro = 2.8125;
  const tradEqual = 4.125;

  const rails = iRailSize === '5 1/4"' ? 4 : 1.25;
  const numWide = Number(dlLayout.split("")[0]);
  const railMultiplier = numWide - 1;
  const totalRails = rails * railMultiplier;

  function calc(leftStile: number, rightStile: number) {
    const openingWidth =
      (panelWidth - leftStile - rightStile - totalRails) / numWide;
    return roundTo4(openingWidth);
  }

  let openingWidth;

  if (isSidelite) {
    openingWidth = calc(slim, slim);
    return { openingWidth };
  }

  if (stiles.includes("Bottom")) {
    openingWidth = calc(tradEqual, tradEqual);
  } else if (stiles.includes("Euro")) {
    openingWidth = calc(euro, euro);
  } else {
    //slimline
    openingWidth = calc(euro, slim);
  }

  return { openingWidth };
}

export default iRailGlassWidth;
