import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../Styles";
import noAccess from "../../../util/noAccess";
import { EVCornerProps } from "./EVCorner";

const refKeyLettersLeft = [
  "A.L",
  "B.L",
  "C.L",
  "D.L",
  "E.L",
  "F.L",
  "G.L",
  "H.L"
];

const refKeyLettersRight = [
  "A.R",
  "B.R",
  "C.R",
  "D.R",
  "E.R",
  "F.R",
  "G.R",
  "H.R"
];

const RefKeyCorner: FC<EVCornerProps> = ({ item, side }) => {
  const letters = side === "left" ? refKeyLettersLeft : refKeyLettersRight;

  const showH = (side === "left" && Number(item.exactConfig.split("")[0]) % 2 !== 0)
  || (side === "right" && Number(item.cornerConfigRight?.split("")[0]) % 2 !== 0) ? true : false

  return (
    <View style={[styles.column, { alignItems: "center" }]}>
      <Text style={[styles.textBold, { fontSize: 11 }]}>{side === "left" ? "Left Side" : "Right Side"} Reference Key</Text>

      <View style={[styles.row, { border: "1px solid black" }]}>
        <View style={[styles.column, { borderRight: "1px solid black" }]}>
          {letters.map((letter) => (
            <Text
              key={letter}
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              {letter}
            </Text>
          ))}
        </View>
        <View style={[styles.column, { alignItems: "center" }]}>
          <View style={styles.refKeyCol}>
            {side === "left" ? (
              <Text style={styles.refKeyText}>
                {item.unitSize} {item.unitSizeFraction || ""}" Net Frame Width
              </Text>
            ) : (
              <Text style={styles.refKeyText}>
                {item.cornerWidthRight} {item.cornerWidthFractionRight || ""}"
                Net Frame Width
              </Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.height} {item.heightFraction || ""}" Net Frame Height
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.panelHeight}" Panel Height
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.glassHeight}" DLO Height
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            {side === "left" ? (
              <Text style={styles.refKeyText}>
                {item.panelWidth}" Panel Width
              </Text>
            ) : (
              <Text style={styles.refKeyText}>
                {item.panelWidthRightCorner}" Panel Width
              </Text>
            )}
          </View>

          {side === "left" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.glassWidth}" DLO Width
              </Text>
            </View>
          ) : (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.glassWidthRightCorner}" DLO Width
              </Text>
            </View>
          )}

          <View style={styles.refKeyCol}>
            {side === "left" ? (
              <>
                {noAccess.includes(item.exactConfig) ? (
                  <Text style={styles.refKeyText}>N/A</Text>
                ) : item.glassWidthDD ? (
                  <Text style={styles.refKeyText}>
                    {item.glassWidthDD}" DD DLO Width
                  </Text>
                ) : (
                  <Text style={styles.refKeyText}>
                    {item.glassWidth}" DD DLO Width
                  </Text>
                )}
              </>
            ) : (
              <>
                {noAccess.includes(item.cornerConfigRight || "") ? (
                  <Text style={styles.refKeyText}>N/A</Text>
                ) : item.glassWidthDDRightCorner ? (
                  <Text style={styles.refKeyText}>
                    {item.glassWidthDDRightCorner}" DD DLO Width
                  </Text>
                ) : (
                  <Text style={styles.refKeyText}>
                    {item.glassWidthRightCorner}" DD DLO Width
                  </Text>
                )}
              </>
            )}
          </View>

          {showH ?
          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.handleHeight || '36"'} {item.withHandle === "Add Handle Set" ? "Handle" : "Shotbolt"} Height
            </Text>
          </View>
          :
          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>36" Shootbolt Height</Text>
          </View>
          }
        </View>
      </View>
    </View>
  );
};

export default RefKeyCorner;
