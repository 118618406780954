import React, { memo } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { stripColor } from "./defaults";
import { RowLayout } from "../../util/accordianStyles";

function StripAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const weatherStripColor = useStore((state) => state.quote.weatherStripColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[14].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    addToQuote({
      weatherStripColor: !checked ? label : ""
    }, "weatherStripColor")

    if (!checked && findRequired("Weather Strip Color"))
      removeRequired("Weather Strip Color");
  };

  const variant = (label: string) =>
    label === weatherStripColor ? "filled" : "outlined";

  const checkMark = (label: string) => label === weatherStripColor;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="strip"
      heading="Weather Strip Color"
      secondaryHeading="Select color"
    >
      <RowLayout>
        {stripColor.map((state) => (
          <div key={state.id} onClick={() => handleClick(state.label)}>
            <Choices
              label={state.label}
              variant={variant(state.label)}
              checkMark={checkMark(state.label)}
              wide={true}
            />
          </div>
        ))}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(StripAccordian);
