import React, { SyntheticEvent, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import News from "./News";
import TabPanel from "../TabPanel";
import Chart from "./Chart";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

function a11yProps(index: number) {
  return {
    id: `fullwidth-tab-${index}`,
    "aria-controls": `fullwidth-tabpanel-${index}`,
  };
}

const StyledRootDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: 20,
  [theme.breakpoints.down("md")]: {
    width: "100vw",
  },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  border: "2px solid lightgrey",
  backgroundColor: "white",
  // height: 270,
  width: 500,
  position: "relative",
  boxShadow: "4px 4px 8px #888888",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontWeight: "bold",
  fontSize: 16,
  border: "1px solid lightgrey",
  boxShadow: "4px 4px 8px #888888",
});

const StyledDiv = styled("div")({
  position: "absolute",
  right: 0,
  bottom: 0,
  zIndex: 10,
  borderLeft: "1px solid lightgrey",
  borderTop: "1px solid lightgrey",
  backgroundColor: "whitesmoke",
});

const StyledTypography = styled(Typography)({
  padding: 2,
  fontSize: 9,
});

const StyledChartDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  height: 270,
});

const ChartAndNewsTab = () => {
  const theme = useTheme();

  const role = JSON.parse(sessionStorage.getItem('role') || JSON.stringify(""));

  const isAdmin =
    role === "Admin" || role === "Inside Sales" || role === "Outside Sales";

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => setValue(newValue);

  return (
    <StyledRootDiv>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        style={{ backgroundColor: "#fafafa" }}
        aria-label="full width tabs example"
      >
        <StyledTab
          style={{ backgroundColor: value === 0 ? "white" : "#e1dfe0" }}
          label="Our Latest News"
          {...a11yProps(0)}
        />
        {!isAdmin && (
          <StyledTab
            style={{ backgroundColor: value === 1 ? "white" : "#e1dfe0" }}
            label="My Stats"
            {...a11yProps(1)}
          />
        )}
      </Tabs>

      <StyledTabPanel value={value} index={0} dir={theme.direction}>
        <News />

        <StyledDiv>
          <StyledTypography>Updated 07/19</StyledTypography>
        </StyledDiv>
      </StyledTabPanel>
      <StyledTabPanel value={value} index={1} dir={theme.direction}>
        {!isAdmin && (
          <StyledChartDiv>
            <Chart />
          </StyledChartDiv>
        )}
      </StyledTabPanel>
    </StyledRootDiv>
  );
};

export default ChartAndNewsTab;
