import React, { memo, FC } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { Typography, Paper } from "@mui/material";
import { useStore, useAccordion } from "../../store/store";
import { Image, Transformation } from "cloudinary-react";
import { styled } from "@mui/material/styles";

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledPaper = styled(Paper)({
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledTypography = styled(Typography)({
  fontWeight: "bold",
  fontSize: 20,
  paddingTop: 10,
});

const CYAN = "#57d5f2";

const BifoldCornerAccordian: FC = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[34].expanded);

  const cornerType = useStore((state) => state.quote.cornerType);

  const handleClick = (type: "Outside" | "Inside") => {
    addToQuote({
      cornerType: type,
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
      screenType: "",
    });
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="corner"
      heading="Zero Corner Type"
      secondaryHeading="Select type of zero corner"
      required5={true}
    >
      <StyledDivCol>
        <Typography style={{ paddingBottom: 20 }}>
          *As viewed from exterior
        </Typography>

        <StyledDivRow>
          <StyledDivCol style={{ paddingRight: 100 }}>
            <StyledPaper
              elevation={3}
              style={{
                border: cornerType === "Outside" ? `5px solid ${CYAN}` : "",
              }}
              onClick={() => handleClick("Outside")}
            >
              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId={"v1689373218/Bifold_Corner_Configs/OUTSIDE_CORNER"}
              >
                <Transformation height="229" width="250" crop="scale" />
              </Image>
            </StyledPaper>
            <StyledTypography
              style={{ color: cornerType === "Outside" ? CYAN : "black" }}
            >
              Outside Corner
            </StyledTypography>
          </StyledDivCol>

          <StyledDivCol>
            <StyledPaper
              elevation={3}
              style={{
                border: cornerType === "Inside" ? `5px solid ${CYAN}` : "",
              }}
              onClick={() => handleClick("Inside")}
            >
              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId={"v1689373223/Bifold_Corner_Configs/INSIDE_CORNER"}
              >
                <Transformation height="229" width="250" crop="scale" />
              </Image>
            </StyledPaper>
            <StyledTypography
              style={{ color: cornerType === "Inside" ? CYAN : "black" }}
            >
              Inside Corner
            </StyledTypography>
          </StyledDivCol>
        </StyledDivRow>
      </StyledDivCol>
    </ReusableAccordian>
  );
};

export default memo(BifoldCornerAccordian);
