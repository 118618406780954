import React, { useState, Dispatch, SetStateAction, FC } from "react";
import { usePDF } from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { GetApp } from "@mui/icons-material";
import axios from "axios";
import ConfirmSignModal from "../components/orderSummary/modals/ConfirmSignModal";
import DealerCost from "./DealerCost";
import { CutsheetDataObj } from "../GlobalTypes";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-bewteen",
  flexDirection: "column",
  paddingBottom: 60,
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    paddingBottom: 10,
  },
}));

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 20,
});

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: 50,
  left: "4vw",
  width: "150%",
  [theme.breakpoints.down("lg")]: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
}));

const StyledAnchor = styled("a")({
  textDecoration: "none",
  marginBottom: 5,
});

const StyledSpan = styled("span")({
  fontWeight: "bold",
  paddingLeft: 5,
  paddingRight: 5,
});

interface DealerProps {
  setPdfErr?: Dispatch<SetStateAction<boolean>>;
  data: any;
  salesOrder?: string;
  viewAllState?: boolean;
  codOrTerms?: string;
  isTable?: boolean;
  poNumber?: string;
  region?: string;
  factor?: number;
  grandTotal: string;
  suggestedRetail: string;
  isOrder?: boolean;
  isProduction?: boolean;
  cutsheetData?: CutsheetDataObj[];
  series: string;
  isCalendar?: boolean;
  cantSign?: string;
  dealerTax?: number;
}

const GenerateDealer: FC<DealerProps> = ({
  setPdfErr,
  data,
  salesOrder,
  viewAllState,
  codOrTerms,
  isTable,
  poNumber,
  region,
  factor,
  grandTotal,
  suggestedRetail,
  isOrder,
  isProduction,
  cutsheetData,
  series,
  isCalendar,
  cantSign,
  dealerTax,
}) => {
  const navigate = useNavigate();

  const [instance, updateInstance] = usePDF({
    document: (
      <DealerCost
        data={data}
        codOrTerms={codOrTerms || ""}
        region={region || ""}
        poNumber={poNumber || ""}
        factor={factor || 1}
        grandTotal={grandTotal}
        suggestedRetail={suggestedRetail}
        isOrder={isOrder || false}
        salesOrder={salesOrder || ""}
        isProduction={isProduction}
        cutsheetData={cutsheetData}
        series={series}
        dealerTax={dealerTax || 0}
      />
    ),
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [loading, setLoading] = useState(false);

  const getBlobBase64 = () => {
    if (!instance) return;

    setLoading(true);

    if (instance.blob instanceof Blob) {
      var reader = new FileReader();

      reader.readAsDataURL(instance.blob);

      reader.onloadend = function () {
        var base64String = reader.result;

        if (!base64String) return setLoading(false);

        axios
          .post(
            "/api/createEsignFolder",
            {
              base64PDF: base64String.slice(
                String(base64String).indexOf(",") + 1
              ),
              fileName: fileName,
            },
            {
              headers: { "x-auth-token": sessionStorage.getItem("auth-token") },
              maxContentLength: Infinity,
              maxBodyLength: Infinity,
            }
          )
          .then((res) => {
            sessionStorage.setItem(
              "esignURL",
              JSON.stringify(
                res.data.embeddedSigningSessions[0].embeddedSessionURL
              )
            );
            setLoading(false);
          })
          .then((data) => navigate("/esign"))
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      };
    } else {
      return setLoading(false);
    }
  };

  if (isCalendar && instance.loading && !instance.error) {
    return (
      <div style={{ marginLeft: 20 }}>
        <Typography variant="caption">Generating PDF...</Typography>
      </div>
    );
  }

  if (instance.loading && !instance.error) return <div>Calculating ...</div>;

  if (instance.error) {
    setPdfErr && setPdfErr(true);
    return null;
  }

  const fileName = !isProduction
    ? `AGMillworks(PC-${data.quoteNumber})Dealer.pdf`
    : `AGMillworks(PC-${data.quoteNumber})Production.pdf`;

  return (
    <>
      {isCalendar && instance.url ? (
        <StyledDivRow>
          <Typography variant="caption" style={{ paddingRight: 10 }}>
            Order
          </Typography>
          <a
            href={instance.url}
            download={fileName}
            style={{ textDecoration: "none" }}
          >
            <GetApp color="primary" style={{ fontSize: 20 }} />
          </a>
        </StyledDivRow>
      ) : !isTable && instance.url ? (
        <>
          {!isOrder && (
            <ConfirmSignModal
              open={open}
              handleClose={handleClose}
              handleSign={getBlobBase64}
              loading={loading}
              cantSign={cantSign || ""}
            />
          )}
          {viewAllState ? (
            <StyledDiv>
              <StyledAnchor href={instance.url} download={fileName}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#21c7f5", color: "black" }}
                >
                  <StyledSpan> Dealer </StyledSpan> Details
                </Button>
              </StyledAnchor>
              {!isOrder && data.status !== "Archived" && viewAllState && (
                <StyledSubmitButton
                  onClick={handleOpen}
                  variant="contained"
                  color="customGrey"
                  style={{ color: "white" }}
                >
                  Sign / Submit <StyledSpan> Order </StyledSpan>
                </StyledSubmitButton>
              )}
            </StyledDiv>
          ) : (
            <div style={{ padding: 10 }}>
              <StyledAnchor href={instance.url} download={fileName}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#21c7f5", color: "black" }}
                >
                  <StyledSpan> Dealer </StyledSpan> Details
                </Button>
              </StyledAnchor>
              {!isOrder && data.status !== "Archived" && viewAllState && (
                <StyledSubmitButton
                  onClick={handleOpen}
                  variant="contained"
                  color="customGrey"
                  style={{ color: "white" }}
                >
                  Sign / Submit <StyledSpan> Order </StyledSpan>
                </StyledSubmitButton>
              )}
            </div>
          )}
        </>
      ) : instance.url ? (
        <a
          href={instance.url}
          download={fileName}
          style={{ textDecoration: "none" }}
        >
          <StyledTypography variant="caption">View PDF</StyledTypography>
        </a>
      ) : null}
    </>
  );
};

export default GenerateDealer;
