import React, { FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useStore } from "../store/store";
import { calculatePanelWidth } from "../util/pdfFormulas/calculatePanelWidth";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 240,
  height: 20,
  position: "relative",
  top: -25,
}));

function findNumberOfPanels(config: string): number{
  const panels: string[] = config.split('OR')[0].trim().split('').filter(item => !isNaN(Number(item)))

  if (panels.length === 1) {
    return Number(panels[0]);
  }

  const numOfPanels: number = panels.reduce((x: number, y: string) => Number(x) + Number(y), 0);

  return numOfPanels;
};

interface SelectionProps {
  chosenConfig?: string,
  isWeirdSixteenRange?: boolean,
  setIsPocketAllowed?: (isAllowed: boolean) => void,
  setFivePanel16?: (isFivePanel: boolean) => void,
  selectedConfig?: (config: string) => void,
  typeRequired: string,
  displayedConfig?: string[],
  netWidth?: number
}

const Selection: FC<SelectionProps> = ({
  chosenConfig,
  selectedConfig,
  typeRequired,
  displayedConfig,
  netWidth
}) => {
  const addToQuote = useStore(state => state.addToQuote);

  const qty = useStore((state) => state.quote.quantity);

  const qtyOptions = Array.from({length: 20}, (x, i) => i + 1);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    let update = { [name as string]: value };

    if (name === "configuration") {
      selectedConfig && selectedConfig(value);
    } else {
      addToQuote(update);
    }
  };

  const isLargePanel = (config: string): string => {
    const numOfPanels = findNumberOfPanels(config);
    const panelWidth = calculatePanelWidth("Bi-fold", netWidth, numOfPanels);

    if (panelWidth && panelWidth > 42) return `${config} (Wider Panels)`;
    return config;
  }

  const configs = (
      displayedConfig?.length &&
      displayedConfig.map((option, index) => (
        <MenuItem 
          key={index} 
          value={option}
        >
          {isLargePanel(option)}
        </MenuItem>
      ))
    );

  return (
    <div style={{ height: 50 }}>
      <StyledFormControl
        required={typeRequired === "config" ? true : false}
        variant="outlined"
      >
        <InputLabel>
          {typeRequired === "unitSize"
            ? "fraction"
            : typeRequired === "config"
            ? "Configuration"
            : "Quantity"}
        </InputLabel>

        {typeRequired === "config" && (
          <Select
            value={chosenConfig}
            onChange={handleChange}
            name="configuration"
            style={{ backgroundColor: 'white' }}
            label={typeRequired === "config"
            ? "Configuration"
            : "Quantity"}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {configs}
          </Select>
        )}
        {typeRequired === "quantity" && (
          <Select
            value={String(qty)}
            onChange={handleChange}
            name="quantity"
            label="Quantity"
          >
            {qtyOptions.map(num => (
              <MenuItem key={num} value={num}>{num}</MenuItem>
            ))}
          </Select>
        )}

        {typeRequired === "quantity" ? null : (
          <FormHelperText>Required</FormHelperText>
        )}
      </StyledFormControl>
    </div>
  );
}

export default Selection

Selection.defaultProps = {
  displayedConfig: [],
  netWidth: 0
};
