function priceWithZeros(price: string | number){
    const priceAsString = price.toString();

    if (
      priceAsString.includes(".") &&
      priceAsString.split(".")[1].length === 1
    ) {
      return `${price}0`;
    }else if(!priceAsString.includes('.')){
      return `${price}.00`
    }
    
    return String(price);
};

export default priceWithZeros;