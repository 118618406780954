import React from "react";
import { Typography, TextField } from "@mui/material";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { stain, stains } from "./defaults";
import { styled } from "@mui/material/styles";

const RowLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
  borderTop: `2px solid ${theme.palette.divider}`,
  marginTop: 20,
}));

const RowLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
  marginTop: 20,
}));

interface UpdateObj {
  stainColor?: string
  customPaintOrStain?: string
  stain?: string
}

export default function StainAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const stainChoice = useStore((state) => state.quote.stain);
  const customStain = useStore((state) => state.quote.customPaintOrStain);
  const customStainColor = useStore((state) => state.quote.customStainColor);

  const btnChoice = stainChoice || customStain || "";

  const stainColor = useStore((state) => state.quote.stainColor);

  const materialChoice = useStore((state) => state.quote.material);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[11].expanded);
  const require = useAccordion((state) => state.expanded[11].require);

  const handleClick = (label: string, type: string) => {
    const checked = label === btnChoice;

    if (checked) {
      addToQuote({ [type]: "" }, type);
    } else {
      let update: UpdateObj = { [type]: label };
      let ignoreResetKey = "";

      if (type !== "customPaintOrStain") {
        update["customPaintOrStain"] = "";
        
        if(customStain){
          ignoreResetKey = "customPaintOrStain"
        }
      } else {
        update["stain"] = "";

        if(stainChoice){
          ignoreResetKey = "stain"
        }
      }

      addToQuote(update, ignoreResetKey);
    }
  };

  const handleClickColor = (label: string) => {
    const checked = label === stainColor;

    if (checked) {
      addToQuote({ stainColor: "" });
    } else {
      addToQuote({ stainColor: label });

      if (findRequired("Stain Color")) removeRequired("Stain Color");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 15) {
      addToQuote({ customStainColor: value });

      if (findRequired("Custom Stain Color"))
        removeRequired("Custom Stain Color");
    }
  };

  const standardStainSelected = btnChoice === "Add Standard Stain";

  const customStainSelected = btnChoice === "Add Custom Stain";

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="stain"
      heading={`${
        materialChoice.includes("Clad") ? "Interior" : ""
      } Prefinished Stain`}
      secondaryHeading="Select prefinished stain"
      colOrRow="col"
      required={require ? true : false}
    >
      {customStainSelected && (
        <Typography variant="subtitle1">
          *For custom stain please provide sample to AGM for proper pricing
        </Typography>
      )}
      <Typography variant="subtitle1">
        **A shade variation in color is expected in production line.
      </Typography>
      <RowLayoutSpacing>
        {stain.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={state.label === btnChoice ? "filled" : "outlined"}
              checkMark={state.label === btnChoice}
            />
          </div>
        ))}
      </RowLayoutSpacing>
      {standardStainSelected && (
        <RowLayoutTopDivider>
          {stains.map((state) => (
            <div key={state.id} onClick={() => handleClickColor(state.label)}>
              <Choices
                label={state.label}
                variant={state.label === stainColor ? "filled" : "outlined"}
                checkMark={state.label === stainColor}
              />
            </div>
          ))}
        </RowLayoutTopDivider>
      )}

      {customStain && (
        <TextField
          error={!customStainColor ? true : false}
          helperText={
            !customStainColor ? "Please Enter a Custom Stain Color" : ""
          }
          variant="outlined"
          margin="dense"
          size="small"
          onChange={handleChange}
          value={customStainColor}
        />
      )}
    </ReusableAccordian>
  );
}
