const screenLayoutImages = [
  { label: "Double Screen", img: "Screens/double_screen" },
  { label: "Single Compress Left", img: "Screens/left_screen" },
  { label: "Single Compress Right", img: "Screens/right_screen" },
  { label: "Combo Screen Right", img: "Screens/combo_shade_right" },
  { label: "Combo Screen Left", img: "Screens/combo_shade_left" },
  {
    label: "Shade Right",
    img: "Screens/right_shade",
  },
  {
    label: "Shade Left",
    img: "Screens/left_shade",
  },
  {
    label: "Double Shade",
    img: "Screens/double_shade",
  },
];

const screenColors = [
  {
    id: 1,
    type: "screenColor",
    label: "Black Extrusion",
    variant: "default",
    checkMark: false,
    disabled: false
  },
  {
    id: 2,
    type: "screenColor",
    label: "White Extrusion",
    variant: "default",
    checkMark: false,
    disabled: false
  },
];

const screenLayoutLeft = [
  {
    id: 1,
    type: "screenLayout",
    label: "Single Screen Compress Left",
    pdfLabel: "Single Compress Left",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const screenLayoutRight = [
  {
    id: 1,
    type: "screenLayout",
    label: "Single Screen Compress Right",
    pdfLabel: "Single Compress Right",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const screenLayoutBoth = [
  {
    id: 1,
    type: "screenLayout",
    label: "Single Screen Compress Left",
    pdfLabel: "Single Compress Left",
    variant: "default",
    checkMark: false,
    disabled: false
  },
  {
    id: 2,
    type: "screenLayout",
    label: "Single Screen Compress Right",
    pdfLabel: "Single Compress Right",
    variant: "default",
    checkMark: false,
    disabled: false
  },
];

const shadeLayoutBoth = [
  {
    id: 2,
    type: "screenLayout",
    label: "Left Shade",
    pdfLabel: "Left Shade",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 3,
    type: "screenLayout",
    label: "Right Shade",
    pdfLabel: "Right Shade",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

const shadeLayoutRight = [
  {
    id: 3,
    type: "screenLayout",
    label: "Right Shade",
    pdfLabel: "Right Shade",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const shadeLayoutLeft = [
  {
    id: 2,
    type: "screenLayout",
    label: "Left Shade",
    pdfLabel: "Left Shade",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const pocket = [
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

const doublePocket = [
  {
    id: 2,
    type: "screenPocket",
    label: "Add Double Pocket",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const doublePocketSingleDefault = [
  //For single direction slider with double screen
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
  {
    id: 2,
    type: "screenPocket",
    label: "Add Double Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

const doublePocketUnChecked = [
  {
    id: 2,
    type: "screenPocket",
    label: "Add Double Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

const pocketScreen = [
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

const addSliderScreen = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Screen",
    variant: "default",
    checkMark: false,
    disabled: false
  },
];

const addSliderScreenDbl = [
  {
    id: 2,
    type: "addScreen",
    label: "Add Double Screen",
    variant: "default",
    checkMark: false,
    disabled: false
  },
];

const addSliderScreenBoth = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Screen",
    variant: "default",
    checkMark: false,
    disabled: false
  },
  {
    id: 2,
    type: "addScreen",
    label: "Add Double Screen",
    variant: "default",
    checkMark: false,
    disabled: false
  },
];

const group1 = [
  "PX-AP",
  "PA-XP",
  "PXA-XXP",
  "PXX-AXP",
  "PXXA-XXXP",
  "PXXX-AXXP",
  "PXXXA-XXXXP",
  "PXXXX-AXXXP",
];
const group2 = [
  "OA-XO",
  "OX-AO",
  "OXA-XXO",
  "OXX-AXO",
  "OXXX-AXXO",
  "OXXA-XXXO",
  "OXXXA-XXXXO",
  "OXXXX-AXXXO",
];
const group3 = ["PX", "PXX"];
const group4 = ["PXXX", "PXXXX"];
const group5 = ["XXXP", "XXXXP"];
const group6 = ["OXX", "OXXX", "OXXXX"];
const group7 = ["XXO", "XXXO", "XXXXO"];
const group8 = ["XP", "XXP"];
const group9 = ["OX"];
const group10 = ["XO"];
const group11 = [
  "XX Left Primary",
  "XX Right Primary",
  "XXX Left Primary",
  "XXX Right Primary",
  "XXXX Left Primary",
  "XXXX Right Primary",
];

const screenType = [
  { id: 1, type: "screenType", label: "Brio Accordion Screen" },
  { id: 2, type: "screenType", label: "Centor Flat Screen/Shade" },
  { id: 3, type: "screenType", label: "Sliding Screen" },
];

function sliderScreenOptions(config: string) {
  const options = [
    {
      group: group1,
      addScreen: addSliderScreenDbl,
      pocket: doublePocket,
      shadeLayout: shadeLayoutBoth,
      layout: screenLayoutBoth
    },
    {
      group: group2,
      addScreen: addSliderScreenDbl,
      pocket: doublePocketUnChecked,
      shadeLayout: shadeLayoutBoth,
      layout: screenLayoutBoth
    },
    {
      group: group3,
      layout: screenLayoutLeft,
      shadeLayout: shadeLayoutLeft,
      addScreen: addSliderScreen,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocketScreen,
    },
    {
      group: group4,
      layout: screenLayoutLeft,
      shadeLayout: shadeLayoutLeft,
      addScreen: addSliderScreenBoth,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocketScreen,
      doublePocket: doublePocket,
    },
    {
      group: group5,
      layout: screenLayoutRight,
      shadeLayout: shadeLayoutRight,
      addScreen: addSliderScreenBoth,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocketScreen,
      doublePocket: doublePocket,
    },
    {
      group: group6,
      layout: screenLayoutLeft,
      shadeLayout: shadeLayoutLeft,
      addScreen: addSliderScreenBoth,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocket,
      doublePocket: doublePocketSingleDefault,
    },
    {
      group: group7,
      layout: screenLayoutRight,
      shadeLayout: shadeLayoutRight,
      addScreen: addSliderScreenBoth,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocket,
      doublePocket: doublePocketSingleDefault,
    },
    {
      group: group8,
      layout: screenLayoutRight,
      shadeLayout: shadeLayoutRight,
      addScreen: addSliderScreen,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocketScreen,
    },
    {
      group: group9,
      layout: screenLayoutLeft,
      shadeLayout: shadeLayoutLeft,
      addScreen: addSliderScreen,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocket,
    },
    {
      group: group10,
      layout: screenLayoutRight,
      shadeLayout: shadeLayoutRight,
      addScreen: addSliderScreen,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocket,
    },
    {
      group: group11,
      layout: screenLayoutBoth,
      shadeLayout: shadeLayoutBoth,
      addScreen: addSliderScreenBoth,
      addScreenDbl: addSliderScreenDbl,
      pocket: pocketScreen,
      doublePocket: doublePocket,
    },
  ];

  const configScreenOptions = options.find((item) =>
    item.group.includes(config)
  );

  return configScreenOptions;
}

export interface ScreenObj {
  id: number
  type: string
  label: string
  pdfLabel?: string
  variant: string
  checkMark: boolean
  disabled: boolean
}

export interface SliderScreenOption {
  group: string[]
  layout?: ScreenObj[]
  addScreen: ScreenObj[]
  addScreenDbl?: ScreenObj[]
  pocket: ScreenObj[]
  doublePocket?: ScreenObj[]
}

export {
  screenColors,
  screenLayoutImages,
  sliderScreenOptions,
  group6,
  group7,
  screenType
};
