import React, { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AttemptSignInOnMount, checkForExpiredToken } from "./api/Authentication";

interface PrivateRouteProps {
  children: React.ReactElement<any, any> | null
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const userId = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
  const [validToken, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    (async function(){
      const isValid = await AttemptSignInOnMount();
      setIsValid(isValid as boolean)
    })()
  }, [])

  if (!checkForExpiredToken()) {
    return <Navigate to="/logout" />;
  }

  return validToken && userId ? children : <Navigate to="/" />;
};

export default PrivateRoute;
