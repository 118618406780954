import React, { useState, useEffect, useRef, FC } from "react";
import ReusableAccordian from "./ReusableAccordion";
import fractionToInt from "../util/fractionToInt";
import SizeLayout from "./SizeLayout";
import { useStore } from "../store/store";
import { useAccordion } from "../store/store";
import { Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { RowLayout, ColLayout } from "../util/accordianStyles";

const errMsgHigh = "Width can not exceed";
const errMsgLow = "Width must exceed";

interface UnitSizeProps {
  bifoldType: string;
}

interface UpdateObj {
  unitSize?: string
  unitSizeFraction?: string
  jamb?: string
  addScreen?: string
  screenPocket?: string
  screenColor?: string
  screenLayout?: string
  screenMeshColor?: string
  screenType?: string
}

const UnitSizeAccordian: FC<UnitSizeProps> = ({ bifoldType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const width = useStore((state) => state.quote.unitSize);
  const fraction = useStore((state) => state.quote.unitSizeFraction);

  const unitSizeFraction = !fraction ? "0" : fraction;

  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[2].expanded);

  const screenType = useStore((state) => state.quote.screenType);
  const screen = useStore((state) => state.quote.addScreen);

  const [max, setMax] = useState({ int: 339.25, string: "339 1/4" });

  const initialRef = useRef(true);

  useEffect(() => {
    if (!bifoldType) return;

    if (initialRef.current) {
      initialRef.current = false;
    } else {
      handleError("widthErr", "");

      switch (bifoldType) {
        case "door":
          setMax({ int: 339.25, string: "339 1/4" });
          break;
        case "window":
          setMax({ int: 255, string: "255" });
          break;
        default:
          return;
      }
    }
  }, [bifoldType]);

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value !== width) {
      let update: UpdateObj = { unitSize: value };

      const overall = fractionToInt(value, fraction);

      if (overall > max.int) {
        handleError("widthErr", `${errMsgHigh} ${max.string}"`);
      } else if (overall <= max.int && overall >= 38.375) {
        handleError("widthErr", "");
        update = {
          ...update,
          jamb: "",
          addScreen: "",
          screenPocket: "",
          screenColor: "",
        };
        handleError("jambErr", "");
      } else if (overall < 38.375) {
        handleError("widthErr", `${errMsgLow} 38 3/8"`);
      }

      if (screenType === "Centor Flat Screen/Shade") {
        if (
          (screen === "Add Double Shade" && overall > 316.8125) ||
          (screen === "Add Shade" && overall > 159.8125) ||
          (screen === "Add Combo Screen/Shade" && overall > 161.8125) ||
          (screen === "Add Screen" && overall > 175.8125)
        ) {
          update = {
            ...update,
            addScreen: "",
            screenType: "",
            screenPocket: "",
            screenLayout: "",
            screenMeshColor: "",
            screenColor: "",
            jamb: "",
          };
        }
      }

      addToQuote(update, "unitSize");
    }
  };

  const handleFractionChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (value !== fraction) {
      let update: UpdateObj = {};

      if (value === null) {
        update["unitSizeFraction"] = "";
      } else {
        update["unitSizeFraction"] = value;
      }

      const overall = fractionToInt(width, value);

      if (overall > max.int) {
        handleError("widthErr", `${errMsgHigh} ${max.string}"`);
      } else if (overall <= max.int && overall >= 38.375) {
        handleError("widthErr", "");
        update = {
          ...update,
          jamb: "",
          addScreen: "",
          screenPocket: "",
          screenColor: "",
        };
        handleError("jambErr", "");
      } else if (overall < 38.375) {
        handleError("widthErr", `${errMsgLow} 38 3/8"`);
      }

      if (screenType === "Centor Flat Screen/Shade") {
        if (
          (screen === "Add Double Shade" && overall > 316.8125) ||
          (screen === "Add Shade" && overall > 159.8125) ||
          (screen === "Add Combo Screen/Shade" && overall > 161.8125) ||
          (screen === "Add Screen" && overall > 175.8125)
        ) {
          update = {
            ...update,
            addScreen: "",
            screenType: "",
            screenPocket: "",
            screenLayout: "",
            screenMeshColor: "",
            screenColor: "",
            jamb: "",
          };
        }
      }

      addToQuote(update);
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="unitSize"
      heading="Net Frame Width"
      secondaryHeading=" Select a Width"
      required={false}
      required5={true}
    >
      <ColLayout>
        <Typography style={{ paddingBottom: 20, marginTop: -20 }}>
          Note: We recommend Net Frame Width to be 1 inch less than the Rough
          Opening Width
        </Typography>

        <RowLayout>
          <SizeLayout
            size={width}
            handleChange={handleWidthChange}
            sizeErr={widthErr}
            name="width"
            fractionValue={unitSizeFraction}
            handleFractionChange={handleFractionChange}
          />
        </RowLayout>
      </ColLayout>
    </ReusableAccordian>
  );
};

export default UnitSizeAccordian;
