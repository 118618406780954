import React, { FC } from "react";
import Choices from "../Choices";

interface Option {
  id: number;
  type: string;
  label: string;
}

interface JambOptionsProp {
  options: Option[];
  handleClick: (label: string, type: string) => void;
  type: string;
  variant: (label: string) => 'filled' | 'outlined';
  checkMark: (label: string) => boolean;
}

const JambOptions: FC<JambOptionsProp> = ({
  options,
  handleClick,
  type,
  variant,
  checkMark,
}) => {
  return (
    <>
      {options.map((state) => (
        <div key={state.id} onClick={() => handleClick(state.label, type)}>
          <Choices
            label={state.label}
            variant={variant(state.label)}
            checkMark={checkMark(state.label)}
          />
        </div>
      ))}
    </>
  );
};

export default JambOptions;
