import React, { memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../../Choices";
import usePersistedState from "../../../hooks/usePersistedState";
import Grow from "@mui/material/Grow";
import SwingWidthAccordian from "../SwingWidthAccordian";
import SwingConfigs from "../SwingConfigs";
import EqualPanels from "../EqualPanels";
import ReusableAccordian from "../../ReusableAccordion";
import { useStore, useAccordion } from "../../../store/store";
import { pairSideLite, sideLite, swingDirection, swingType } from "./defaults";
import { RowLayout, ColLayout } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const RowLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
  width: "100%",
  justifyContent: "space-between",
}));

interface SwingDirectionProps {
  editQuote: boolean;
  submitPressed: boolean;
}

const SwingDirectionAccordian: FC<SwingDirectionProps> = ({
  editQuote,
  submitPressed,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const editBtn = useStore((state) => state.isEdit);

  const direction = useStore((state) => state.quote.swingDirection);
  const sType = useStore((state) => state.quote.swingType);
  const sLite = useStore((state) => state.quote.sideLite);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[7].expanded);

  const handleClickSDir = (label: string) => {
    const checked = label === direction;

    if (checked) return;

    addToQuote({
      swingDirection: label,
      swingType: "",
      sideLite: "none",
      sillStyle: label === "Outswing" ? "Outswing Sill" : "Inswing Sill",
      exactConfig: "",
      exactConfigImg: "",
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
      unitSize: "",
      unitSizeFraction: "",
      swingPanelWidth: "",
      swingPanelFraction: "",
      equalPanels: false,
    });

    handleErrMsgChange("sideliteWidthErr", "");
    handleErrMsgChange("jambErr", "");
  };

  const handleClickSType = (label: string) => {
    const checked = label === sType;

    if (checked) return;

    addToQuote({
      swingType: label,
      sideLite: "none",
      exactConfigImg: "",
      exactConfig: "",
    });

    handleErrMsgChange("widthErr", "");
    handleErrMsgChange("sideliteWidthErr", "");
  };

  const handleClickSLite = (label: string) => {
    const checked = label === sLite;

    if (checked) {
      addToQuote(
        {
          equalPanels: false,
          sideLite: "none",
          exactConfig: "",
        },
        "sideLite"
      );
    } else {
      addToQuote(
        {
          exactConfigImg: "",
          sideLite: label,
          exactConfig: "",
        },
        "sideLite"
      );
    }

    handleErrMsgChange("widthErr", "");
    handleErrMsgChange("sideliteWidthErr", "");
  };

  const [widthMeasureImg, setWidthMeasureImg] = usePersistedState(
    "widthMeasureImg",
    "",
    submitPressed,
    editQuote
  );

  return (
    <>
      <ReusableAccordian
        expanded={expanded}
        handleAccordClick={handleAccordClick}
        accordionName="swingDirection"
        heading="Configuration"
        secondaryHeading="Select Configuration"
        colOrRow="col"
        required={false}
        required5={true}
      >
        {direction === "Inswing" && (
          <>
            <Typography variant="caption">
              PLEASE NOTE: No warranty for water penetration, wind penetration,
              or airborne particles with this option
            </Typography>
          </>
        )}
        <RowLayout style={{ justifyContent: "flex-start", width: "100%" }}>
          <ColLayout style={{ width: "60%" }}>
            <RowLayout
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Typography style={{ flex: 1 }}>Swing Direction:</Typography>
              <RowLayout style={{ marginRight: 100 }}>
                {swingDirection.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickSDir(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === direction ? "filled" : "outlined"
                      }
                      checkMark={state.label === direction}
                    />
                  </div>
                ))}
              </RowLayout>
            </RowLayout>

            {direction && (
              <Grow in={true} timeout={500}>
                <RowLayoutTopDivider>
                  <Typography style={{ flex: 1 }}>Swing Type:</Typography>
                  <RowLayout style={{ marginRight: 60 }}>
                    {swingType.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleClickSType(state.label)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === sType ? "filled" : "outlined"
                          }
                          checkMark={state.label === sType}
                        />
                      </div>
                    ))}
                  </RowLayout>
                </RowLayoutTopDivider>
              </Grow>
            )}

            {direction && sType ? (
              <Grow in={true} timeout={500}>
                <RowLayoutTopDivider>
                  <Typography style={{ flex: 1 }}>Sidelite:</Typography>

                  <RowLayout
                    style={{ paddingRight: sType === "Pair Swing" ? 100 : 0 }}
                  >
                    {sType === "Single Swing"
                      ? sideLite.map((state) => (
                          <div
                            key={state.id}
                            onClick={() => handleClickSLite(state.label)}
                          >
                            <Choices
                              label={state.label}
                              variant={
                                state.label === sLite ? "filled" : "outlined"
                              }
                              checkMark={state.label === sLite}
                            />
                          </div>
                        ))
                      : pairSideLite.map((state) => (
                          <div
                            key={state.id}
                            onClick={() => handleClickSLite(state.label)}
                          >
                            <Choices
                              label={state.label}
                              variant={
                                state.label === sLite ? "filled" : "outlined"
                              }
                              checkMark={state.label === sLite}
                            />
                          </div>
                        ))}
                  </RowLayout>
                </RowLayoutTopDivider>
              </Grow>
            ) : null}

            {direction && sType && sLite !== "none" ? (
              <Grow in={true} timeout={500}>
                <RowLayout style={{ width: "100%" }}>
                  <EqualPanels />
                </RowLayout>
              </Grow>
            ) : null}
          </ColLayout>

          {(sType || direction) && (
            <ColLayout>
              <RowLayoutTopDivider style={{ borderTop: "none" }}>
                <SwingConfigs
                  setWidthMeasureImg={setWidthMeasureImg}
                  sType={sType || ""}
                  sLite={sLite || ""}
                  swingDir={direction || ""}
                />
              </RowLayoutTopDivider>
            </ColLayout>
          )}
        </RowLayout>
      </ReusableAccordian>

      <SwingWidthAccordian
        widthMeasureImg={widthMeasureImg}
        editBtn={editBtn}
        sideLites={sLite || ""}
        swingType={sType || ""}
        direction={direction || ""}
      />
    </>
  );
};

export default memo(SwingDirectionAccordian);
