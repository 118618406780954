const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/CutSheets";

function findLabel(config: string) {
  let label;

  switch (config) {
    case "Fixed Bi-fold":
      label = "bifold";
      break;
    case "Fixed Slider":
      label = "slider";
      break;
    case "Fixed Inswing":
      label = "inswing";
      break;
    case "Fixed Outswing":
      label = "outswing";
      break;
    case "Fixed Full Bound":
      label = "slider";
      break;
    default:
      label = "";
  }

  return label;
}

function headJambImagesFixed(doorMaterial: string, config: string) {
  const material = doorMaterial.includes("Clad") ? "" : "wood_";

  let label = findLabel(config);
  const cloudinaryID = `${material}${label}`.trim();
  return `${CLOUDINARY_URL}/headJamb/Fixed_Panel/${cloudinaryID}.jpg`;
}

function sillImagesFixed(config: string, sill: string) {
  const label = findLabel(config);

  const sillLabel =
    sill === "Rounded Dam"
      ? "_roundSill"
      : sill === "Squared Dam"
      ? "_squareSill"
      : "";

  const cloudinaryID = `${label}${sillLabel}`.trim();

  return `${CLOUDINARY_URL}/sill/Fixed_Panel/${cloudinaryID}.jpg`;
}

function jambLegImagesFixed(doorMaterial: string, config: string) {
  const material = doorMaterial.includes("Clad") ? "" : "wood_";

  let label = findLabel(config);
  const cloudinaryID = `${material}${label}`.trim();

  return `${CLOUDINARY_URL}/jamb/Fixed_Panel/${cloudinaryID}.jpg`;
}

export { headJambImagesFixed, sillImagesFixed, jambLegImagesFixed };
