import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "./Styles";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import noAccess from "../../util/noAccess";
import fractionToInt from "../../util/fractionToInt";
import { UnitObj } from "../../util/quoteCartStorage";

const refKeyLetters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
];

const windowShowG = ["3L OR 3R", "5L OR 5R"];

function getNvaluePSlider(
  screen: string,
  pocket: string,
  nfwp: string,
  frameWidth: string
): number {
  if (!screen || !pocket) return 0;

  let nfwpInt = fractionToIntPDF(nfwp);
  let width = fractionToIntPDF(
    `${frameWidth.split(" ")[0]} ${frameWidth.split(" ")[1] || ""}`
  );

  return nfwpInt - width;
}

export function getNvalue(
  screen: string,
  pocket: string,
  nfwp: string,
  unitSize: string,
  unitSizeFraction: string
): number {
  if (!screen || !pocket) return 0;

  const nfwpInt = fractionToIntPDF(nfwp);
  const width = fractionToIntPDF(`${unitSize} ${unitSizeFraction}`);

  if (!pocket.includes("Double")) {
    return nfwpInt - width;
  }

  //double pocket
  return (nfwpInt - width) / 2;
}

export function getKvalue(
  finConfig: string,
  jamb: string,
  finSetback: string
): number {
  const jambInt = fractionToIntPDF(jamb);

  if (finConfig !== "Custom Setback") {
    return jambInt - 1 - 0.0625;
  }
  //with custom setback
  return jambInt - fractionToIntPDF(finSetback) - 0.0625;
}

function getOvalue(sliderPocketSize: string): string {
  const sps = parseInt(sliderPocketSize.split(" ")[0]);

  if (sliderPocketSize.includes("1/2")) {
    return String(sps - 2);
  }
  return `${sps - 3} 1/2`;
}

export function getPivotSillWidth(jamb: string): number {
  const jambWidth = fractionToIntPDF(jamb);

  if (jambWidth <= 5.5) {
    return 6;
  }

  if (jambWidth <= 6.5) {
    return 7;
  }

  if (jambWidth <= 7.5) {
    return 8;
  }

  if (jambWidth <= 8.5) {
    return 9;
  }

  return 10;
}

interface RefKeyProps {
  item: UnitObj;
}

const RefKey: FC<RefKeyProps> = ({ item }) => {
  const jamb = item.jamb
    ? item.jamb
        .replace("Standard", "")
        .replace('" Overall Jamb Width', "")
        .replace("-", " ")
        .trim()
    : "";

  const N =
    item.doorType === "Slider" &&
    item.sliderPocket === "With Pocket" &&
    !item.exactConfig.includes("-") &&
    item.addScreen?.includes("Double") &&
    item.screenPocket?.includes("Double")
      ? getNvaluePSlider(
          item.addScreen,
          item.screenPocket,
          item.netFrameWidthWithPocket || "",
          item.pocketingSliderNetWidth || ""
        )
      : getNvalue(
          item.addScreen || "",
          item.screenPocket || "",
          item.netFrameWidthWithPocket || "",
          item.unitSize,
          item.unitSizeFraction || ""
        );

  const K =
    !item.flashingFin || item.sliderPocket === "With Pocket"
      ? null
      : getKvalue(item.flashingFinConfig || "", jamb, item.finSetback || "");

  const O =
    item.doorType === "Slider" &&
    item.addScreen &&
    item.screenPocket &&
    item.sliderPocket === "With Pocket" &&
    item.sliderPocketSize
      ? getOvalue(item.sliderPocketSize)
      : "";

  const P =
    item.doorType === "Slider" &&
    item.addScreen?.includes("Double Pocket") &&
    item.sliderPocket === "With Pocket" &&
    !item.exactConfig.includes("-");

  return (
    <View style={[styles.column, { alignItems: "center" }]}>
      <Text style={[styles.textBold, { fontSize: 12 }]}>Reference Key</Text>

      <View style={[styles.row, { border: "1px solid black" }]}>
        <View style={[styles.column, { borderRight: "1px solid black" }]}>
          {refKeyLetters.map((letter) => (
            <Text
              key={letter}
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              {letter}
            </Text>
          ))}

          {item.doorType === "Swing" ||
          item.doorType === "Pivot" ||
          item.doorType === "Slider" ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              M
            </Text>
          ) : (
            <Text></Text>
          )}

          {item.doorType === "Pivot" ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              N
            </Text>
          ) : (
            <Text />
          )}

          {item.doorType === "Slider" &&
          (item.sliderPocket === "With Pocket" ||
            (item.exactConfig.includes("Primary") && item.addScreen) ||
            (item.addScreen && item.screenPocket)) ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              N
            </Text>
          ) : (
            <Text></Text>
          )}

          {item.doorType === "Slider" && item.addScreen && item.screenPocket ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              O
            </Text>
          ) : (
            <Text></Text>
          )}

          {P ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              P
            </Text>
          ) : (
            <Text></Text>
          )}

          {item.addScreen &&
          item.screenPocket &&
          item.doorType.includes("Bi-fold") ? (
            <>
              <Text
                style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
              >
                M
              </Text>
              <Text
                style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
              >
                N
              </Text>
            </>
          ) : item.addScreen &&
            item.screenPocket &&
            item.doorType === "Swing" ? (
            <>
              <Text
                style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
              >
                N
              </Text>
              <Text
                style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
              >
                O
              </Text>
            </>
          ) : (
            <Text></Text>
          )}

          {item.kerf ? (
            <Text
              style={[styles.refKeyText, { borderBottom: "1px solid black" }]}
            >
              KF
            </Text>
          ) : (
            <Text />
          )}
        </View>
        <View style={[styles.column, { alignItems: "center" }]}>
          {item.doorType !== "Slider" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.unitSize} {item.unitSizeFraction || ""}" Net Frame Width
              </Text>
            </View>
          ) : item.sliderPocket === "With Pocket" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.pocketingSliderNetWidth}" Net Frame Width
              </Text>
            </View>
          ) : (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.unitSize} {item.unitSizeFraction || ""}" Net Frame Width
              </Text>
            </View>
          )}

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.height} {item.heightFraction || ""}" Net Frame Height
            </Text>
          </View>

          {item.doorType === "Slider" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.sliderPocket === "With Pocket"
                  ? `${item.unitSize} ${
                      item.unitSizeFraction || ""
                    }" Daylight Frame Size`
                  : "N/A"}
              </Text>
            </View>
          ) : (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.panelHeight}"{" "}
                {item.doorType === "Bi-fold Window" ? "Sash" : "Panel"} Height
              </Text>
            </View>
          )}

          {item.doorType === "Slider" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {item.panelHeight}" Panel Height
              </Text>
            </View>
          ) : (
            <Text></Text>
          )}

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>
              {item.glassHeight}" DLO Height
            </Text>
          </View>

          <View style={styles.refKeyCol}>
            {item.doorType !== "Swing" && item.doorType !== "Pivot" ? (
              <Text style={styles.refKeyText}>
                {item.panelWidth}"{" "}
                {item.doorType === "Bi-fold Window" ? "Sash" : "Panel"} Width
              </Text>
            ) : (
              <Text style={styles.refKeyText}>
                {item.swingPanelWidth} {item.swingPanelFraction}" Panel Width
              </Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>{item.glassWidth}" DLO Width</Text>
          </View>

          <View style={styles.refKeyCol}>
            {item.doorType === "Bi-fold Window" &&
            item.configuration &&
            !windowShowG.includes(item.configuration) ? (
              <Text style={styles.refKeyText}>N/A</Text>
            ) : (
              <>
                {item.doorType !== "Swing" &&
                item.doorType !== "Pivot" &&
                noAccess.includes(item.exactConfig) ? (
                  <Text style={styles.refKeyText}>N/A</Text>
                ) : item.doorType !== "Swing" &&
                  item.doorType !== "Pivot" &&
                  item.glassWidthDD ? (
                  <Text style={styles.refKeyText}>
                    {item.glassWidthDD}"{" "}
                    {item.doorType === "Bi-fold Window"
                      ? "DS DLO Width"
                      : "DD DLO Width"}
                  </Text>
                ) : item.doorType !== "Swing" &&
                  item.doorType !== "Pivot" &&
                  !item.glassWidthDD ? (
                  <Text style={styles.refKeyText}>
                    {item.glassWidth}"{" "}
                    {item.doorType === "Bi-fold Window"
                      ? "DS DLO Width"
                      : "DD DLO Width"}
                  </Text>
                ) : (
                  <Text></Text>
                )}
                {(item.doorType === "Swing" || item.doorType === "Pivot") &&
                item.sideLite !== "none" &&
                item.sideLiteWidth ? (
                  <Text style={styles.refKeyText}>
                    {item.sideLiteWidth}" Sidelite Width
                  </Text>
                ) : (item.doorType === "Swing" || item.doorType === "Pivot") &&
                  item.sideLite === "none" ? (
                  <Text style={styles.refKeyText}>N/A</Text>
                ) : (
                  <Text></Text>
                )}
              </>
            )}
          </View>

          {item.doorType === "Swing" || item.doorType === "Pivot" ? (
            <View style={styles.refKeyCol}>
              {item.sideLite !== "none" ? (
                <Text style={styles.refKeyText}>
                  {item.sideLiteDLO}" Sidelite DLO Width
                </Text>
              ) : (
                <Text style={styles.refKeyText}>N/A</Text>
              )}
            </View>
          ) : (
            <Text></Text>
          )}

          <View style={styles.refKeyCol}>
            {item.doorType === "Bi-fold Window" ? (
              <Text style={styles.refKeyText}>12" Handle Height</Text>
            ) : item.handleHeight && item.doorType === "Pivot" ? (
              <Text style={styles.refKeyText}>
                {item.handleHeight} Thumb Turn Height
              </Text>
            ) : item.handleHeight ? (
              <Text style={styles.refKeyText}>
                {item.handleHeight} Handle Height
              </Text>
            ) : item.doorType === "Pivot" ? (
              <Text style={styles.refKeyText}>36" Thumb Turn Height</Text>
            ) : (
              <Text style={styles.refKeyText}>36" Handle Height</Text>
            )}
          </View>

          {item.doorType === "Pivot" &&
          item.pivotPoint === "Middle Pivot Point" ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {convertDecimalToFraction(
                  fractionToInt(
                    item.swingPanelWidth || "",
                    item.swingPanelFraction || ""
                  ) / 2
                )}
                Pivot Axis Point
              </Text>
            </View>
          ) : item.doorType === "Pivot" && item.pivotPoint ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>{item.pivotPoint}</Text>
            </View>
          ) : (
            <Text />
          )}

          <View style={styles.refKeyCol}>
            <Text style={styles.refKeyText}>{jamb}" Overall Jamb Width</Text>
          </View>

          <View style={styles.refKeyCol}>
            {item.sillStyle === "Ultra Guide" ||
            item.sillStyle === "ADA Ultra Guide" ||
            item.doorType === "Slider" ? (
              <Text style={styles.refKeyText}>N/A</Text>
            ) : item.doorType === "Pivot" ? (
              <Text style={styles.refKeyText}>
                {getPivotSillWidth(jamb)}" Sill Width
              </Text>
            ) : (
              <Text style={styles.refKeyText}>{jamb}" Sill Width</Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            {K ? (
              <Text style={styles.refKeyText}>
                {convertDecimalToFraction(K)}" Behind Flashing Fin
              </Text>
            ) : (
              <Text style={styles.refKeyText}>N/A (No Flashing Fin)</Text>
            )}
          </View>

          <View style={styles.refKeyCol}>
            {item.flashingFin &&
            item.flashingFinConfig !== "Custom Setback" &&
            item.sliderPocket !== "With Pocket" ? (
              <Text style={styles.refKeyText}>
                {item.flashingFinConfig?.replace("Standard", "").includes("7/8")
                  ? '1" Setback'
                  : item.flashingFinConfig?.replace("Standard", "")}
              </Text>
            ) : item.flashingFin &&
              item.flashingFinConfig === "Custom Setback" &&
              item.sliderPocket !== "With Pocket" ? (
              <Text style={styles.refKeyText}>{item.finSetback}" Setback</Text>
            ) : (
              <Text style={styles.refKeyText}>N/A (No Flashing Fin)</Text>
            )}
          </View>

          {item.doorType === "Slider" && item.sliderPocket === "With Pocket" ? (
            <>
              {item.addScreen && item.screenPocket ? (
                <>
                  <View style={styles.refKeyCol}>
                    <Text style={styles.refKeyText}>
                      {item.sliderPocketSize}" Exterior Pocket
                    </Text>
                  </View>
                  {O ? (
                    <View style={styles.refKeyCol}>
                      <Text style={styles.refKeyText}>
                        {O}" Interior Pocket
                      </Text>
                    </View>
                  ) : (
                    <Text></Text>
                  )}
                </>
              ) : (
                <View style={styles.refKeyCol}>
                  <Text style={styles.refKeyText}>
                    {item.sliderPocketSize}" Per Pocket Width
                  </Text>
                </View>
              )}
            </>
          ) : (
            <Text></Text>
          )}

          {item.addScreen &&
          item.screenPocket &&
          item.sliderPocket !== "With Pocket" ? (
            <>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {item.netFrameWidthWithPocket}" Width w/Pocket
                </Text>
              </View>
              <View style={styles.refKeyCol}>
                <Text style={styles.refKeyText}>
                  {convertDecimalToFraction(N)}" Screen Pocket
                </Text>
              </View>
            </>
          ) : (
            <Text></Text>
          )}

          {P ? (
            <View style={styles.refKeyCol}>
              <Text style={styles.refKeyText}>
                {convertDecimalToFraction(N)}" Screen Pocket
              </Text>
            </View>
          ) : (
            <Text></Text>
          )}

          {item.kerf ? (
            <View style={styles.refKeyCol}>
              {item.kerfConfig !== "Custom Setback" ? (
                <Text style={styles.refKeyText}>
                  {item.kerfConfig?.replace("Standard", "")}
                </Text>
              ) : item.kerfConfig === "Custom Setback" ? (
                <Text style={styles.refKeyText}>
                  {item.kerfSetback}" Setback
                </Text>
              ) : (
                <Text style={styles.refKeyText}>N/A (No Kerf)</Text>
              )}
            </View>
          ) : (
            <Text />
          )}
        </View>
      </View>
    </View>
  );
};

function fractionToIntPDF(fraction: string) {
  const onlyFraction = fraction.split(" ")[0].includes("/");

  if (!onlyFraction) {
    const fractionAsDecimal = fraction.split(" ")[1]
      ? parseInt(fraction.split(" ")[1].split("/")[0]) /
        parseInt(fraction.split(" ")[1].split("/")[1])
      : 0;
    const integer = parseInt(fraction.split(" ")[0]) + fractionAsDecimal;

    return integer;
  } else {
    const fractionAsDecimal =
      parseInt(fraction.split("/")[0]) / parseInt(fraction.split("/")[1]);

    return fractionAsDecimal;
  }
}

export default RefKey;
