import React from "react";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  height: "100%",
  width: "100%",
  overflow: "auto",
});

const StyledIFrame = styled("iframe")({
  width: "99%",
  height: "99%",
  position: "absolute",
});

const EsignIframe = () => {
  const esignURL = JSON.parse(sessionStorage.getItem("esignURL") || JSON.stringify(""));

  return (
    <>
      {esignURL ? (
        <StyledDiv data-role="content">
          <StyledIFrame
            title="Dealer Cost PDF"
            id="esignIframe"
            src={esignURL}
            frameBorder="0"
          ></StyledIFrame>
        </StyledDiv>
      ) : (
        <p>No page found</p>
      )}
    </>
  );
};

export default EsignIframe;
