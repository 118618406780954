import React, { FC } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../Styles";
import litesCloudinaryImg from "../../../Drawings/LitesLayout";
import { UnitObj } from "../../../util/quoteCartStorage";

interface JambLegProps {
  item: UnitObj
}

const LiteCorner: FC<JambLegProps> = ({ item }) => {
  const liteImg = litesCloudinaryImg(item.dlLayout || "");

  const withTDL = item.liteConfig === '1 3/4"';

  const isCottageStyle =
    ["2W1H", "3W1H", "4W1H", "3 Lite Marginal", "6 Lite Marginal"].includes(
      item.dlLayout || ""
    ) && item.daylightOpening
      ? true
      : false;

  return (
    <View style={styles.liteCorner}>
      {withTDL ? (
        <Text style={[styles.textBold, { fontSize: 11 }]}>
          TDL Configuration:
        </Text>
      ) : (
        <Text style={[styles.textBold, { fontSize: 11 }]}>
          SDL Configuration:
        </Text>
      )}

      <View style={[styles.row, { alignItems: "center" }]}>
        <Image src={liteImg} style={{ height: 130, width: 60 }} />

        <View style={[styles.column, { alignItems: "center" }]}>
          <Text style={styles.text}>All Panels will be:</Text>
          <Text style={styles.text}>{item.dlLayout}</Text>
          
          {isCottageStyle ? (
            <Text style={styles.text}>(DLO {item.daylightOpening}")</Text>
          ) : (
            <Text />
          )}
        </View>
      </View>
    </View>
  );
};

export default LiteCorner;
