import React, { useState, useEffect, FC } from "react";
import {
  Switch,
  FormControl,
  FormControlLabel,
  Modal,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import getModalStyle from "../../../util/getModalStyle";
import { useQueryClient } from "react-query";
import round from "../../../util/round";
import { postAdminCharge } from "../../../api/OrderSummaryApi";
import {
  StyledModalDiv,
  RowLayout,
  ColLayout,
} from "../../../util/accordianStyles";

interface AdminCostModalProps {
  open: boolean;
  handleClose: () => void;
  charge: string;
  withMargin: boolean;
  chargeLabel: string;
  quoteId: string;
  userId: string;
}

const AdminCostModal: FC<AdminCostModalProps> = ({
  open,
  handleClose,
  charge,
  withMargin,
  chargeLabel,
  quoteId,
  userId,
}) => {
  const queryClient = useQueryClient();

  const [modalStyle] = useState(() => getModalStyle(50, 50));

  useEffect(() => {
    if (!charge && !chargeLabel) return;

    setCost({ charge, label: chargeLabel });
    setChecked(withMargin);
  }, []);

  const [cost, setCost] = useState({ charge: "", label: "" });

  const [err, setErr] = useState("");

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCost({ ...cost, [name]: value });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(event.target.checked);

  const addAdminCost = async () => {
    if ((!cost.charge && cost.label) || (!cost.label && cost.charge)) {
      setErr(
        "Please Fill Out Both Fields Or Leave Both Empty to Remove the Charge"
      );
      return;
    }

    setErr("");
    setLoading(true);

    const response = await postAdminCharge(
      cost.charge ? round(parseFloat(cost.charge)) : 0,
      cost.label,
      checked,
      quoteId,
      userId
    );

    if (response.data === "Admin Charge Added") {
      queryClient.invalidateQueries("Cart");
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setErr("Error: A Problem Occurred when Adding the Charge");
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle}>
      <ColLayout>
        <h2>Add a Charge</h2>

        <RowLayout style={{ paddingBottom: 20 }}>
          <input
            name="label"
            placeholder="Type of Charge"
            autoComplete="off"
            value={cost.label}
            onChange={handleCostChange}
            style={{ padding: 5, width: 150, marginRight: 20 }}
          />

          <input
            step="any"
            type="number"
            name="charge"
            autoComplete="off"
            placeholder="Amount of Charge"
            value={cost.charge}
            onChange={handleCostChange}
            style={{ padding: 5, width: 150 }}
          />
        </RowLayout>

        <FormControl>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={handleChange}
              />
            }
            label={
              checked
                ? "Include In Customer Price"
                : "Only Include in Dealer Price"
            }
          />
        </FormControl>

        {err && <Typography style={{ color: "red" }}>{err}</Typography>}

        <RowLayout style={{ paddingTop: 20 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginRight: 10 }}
            color="inherit"
          >
            Cancel
          </Button>

          {!loading ? (
            <Button
              onClick={addAdminCost}
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              Add
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              <CircularProgress style={{ color: "white" }} />
            </Button>
          )}
        </RowLayout>
      </ColLayout>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default AdminCostModal;
