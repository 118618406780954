import React, { useEffect, FC } from "react";
import { Typography, TextField } from "@mui/material";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import { useStore } from "../../store/store";
import NumberFormatCustom from "../NumberFormatCustom";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledForm = styled("form")(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(1),
    width: "25ch",
  },
}));

interface SwingPanelInputProps {
  overall: number
  panelWidth: string
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, option: string) => void
  inputTitle: string  
  option: string
  isPivot?: boolean
}

interface SwingWidthInputProps {
  overall: number
  width: string
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, option: string) => void
  inputTitle: string
  minWidth: number
  maxWidth: number
  option: string
  equalPanels: boolean
}

export const SwingWidthInput: FC<SwingWidthInputProps> = ({
  overall,
  width,
  handleChange,
  inputTitle,
  minWidth,
  maxWidth,
  option,
  equalPanels,
}) => {
  const handleErrMsgChange = useStore((state) => state.handleError);
  const widthErr = useStore((state) => state.widthErr);

  useEffect(() => {
    if (!overall) return;
    overall < minWidth || overall > maxWidth
      ? handleErrMsgChange(
          "widthErr",
          `Between ${convertDecimalToFraction(
            minWidth
          )}" and ${convertDecimalToFraction(maxWidth)}"`
        )
      : handleErrMsgChange("widthErr", "");
  }, [overall, minWidth, maxWidth]);

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
      }}
      noValidate
      autoComplete="off"
    >
      <RowLayout
        style={{
          width: "100%",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Typography style={{ fontWeight: "bold", paddingRight: 20 }}>
          Net Frame Width{!equalPanels ? "(X)" : ""}:
        </Typography>

        <ColLayout>
          <TextField
            required
            style={{ backgroundColor: "white" }}
            variant="outlined"
            label={`Enter ${inputTitle}`}
            value={width}
            onChange={(event) => handleChange(event, option)}
            name="width"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          {widthErr ? (
            <Typography style={{ color: "red" }} variant="caption">
              {widthErr}
            </Typography>
          ) : (
            <Typography variant="caption">
              {convertDecimalToFraction(minWidth)}" to{" "}
              {convertDecimalToFraction(maxWidth)}"
            </Typography>
          )}
        </ColLayout>
      </RowLayout>
    </StyledForm>
  );
};


export const SwingPanelInput: FC<SwingPanelInputProps> = ({
  overall,
  panelWidth,
  handleChange,
  inputTitle,
  option,
  isPivot,
}) => {
  const handleErrMsgChange = useStore((state) => state.handleError);
  const swingWidthErr = useStore((state) => state.swingWidthErr);

  const equalPanels = useStore((state) => state.quote.equalPanels);

  useEffect(() => {
    if (!overall) return;
    if (isPivot && !equalPanels) {
      overall < 28 || overall > 96
        ? handleErrMsgChange("swingWidthErr", `Between 28" and 96"`)
        : handleErrMsgChange("swingWidthErr", "");
    } else {
      const min = isPivot ? 28 : 24;
      overall < min || overall > 42
        ? handleErrMsgChange("swingWidthErr", `Between ${min}" and 42"`)
        : handleErrMsgChange("swingWidthErr", "");
    }
  }, [overall]);

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
      }}
      noValidate
      autoComplete="off"
    >
      <RowLayout
        style={{
          width: "100%",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Typography style={{ fontWeight: "bold", paddingRight: 20 }}>
          Per Panel Width(Y):
        </Typography>
        <ColLayout>
          <TextField
            required
            style={{ backgroundColor: "white" }}
            variant="outlined"
            label={`Enter ${inputTitle}`}
            value={panelWidth}
            onChange={(event) => handleChange(event, option)}
            name="panelWidth"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          {swingWidthErr ? (
            <Typography style={{ color: "red" }} variant="caption">
              {swingWidthErr}
            </Typography>
          ) : isPivot ? (
            <Typography variant="caption">28" to 96"</Typography>
          ) : (
            <Typography variant="caption">24" to 42"</Typography>
          )}
        </ColLayout>
      </RowLayout>
    </StyledForm>
  );
};
