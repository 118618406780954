import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getModalStyle from "../../util/getModalStyle";
import { StyledModalDiv } from "../../util/accordianStyles";

interface DeleteErrorModalProps {
  open: boolean
  handleClose: () => void
}

const DeleteErrorModal: FC<DeleteErrorModalProps> = ({
  open,
  handleClose,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3 style={{ color: "red", fontWeight: "bold" }}>
        A Problem Occurred with Deleting Units
      </h3>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained">
          Ok
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
}

export default DeleteErrorModal;
