import React, { useEffect, memo, useRef, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../../Choices";
import ReusableAccordian from "../../ReusableAccordion";
import SillFinish from "../../SillFinish";
import { useStore, useAccordion } from "../../../store/store";
import { useRequiredMsgs } from "../../../store/requiredMsgs";
import { Image, Transformation } from "cloudinary-react";
import {
  sliderSills,
  slidingScrSills,
  sillColor,
  group1SS,
  group1Sill,
  group2SS,
  group2Sill,
  group3SS,
  group3Sill,
  group4SS,
  group4Sill,
  group5SS,
  group5Sill,
  group6Sill,
  group7Sill,
  group8Sill,
} from "./defaults";
import { RowLayout, ColLayout } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

interface SliderSillProps {
  editQuote: boolean
  submitPressed: boolean
}

interface UpdateObj {
  sillFinish?: string
  sillStyle?: string
}

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  paddingBottom: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const SliderSillAccordian: FC<SliderSillProps> = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const editBtn = useStore((state) => state.isEdit);

  const chosenConfig = useStore((state) => state.quote.exactConfig);
  const sliderPocket = useStore((state) => state.quote.sliderPocket);

  const sillStyleChoice = useStore((state) => state.quote.sillStyle);
  const sillColorChoice = useStore((state) => state.quote.sillColor);

  const paintPrimeChoice = useStore((state) => state.quote.paintAndPrime);
  const primeChoice = useStore((state) => state.quote.prime);
  const stainChoice = useStore((state) => state.quote.stain);
  const customPaintChoice = useStore((state) => state.quote.customPaintOrStain);

  const screenVal = useStore((state) => state.quote.addScreen);
  const screenType = useStore((state) => state.quote.screenType);
  const addSlidingScreen = useStore((state) => state.quote.addSlidingScreen);

  const slidingScrType = screenType && screenType === "Sliding Screen";
  const isCentorScr =
    screenVal && screenType?.includes("Centor") ? true : false;

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[13].expanded);

  const withSlidingScreen =
    ["OX", "XO", "OA-XO", "OX-AO"].includes(chosenConfig) &&
    slidingScrType &&
    addSlidingScreen
      ? true
      : false;

  const showSillFinish =
    paintPrimeChoice || primeChoice || stainChoice || customPaintChoice
      ? true
      : false;

  const editRef = useRef(true);
  useEffect(() => {
    if (editBtn) return;
    editRef.current = true;
  }, [editBtn]);

  useEffect(() => {
    if (editBtn && editRef.current) {
      editRef.current = false;
    } else {
      if (!withSlidingScreen) {
        addToQuote({ sillStyle: "" });
      } else {
        addToQuote({ sillStyle: "Raised SILLution" });
      }
    }
  }, [withSlidingScreen]);

  const findSliderSillImg = () => {
    const nonpocket = "Sills/sliders/nonpocketing";
    const pocket = "Sills/sliders/pocketing";

    const scrLbl = isCentorScr ? "_CENTOR" : screenVal ? "_SCR" : "";

    if (group1Sill.includes(chosenConfig)) {
      const oxscrLbl = isCentorScr
        ? "_CENTOR"
        : withSlidingScreen && screenVal
        ? "_SLIDING_SCR"
        : screenVal
        ? "_SCR"
        : "";
      return `${nonpocket}/OX${oxscrLbl}`;
    } else if (group2Sill.includes(chosenConfig)) {
      return `${nonpocket}/OXX${scrLbl}`;
    } else if (group3Sill.includes(chosenConfig)) {
      return `${nonpocket}/OXXX${scrLbl}`;
    } else if (group4Sill.includes(chosenConfig)) {
      return `${nonpocket}/OXXXX${scrLbl}`;
    } else if (group5Sill.includes(chosenConfig)) {
      return `${pocket}/X${scrLbl}`;
    } else if (group6Sill.includes(chosenConfig)) {
      return `${pocket}/XX${scrLbl}`;
    } else if (group7Sill.includes(chosenConfig)) {
      return `${pocket}/XXX${scrLbl}`;
    } else if (group8Sill.includes(chosenConfig)) {
      return `${pocket}/XXXX${scrLbl}`;
    }
  };

  const findSliderSillSSImg = () => {
    const stainlessSteel = "Sills/sliders/StainlessSteel";

    const scrLbl = isCentorScr ? "_CENTOR" : screenVal ? "_SCR" : "";

    if (group1SS.includes(chosenConfig)) {
      return `${stainlessSteel}/X${scrLbl}`;
    } else if (group2SS.includes(chosenConfig)) {
      return `${stainlessSteel}/XX${scrLbl}`;
    } else if (group3SS.includes(chosenConfig)) {
      return `${stainlessSteel}/XXX${scrLbl}`;
    } else if (group4SS.includes(chosenConfig)) {
      return `${stainlessSteel}/XXXX${scrLbl}`;
    } else if (group5SS.includes(chosenConfig)) {
      return `${stainlessSteel}/XXXXX${scrLbl}`;
    }
  };

  const sillImageShown =
    !sillStyleChoice || !chosenConfig
      ? ""
      : withSlidingScreen
      ? "Sills/sliders/nonpocketing/OX_SLIDING_SCR"
      : sillStyleChoice === "Raised SILLution"
      ? findSliderSillImg()
      : findSliderSillSSImg();

  const handleClickSill = (label: string, disabled?: boolean) => {
    if(disabled) return;

    const checked = label === sillStyleChoice;

    if (checked) {
      addToQuote({ sillStyle: "" });
    } else {
      let update: UpdateObj = {}
      
      if (label.includes("Steel")) update["sillFinish"] = "";

      if (findRequired("Sill Type") || findRequired("Sill Finish"))
        removeRequired("Sill Type");
      update["sillStyle"] = label;
      addToQuote(update);
    }
  };

  const handleClickColor = (label: string) => {
    const checked = label === sillColorChoice;

    if (checked) {
      addToQuote({ sillColor: "" });
    } else {
      if (findRequired("Sill Type") || findRequired("Sill Finish"))
        removeRequired("Sill Finish");
      addToQuote({ sillColor: label });
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sill"
      heading="Sill Choice"
      secondaryHeading="Select sill choice"
      colOrRow="col"
    >
      {sillStyleChoice === "Stainless Steel Recessed *" && (
        <Typography variant="caption">* Upcharge Applies</Typography>
      )}
      <RowLayout style={{ alignItems: "flex-start" }}>
        <ColLayoutSpacing>
          <Typography style={{ margin: "auto" }} variant="subtitle1">
            Sill Types <span style={{ color: "red", fontSize: 22 }}>*</span>
          </Typography>
          <RowLayout>
            {!withSlidingScreen
              ? sliderSills.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickSill(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === sillStyleChoice ? "filled" : "outlined"
                      }
                      checkMark={state.label === sillStyleChoice}
                      disabled={false}
                    />
                  </div>
                ))
              : slidingScrSills.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickSill(state.label, true)}
                  >
                    <Choices
                      label={state.label}
                      variant="filled"
                      checkMark={true}
                      disabled={true}
                    />
                  </div>
                ))}
          </RowLayout>
        </ColLayoutSpacing>
        {sillStyleChoice !== "Stainless Steel Recessed *" && (
          <>
            <ColLayoutLeftDivider>
              <Typography style={{ margin: "auto" }} variant="subtitle1">
                Sill Colors{" "}
                <span style={{ color: "red", fontSize: 22 }}>*</span>
              </Typography>
              <RowLayout>
                {sillColor.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickColor(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === sillColorChoice ? "filled" : "outlined"
                      }
                      checkMark={state.label === sillColorChoice}
                      disabled={false}
                    />
                  </div>
                ))}
              </RowLayout>
            </ColLayoutLeftDivider>

            {showSillFinish && <SillFinish />}
          </>
        )}
      </RowLayout>

      {sliderPocket === "With Pocket" && sillImageShown ? (
        <RowLayout style={{ justifyContent: "space-around", width: "70%" }}>
          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId={sillImageShown}
          />

          <ColLayout>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId="Sills/sliders/pocketing/SILL_PAN_2"
            >
              <Transformation width="450" height="250" crop="scale" />
            </Image>

            <Typography style={{ paddingTop: 10 }} variant="subtitle2">
              * Need to have 3 sided sill pan with welded corners for the
              pocket. (BY OTHERS)
            </Typography>
          </ColLayout>
        </RowLayout>
      ) : sillImageShown ? (
        <Image
          cloudName="ag-millworks"
          secure="true"
          publicId={sillImageShown}
        />
      ) : null}
    </ReusableAccordian>
  );
}

export default memo(SliderSillAccordian);
