import api from "./api";
import { UserObj, SalesReportObj } from "../GlobalTypes";
import { PaintLeadTimesObj } from "../components/dealerZone/LeadTimes";

async function searchAllQuotes(
  searchTerm: string,
  status: string,
  searchBy: string,
  pageNumber: number,
  rowsPerPage: number,
  showArchive?: boolean
) {
  return api.get("/api/searchAll", {
    params: {
      searchTerm,
      status,
      searchBy,
      pageNumber,
      rowsPerPage,
      showArchive,
    },
  });
}

async function getAllQuotes(
  pageNumber: number,
  rowsPerPage: number,
  status: string,
  showArchive?: boolean
) {
  return await api.get("/api/getAllQuotes", {
    params: {
      pageNumber,
      rowsPerPage,
      status,
      showArchive,
    },
  });
}

async function postHideTips() {
  return await api.put("/api/hideTips");
}

async function getLogo() {
  return await api.get("/api/getDealerLogo");
}

async function postStatusChange(
  showTab: string,
  selected: string[],
  newStatus: string
) {
  return await api.put("/api/changeStatus", {
    showTab,
    selected,
    newStatus,
    dateOrdered: new Date(),
  });
}

async function getUser(id: string) {
  return await api.get("/userInfo", {
    params: { id },
  });
}

async function postCloneQuote(quoteNumber: number, userId: string) {
  return await api.post("/api/cloneQuote", {
    quoteNumber,
    userId,
  });
}

async function postGlassOrderDate(selected: string[], date: string) {
  return await api.put("/api/addGlassOrderDate", {
    selected,
    date,
  });
}

async function getTableData(quoteId: string) {
  return await api.get("/api/getTableData", {
    params: { quoteId },
  });
}

async function getLabelData(salesOrder: string) {
  return await api.get("/api/labelData", {
    params: { salesOrder },
  });
}

async function getDeliveryDates(month: number, year: number) {
  return await api.get("/api/getDeliveryDates", {
    params: { month, year },
  });
}

async function getEventDetails(id: string) {
  return await api.get("/api/eventDetails", {
    params: { id },
  });
}

async function getEventDetailsService(id: string) {
  return await api.get("/api/eventDetailsService", {
    params: { id },
  });
}

async function getCustomEventDetails(id: string) {
  return await api.get("/api/customEventDetails", {
    params: { id },
  });
}

async function addEventDetails(
  id: string,
  name: string | undefined,
  phone: string | undefined,
  notes: string | undefined,
  confirmDelivery?: boolean
) {
  return await api.post("/api/eventDetails", {
    id,
    name,
    phone,
    calendarNotes: notes,
    confirmDelivery,
  });
}

async function searchCalSO(salesOrder: string) {
  return await api.post("/api/searchCalendarSO", { salesOrder });
}

async function setSchedule(
  ids: string[],
  date: Date,
  time: string | undefined,
  calendarYear: number,
  calendarMonth: number,
  sendEmail?: boolean,
  hasCustomEvent?: boolean
) {
  return await api.post("/api/setSchedule", {
    ids,
    date,
    time,
    calendarYear,
    calendarMonth,
    sendEmail,
    hasCustomEvent: hasCustomEvent || false,
  });
}

async function setCustomEvent(eventObj: any) {
  return await api.post("/api/setEvent", { eventObj });
}

async function setCustomEventDetails(
  id: string,
  eventObj: any,
  confirmDelivery: boolean
) {
  return await api.post("/api/setEventDetails", {
    id,
    eventObj,
    confirmDelivery,
  });
}

async function changeStatusToDelivered(
  ids: string[],
  calendarYear: number,
  calendarMonth: number,
  calendarDay: number,
  hasCustomEvent?: boolean
) {
  return await api.post("/api/changeStatusToDelivered", {
    ids,
    calendarYear,
    calendarMonth,
    calendarDay,
    hasCustomEvent: hasCustomEvent || false,
  });
}

async function createReport(form: SalesReportObj) {
  return await api.post("/api/createReport", { form });
}

async function searchReports(
  page: number,
  rowsPerPage: number,
  searchBy: string,
  searchTerm: string
) {
  return api.get("/api/searchReportTable", {
    params: {
      page,
      rowsPerPage,
      searchBy,
      searchTerm,
    },
  });
}

async function getReports(page: number, rowsPerPage: number) {
  return await api.get("/api/salesReportTable", {
    params: {
      page,
      rowsPerPage,
    },
  });
}

async function viewReport(id: string) {
  return await api.get("/api/viewReport", {
    params: {
      id,
    },
  });
}

async function uploadCsv(formData: FormData) {
  return await api.post("/api/uploadCsv", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

async function getGlassDetails(searchTerm: string, filterBy: string) {
  return await api.get("/api/glassDetails", {
    params: {
      searchTerm,
      filterBy,
    },
  });
}

async function getGlassOrderSheet(quoteIds: string[], date: string | Date) {
  return await api.get("/api/glassOrderSheet", {
    params: { quoteIds, date },
  });
}

async function getLeadTimes() {
  return await api.get("/api/leadTimes");
}

async function getPaintLeadTimes() {
  return await api.get("/api/paintLeadTimes");
}

async function updatePaintLeadTimes(paintLead: PaintLeadTimesObj) {
  return await api.post("/api/admin/changePaintLeadTimes", {
    state: paintLead,
  });
}

async function archiveQuotes(
  ids: string[],
  archiveReason: string,
  archiveReasonTextBox: string
) {
  return await api.post("/api/archive", {
    ids,
    archiveReason,
    archiveReasonTextBox,
  });
}

async function unArchiveQuotes(ids: string[]) {
  return await api.post("/api/unarchive", { ids });
}

async function getCustomerList(
  page: number,
  rowsPerPage: number,
  showActive?: string
) {
  return await api.get("/customerList", {
    params: { page, rowsPerPage, activeStatus: showActive },
  });
}

async function searchCustomerList(
  page: number,
  rowsPerPage: number,
  filterBy: string,
  searchTerm: string,
  showActive: string
) {
  return await api.get("/searchCustomerList", {
    params: {
      page,
      rowsPerPage,
      filterBy,
      searchTerm,
      activeStatus: showActive,
    },
  });
}

async function getClipboardEmails(
  filterBy: string,
  searchTerm: string,
  showActive: string,
  selected: (string | undefined)[]
) {
  return await api.get("/clipboardEmails", {
    params: { filterBy, searchTerm, activeStatus: showActive, ids: selected },
  });
}

async function postInactiveStatus(
  ids: (string | undefined)[],
  activeStatus: string,
  reason?: string
) {
  return await api.post("/inactiveStatus", {
    ids,
    activeStatus,
    inactiveReason: reason,
  });
}

async function updateUser(
  ids: (string | undefined)[],
  userInfo: Partial<UserObj>
) {
  return await api.post("/updateUser", {
    ids,
    userInfo,
  });
}

export {
  updateUser,
  postInactiveStatus,
  getClipboardEmails,
  getCustomerList,
  searchCustomerList,
  archiveQuotes,
  unArchiveQuotes,
  getGlassOrderSheet,
  getGlassDetails,
  uploadCsv,
  createReport,
  changeStatusToDelivered,
  setCustomEventDetails,
  getCustomEventDetails,
  setCustomEvent,
  setSchedule,
  searchCalSO,
  addEventDetails,
  getEventDetails,
  getLabelData,
  searchAllQuotes,
  getAllQuotes,
  postHideTips,
  getLogo,
  postStatusChange,
  getUser,
  postCloneQuote,
  postGlassOrderDate,
  getTableData,
  getDeliveryDates,
  getLeadTimes,
  getPaintLeadTimes,
  updatePaintLeadTimes,
  searchReports,
  getReports,
  viewReport,
  getEventDetailsService,
};
