import React, { memo, FC } from "react";
import Choices from "../Choices";

interface LockStileProps {
  label: string
}

const LockStileChoices: FC<LockStileProps> = ({ label }) => {
  return (
    <div style={{ marginTop: "-20px" }}>
      <Choices
        label={label}
        variant="filled"
        checkMark={true}
        disabled={true}
      />
    </div>
  );
};

export default memo(LockStileChoices);
