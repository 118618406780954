import React, { memo, useCallback, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../Choices";
import Paper from "@mui/material/Paper";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import {
  woodExtStops,
  woodIntStops,
  stops,
  woodInteriorStopsImages,
  stopsImages,
} from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutSpace = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const borderColor = "#04bff3";

function StickStopsAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const materialChoice = useStore((state) => state.quote.material);

  const stickingStops = useStore((state) => state.quote.stickingStops); //clad

  const stopsInterior = useStore((state) => state.quote.stickingStopsInterior); //wood
  const stopsExterior = useStore((state) => state.quote.stickingStopsExterior); //wood

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[19].expanded);

  const handleClickStop = useCallback(
    (label: string) => {
      const checked = label === stickingStops;

      if (checked) {
        addToQuote({ stickingStops: "" });
      } else {
        if (findRequired("Stickings and Stops Profile"))
          removeRequired("Stickings and Stops Profile");
        addToQuote({ stickingStops: label });
      }
    },
    [stickingStops]
  );

  const handleClickExt = useCallback(
    (label: string) => {
      const checked = label === stopsExterior;

      if (checked) {
        addToQuote({ stickingStopsExterior: "" });
      } else {
        if (findRequired("Exterior Stop Profile"))
          removeRequired("Exterior Stop Profile");
        addToQuote({ stickingStopsExterior: label });
      }
    },
    [stopsExterior]
  );

  const handleClickInt = useCallback(
    (label: string) => {
      const checked = label === stopsInterior;

      if (checked) {
        addToQuote({ stickingStopsInterior: "" });
      } else {
        if (findRequired("Interior Stop Profile"))
          removeRequired("Interior Stop Profile");
        addToQuote({ stickingStopsInterior: label });
      }
    },
    [stopsInterior]
  );

  return (
    <>
      {materialChoice && (
        <ReusableAccordian
          expanded={expanded}
          handleAccordClick={handleAccordClick}
          accordionName="stickStops"
          heading="Sticking/Stops"
          secondaryHeading="Select sticking/stops shape"
          colOrRow="col"
        >
          {materialChoice === "All Wood" ? (
            <>
              <RowLayout>
                <ColLayoutSpace>
                  <ColLayout>
                    <Typography variant="subtitle1">
                      * choose an interior sticking
                    </Typography>
                    <RowLayout>
                      {woodIntStops.map((state) => (
                        <div
                          key={state.id}
                          onClick={() => handleClickInt(state.label)}
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === stopsInterior
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === stopsInterior}
                          />
                        </div>
                      ))}
                    </RowLayout>
                  </ColLayout>
                  <RowLayout style={{ marginLeft: 50 }}>
                    <StopsImages
                      stickingStops={stopsInterior || ""}
                      handleClick={handleClickInt}
                      isInterior={true}
                    />
                  </RowLayout>
                </ColLayoutSpace>

                <ColLayoutSpace>
                  <ColLayout>
                    <Typography variant="subtitle1">
                      * choose an exterior stop
                    </Typography>
                    <RowLayout>
                      {woodExtStops.map((state) => (
                        <div
                          key={state.id}
                          onClick={() => handleClickExt(state.label)}
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === stopsExterior
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === stopsExterior}
                          />
                        </div>
                      ))}
                    </RowLayout>
                  </ColLayout>
                  <RowLayout style={{ marginLeft: 50 }}>
                    <StopsImages
                      stickingStops={stopsExterior || ""}
                      handleClick={handleClickExt}
                    />
                  </RowLayout>
                </ColLayoutSpace>
              </RowLayout>
            </>
          ) : (
            <>
              <RowLayout>
                {stops.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickStop(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === stickingStops ? "filled" : "outlined"
                      }
                      checkMark={state.label === stickingStops}
                    />
                  </div>
                ))}
              </RowLayout>
              <RowLayout style={{ marginLeft: 50 }}>
                <StopsImages
                  stickingStops={stickingStops || ""}
                  handleClick={handleClickStop}
                />
              </RowLayout>
            </>
          )}
        </ReusableAccordian>
      )}
    </>
  );
}

interface StopsImagesProps {
  stickingStops: string;
  handleClick: (label: string) => void;
  isInterior?: boolean;
}

const StopsImages: FC<StopsImagesProps> = memo(
  ({ stickingStops, handleClick, isInterior }) => {
    const images = !isInterior ? stopsImages : woodInteriorStopsImages;

    return (
      <>
        {images.map((image) => (
          <Paper
            key={image.id}
            style={{
              border:
                image.label === stickingStops ? `2px solid ${borderColor}` : "",
              marginLeft: 10,
              marginRight: 10,
            }}
            elevation={3}
          >
            <Image
              onClick={() => handleClick(image.label)}
              cloudName="ag-millworks"
              secure="true"
              publicId={`Stops/${image.label.toLowerCase()}`}
            />
          </Paper>
        ))}
      </>
    );
  }
);

export default memo(StickStopsAccordian);
