import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { styled } from "@mui/material/styles";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .MuiTooltip-tooltip`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//   }
// }));

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledDivContainer = styled("div")({
  fontSize: "60px",
  position: "fixed",
  bottom: 20,
  right: 30,
});

const StyledTypographyRedBold = styled(Typography)({
  color: "red",
  fontWeight: "bold",
});

const StyledTypographyRed = styled(Typography)({
  color: "red",
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const StyledErrIcon = styled(ErrorRoundedIcon)({
  fontSize: "60px",
  color: "red",
});

interface RequiredMsgsProps {
  requireds: string[];
}

const RequiredMsgs: FC<RequiredMsgsProps> = ({ requireds }) => {
  return (
    <StyledDivContainer>
      <HtmlTooltip
        open={true}
        placement="top"
        aria-label="error"
        title={
          <>
            <StyledTypographyRedBold>Required:</StyledTypographyRedBold>
            <StyledDivCol>
              {requireds.map((msg) => (
                <StyledTypographyRed key={msg}>- {msg}</StyledTypographyRed>
              ))}
            </StyledDivCol>
          </>
        }
      >
        <StyledErrIcon />
      </HtmlTooltip>
    </StyledDivContainer>
  );
};

export default RequiredMsgs;
