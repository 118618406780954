import api from "./api";

async function getUser(userId: string) {
  return await api.get("/userInfo", {
    params: { id: userId },
  });
}

async function getQuote(userId: string, quoteNumber: number) {
  return await api.get("/api/history/getSummaryQuote", {
    params: {
      userId,
      quoteNumber,
    },
  });
}

async function postPO(quoteId: string, poNumber: string) {
  return await api.put("/api/editPO", {
    quoteId,
    poNumber,
  });
}

async function postSummaryData(
  quoteId: string,
  margin: string,
  withMarkup: boolean
) {
  return api.put("/api/editOrderSummary", {
    quoteId,
    margin,
    withMarkup,
  });
}

async function postCustomSalesTax(salesTax: number, quoteId: string) {
  return await api.put("/api/customSalesTax", {
    salesTax,
    quoteId,
  });
}

async function postAdminCharge(
  adminCharge: number,
  adminChargeLabel: string,
  withMargin: boolean,
  quoteId: string,
  userId: string
) {
  return await api.put("/api/addAdminCharge", {
    adminCharge,
    adminChargeLabel,
    withMargin,
    quoteId,
    userId,
  });
}

async function postToggle(bool: boolean, quoteId: string) {
  return await api.put(`/api/overrideFixedMargin`, {
    overrideFixedMargin: bool,
    quoteId,
  });
}

async function postToggleMarkup(bool: boolean, quoteId: string) {
  return await api.put(`/api/overrideFixedMarkup`, {
    overrideFixedMarkup: bool,
    quoteId,
  });
}

async function postHideCustomer(val: string, quoteId: string) {
  return await api.put(`/api/hideCustomerPricing`, {
    hideCustomerPricing: val,
    quoteId,
  });
}

async function postSeriesOverride(val: string, quoteId: string) {
  return await api.put(`/api/seriesOverride`, {
    seriesOverride: val,
    quoteId,
  });
}

interface Jobsite {
  contactName: string;
  contactNumber: string;
  city: string;
  zip: string;
  address: string;
}

async function postJobsiteDetails(
  id: string,
  jobsite: Jobsite,
  chosenTax: string | null,
  currentTax: number
) {
  return await api.put(`/api/jobsiteDetails`, {
    id,
    jobsite,
    chosenTax,
    currentTax,
  });
}

async function postAddCost(
  quoteId: string,
  addCostName: string,
  addCostAmount: number,
  taxable: boolean,
  margin: string,
  withMarkup: boolean
) {
  return await api.post(`/api/addCost`, {
    quoteId,
    addCostName,
    addCostAmount,
    taxable,
    margin,
    withMarkup,
  });
}

async function updateAddCost(
  quoteId: string,
  costId: string,
  addCostName: string,
  addCostAmount: number,
  taxable: boolean,
  margin: string,
  withMarkup: boolean
) {
  return await api.post(`/api/updateAddCost`, {
    quoteId,
    costId,
    addCostName,
    addCostAmount,
    taxable,
    margin,
    withMarkup,
  });
}

async function deleteAddCost(
  quoteId: string,
  costId: string,
  margin: string,
  withMarkup: boolean
) {
  return await api.post(`/api/deleteAddCost`, {
    quoteId,
    costId,
    margin,
    withMarkup,
  });
}

export {
  deleteAddCost,
  updateAddCost,
  postAddCost,
  postJobsiteDetails,
  postSummaryData,
  postPO,
  getQuote,
  getUser,
  postCustomSalesTax,
  postAdminCharge,
  postToggle,
  postHideCustomer,
  postToggleMarkup,
  postSeriesOverride,
};
