import React, { useState, createContext, lazy, Suspense, FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { SignIn } from "./api/Authentication";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";

import DealerZone from "./pages/DealerZone";
import SideBar from "./components/SideBar";
import QuoteForm from "./pages/QuoteForm";
import OrderSummary from "./pages/OrderSummary";
import ResetPassword from "./pages/ResetPassword";
import SignSuccessPage from "./pages/SignSuccessPage";
import LoginWrapper from "./components/LoginWrapper";
import LogoutWrapper from "./components/LogoutWrapper";
import EsignIframe from "./quotePDF/EsignIframe";
import Settings from './pages/Settings'
import ShippingLabel from "./pages/ShippingLabel";
import { indigo } from '@mui/material/colors'
import { ThemeOptions } from "@mui/material/styles";

const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

export const UserContext = createContext("");

const queryClient = new QueryClient();

const appTheme: ThemeOptions = {
  typography: {
    fontFamily: `"Arial", "Helvetica", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // pxToRem: (size) => `${size / 16}rem`,
  },
  palette: {
    primary: {
      main: indigo[500]
    },
    cyan: {
      main: "#57d5f2",
      contrastText: "#000000"
    },
    customGrey: {
      main: "#4b4b4b",
      contrastText: "#ffffff",
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1000,
      xl: 1400,
    },
  },
};

const THEME = createTheme(appTheme);

const App: FC = () => {
  const [user, setUser] = useState("");

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  
  const handleSignIn = (event: React.KeyboardEvent<any> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if(loading) return;

    if (userPassword.length < 6) {
      setLoading(false)
      setErrorMessage("Password must be at least 6 characters");
      return;
    }

    if (!userEmail || !userPassword) {
      setLoading(false)
      setErrorMessage("Enter Username and Password");
    } else {
      setLoading(true)

      SignIn(userEmail.toLowerCase(), userPassword)
        .then((userFound) => {
          setLoading(false)

          if (!userFound) {
            setUser("");
            setErrorMessage("User not found");
          }else {
            setUser(userFound.id);
            setErrorMessage("");
          }

          userFound.error &&
            setErrorMessage(
              "Issue with Sign in attempt, Check User Name and Password."
            );
        })
        .catch((err) => {
          setLoading(false)
          setErrorMessage("Issue with Sign in attempt");
          console.error(err);
        });
    }
  };

  const handleAuthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
    if (name === "email") {
      setUserEmail(value);
    } else if (name === "password") {
      setUserPassword(value);
    }
  };


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>
      <div className="App">
      <UserContext.Provider value={user}>
        <QueryClientProvider client={queryClient}>
              <CssBaseline />
              <Router>
                <Routes>
                <Route path="/form/:id" element={<PrivateRoute><QuoteForm /></PrivateRoute>} />
                  <Route path="/config/:id" element={<PrivateRoute><SideBar /></PrivateRoute>} />
                  <Route path="/dealerZone/:id" element={<PrivateRoute><DealerZone /></PrivateRoute>} />
                  <Route path="/userSettings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                  <Route path="/orderSummary/:id" element={<PrivateRoute><OrderSummary /></PrivateRoute>} />
                  <Route path="/" element={
                    <LoginWrapper
                      handleAuthChange={handleAuthChange}
                      handleSignIn={handleSignIn}
                      errorMessage={errorMessage}
                      email={userEmail}
                      password={userPassword}
                      user={user}
                      setUser={setUser}
                      loading={loading}
                    />
                  } />
                  <Route path="/logout" element={
                    <LogoutWrapper
                      setUser={setUser}
                      handleAuthChange={handleAuthChange}
                      handleSignIn={handleSignIn}
                      errorMessage={errorMessage}
                      email={userEmail}
                      password={userPassword}
                      loading={loading}
                    />
                  } />
                  <Route path="/forgotPassword" element={
                    <Suspense fallback={
                      <Backdrop open={true}>
                        <CircularProgress />
                      </Backdrop>
                    }>
                      <ForgotPassword />
                    </Suspense>
                  } />
                  <Route path="/reset/:token" element={<ResetPassword />} />
                  <Route path="/reset/:token/:ip" element={<ResetPassword />} />
                  <Route path="/esign" element={<EsignIframe />} />
                  <Route path="/esign/signSuccess" element={<SignSuccessPage />} />
                  <Route path="/installDetails/:id" element={<ShippingLabel />} />
                </Routes>
                <ReactQueryDevtools initialIsOpen={false} />
              </Router>
        </QueryClientProvider>
      </UserContext.Provider>
    </div>
    </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;


