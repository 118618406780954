import React, { useState, FC } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ListItemIcon, MenuItem, Menu, IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
});

const styles = {
  signoutRl: {
    color: "black",
    textDecoration: "underline",
    zIndex: 2000,
    "&:hover": {
      cursor: "pointer",
    },
  },
  signoutDZ: {
    color: "black",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  signout: {
    color: "black",
    textDecoration: "underline",
    zIndex: 2000,
    "&:hover": {
      cursor: "pointer",
    },
  },
};

export interface HistoryState {
  isQuoteCreated?: boolean;
  userId?: string;
  resetStore?: boolean;
  previous?: string;
}

interface AccountDropdownProps {
  color: string;
  resumeLaterBtn?: boolean;
  returnedPath?: string;
  historyState?: HistoryState;
}

const AccountDropdown: FC<AccountDropdownProps> = ({
  color,
  resumeLaterBtn,
  returnedPath,
  historyState,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<
    Element | (() => Element) | null | undefined
  >(null);

  const email = JSON.parse(sessionStorage.getItem("email") || JSON.stringify(""));

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/logout");
  };

  const routeToSettings = () => {
    if (role === "Dealer" || role === "Manager" || role === "Company Manager") {
      navigate("/userSettings", {
        state: {
          userId: JSON.parse(sessionStorage.getItem("user") || JSON.stringify("")),
          previous: returnedPath,
          ...historyState,
        },
      });
    }
  };

  return (
    <div
      style={
        resumeLaterBtn
          ? styles.signoutRl
          : resumeLaterBtn
          ? styles.signout
          : styles.signoutDZ
      }
    >
      <StyledDivCol>
        <IconButton style={{ color: color }} onClick={handleClick} size="large">
          <AccountCircleIcon fontSize="large" />
        </IconButton>

        <Menu
          style={{ zIndex: 2000 }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 6,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleClose}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <ListItemIcon>
              <PersonIcon style={{ color: "black" }} fontSize="small" />
            </ListItemIcon>
            {email}
          </MenuItem>

          {(role === "Manager" ||
            role === "Dealer" ||
            role === "Company Manager") && (
            <MenuItem
              onClick={routeToSettings}
              style={{
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <ListItemIcon>
                <SettingsIcon style={{ color: "black" }} fontSize="small" />
              </ListItemIcon>
              Account Settings
            </MenuItem>
          )}

          <MenuItem
            onClick={handleSignOut}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "black" }} fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </StyledDivCol>
    </div>
  );
};

export default AccountDropdown;
