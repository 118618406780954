function findEqualPanels(width: number, sub: number, numberOfPanels: number){
  const equal = (width - sub) / numberOfPanels;
  return equal;
};

function calcSwingWidths(
  width: string,
  widthFraction: string,
  direction: string,
  swingType: string,
  sideLite: string,
  pWidth: string,
  pWidthFraction: string,
  equalPanels: boolean,
  material?: string,
  isPivot?: boolean,
  screenType?: string
){
  let panelW = 0
  let slw = 0
  let equalized = 0

  let fraction =
    widthFraction === "0" || !widthFraction ? "" : widthFraction.split("/");
  let overall =
    fraction !== ""
      ? parseInt(width) + parseInt(fraction[0]) / parseInt(fraction[1])
      : parseInt(width);

  let pfraction =
    pWidthFraction === "0" || !pWidthFraction ? "" : pWidthFraction.split("/");
  let pOverall =
    pfraction !== ""
      ? parseInt(pWidth) + parseInt(pfraction[0]) / parseInt(pfraction[1])
      : parseInt(pWidth);

  if(isPivot){
    switch (sideLite) {
      case "Add Single Sidelite":
        // let materialSub1 = 4; changed on 04/18/2024 from Brian
        let materialSub1 = 4.0625;
        !equalPanels
          ? (slw = overall - pOverall - materialSub1)
          : (equalized = findEqualPanels(overall, materialSub1, 2));
        break;
      case "Add Double Sidelite":
        // let materialSub2 = 5.875; changed on 04/18/2024 from Brian
        let materialSub2 = 5.9375;
        !equalPanels
          ? (slw = (overall - pOverall - materialSub2) / 2)
          : (equalized = findEqualPanels(overall, materialSub2, 3));
        break;
      default:
        // let materialSub3 = 2.125; changed on 04/18/2024 from Brian
        let materialSub3 = 2.1875;
        !equalPanels
          ? (panelW = overall - materialSub3)
          : (equalized = findEqualPanels(overall, materialSub3, 1));
    }
  }else if (direction === "Outswing") {
    const jambLeg = screenType?.includes("Centor") ? 6.25 : 0;

    if (swingType === "Single Swing") {
      switch (sideLite) {
        case "Add Single Sidelite":
          const materialSub1 = material === "All Wood" ? 2.875 : 2.9374;

          !equalPanels
            ? (slw = overall - jambLeg - pOverall - materialSub1)
            : (equalized = findEqualPanels(overall - jambLeg, materialSub1, 2));
          break;
        case "Add Double Sidelite":
          const materialSub2 = material === "All Wood" ? 3.75 : 3.8124;

          !equalPanels
            ? (slw = (overall - jambLeg - pOverall - materialSub2) / 2)
            : (equalized = findEqualPanels(overall - jambLeg, materialSub2, 3));
          break;
        default:
          const materialSub3 = material === "All Wood" ? 2 : 2.0624;

          !equalPanels
            ? (panelW = overall - jambLeg - materialSub3)
            : (equalized = findEqualPanels(overall, materialSub3, 1));
      }
    } else if (swingType === "Pair Swing") {
      switch (sideLite) {
        case "Add Double Sidelite":
          const materialSub4 = material === "All Wood" ? 4.375 : 4.4374;

          !equalPanels
            ? (slw = (overall - jambLeg - 2 * pOverall - materialSub4) / 2)
            : (equalized = findEqualPanels(overall - jambLeg, materialSub4, 4));
          break;
        default:
          const materialSub5 = material === "All Wood" ? 2.625 : 2.6874;

          !equalPanels
            ? (panelW = (overall - jambLeg - materialSub5) / 2)
            : (equalized = findEqualPanels(overall, materialSub5, 2));
      }
    }
  } else if (direction === "Inswing") {
    if (swingType === "Single Swing") {
      switch (sideLite) {
        case "Add Single Sidelite":
          let materialSub1 = material === "All Wood" ? 3.5625 : 3.9374;
          !equalPanels
            ? (slw = overall - pOverall - materialSub1)
            : (equalized = findEqualPanels(overall, materialSub1, 2));
          break;
        case "Add Double Sidelite":
          let materialSub2 = material === "All Wood" ? 5.43755 : 5.8124;
          !equalPanels
            ? (slw = (overall - pOverall - materialSub2) / 2)
            : (equalized = findEqualPanels(overall, materialSub2, 3));
          break;
        default:
          let materialSub3 = material === "All Wood" ? 1.6875 : 2.0624;
          !equalPanels
            ? (panelW = overall - materialSub3)
            : (equalized = findEqualPanels(overall, materialSub3, 1));
      }
    } else if (swingType === "Pair Swing") {
      switch (sideLite) {
        case "Add Double Sidelite":
          let materialSub4 = material === "All Wood" ? 6.0625 : 6.4374;
          !equalPanels
            ? (slw = (overall - 2 * pOverall - materialSub4) / 2)
            : (equalized = findEqualPanels(overall, materialSub4, 4));
          break;
        default:
          let materialSub5 = material === "All Wood" ? 2.3125 : 2.6875;
          !equalPanels
            ? (panelW = (overall - materialSub5) / 2)
            : (equalized = findEqualPanels(overall, materialSub5, 2));
      }
    }
  }

  return {
    panelW,
    slw,
    equalized,
  };
};

export default calcSwingWidths;
