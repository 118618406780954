import React, { FC } from "react";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import fractionToInt from "../../util/fractionToInt";
import {
  jambLegImagesBifold,
  headJambImagesBifold,
  sillImagesBifold,
  jambLegImagesCorner
} from "../CutSheets/imageMatch/bifold";
import {
  jambLegImagesSwing,
  sillImagesSidelite,
  sillImagesSwing,
} from "../CutSheets/imageMatch/swing";
import {
  jambLegImagesSlider,
  headJambImagesSlider,
  sillImagesSlider,
} from "../CutSheets/imageMatch/slider";
import {
  jambLegImagesFixed,
  headJambImagesFixed,
  sillImagesFixed,
} from "../CutSheets/imageMatch/fixedPanel";
import {
  jambLegImagesPivot,
  sillImagesPivot
} from "./imageMatch/pivot";
import CutSheetHeader from "./CutSheetHeader";
import StoryPole from "./StoryPole";
import PivotAxis from "./PivotAxis";
import calculateScreenPocket from "./tableData/pocketCalc";
import CutSheetTable from "./CutSheetTable";
import CutSheetLockStile from "./CutSheetLockStile";
import { cornerStileLetters } from '../../Drawings/EPVCorner'
import litesCloudinaryImg from "../../Drawings/LitesLayout";
import { findStopsImage } from "../../components/lites/findStopsImage";
import { UnitObj } from "../../util/quoteCartStorage";
import { CutsheetDataObj } from "../../GlobalTypes";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "space-around",
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const abbreviateWood = (wood: string) => {
  if (!wood.includes("-") && !wood.includes(" ")) {
    return wood;
  }
  const woodLabel = wood.replace("-", " ");
  const separateWords = woodLabel.split(" ");
  const woodAbbrev = separateWords.map((word) => word[0]).join("");

  return woodAbbrev;
};

interface CutsheetsProps {
  unit: UnitObj
  dealer: string
  salesOrder: string
  poNumber: string
  totalItems: number
  unitNumber: number
  cutsheetData: CutsheetDataObj
  cornerSide?: string
}

const CutSheets: FC<CutsheetsProps> = ({
  unit,
  dealer,
  salesOrder,
  poNumber,
  totalItems,
  unitNumber,
  cutsheetData,
  cornerSide
}) => {
  const height = `${unit.height} ${unit.heightFraction || ''}`;
  const width = 
   unit.sliderPocket === 'With Pocket' 
    ? unit.pocketingSliderNetWidth 
    : unit.doorType.includes("Corner") && 
      cornerSide === "right" 
    ? `${unit.cornerWidthRight} ${unit.cornerWidthFractionRight || ''}`
    : `${unit.unitSize} ${unit.unitSizeFraction || ''}`;

  const heightInt = fractionToInt(unit.height, unit.heightFraction || '');

  const widthInt = 
   unit.doorType.includes("Corner") && 
   cornerSide === "right" 
    ? fractionToInt(unit.cornerWidthRight || "", unit.cornerWidthFractionRight || '')
    : fractionToInt(unit.unitSize, unit.unitSizeFraction || '');

  const woodAbbrev = abbreviateWood(unit.woodSpecies);

  const jambLegSrc =
    unit.doorType === "Fixed Panel" ?
      jambLegImagesFixed(
        unit.material, 
        unit.exactConfig
      )
    : unit.doorType.includes('Bi-fold') ?
      jambLegImagesBifold(
          unit.material,
          unit.screenType || "",
          unit.addScreen || "",
          unit.screenLayout || "",
          unit.screenPocket || "",
        )
      : unit.doorType === 'Swing' ? 
        jambLegImagesSwing(
          unit.material,
          unit.screenType || "",
          unit.addScreen || "",
          unit.screenLayout || "",
          unit.screenPocket || "",
          unit.swingDirection || "",
          unit.exactConfig.slice(-2),
          unit.swingType || "",
          unit.sideLite || "",
          unit.jamb || "",
          unit.exactConfigImg?.replace(".jpg", '').slice(-4) || ""
        )
      : unit.doorType === "Pivot" ?
        jambLegImagesPivot(
          unit.material, 
          unit.exactConfig.slice(-2), 
          unit.sideLite || "", 
          unit.exactConfigImg?.includes("LOCK") ? "LOCK" : ""
        )
      :
      jambLegImagesSlider(
        unit.material, 
        unit.screenType || "",
        unit.addScreen || "", 
        unit.addSlidingScreen || "", 
        unit.screenLayout || "", 
        unit.screenPocket || "", 
        unit.exactConfig, 
        unit.handleStyle || "", 
        unit.stilesAndRails || "", 
        unit.jamb || "",
        unit.sillStyle || ""
      );

  const jambLegCornerSrc = unit.doorType.includes("Corner") 
  ? jambLegImagesCorner(
    unit.cornerType || "", 
    unit.material, 
    unit.primaryDoor || "", 
    cornerSide || ""
  )
  : "";

  const sillSrc =
    unit.doorType === "Fixed Panel" ?
      sillImagesFixed(
        unit.exactConfig,
        unit.sillStyle || ""
      )
    : unit.doorType.includes('Bi-fold') ?
        sillImagesBifold(
          unit.sillStyle || "",
          unit.screenType || "",
          unit.addScreen || "",
          unit.stilesAndRails || ""
        )
    : unit.doorType === 'Swing' ? 
        sillImagesSwing(
          unit.material,
          unit.screenType || "",
          unit.addScreen || "",
          unit.swingDirection || "",
          unit.jamb || ""
        )
    : unit.doorType === 'Pivot' ? 
      sillImagesPivot(
        unit.material,
        "none",
        unit.sillStyle || ""
      )
    :
      sillImagesSlider(
        unit.material, 
        unit.sillStyle || "", 
        unit.screenType || "",
        unit.addScreen || "", 
        unit.addSlidingScreen || "", 
        unit.exactConfig, 
        unit.handleStyle || "", 
        unit.sliderPocket === 'With Pocket',
        unit.jamb || ""
      );

  const headJambSrc =
    unit.doorType === "Fixed Panel" ?
      headJambImagesFixed(
        unit.material, 
        unit.exactConfig
      )
    : unit.doorType === "Swing" || unit.doorType === "Pivot" ? ''
    : unit.doorType.includes('Bi-fold') ?
      headJambImagesBifold(
        unit.material, 
        unit.screenType || "", 
        unit.addScreen || ""
      )
    : 
      headJambImagesSlider(
        unit.material, 
        unit.screenType || "",
        unit.addScreen || "", 
        unit.addSlidingScreen || "", 
        unit.exactConfig, 
        unit.handleStyle || "", 
        unit.jamb || ""
      );

  const sillSideliteSrc =
    unit.doorType === "Swing" &&
    unit.swingDirection === "Outswing" &&
    unit.sideLite !== "none" ?
    sillImagesSidelite(
      unit.material,
      unit.screenType || "",
      unit.addScreen || "",
      unit.swingDirection,
      unit.sideLite || ""
    )
    : unit.doorType === "Pivot" &&
      unit.sideLite !== "none" ?
    sillImagesPivot(
      unit.material,
      unit.sideLite || "",
      unit.sillStyle || ""
    )
    :
    null;

  const iRailImg = 
    (unit.doorType === "Pivot" && unit.addDL === "Add Intermediate Rail") &&
      litesCloudinaryImg(unit.dlLayout || "", unit.addDL);

  const iRailImg2 = 
    (unit.doorType === "Pivot" && unit.addDL === "Add Intermediate Rail") &&
      findStopsImage(
        "Pivot",
        unit.material,
        false,
        unit.stickingStops || "",
        unit.stickingStopsInterior || "",
        unit.stickingStopsExterior || "",
        unit.liteConfig || ""
      );

  const finSetback = 
    !unit.flashingFin || 
    unit.flashingFinConfig?.includes("Standard") 
      ? 1 
      : unit.finSetback?.split(" ")[1]
      ? fractionToInt(unit.finSetback.split(" ")[0], unit.finSetback.split(" ")[1])
      : Number(unit.finSetback?.split(" ")[0])

  const autoSizeImgStyle = { width: "auto", height: "auto" }

  return (
    <>
      <CutSheetHeader
        totalItems={totalItems}
        unitNumber={unitNumber}
        salesOrder={salesOrder}
        poNumber={poNumber}
        dealer={dealer}
        unit={unit}
        woodSpecies={unit.woodAbbrev || ""}
        cornerSide={cornerSide}
      />

      <View style={styles.row} wrap={false}>
        {unit.doorType.includes("Corner") ? 
          <View style={styles.colCenter}>
            <Image
              src={jambLegCornerSrc}
              style={{ ...autoSizeImgStyle, maxWidth: 150, maxHeight: 118, padding: 5 }}
            />
            <Image
              src={jambLegSrc}
              style={{ ...autoSizeImgStyle, maxWidth: 250, maxHeight: 208, padding: 10 }}
            />
          </View>
        : iRailImg && iRailImg2 ?
        <View style={styles.col}>
            <Image
              src={iRailImg}
              style={{
                ...autoSizeImgStyle,
                // height: "auto",
                maxWidth: 80,
                padding: 10,
                alignSelf: "center"
              }}
            />
            <Image
              src={`https://res.cloudinary.com/ag-millworks/image/upload/${iRailImg2}`}
              style={{
                ...autoSizeImgStyle,
                maxWidth: 220,
                // height: "auto",
                padding: 10,
                alignSelf: "center"
              }}
            />

          <Image
            src={jambLegSrc}
            style={{
              ...autoSizeImgStyle,
              maxWidth: 250,
              // height: "auto",
              padding: 10
            }}
          />
        </View>
        :
        <Image
          src={jambLegSrc}
          style={
            unit.doorType.includes("Bi-fold")
              ? {...autoSizeImgStyle, maxWidth: 290, maxHeight: 240, padding: 10 }
              : unit.doorType === "Fixed Panel" ?
              { 
                ...autoSizeImgStyle,
                maxWidth: 200, 
                // height: 'auto',
                objectFit: "contain",
                padding: 10 
              }
              : unit.doorType === "Pivot" ?
              {
                ...autoSizeImgStyle,
                maxWidth: 250,
                // height: "auto",
                padding: 10
              }
              :
              { 
                ...autoSizeImgStyle,
                maxWidth: 250, 
                maxHeight: 362, 
                padding: 10 
              }
          }
        />
        }

        <View style={styles.col}>
          <CutSheetLockStile data={unit} cornerSide={cornerSide} />

          {unit.doorType.includes('Bi-fold') ? (
            <View style={{ alignSelf: "flex-start" }}>
              <StoryPole
                height={heightInt}
                pocket={
                  unit.addScreen && unit.screenPocket
                    ? calculateScreenPocket(widthInt, unit.screenPocket, "pocketCalc_9375")
                    : ""
                }
              />
            </View>
          ) : unit.doorType === "Pivot" ? (
            <PivotAxis 
              axis={unit.pivotPoint || ""}
              panelWidth={unit.swingPanelWidth || ""}
              panelWidthFraction={unit.swingPanelFraction || ""}
            />
          ) : null}

          <CutSheetTable
            netHeight={heightInt}
            exactConfig={unit.exactConfig}
            material={unit.material}
            swingDirection={unit.swingDirection || ""}
            jamb={unit.jamb || ""}
            sill={unit.sillStyle}
            screen={unit.addScreen || ""}
            screenType={unit.screenType || ""}
            screenPocket={unit.screenPocket || ""}
            doorType={unit.doorType}
            width={widthInt}
            wood={woodAbbrev}
            qty={unit.quantity}
            finSetback={finSetback}
            stile={unit.stilesAndRails}
            data={cutsheetData.jambLeg}
            length={heightInt}
            name="JAMB LEGS"
          />
        </View>
      </View>
      {unit.doorType !== "Swing" && 
        unit.exactConfig !== "Fixed Full Bound" && 
        unit.doorType !== "Pivot" ? (
        <View style={styles.row} wrap={false}>
          <Image
            src={headJambSrc}
            style={
              unit.doorType === "Fixed Panel" ?
              { 
                ...autoSizeImgStyle,
                maxWidth: 250, 
                // height: 'auto',
                objectFit: "contain",
                padding: 10 
              }
              :
              { 
                ...autoSizeImgStyle,
              maxWidth: 365, 
              maxHeight: 125, 
              padding: 10 
            }}
          />
          <CutSheetTable
            jamb={unit.jamb || ""}
            exactConfig={unit.exactConfig}
            netHeight={heightInt}
            material={unit.material}
            swingDirection={unit.swingDirection || ""}
            screen={unit.addScreen || ""}
            screenType={unit.screenType || ""}
            screenPocket={unit.screenPocket || ""}
            doorType={unit.doorType}
            width={widthInt}
            wood={woodAbbrev}
            qty={unit.quantity}
            data={cutsheetData.headJamb}
            length={widthInt}
            finSetback={finSetback}
            name="HEAD JAMB"
          />
        </View>
      ) 
      : null}
      <View style={styles.row} wrap={false}>
        {unit.doorType === "Swing" && unit.sideLite !== "none" ? (
          <View style={styles.col} wrap={false}>
            <Image
              src={sillSrc}
              style={{ ...autoSizeImgStyle, maxWidth: 200, maxHeight: 175, padding: 10 }}
            />

            {unit.swingDirection === "Outswing" && (
              <Image
                src={sillSideliteSrc || ""}
                style={{ ...autoSizeImgStyle, maxWidth: 200, maxHeight: 175, padding: 10 }}
              />
            )}
          </View>
        ) : (
          <View style={styles.col} wrap={false}> 
            <Image
              src={unit.exactConfig === "Fixed Full Bound" ? jambLegSrc : sillSrc}
              style={
                unit.doorType.includes("Bi-fold") || unit.doorType === 'Slider'
                  ? { ...autoSizeImgStyle, maxWidth: 342, maxHeight: 120, padding: 10 }
                  : unit.doorType === "Fixed Panel" ?
                  { 
                    ...autoSizeImgStyle,
                    maxWidth: unit.exactConfig === "Fixed Full Bound" ? 200 : 250, 
                    // height: 'auto',
                    objectFit: "contain",
                    padding: 10 
                  }
                  : unit.doorType === "Pivot" && unit.addDL === "Add Intermediate Rail" ?
                  { ...autoSizeImgStyle, maxWidth: 150, padding: 10 }
                  : unit.doorType === "Pivot" ?
                  { ...autoSizeImgStyle, maxWidth: 200, padding: 10 }
                  : {...autoSizeImgStyle, maxWidth: 300, maxHeight: 263, padding: 10 }
              }
            />

            {unit.doorType === "Pivot" && 
             unit.addDL === "Add Intermediate Rail" && 
             sillSideliteSrc ?
            <Image
              src={sillSideliteSrc}
              style={{ ...autoSizeImgStyle, maxWidth: 150, padding: 10 }}
            />
            : unit.doorType === "Pivot" && sillSideliteSrc ?
            <Image
              src={sillSideliteSrc}
              style={{ ...autoSizeImgStyle, maxWidth: 200, padding: 10 }}
            />
            :
            <Text />
            }
          </View>
        )}

        {unit.doorType === "Swing" ? (
          <>
            {unit.sideLite !== "none" ? (
              <View style={styles.col} wrap={false}>
                <CutSheetTable
                  jamb={unit.jamb || ""}
                  exactConfig={unit.exactConfig}
                  netHeight={heightInt}
                  material={unit.material}
                  swingDirection={unit.swingDirection || ""}
                  screen={unit.addScreen || ""}
                  screenType={unit.screenType || ""}
                  screenPocket={unit.screenPocket || ""}
                  doorType={unit.doorType}
                  width={widthInt}
                  wood={woodAbbrev}
                  qty={unit.quantity}
                  data={cutsheetData.sill}
                  finSetback={finSetback}
                  length={widthInt}
                  name="DOOR SILL"
                />

                {unit.swingDirection === "Outswing" && (
                  <CutSheetTable
                    jamb={unit.jamb || ""}
                    exactConfig={unit.exactConfig}
                    netHeight={heightInt}
                    material={unit.material}
                    swingDirection={unit.swingDirection || ""}
                    screen={unit.addScreen || ""}
                    screenType={unit.screenType || ""}
                    screenPocket={unit.screenPocket || ""}
                    doorType={unit.doorType}
                    width={widthInt}
                    wood={woodAbbrev}
                    qty={unit.quantity}
                    data={cutsheetData.sideliteSill}
                    length={widthInt}
                    finSetback={finSetback}
                    name="SIDELITE SILL"
                  />
                )}
              </View>
            ) : (
              <CutSheetTable
                jamb={unit.jamb || ""}
                exactConfig={unit.exactConfig}
                netHeight={heightInt}
                material={unit.material}
                swingDirection={unit.swingDirection || ""}
                screen={unit.addScreen || ""}
                screenType={unit.screenType || ""}
                screenPocket={unit.screenPocket || ""}
                doorType={unit.doorType}
                width={widthInt}
                wood={woodAbbrev}
                qty={unit.quantity}
                data={cutsheetData.sill}
                finSetback={finSetback}
                length={widthInt}
                name="SILL"
              />
            )}
          </>
        ) : (
          <CutSheetTable
            jamb={unit.jamb || ""}
            exactConfig={unit.exactConfig}
            netHeight={heightInt}
            material={unit.material}
            swingDirection={unit.swingDirection || ""}
            screen={unit.addScreen || ""}
            screenType={unit.screenType || ""}
            screenPocket={unit.screenPocket || ""}
            doorType={unit.doorType}
            width={widthInt}
            wood={woodAbbrev}
            qty={unit.quantity}
            data={cutsheetData.sill}
            length={widthInt}
            finSetback={finSetback}
            name="SILL"
          />
        )}
      </View>

      {unit.doorType.includes("Corner") ?
        <View style={styles.row} wrap={false}>
          <Image 
            src={
              cornerSide === "left" 
              ? cornerStileLetters(unit.exactConfig, unit.primaryDoor === "Left")
              : cornerStileLetters(unit.cornerConfigRight || "", unit.primaryDoor === "Right")
            } 
            style={{ ...autoSizeImgStyle, maxWidth: 300, maxHeight: 90 }}
          />
        </View>
      :
      <Text />
      }
    </>
  );
};

export default CutSheets;
