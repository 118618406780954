import { UnitObj } from "./quoteCartStorage";

function sortUnits(units: UnitObj[]){
    if(!units.length) return null;
    const sorted = units.sort(
      (a, b) => {
        const aIndex = a.orderIndex || 0
        const bIndex = b.orderIndex || 0

        return aIndex - bIndex
      }
    );

    return sorted;
};

export default sortUnits