const tier1 = [
  {
    id: 5,
    type: "woodSpecies",
    label: "Paint-Grade Vertical Douglas Fir",
    abbrev: "PGV-Douglas Fir",
    material: "both"
  },
  {
    id: 4,
    type: "woodSpecies",
    label: "Stain-Grade Pine",
    abbrev: "SG-Pine",
    material: "clad"
  },
  {
    id: 3,
    type: "woodSpecies",
    label: "Paint-Grade Pine",
    abbrev: "PG-Pine",
    material: "clad"
  },
  {
    id: 2,
    type: "woodSpecies",
    label: "Stain-Grade Bass",
    abbrev: "SG-Bass",
    material: "clad"
  },
  {
    id: 1,
    type: "woodSpecies",
    label: "Paint-Grade Bass",
    abbrev: "PG-Bass",
    material: "clad"
  },
];

const tier2 = [
  {
    id: 8,
    type: "woodSpecies",
    label: "Red Oak",
    abbrev: "Red Oak",
    material: "both"
  },
  {
    id: 7,
    type: "woodSpecies",
    label: "Knotty Alder",
    abbrev: "Knotty Alder",
    material: "both"
  },
];

const tier3 = [
  {
    id: 10,
    type: "woodSpecies",
    label: "Clear Alder",
    abbrev: "Clear Alder",
    material: "both"
  },
  {
    id: 9,
    type: "woodSpecies",
    label: "Stain-Grade Vertical Douglas Fir",
    abbrev: "SGV-Douglas Fir",
    material: "both"
  },
];

const tier4 = [
  {
    id: 14,
    type: "woodSpecies",
    label: "White Oak Flat Grain",
    abbrev: "White Oak FG",
    material: "both"
  },
  {
    id: 13,
    type: "woodSpecies",
    label: "Cherry",
    abbrev: "Cherry",
    material: "both"
  },
  {
    id: 12,
    type: "woodSpecies",
    label: "Sapele Mahogany",
    abbrev: "Sapele Mahogany",
    material: "both"
  },
  {
    id: 11,
    type: "woodSpecies",
    label: "African Mahogany",
    abbrev: "African Mahogany",
    material: "both"
  },
]

const tier5 = [
  {
    id: 20,
    type: "woodSpecies",
    label: "Stain-Grade Accoya",
    abbrev: "SG-Accoya",
    material: "wood"
  },
  {
    id: 19,
    type: "woodSpecies",
    label: "Paint-Grade Accoya",
    abbrev: "PG-Accoya",
    material: "wood"
  },
  {
    id: 18,
    type: "woodSpecies",
    label: "White Oak Rift Sawn",
    abbrev: "White Oak RS",
    material: "both"
  },
  {
    id: 17,
    type: "woodSpecies",
    label: "White Oak Quarter Sawn",
    abbrev: "White Oak QS",
    material: "both"
  },
  {
    id: 16,
    type: "woodSpecies",
    label: "Genuine Mahogany",
    abbrev: "Genuine Mahogany",
    material: "both"
  },
  {
    id: 15,
    type: "woodSpecies",
    label: "Walnut",
    abbrev: "Walnut",
    material: "both"
  },
]

const woodImgs = [
  {
    label: "Paint-Grade Bass",
    abbrev: "PG-Bass",
    imgLabel: "pgBass",
  },
  {
    label: "Stain-Grade Bass",
    abbrev: "SG-Bass",
    imgLabel: "sgBass",
  },
  {
    label: "Paint-Grade Pine",
    abbrev: "PG-Pine",
    imgLabel: "pgPine",
  },
  {
    label: "Stain-Grade Pine",
    abbrev: "SG-Pine",
    imgLabel: "sgPine",
  },
  {
    label: "Paint-Grade Vertical Douglas Fir",
    abbrev: "PGV-Douglas Fir",
    imgLabel: "pgDougFir",
  },
  {
    label: "Knotty Alder",
    abbrev: "Knotty Alder",
    imgLabel: "knottyAlder",
  },
  {
    label: "Red Oak",
    abbrev: "Red Oak",
    imgLabel: "redOak",
  },
  {
    label: "Stain-Grade Vertical Douglas Fir",
    abbrev: "SGV-Douglas Fir",
    imgLabel: "sgDougFir",
  },
  {
    label: "Clear Alder",
    abbrev: "Clear Alder",
    imgLabel: "alder",
  },
  {
    label: "African Mahogany",
    abbrev: "African Mahogany",
    imgLabel: "africanMahogany",
  },
  {
    label: "Sapele Mahogany",
    abbrev: "Sapele Mahogany",
    imgLabel: "sapeleMahogany",
  },
  {
    label: "Cherry",
    abbrev: "Cherry",
    imgLabel: "cherry",
  },
  {
    label: "White Oak Flat Grain",
    abbrev: "White Oak FG",
    imgLabel: "flatGrainWhite",
  },
  {
    label: "Walnut",
    abbrev: "Walnut",
    imgLabel: "walnut",
  },
  {
    label: "Genuine Mahogany",
    abbrev: "Genuine Mahogany",
    imgLabel: "mahogany",
  },
  {
    label: "White Oak Quarter Sawn",
    abbrev: "White Oak QS",
    imgLabel: "qsWhiteOak",
  },
  {
    label: "White Oak Rift Sawn",
    abbrev: "White Oak RS",
    imgLabel: "riftWhiteOak",
  },
  {
    label: "Paint-Grade Accoya",
    abbrev: "PG-Accoya",
    imgLabel: "pgAccoya",
  },
  {
    label: "Stain-Grade Accoya",
    abbrev: "SG-Accoya",
    imgLabel: "sgAccoya",
  },
]

const wood = [
  {
    id: 1,
    type: "woodSpecies",
    label: "Paint-Grade Bass",
    abbrev: "PG-Bass",
    imgLabel: "pgBass",
  },
  {
    id: 2,
    type: "woodSpecies",
    label: "Stain-Grade Bass",
    abbrev: "SG-Bass",
    imgLabel: "sgBass",
  },
  {
    id: 3,
    type: "woodSpecies",
    label: "Paint-Grade Pine",
    abbrev: "PG-Pine",
    imgLabel: "pgPine",
  },
  {
    id: 4,
    type: "woodSpecies",
    label: "Stain-Grade Pine",
    abbrev: "SG-Pine",
    imgLabel: "sgPine",
  },
  {
    id: 6,
    type: "woodSpecies",
    label: "Paint-Grade Vertical Douglas Fir",
    abbrev: "PGV-Douglas Fir",
    imgLabel: "pgDougFir",
  },
  {
    id: 7,
    type: "woodSpecies",
    label: "Knotty Alder",
    abbrev: "Knotty Alder",
    imgLabel: "knottyAlder",
  },
  {
    id: 8,
    type: "woodSpecies",
    label: "Red Oak",
    abbrev: "Red Oak",
    imgLabel: "redOak",
  },
  {
    id: 9,
    type: "woodSpecies",
    label: "Stain-Grade Vertical Douglas Fir",
    imgLabel: "sgDougFir",
    abbrev: "SGV-Douglas Fir",
  },
  {
    id: 10,
    type: "woodSpecies",
    label: "Clear Alder",
    abbrev: "Clear Alder",
    imgLabel: "alder",
  },
  {
    id: 11,
    type: "woodSpecies",
    label: "African Mahogany",
    abbrev: "African Mahogany",
    imgLabel: "africanMahogany",
  },
  {
    id: 12,
    type: "woodSpecies",
    label: "Sapele Mahogany",
    abbrev: "Sapele Mahogany",
    imgLabel: "sapeleMahogany",
  },
  {
    id: 13,
    type: "woodSpecies",
    label: "Cherry",
    abbrev: "Cherry",
    imgLabel: "cherry",
  },
  {
    id: 14,
    type: "woodSpecies",
    label: "White Oak Flat Grain",
    abbrev: "White Oak FG",
    imgLabel: "flatGrainWhite",
  },
  {
    id: 15,
    type: "woodSpecies",
    label: "Walnut",
    abbrev: "Walnut",
    imgLabel: "walnut",
  },
  {
    id: 16,
    type: "woodSpecies",
    label: "Genuine Mahogany",
    abbrev: "Genuine Mahogany",
    imgLabel: "mahogany",
  },
  {
    id: 17,
    type: "woodSpecies",
    label: "White Oak Quarter Sawn",
    abbrev: "White Oak QS",
    imgLabel: "qsWhiteOak",
  },
  {
    id: 18,
    type: "woodSpecies",
    label: "White Oak Rift Sawn",
    abbrev: "White Oak RS",
    imgLabel: "riftWhiteOak",
  },
];

const woodForWood = [
  {
    id: 4,
    label: "Paint-Grade Vertical Douglas Fir",
    abbrev: "PGV-Douglas Fir",
  },
  {
    id: 5,
    label: "Knotty Alder",
    abbrev: "Knotty Alder",
  },
  {
    id: 6,
    label: "Red Oak",
    abbrev: "Red Oak",
  },
  {
    id: 7,
    label: "Stain-Grade Vertical Douglas Fir",
    abbrev: "SGV-Douglas Fir",
  },
  {
    id: 8,
    label: "Clear Alder",
    abbrev: "Clear Alder",
  },
  {
    id: 9,
    label: "African Mahogany",
    abbrev: "African Mahogany",
  },
  {
    id: 10,
    label: "Sapele Mahogany",
    abbrev: "Sapele Mahogany",
  },
  {
    id: 11,
    label: "Cherry",
    abbrev: "Cherry",
  },
  {
    id: 12,
    label: "White Oak Flat Grain",
    abbrev: "White Oak FG",
  },
  {
    id: 13,
    label: "Walnut",
    abbrev: "Walnut",
  },
  {
    id: 14,
    label: "Genuine Mahogany",
    abbrev: "Genuine Mahogany",
  },
  {
    id: 15,
    label: "White Oak Quarter Sawn",
    abbrev: "White Oak QS",
  },
  {
    id: 16,
    label: "White Oak Rift Sawn",
    abbrev: "White Oak RS",
  },
  {
    id: 17,
    label: "Paint-Grade Accoya",
    abbrev: "PG-Accoya",
  },
  {
    id: 18,
    label: "Stain-Grade Accoya",
    abbrev: "SG-Accoya",
  },
];

export { wood, woodForWood, tier1, tier2, tier3, tier4, tier5, woodImgs };
