import React, { memo } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { boltColor } from "./defaults";
import { RowLayout } from "../../util/accordianStyles";

function ShootBoltAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const shootBoltColor = useStore((state) => state.quote.shootBoltColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[18].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    if (checked) {
      addToQuote({ shootBoltColor: "" });
    } else {
      addToQuote({ shootBoltColor: label });
      if (findRequired("Shoot Bolt Color")) removeRequired("Shoot Bolt Color");
    }
  };

  const variant = (label: string) => label === shootBoltColor ? 'filled' : 'outlined';

  const checkMark = (label: string) => label === shootBoltColor;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="shootBolt"
      heading="Shoot-Bolt Handle Color"
      secondaryHeading="Select desired shoot bolt color"
    >
      <RowLayout>
        {boltColor.map((state) => (
          <div key={state.id} onClick={() => handleClick(state.label)}>
            <Choices
              label={state.label}
              variant={variant(state.label)}
              checkMark={checkMark(state.label)}
              wide={true}
            />
          </div>
        ))}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(ShootBoltAccordian);
