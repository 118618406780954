import React, { useState, useEffect, FC } from "react";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import api from "../api/api";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StylesDivWrap = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: 10,
});

const StylesDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: 10,
});

const StyledButton = styled(Button)({
  margin: 5,
  color: "white",
});

interface FormNotesProps {
  formNotes: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isService?: boolean;
  serviceId?: string;
  role?: string;
}

const FormNotes: FC<FormNotesProps> = ({
  formNotes,
  handleChange,
  isService,
  serviceId,
  role,
}) => {
  const [showNotes, setShowNotes] = useState(false);

  const [notesAdded, setNotesAdded] = useState("");

  const handleClick = () => setShowNotes(!showNotes);

  const notesText = formNotes ? "View Private" : "Add Private";

  const addNotes = async () => {
    if (!serviceId || notesAdded === "Added") return;

    const body = {
      notes: formNotes,
      id: serviceId,
    };
    const note = await api.post("/api/addPrivateNote", body);

    if (note.data === "Added") setNotesAdded(note.data);
  };

  useEffect(() => {
    if (!notesAdded) return;

    let timeout: any;

    timeout = setTimeout(() => {
      setNotesAdded("");
    }, 1000);

    return () => clearTimeout(timeout);
  }, [notesAdded]);

  return (
    <>
      {!isService ? (
        <StylesDivWrap>
          <Button
            onClick={handleClick}
            color="customGrey"
            style={{ color: "white" }}
            variant="contained"
          >
            {showNotes ? "Hide" : notesText} Notes
          </Button>
        </StylesDivWrap>
      ) : (
        <StylesDivWrap>
          <Typography style={{ fontWeight: "bold" }}>Service Notes</Typography>
        </StylesDivWrap>
      )}
      <StylesDivCol>
        {(showNotes || isService) && (
          <TextareaAutosize
            value={formNotes}
            name="formNotes"
            onChange={handleChange}
            minRows={5}
            style={{ width: "30%" }}
            placeholder="Add Notes..."
            disabled={role === "Outside Sales" && isService}
          />
        )}

        {isService && role !== "Outside Sales" && (
          <StyledButton
            startIcon={notesAdded === "Added" ? <CheckCircleIcon /> : null}
            onClick={addNotes}
            size="small"
            variant="contained"
            style={{
              backgroundColor: notesAdded === "Added" ? "#4dae4f" : "#1976d2",
            }}
          >
            Add Service Notes
          </StyledButton>
        )}
      </StylesDivCol>
    </>
  );
};

export default FormNotes;
