import React, { FC, Dispatch, SetStateAction } from "react";
import {
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  Typography,
  Paper,
  Grow,
  Radio,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import { taggedSliderConfigs } from "../../taggedImages/taggedSliderConfigs";
import ReusableAccordian from "../ReusableAccordion";
import usePersistedState from "../../hooks/usePersistedState";
import fractionToInt from "../../util/fractionToInt";
import {
  sliderPocketOptions,
  sliderNonPocketOptions,
} from "../../initialValues/index";
import { useStore, useAccordion } from "../../store/store";
import { RowLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

interface SliderConfigProps {
  editQuote: boolean
  submitPressed: boolean
  sliderConfig: string
  setSliderConfig: Dispatch<SetStateAction<string>>
}

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 240,
  height: 20,
  position: "relative",
  top: -25,
}));

const StyledTypography = styled(Typography)({
  paddingRight: 20,
  fontWeight: "bold",
});

const StyledRowDivAbsolute = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: " center",
  position: "absolute",
  top: 70,
  right: 10,
});

const SliderConfigAccordian: FC<SliderConfigProps> = ({
  editQuote,
  submitPressed,
  sliderConfig,
  setSliderConfig,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const sliderP = useStore((state) => state.quote.sliderPocket);
  const isPocketing = sliderP === "With Pocket";

  const chosenConfig = useStore((state) => state.quote.exactConfig);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const sliderOverallWidth = fractionToInt(unitSize, unitSizeFraction);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[28].expanded);

  const options = isPocketing ? sliderPocketOptions : sliderNonPocketOptions;

  const [selectedSlider, setSelectedSlider] = usePersistedState(
    "selectedSlider",
    "",
    submitPressed,
    editQuote
  );

  const primaryRadio =
    chosenConfig?.includes("-") && chosenConfig?.split("-")[0].slice(-1) === "A"
      ? "Left"
      : chosenConfig?.includes("-") &&
        chosenConfig?.split("-")[1].slice(0, 1) === "A"
      ? "Right"
      : "";

  const borderColor = "#57d5f2";

  const handleChange = (event: SelectChangeEvent<string>) => {
    const val = event.target.value;
    setSliderConfig(val);

    if (val.includes("-")) {
      const findImg = taggedSliderConfigs.find(
        (option) => option.config === val
      );
      handleErrMsgChange("primaryDoorErr", "Select a Primary Door");

      addToQuote({
        exactConfigImg: findImg?.img1 || "",
        exactConfig: "",
      });
    } else {
      setSelectedSlider("");
      addToQuote({
        exactConfigImg: "",
        exactConfig: "",
      });
      handleErrMsgChange("primaryDoorErr", "");
    }
  };

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value === "Left") {
      const config =
        sliderConfig === "PXXP"
          ? "PX-XP"
          : sliderConfig === "OXXO"
          ? "OX-XO"
          : sliderConfig;
      const sep = config.split("-");
      const separate = sep[0].split("");
      separate.splice(-1, 1, "A");

      const primary = separate.join("") + "-" + sep[1];
      addToQuote({
        exactConfig: primary,
        stilesAndRails: "",
        stileCategory: "",
        addScreen: "",
        screenPocket: "",
        screenColor: "",
      });
      handleErrMsgChange("primaryDoorErr", "");
    }
    if (value === "Right") {
      const config =
        sliderConfig === "PXXP"
          ? "PX-XP"
          : sliderConfig === "OXXO"
          ? "OX-XO"
          : sliderConfig;
      const sep = config.split("-");
      const separate = sep[1].split("");
      separate.splice(0, 1, "A");

      const primary = `${sep[0]}-${separate.join("")}`;
      addToQuote({
        exactConfig: primary,
        stilesAndRails: "",
        stileCategory: "",
        addScreen: "",
        screenPocket: "",
        screenColor: "",
      });
      handleErrMsgChange("primaryDoorErr", "");
    }
  };

  const handleImgClick = (config: string, side: string, image: string) => {
    if (config.includes("-"))
      return null;
    let chosen;

    if (side === "left") {
      chosen = config.includes("OR")
        ? config.split("OR")[0].trim()
        : `${config} Left Primary`;
    }
    if (side === "right") {
      chosen = config.includes("OR")
        ? config.split("OR")[1].trim()
        : `${config} Right Primary`;
    }
    setSelectedSlider(side);

    if (chosen === chosenConfig) return;

    addToQuote({
      exactConfig: chosen,
      exactConfigImg: image,
      stilesAndRails: "",
      stileCategory: "",
      addScreen: "",
      screenPocket: "",
      addSlidingScreen: "",
      screenType: "",
    });
  };

  const findSliderOptions = () => {
    const copy2 = options.find(
      (item) =>
        sliderOverallWidth >= item.minWidth &&
        sliderOverallWidth <= item.maxWidth
    );

    if (copy2 && copy2.options.length) {
      return copy2.options;
    }

    return [];
  };

  const sliderConfigOptions = findSliderOptions();

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sliderConfig"
      heading="Configuration"
      secondaryHeading="Select a Configuration"
      required={false}
      required5={true}
      padding={true}
    >
      {sliderConfigOptions.length ? (
        <RowLayout
          style={{
            justifyContent: !sliderConfig ? "flex-start" : "space-around",
            width: !sliderConfig ? "50%" : "100%",
          }}
        >
          <RowLayout>
            {isPocketing ? (
              <StyledTypography>Pocketing Options: </StyledTypography>
            ) : (
              <StyledTypography>Non-Pocketing Options:</StyledTypography>
            )}

            <div style={{ position: "relative" }}>
              <div style={{ paddingLeft: 20 }}>
                <StyledFormControl required variant={"outlined"}>
                  <InputLabel>
                    Configuration
                  </InputLabel>

                  <Select
                    style={{ backgroundColor: "white" }}
                    value={sliderConfig}
                    onChange={handleChange}
                    name="configuration"
                    label="Configuration"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sliderConfigOptions.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>Required</FormHelperText>
                </StyledFormControl>
              </div>

              {(sliderConfig === "PXXP" ||
                sliderConfig === "OXXO" ||
                sliderConfig?.includes("-")) && (
                <StyledRowDivAbsolute>
                  <Typography style={{ fontWeight: "bold", paddingRight: 20 }}>
                    Primary Door:{" "}
                    <span style={{ color: "red", fontSize: 20 }}>*</span>
                  </Typography>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Radio
                          color="primary"
                          checked={primaryRadio === "Left"}
                          onChange={handleRadio}
                          value="Left"
                        />
                      }
                      label="Left"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="end"
                      control={
                        <Radio
                          color="primary"
                          checked={primaryRadio === "Right"}
                          onChange={handleRadio}
                          value="Right"
                        />
                      }
                      label="Right"
                      labelPlacement="end"
                    />
                  </FormControl>
                </StyledRowDivAbsolute>
              )}
            </div>
          </RowLayout>
          {sliderConfig && sliderConfigOptions.length && (
            <ColLayoutLeftDivider>
              {!chosenConfig && !sliderConfig?.includes("-") ? (
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Select an Exact Configuration
                </Typography>
              ) : !chosenConfig && sliderConfig?.includes("-") ? (
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Select a Primary Door
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{ color: borderColor, fontWeight: "bold" }}
                >
                  You Selected {chosenConfig}
                </Typography>
              )}

              <Typography variant="caption">
                (As Viewed from Exterior)
              </Typography>

              <Grow in={sliderConfig ? true : false}>
                <div>
                  {taggedSliderConfigs
                    .filter((item) => item.config === sliderConfig)
                    .map((config) => (
                      <RowLayout
                        key={config.config}
                        style={{ justifyContent: "space-between" }}
                      >
                        <Paper
                          style={{
                            border:
                              (selectedSlider === "left" ||
                                config.config?.includes("-")) ?
                              `3px solid ${borderColor}` : "",
                          }}
                          onClick={() =>
                            handleImgClick(config.config, "left", config.img1)
                          }
                          elevation={3}
                        >
                          <img src={config.img1} alt="slider" />
                        </Paper>

                        {config.img2 && (
                          <Paper
                            style={{
                              border:
                                selectedSlider === "right" ?
                                `3px solid ${borderColor}` : "",
                              marginLeft: 20,
                            }}
                            onClick={() =>
                              handleImgClick(
                                config.config,
                                "right",
                                config.img2
                              )
                            }
                            elevation={3}
                          >
                            <img src={config.img2} alt="slider 2" />
                          </Paper>
                        )}
                      </RowLayout>
                    ))}
                </div>
              </Grow>
            </ColLayoutLeftDivider>
          )}
        </RowLayout>
      ) : (
        <Typography>Enter a Width to View Configuration Options</Typography>
      )}
    </ReusableAccordian>
  );
};

export default SliderConfigAccordian;
