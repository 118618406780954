import React, { useState, FC } from "react";
import {
  MenuList,
  MenuItem,
  Menu,
  ListItemText,
  Typography,
} from "@mui/material";
import priceWithZeros from "../../util/priceWithZeros";
import getBasePrice from "../../util/getBasePrice";
import { AddonObj } from "../../util/quoteCartStorage";
import formatIntWithCommas from "../../util/formatIntWithCommas";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  position: "absolute",
  top: -25,
  width: 110,
});

const StyledTypography = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
});

interface ItemizedCostsProps {
  data: AddonObj[]
  quantity: number
  unitPrice: string
}

const ItemizedCosts: FC<ItemizedCostsProps> = ({ data, quantity, unitPrice }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const basePrice = getBasePrice(data, unitPrice, quantity);

  return (
    <StyledDiv>
      <StyledTypography variant="caption" onClick={handleClick}>
        Options Breakdown
      </StyledTypography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuList variant="menu" style={{ width: 300 }}>
          <MenuItem
            style={{ position: "relative", borderBottom: "2px solid black" }}
          >
            <ListItemText>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Base Price:
              </Typography>
            </ListItemText>
            <ListItemText style={{ position: "absolute", right: 10 }}>
              ${basePrice} {quantity > 1 && "ea."}
            </ListItemText>
          </MenuItem>
          {data.map((item) => (
            <MenuItem key={item.addon} style={{ position: "relative" }}>
              <ListItemText style={{ paddingRight: 10 }}>
                -{item.addon}
              </ListItemText>
              {item.price !== 0 && (
                <ListItemText style={{ position: "absolute", right: 10 }}>
                  ${formatIntWithCommas(priceWithZeros(item.price))}{" "}
                  {quantity > 1 && "ea."}
                </ListItemText>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </StyledDiv>
  );
};

export default ItemizedCosts;
