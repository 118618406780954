import React, { memo, useEffect } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { Typography } from "@mui/material";
import fractionToInt from "../../util/fractionToInt";
import Choices from '../Choices'
import pivotPointOptions from "./pivotPointOptions";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from 'cloudinary-react'
import InfoIcon from '@mui/icons-material/Info';
import { RowLayout } from "../../util/accordianStyles";

function PivotPointAccordian() { 
  const addToQuote = useStore((state) => state.addToQuote);

  const pivotPoint = useStore((state) => state.quote.pivotPoint);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);

  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore((state) => state.quote.swingPanelFraction);

  const overall = fractionToInt(swingPanelWidth || "", swingPanelFraction);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[35].expanded);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleClick = (label: string) => {
    const checked = label === pivotPoint;

    if(checked){
      addToQuote({ pivotPoint: "" }, "pivotPoint")
    }else{
      addToQuote({ pivotPoint: label })
      if(findRequired("Pivot Point")){
        removeRequired("Pivot Point")
      }
    }
  }

  const variant = (label: string) => label === pivotPoint ? 'filled' : 'outlined';

  const checkMark = (label: string) => label === pivotPoint;

  const pivots = pivotPointOptions(pivotHardware || "", overall);

  useEffect(() => {
    if(!pivots?.length || !overall) return;

    if(overall < 24) return

    const included = pivots.filter(p => p.label === pivotPoint);
    if(!included.length){
      addToQuote({ pivotPoint: "" })
    }
  }, [pivots]);

  const imgLabel = 
   !pivotPoint 
   ? "" 
   : pivotPoint.includes("Middle") 
   ? "Middle_bold" 
   : pivotPoint.includes("16") 
   ? "16_bold" 
   : pivotPoint.includes("12") 
   ? "12_bold" 
   : pivotPoint.includes("8") 
   ? "8_bold" 
   : "4.75_bold";

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="pivot"
      heading="Pivot Point"
      secondaryHeading="Select pivot point (inches)"
      colOrRow="col"
    >
      {overall && pivotHardware && pivots?.length ?
      <>
      <RowLayout style={{paddingBottom: 20}}>
        <InfoIcon color="primary" style={{marginRight: 10}} />

        <Typography>
            The pivot axis point is the vertical point around which the door swings, enabling its opening and closing motion.
          </Typography>
      </RowLayout>
        
        {/* //remove 4 3/4 pivot axis from fristjurgens because we need enough room for dowels */}
        <RowLayout>
          {pivotHardware === "Fritsjurgens" ?
          pivots.filter(itm => itm.label !== '4-3/4" Pivot Axis Point').map(state => (
            <div key={state.id} onClick={() => handleClick(state.label)}>
              <Choices 
                label={state.label}
                variant={variant(state.label)}
                checkMark={checkMark(state.label)}
                wide={true}
              />
            </div>
          ))
          : pivots.map(state => (
            <div key={state.id} onClick={() => handleClick(state.label)}>
              <Choices 
                label={state.label}
                variant={variant(state.label)}
                checkMark={checkMark(state.label)}
                wide={true}
              />
            </div>
          ))}
        </RowLayout>
      </>
      :
        <Typography>Enter a Frame Width and Pivot Hardware</Typography>
      }

      {pivotPoint &&
        <Image 
          cloudName="ag-millworks"
          secure="true"
          publicId={`/Pivot_Axis/${imgLabel}`}
        />
      }
    </ReusableAccordian>
  );
}

export default memo(PivotPointAccordian);
