import { convertDecimalToFraction } from "../../../util/pdfFormulas/convertDecimalToFraction";

const width2_375 = (width: number) => convertDecimalToFraction(width - 2.375); 

const width2_25 = (width: number) => convertDecimalToFraction(width - 2.25);

const width2_8125 = (width: number) => convertDecimalToFraction(width - 2.8125);

const width1_8125 = (width: number) => convertDecimalToFraction(width - 1.8125);

const width1_75 = (width: number) => convertDecimalToFraction(width - 1.75);

export {
    width1_75,
    width1_8125,
    width2_8125,
    width2_25,
    width2_375
}