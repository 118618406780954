import round from "./round";
import formatIntWithCommas from "./formatIntWithCommas";
import priceWithZeros from "./priceWithZeros";

interface AddCostsObj {
    title: string,
    price: number,
    taxable: boolean,
}

export function taxableCostsTotal(costs: AddCostsObj[]): number{
    const total = 
      costs.length === 1 
        ? Number(costs[0].price)
        : costs.reduce((a, b) => a + Number(b.price), 0);

    return total
}

export function calcSalesTaxDollar(
  salesTax: number,
  retailCost: string,
  addCosts: AddCostsObj[],
  marginMarkup: string | number,
  withMarkup: boolean,
  adminCharge: number | null,
  customCladPrice?: number
) {
    if(!retailCost || !salesTax) return '';

    let costsTotal: number = 0;
    const taxableCosts: AddCostsObj[] = addCosts.filter(itm => itm.taxable);

    if(taxableCosts.length){
        costsTotal = taxableCostsTotal(taxableCosts);
    }

    let retail: number = parseFloat(retailCost.replace(",", ''));

    if(adminCharge){
        retail += Number(adminCharge)
    }

    let salesTaxDollarAmount: number = 0

    var salesTaxMult: number = 1 + salesTax;

    if(!marginMarkup){
      const customClad: number = customCladPrice ? Number(customCladPrice) : 0;
        const beforeSalesTax: number = retail + costsTotal + customClad;
        const afterSalesTax: number = beforeSalesTax * salesTaxMult;
        salesTaxDollarAmount = afterSalesTax - beforeSalesTax;
    }else{
        if(withMarkup){
            const markupInt: number = 
              typeof marginMarkup === "string" 
                ? (parseFloat(marginMarkup) / 100) + 1
                : (1 - marginMarkup) + 1;

            const customClad: number = customCladPrice ? Number(customCladPrice) * markupInt : 0;

            const markup: number = retail * markupInt;
            const markupWithAddCosts: number = markup + costsTotal + customClad;
            salesTaxDollarAmount = (markupWithAddCosts * salesTaxMult) - markupWithAddCosts;
        }else{
            const marginInt: number = 
              typeof marginMarkup === "string" 
                ? (1 - parseFloat(marginMarkup) / 100)
                : marginMarkup;

            const customClad = customCladPrice ? Number(customCladPrice) / marginInt : 0;
            
            const margin: number = retail / marginInt;
            const marginWithAddCosts: number = margin + costsTotal + customClad;
            salesTaxDollarAmount = (marginWithAddCosts * salesTaxMult) - marginWithAddCosts;
        }
    }

    if(!salesTaxDollarAmount) return "";

    const formatted: string = formatIntWithCommas(
        priceWithZeros(
            round(salesTaxDollarAmount)
        )
    );

    return '$' + formatted
}

export function calcDealerTaxDollar(
  salesTax: number,
  retailCost: string,
  cladCost: number,
  adminCharge: number
): string {
  const tax: number = 1 + salesTax / 100;
  const customCladCharge: number = cladCost || 0;
  const extraCharge: number = adminCharge || 0;

  const beforeTax: number =
    parseFloat(retailCost.replace(",", "")) + customCladCharge + extraCharge;
  const afterTax: number = beforeTax * tax;
  const salesTaxDollarAmount: number = afterTax - beforeTax;

  const formatted: string = formatIntWithCommas(
    priceWithZeros(round(salesTaxDollarAmount))
  );

  return "$" + formatted;
}