import round from "../../util/round";
import formatIntWithCommas from "../../util/formatIntWithCommas";

function calcSuggestedRetail(
  price: string,
  margin: number,
  withMarkup: boolean,
  deliveryCharge?: number
) {
  if (!margin) return price;
  const delivery = deliveryCharge ? Number(deliveryCharge) : 0;

  var customerFullPrice = price.replace(/\,/g, "");
  var cfp = parseFloat(customerFullPrice);

  const totalVal = !withMarkup
    ? cfp / margin + delivery
    : cfp * (1 - margin + 1) + delivery;

  var srpWithMargin = formatIntWithCommas(round(totalVal));

  if (srpWithMargin.includes(".") && srpWithMargin.split(".")[1].length === 1) {
    return `${srpWithMargin}0`;
  } else if (!srpWithMargin.includes(".")) {
    return `${srpWithMargin}.00`;
  }

  return srpWithMargin;
}

export { calcSuggestedRetail };
