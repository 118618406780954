import React, { FC } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDivHeader = styled("div")({
  backgroundColor: "#04bff3",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  border: "1px solid grey",
  margin: 20,
});

const StyledTypography = styled(Typography)({
  padding: 5, fontWeight: "bold"
})

interface HeaderProps {
  text: string;
}

const Header: FC<HeaderProps> = ({ text }) => {
  return (
    <StyledDivHeader>
      <StyledTypography variant="body1">
        {text}
      </StyledTypography>
    </StyledDivHeader>
  );
};

export default Header;
