import React, { FC } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./Styles";
import { QuoteObj } from "../../GlobalTypes";
import "../fontRegister"

interface Page1Props {
  data: QuoteObj;
  poNumber: string;
  isProduction?: boolean;
  isCustomer?: boolean;
  region?: string;
}

const Page1: FC<Page1Props> = ({
  data,
  poNumber,
  isProduction,
  isCustomer,
  region,
}) => {
  const signline = "${signfield:1:y:______}";
  const dateSigned = "${datefield:1:y:Date_Signed}";

  const lt = data.customLeadTime
    ? data.customLeadTime
    : data.leadTime && data.leadTime !== "false"
    ? data.leadTime
    : "N/A";

  const isOrder = data.dateOrdered && data.status !== "Quote" ? true : false;

  const depositRecieved = isOrder && data.depositReceivedDate ? true : false;

  const fullAddress =
    data.address && data.city && data.state && data.zip ? true : false;

  const estimatedDeliveryDate = !isProduction
    ? null
    : region === "SoCal" && isOrder && data.estDeliveryDate
    ? new Date(data.estDeliveryDate).toLocaleDateString("en-US")
    : null;

  const hasJobsiteContact =
    data.checkedJobsite && data.jobsiteContact && data.jobsiteContactNumber
      ? true
      : false;

  return (
    <>
      <View style={styles.rowCenterMargin}>
        <Image
          style={{ width: 400, height: 200 }}
          src="https://res.cloudinary.com/ag-millworks/image/upload/ImageBanner/bifolds/bifold_img1.jpg"
        />
      </View>
      <View style={styles.colStartMargin}>
        <View style={styles.infoBox}>
          <Text style={styles.bigBold}>Job Details</Text>
        </View>
        <View style={styles.rowStartMargin}>
          <View style={styles.colWithMargin}>
            <Text style={styles.page1TextLeft}>Project Name:</Text>
            <Text style={styles.page1TextLeft}>PO Number:</Text>
            {!isCustomer ? (
              <Text style={styles.page1TextLeft}>AG Sales Rep:</Text>
            ) : (
              <Text />
            )}
            <Text style={styles.page1TextLeft}>Lead Time:</Text>

            {isOrder ? (
              <Text style={styles.page1TextLeft}>Sign-Off Date:</Text>
            ) : (
              <Text />
            )}
            {estimatedDeliveryDate ? (
              <Text style={styles.page1TextLeft}>Estimated Delivery:</Text>
            ) : (
              <Text />
            )}
          </View>
          <View style={styles.colCentermargin}>
            <Text style={styles.page1TextRight}>{data.jobName || "None"}</Text>
            <Text style={styles.page1TextRight}>{poNumber || "None"}</Text>
            {!isCustomer ? (
              <Text style={styles.page1TextRight}>
                {data.agSalesperson || "None"}
              </Text>
            ) : (
              <Text></Text>
            )}
            <Text style={styles.page1TextRight}>{lt}</Text>
            {depositRecieved ? (
              <View style={styles.rowStart}>
                <Text style={[styles.page1TextRight, { paddingRight: 40 }]}>
                  {new Date(data.dateOrdered || "").toLocaleDateString("en-US")}
                </Text>
                <Text style={[styles.page1TextLeft, { paddingRight: 20 }]}>
                  {" "}
                  Deposit Date:
                </Text>
                <Text style={styles.page1TextRight}>
                  {new Date(data.depositReceivedDate || "").toLocaleDateString(
                    "en-US",
                    { timeZone: "UTC" }
                  )}
                </Text>
              </View>
            ) : isOrder && !depositRecieved ? (
              <Text style={styles.page1TextRight}>
                {new Date(data.dateOrdered || "").toLocaleDateString("en-US")}
              </Text>
            ) : (
              <Text />
            )}

            {estimatedDeliveryDate ? (
              <Text style={styles.page1TextRight}>{estimatedDeliveryDate}</Text>
            ) : (
              <Text />
            )}
          </View>
        </View>
      </View>

      <View style={styles.colStartMargin2}>
        <View style={styles.infoBox}>
          <Text style={styles.bigBold}>Dealer Information</Text>
        </View>
        <View style={styles.rowStartMargin}>
          <View style={styles.colCenterPad}>
            <Text style={styles.page1TextLeft}>Dealer:</Text>
            <Text style={styles.page1TextLeft}>Name:</Text>
            <Text style={styles.page1TextLeft}>Email:</Text>
            <Text style={styles.page1TextLeft}>Phone:</Text>
          </View>
          <View style={styles.colCentermargin}>
            <Text style={styles.page1TextRight}>{data.dealer || "None"}</Text>
            <Text style={styles.page1TextRight}>
              {data.salesperson || "None"}
            </Text>
            <Text style={styles.page1TextRight}>{data.email || "None"}</Text>
            <Text style={styles.page1TextRight}>{data.phone || "None"}</Text>
          </View>
        </View>
      </View>

      {!isProduction && (
        <View style={styles.colStartMargin2}>
          <View style={styles.infoBox}>
            <Text style={styles.bigBold}>Customer Information</Text>
          </View>
          <View style={styles.rowStartMargin}>
            <View style={styles.colCenterPad}>
              <Text style={styles.page1TextLeft}>Name:</Text>
              <Text style={styles.page1TextLeft}>Email:</Text>
              <Text style={styles.page1TextLeft}>Phone:</Text>
            </View>
            <View style={styles.colCentermargin}>
              <Text style={styles.page1TextRight}>
                {data.customerName && data.customerName.trim()
                  ? data.customerName
                  : "None"}
              </Text>
              <Text style={styles.page1TextRight}>
                {data.customerEmail || "None"}
              </Text>
              <Text style={styles.page1TextRight}>
                {data.customerPhone || "None"}
              </Text>
            </View>
          </View>
        </View>
      )}

      <View style={styles.colStartMargin2}>
        <View style={styles.infoBox}>
          <Text style={styles.bigBold}>Shipping Information</Text>
        </View>
        <View style={styles.rowStartMargin}>
          <View style={styles.colCenterPadRight}>
            <Text style={styles.page1TextLeft}>Ship to:</Text>
            <Text style={styles.page1TextLeft}>Address:</Text>
            {hasJobsiteContact ? (
              <>
                <Text style={styles.page1TextLeft}>JS Contact:</Text>
                <Text style={styles.page1TextLeft}>JS Contact #:</Text>
              </>
            ) : (
              <Text />
            )}
          </View>
          <View style={styles.colCentermargin}>
            {data.checkedDealer ? (
              <Text style={styles.page1TextRight}>Dealer</Text>
            ) : data.checkedJobsite ? (
              <Text style={styles.page1TextRight}>
                Jobsite{" "}
                <Text style={{ fontFamily: "Roboto", fontSize: 9 }}>
                  (minimum of two people required for hand unloading)
                </Text>
              </Text>
            ) : (
              <Text style={styles.text}>Not Specified</Text>
            )}
            <Text style={styles.page1TextRight}>
              {fullAddress
                ? `${data.address} ${data.city}, ${data.state} ${data.zip}`
                : "Incomplete"}
            </Text>
            {hasJobsiteContact ? (
              <>
                <Text style={styles.page1TextRight}>{data.jobsiteContact}</Text>
                <Text style={styles.page1TextRight}>
                  {data.jobsiteContactNumber}
                </Text>
              </>
            ) : null}
          </View>
        </View>
      </View>

      <View style={styles.fixedSign}>
        <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
        <View style={{ position: "relative" }}>
          <Text style={styles.dateSigned}>{dateSigned}</Text>
        </View>
      </View>
    </>
  );
};

export default Page1;
