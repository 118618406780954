import React, { useState, FC } from "react";
import { Typography, Button, Modal, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useQueryClient } from "react-query";
import getModalStyle from "../../util/getModalStyle";
import { postCustomSalesTax } from "../../api/OrderSummaryApi";
import {
  StyledModalDiv,
  RowLayout,
  ColLayout,
} from "../../util/accordianStyles";

const CustomSalesTax: FC<{ quoteId: string }> = ({ quoteId }) => {
  const queryClient = useQueryClient();

  const [customTax, setCustomTax] = useState<null | string>(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleCustomTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const withDecimal = value.includes(".");

    if (withDecimal) {
      const decimalPlaces = value.split(".")[1].length;
      if (decimalPlaces > 3) return;
    } else {
      if (value.length > 2) return;
    }

    setCustomTax(value);
  };

  const changeSalesTax = async () => {
    if (!customTax) return;

    const salesTax = parseFloat(customTax) / 100;
    const response = await postCustomSalesTax(salesTax, quoteId);

    if (response.data === "Sales Tax Changed") {
      queryClient.invalidateQueries("Cart");
      handleClose();
    }
  };

  return (
    <>
      <TaxModal
        open={open}
        handleClose={handleClose}
        customTax={customTax}
        handleCustomTax={handleCustomTax}
        changeSalesTax={changeSalesTax}
      />

      <Tooltip title="Edit Sales Tax" enterDelay={500} placement="top">
        <div>
          <EditIcon
            style={{ fontSize: 20 }}
            color="primary"
            onClick={handleOpen}
          >
            Edit
          </EditIcon>
        </div>
      </Tooltip>
    </>
  );
};

export default CustomSalesTax;

interface TaxModalProps {
  open: boolean
  handleClose: () => void
  customTax: string | null
  handleCustomTax: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeSalesTax: () => void
}

const TaxModal: FC<TaxModalProps> = ({
  open,
  handleClose,
  customTax,
  handleCustomTax,
  changeSalesTax,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <ColLayout>
        <h2 id="simple-modal-description">Sales Tax</h2>

        <RowLayout style={{ paddingBottom: 20 }}>
          <Typography variant="subtitle2" style={{ paddingRight: 10 }}>
            Enter Sales Tax:
          </Typography>
          <input
            placeholder="e.g. 9.775"
            step="any"
            type="number"
            value={customTax || ""}
            onChange={handleCustomTax}
            style={{ padding: 5, width: 100 }}
          />
        </RowLayout>

        <RowLayout>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginRight: 10 }}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={changeSalesTax}
            color="primary"
            variant="contained"
            style={{ marginLeft: 10 }}
          >
            Confirm
          </Button>
        </RowLayout>
      </ColLayout>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};
