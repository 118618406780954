import React, { useState, FC } from "react";
import getModalStyle from "../../../../util/getModalStyle";
import {
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
} from "@mui/material";
import { UseMutationResult } from "react-query"
import { AdminOrderNumsState } from "../QuotesTable";
import { StyledModalDiv } from "../../../../util/accordianStyles";

function getPlaceholder(name: string) {
  switch (name) {
    case "salesOrder":
      return "Enter Sales Order";
    case "invoiceNumber":
      return "Enter Invoice Number";
    case "depositDate":
      return "Enter Deposit Recieved";
    default:
      return "Enter Glass Ordered";
  }
}

interface AdminOrderModalProps {
  handleClose: () => void
  modalText: string
  open: boolean
  mutation: UseMutationResult<void, unknown, void, unknown>
  value: AdminOrderNumsState | {}
  name: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  soMatch: boolean
}

const AdminOrderModal: FC<AdminOrderModalProps> = ({
  handleClose,
  modalText,
  open,
  mutation,
  value,
  name,
  handleChange,
  soMatch,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3 id="simple-modal-description" style={{ marginBottom: 40 }}>
        {modalText}
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          {name === "invoiceNumber" && (
            <Typography style={{ paddingRight: 20 }}>
              Invoice Number:{" "}
            </Typography>
          )}

          <TextField
            label={getPlaceholder(name)}
            value={
              name === "salesOrder" && "salesOrder" in value
                ? value.salesOrder
                : name === "invoiceNumber" && "invoiceNumber" in value
                ? value.invoiceNumber
                : name === "depositDate" && "depositDate" in value
                ? value.depositDate
                : "glassOrderDate" in value
                ? value.glassOrderDate
                : ""
            }
            type={
              name === "depositDate" || name === "glassOrderDate"
                ? "date"
                : "text"
            }
            name={name}
            onChange={handleChange}
            style={{ width: 180 }}
            size="small"
            variant="outlined"
          />
        </div>

        {name === "invoiceNumber" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Typography style={{ paddingRight: 20 }}>Invoice Date: </Typography>
            <TextField
              value={"invoiceDate" in value ? value.invoiceDate : ""}
              type="date"
              name={"invoiceDate"}
              onChange={handleChange}
              size="small"
              variant="outlined"
            />
          </div>
        )}

        {soMatch && name === "salesOrder" && (
          <div style={{ display: "flex", padding: 10 }}>
            <Typography>
              Sales Order already exists do you want to add anyway?
            </Typography>
          </div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        {mutation.isLoading ? (
          <Button variant="contained" color="primary">
            <CircularProgress color="inherit" size={30} />
          </Button>
        ) : (
          <Button
            onClick={() => mutation.mutate()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        )}
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default AdminOrderModal;
