import React, { useState, FC, SyntheticEvent } from "react";
import { MoreVert, FileCopy, GridOn, Extension } from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";

const hasAccess = (role: string) => role === "Admin" || role === "Inside Sales";

interface DotsProps {
  loading: boolean
  openModal: (modal: string) => void
  duplicateUnit: (unitId: string) => void
  unitId: string
  doorType: string
  openModalKD: () => void
  kd: boolean
  status: string
}

const Dots: FC<DotsProps> = ({
  loading,
  openModal,
  duplicateUnit,
  unitId,
  doorType,
  openModalKD,
  kd,
  status,
}) => {
  const [anchor, setAnchor] = useState<any>(null);
  const open = Boolean(anchor);

  const handleClick = (event: SyntheticEvent) => setAnchor(event.currentTarget);

  const handleClose = () => setAnchor(null);

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  return <>
    <IconButton onClick={handleClick} size="large">
      <MoreVert />
    </IconButton>
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchor}
    >
      {!loading ? (
        <div>
          <MenuItem onClick={() => duplicateUnit(unitId)}>
            <FileCopy
              color="primary"
              style={{ fontSize: 20, marginRight: 10 }}
            />
            Duplicate
          </MenuItem>

          {role === "Outside Sales" &&
          status !== "Quote" && status !== "Archived" ? null 
          : doorType === "Swing" ? null 
          : doorType === "Bi-fold Zero Corner" && role !== "Inside Sales" && role !== "Admin" ? null
          : (
            <MenuItem onClick={() => openModalKD()}>
              <Extension
                color="primary"
                style={{ fontSize: 20, marginRight: 10 }}
              />
              {kd ? "Remove Knock Down" : "Knock Down"}
            </MenuItem>
          )}

          {hasAccess(role) && (
            <MenuItem onClick={() => openModal("glass")}>
              <GridOn style={{ fontSize: 20, marginRight: 10 }} />
              Custom Glass
            </MenuItem>
          )}
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          <CircularProgress size={30} />
        </div>
      )}
    </Menu>
  </>;
};

export default Dots;
