import React, { useState, FC, Dispatch, SetStateAction } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, FormGroup, Switch, FormControlLabel } from "@mui/material";
import getModalStyle from "../../../../util/getModalStyle";
import { setAppointment } from "../../../../api/ServiceApi";
import { styled } from "@mui/material/styles";
import { StyledModalDiv } from "../../../../util/accordianStyles";

const StyledDivRowSpace = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: 20,
  height: 200,
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
});

const StyledDivRowStart = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: 5,
  paddingTop: 20,
});

const StyledDivRowStart2 = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: 45,
  padding: 5,
});

const StyledInput = styled("input")({
  padding: 5,
  marginLeft: 10,
});

const StyledDivFlexCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 40,
});

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export function formatAppointmentDate(date: string, time: string) {
  const aptDate = new Date(date.replace(/-/g, "/")).toLocaleDateString("en-US");

  var timeChosen: string | string[] = time;

  timeChosen = timeChosen.split(":"); // convert to array

  var hours = Number(timeChosen[0]);
  var minutes = Number(timeChosen[1]);

  var timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours === 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  timeValue += hours >= 12 ? " P.M." : " A.M."; // get AM/PM

  return `${aptDate} ${timeValue}`;
}

interface AptChangeProps {
  handleClose: () => void 
  open: boolean
  serviceId: string 
  setSearch: Dispatch<SetStateAction<boolean>>
}

const AptChangeModal: FC<AptChangeProps> = ({ handleClose, open, serviceId, setSearch }) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [toggleEmail, setToggleEmail] = useState(false);

  const [err, setErr] = useState("");

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => setToggleEmail(e.target.checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, state: "date" | "time") => {
    state === "date" && setDate(event.target.value);
    state === "time" && setTime(event.target.value);
  };

  const handleSubmit = async () => {
    if (!date || !time) {
      setErr("Must Enter a Date and Time");
      return;
    } else {
      setIsLoading(true);
      const apptDate = formatAppointmentDate(date, time);

      try {
        const response = await setAppointment(serviceId, apptDate, toggleEmail);

        if (response.data === "Success") {
          setIsLoading(false);
          setErr("");
          setSearch((prev) => !prev);
          handleClose();
        }

        if (response.data !== "Success") {
          setIsLoading(false);
          setErr(response.data);
        }
      } catch (err) {
        setIsLoading(false);
        setErr("A Problem Occurred");
      }
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle}>
      <StyledDiv>
        <StyledDivRowSpace>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Add Appointment
          </Typography>

          <StyledDivRowStart>
            <Typography style={{ fontWeight: "bold" }}>Date: </Typography>

            <StyledInput
              type="date"
              value={date}
              onChange={(e) => handleChange(e, "date")}
            />
          </StyledDivRowStart>

          <StyledDivRowStart2>
            <Typography style={{ fontWeight: "bold" }}>Time: </Typography>

            <StyledInput
              type="time"
              value={time}
              onChange={(e) => handleChange(e, "time")}
            />
          </StyledDivRowStart2>
        </StyledDivRowSpace>
      </StyledDiv>

      <StyledDivFlexCenter>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={toggleEmail}
                onChange={handleToggle}
              />
            }
            label="Send Email"
          />
        </FormGroup>
      </StyledDivFlexCenter>

      {err && (
        <Typography style={{ color: "red", padding: 20 }}>{err}</Typography>
      )}

      <StyledDivRow>
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        {isLoading ? (
          <Button variant="contained" size="large" color="primary">
            <CircularProgress color="inherit" size={30} />
          </Button>
        ) : (
          <Button
            size="large"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        )}
      </StyledDivRow>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default AptChangeModal;
