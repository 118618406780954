import React, { useEffect, memo, useMemo, FC } from "react";
import { Alert, Typography } from "@mui/material";
import Choices from "./Choices";
import ReusableAccordian from "./ReusableAccordion";
import { useStore, useAccordion } from "../store/store";
import { useRequiredMsgs } from "../store/requiredMsgs";
import { Image, Transformation } from "cloudinary-react";
import fractionToInt from "../util/fractionToInt";
import {
  screenLayoutImages,
  screenColors,
  singleScreenLayout,
  comboScreenLayout,
  layoutLeft,
  layoutRight,
  addCentorScreens,
  shadeColors,
  shadeLayout,
} from "../initialValues/screens";
import { RowLayout, ColLayout } from "../util/accordianStyles";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const RowLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
  alignSelf: "flex-end",
  marginTop: 20,
}));

interface CentorScreenAccordionProps {
  doorType: string
  width: number
  widthRight: number
}

const CentorScreenAccordian: FC<CentorScreenAccordionProps> = ({ doorType, width, widthRight }) => {
  const theme = useTheme();
  const addToQuote = useStore((state) => state.addToQuote);

  const screen = useStore((state) => state.quote.addScreen);
  const screenVal = screen && screen.replace(" (upcharge applies)", "");

  const screenLayout = useStore((state) => state.quote.screenLayout);
  const screenColorChoice = useStore((state) => state.quote.screenColor);
  const shadeMeshColor = useStore((state) => state.quote.shadeMeshColor);
  const cornerType = useStore((state) => state.quote.cornerType);
  const sillStyle = useStore((state) => state.quote.sillStyle);

  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const heightInt = fractionToInt(height, heightFraction);

  const swingType = useStore((state) => state.quote.swingType);

  const exactConfig = useStore((state) => state.quote.exactConfig);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const centorWidthSub = 1;

  const centorWidth = width - centorWidthSub;
  const centorHeight = sillStyle?.includes("Ultra")
    ? // ? heightInt + 1.2417
      heightInt + 1.1875
    : heightInt - 0.5625

  const widerThan16 = centorWidth > 181;

  const oneDirectionConfig =
    doorType === "Swing" &&
    swingType === "Single Swing" &&
    exactConfig.slice(-2) === "LH"
      ? "sL"
      : doorType === "Swing" &&
        swingType === "Single Swing" &&
        exactConfig.slice(-2) === "RH"
      ? "sR"
      : doorType.includes("Bi-fold") && exactConfig.length === 2
      ? exactConfig
      : "";

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const accordion = useAccordion((state) => state.expanded[25]);

  const isCornerUnit = doorType?.includes("Corner");

  useEffect(() => {
    if (screenVal !== "Add Shade" && screenVal !== "Add Screen") return;

    if (oneDirectionConfig && oneDirectionConfig[1]) {
      defaultLayout();
    }
  }, [screenVal, exactConfig]);

  const defaultLayout = () => {
    if (!oneDirectionConfig || !oneDirectionConfig[1]) return;

    if (oneDirectionConfig[1] === "L") {
      screenVal === "Add Shade"
        ? addToQuote({ screenLayout: "Left Shade" })
        : addToQuote({ screenLayout: "Single Compress Left" });
    }
    if (oneDirectionConfig[1] === "R") {
      screenVal === "Add Shade"
        ? addToQuote({ screenLayout: "Right Shade" })
        : addToQuote({ screenLayout: "Single Compress Right" });
    }
  };

  const handleAddScreenClick = (label: string) => {
    const checked = label === screenVal;

    handleErrMsgChange("jambErr", "");

    if (checked) {
      addToQuote(
        {
          addScreen: "",
          jamb: "",
        },
        "addScreen"
      );
    } else {
      addToQuote(
        {
          addScreen: label,
          jamb: "",
        },
        "addScreen"
      );
    }
  };

  const handleClick = (label: string, choice: string, key: string, reqKey: string) => () => {
    const checked = label === choice;

    if (checked) {
      addToQuote({ [key]: "" });
    } else {
      addToQuote({ [key]: label });

      if (findRequired(reqKey)) removeRequired(reqKey);
    }
  };

  var centor =
    (doorType === "Bi-fold" || doorType === "Bi-fold Window") &&
    exactConfig.includes("L") &&
    exactConfig.includes("R") &&
    centorWidth <= 165
      ? addCentorScreens
      : isCornerUnit && (centorWidth > 165 || widthRight - centorWidthSub > 165)
      ? addCentorScreens.filter((itm) => itm.label.includes("Double Screen"))
      : widerThan16 || isCornerUnit
      ? addCentorScreens.filter((itm) => itm.label.includes("Double"))
      : !widerThan16 && centorWidth > 167
      ? addCentorScreens.filter((itm) => !itm.label.includes("Combo"))
      : // : !widerThan16 && !isSwingPair
        // ? addCentorScreens.filter((itm) => !itm.label.includes("Double"))
        addCentorScreens;

  if (centorHeight >= 114) {
    centor = centor.filter(
      (itm) => !itm.label.includes("Combo") && !itm.label.includes("Shade")
    );
  }

  const displayedImage = useMemo(() => {
    if (!screenVal) return "";

    if (isCornerUnit) {
      let img =
        cornerType === "Inside"
          ? "Double Screen Inside Corner"
          : "Double Screen Outside Corner";

      if (screenVal === "Add Double Shade") {
        img = `${img} Shade`;
      }
      return img;
    }

    if (screenVal.includes("Combo")) {
      if (screenLayout === "Left Shade/Right Screen") {
        return "Combo Screen Left";
      }

      if (screenLayout === "Right Shade/Left Screen") {
        return "Combo Screen Right";
      }

      return "";
    }

    if (screenVal === "Add Double Shade") {
      return "Double Shade";
    }

    if (screenVal === "Add Double Screen") {
      return "Double Screen";
    }

    if (screenVal === "Add Shade") {
      if (screenLayout === "Left Shade") {
        return "Shade Left";
      }

      if (screenLayout === "Right Shade") {
        return "Shade Right";
      }
      return "";
    }

    if (oneDirectionConfig && oneDirectionConfig[1]) {
      if (oneDirectionConfig[1] === "L") {
        return "Single Screen Compress Left";
      }
      if (oneDirectionConfig[1] === "R") {
        return "Single Screen Compress Right";
      }
    }

    if (screenLayout?.includes("Left")) {
      return "Single Screen Compress Left";
    }

    if (screenLayout?.includes("Right")) {
      return "Single Screen Compress Right";
    }
  }, [screenVal, oneDirectionConfig, doorType, cornerType, screenLayout]);

  const hasShade =
    screenVal === "Add Shade" ||
    screenVal === "Add Double Shade" ||
    screenVal === "Add Combo Screen/Shade"
      ? true
      : false;

  return (
    <ReusableAccordian
      expanded={accordion.expanded}
      handleAccordClick={handleAccordClick}
      accordionName="screen"
      heading="Centor Screen"
      secondaryHeading="Select Screen Option"
      required={accordion.require ? true : false}
    >
      {isCornerUnit && (widerThan16 || widthRight - centorWidthSub > 181) ? (
        <Alert severity="error">
          Centor Screens not available if width is greater than 182 inches
        </Alert>
      ) : !isCornerUnit && centorWidth > 354 ? (
        <Alert severity="error">
          Centor Screens not available if width is greater than 355 inches
        </Alert>
      ) : (
        <ColLayout>
          <Typography variant="subtitle1">
            *upcharge applies to all screens/shades
          </Typography>

          {!screenVal ? (
            <RowLayout>
              {centor.map((state) => (
                <div
                  key={state.id}
                  onClick={() => handleAddScreenClick(state.label)}
                >
                  <Choices
                    label={state.label}
                    variant={state.label === screenVal ? "filled" : "outlined"}
                    checkMark={state.label === screenVal}
                    wide={true}
                  />
                </div>
              ))}
            </RowLayout>
          ) : (
            <>
              <RowLayout>
                {centor.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleAddScreenClick(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === screenVal ? "filled" : "outlined"
                      }
                      checkMark={state.label === screenVal}
                      wide={true}
                    />
                  </div>
                ))}
              </RowLayout>

              <RowLayout>
                {screenVal && !screenVal.includes("Double") && (
                  <ColLayout>
                    <Typography variant="subtitle1">
                      Screen Layout
                      <span style={{ color: "red", fontSize: 22 }}>*</span>
                    </Typography>

                    <RowLayoutSpacing style={{ paddingTop: 0 }}>
                      {screenVal === "Add Combo Screen/Shade"
                        ? comboScreenLayout.map((state) => (
                            <div
                              key={state.id}
                              onClick={handleClick(
                                state.label,
                                screenLayout || "",
                                "screenLayout",
                                "Screen Layout"
                              )}
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                }
                              />
                            </div>
                          ))
                        : screenVal === "Add Shade" && !oneDirectionConfig
                        ? shadeLayout.map((state) => (
                            <div
                              key={state.id}
                              onClick={handleClick(
                                state.label,
                                screenLayout || "",
                                "screenLayout",
                                "Screen Layout"
                              )}
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                }
                              />
                            </div>
                          ))
                        : screenVal === "Add Shade" &&
                          oneDirectionConfig[1] === "L"
                        ? shadeLayout
                            .filter((itm) => itm.label === "Left Shade")
                            .map((state) => (
                              <div key={state.id}>
                                <Choices
                                  label={state.label}
                                  variant="filled"
                                  checkMark={true}
                                />
                              </div>
                            ))
                        : screenVal === "Add Shade" &&
                          oneDirectionConfig[1] === "R"
                        ? shadeLayout
                            .filter((itm) => itm.label === "Right Shade")
                            .map((state) => (
                              <div key={state.id}>
                                <Choices
                                  label={state.label}
                                  variant="filled"
                                  checkMark={true}
                                />
                              </div>
                            ))
                        : !oneDirectionConfig
                        ? singleScreenLayout.map((state) => (
                            <div
                              key={state.id}
                              onClick={handleClick(
                                state.label,
                                screenLayout || "",
                                "screenLayout",
                                "Screen Layout"
                              )}
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={
                                  state.label === screenLayout ||
                                  state.pdfLabel === screenLayout
                                }
                              />
                            </div>
                          ))
                        : oneDirectionConfig[1] === "L"
                        ? layoutLeft.map((state) => (
                            <div key={state.id}>
                              <Choices
                                label={state.label}
                                variant="filled"
                                checkMark={true}
                              />
                            </div>
                          ))
                        : oneDirectionConfig[1] === "R"
                        ? layoutRight.map((state) => (
                            <div key={state.id}>
                              <Choices
                                label={state.label}
                                variant="filled"
                                checkMark={true}
                              />
                            </div>
                          ))
                        : null}
                    </RowLayoutSpacing>
                  </ColLayout>
                )}

                {hasShade && (
                  <div
                    style={
                      screenVal === "Add Double Shade"
                        ? {
                            marginBottom: 10,
                            marginTop: 20,
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            padding: theme.spacing(1, 2),
                          }
                        : {
                            marginBottom: 10,
                            marginTop: 20,
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            borderLeft: `2px solid ${theme.palette.divider}`,
                            padding: theme.spacing(1, 2),
                          }
                    }
                  >
                    <Typography variant="subtitle1">
                      Shade Mesh Color
                      <span style={{ color: "red", fontSize: 22 }}>*</span>
                    </Typography>

                    <RowLayout>
                      {shadeColors.map((state) => (
                        <div
                          key={state.id}
                          onClick={handleClick(
                            state.label,
                            shadeMeshColor || "",
                            "shadeMeshColor",
                            "Shade Mesh Color"
                          )}
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === shadeMeshColor
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === shadeMeshColor}
                          />
                        </div>
                      ))}
                    </RowLayout>
                  </div>
                )}

                <div
                  style={
                    screenVal === "Add Screen" ||
                    screenVal === "Add Combo Screen/Shade" ||
                    screenVal === "Add Shade" ||
                    screenVal === "Add Double Shade"
                      ? {
                          marginBottom: 10,
                          marginTop: 20,
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: `2px solid ${theme.palette.divider}`,
                          padding: theme.spacing(1, 2),
                        }
                      : {
                          marginBottom: 10,
                          marginTop: 20,
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }
                  }
                >
                  <Typography variant="subtitle1">
                    Screen Extrusion Color
                    <span style={{ color: "red", fontSize: 22 }}>*</span>
                  </Typography>

                  <RowLayout>
                    {screenColors.map((state) => (
                      <div
                        key={state.id}
                        onClick={handleClick(
                          state.label,
                          screenLayout || "",
                          "screenColor",
                          "Screen Extrusion Color"
                        )}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === screenColorChoice
                              ? "filled"
                              : "outlined"
                          }
                          checkMark={state.label === screenColorChoice}
                        />
                      </div>
                    ))}
                  </RowLayout>
                </div>
              </RowLayout>

              {displayedImage &&
                screenLayoutImages
                  .filter((image) => image.label === displayedImage)
                  .map((imageShown) => (
                    <RowLayout key={imageShown.label}>
                      <ColLayout
                        style={{
                          marginRight: 40,
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                      >
                        <Image
                          cloudName="ag-millworks"
                          secure="true"
                          publicId={imageShown.img}
                        />
                        <Typography
                          style={{ alignSelf: "center" }}
                          variant="caption"
                        >
                          *As viewed from exterior
                        </Typography>
                      </ColLayout>

                      {shadeMeshColor && hasShade && (
                        <div style={{ marginTop: -10 }}>
                          <Image
                            cloudName="ag-millworks"
                            secure="true"
                            publicId={`Shade_Colors/${shadeMeshColor
                              .split(" ")
                              .join("_")}_v2`}
                          >
                            <Transformation height="220" crop="scale" />
                          </Image>
                        </div>
                      )}
                    </RowLayout>
                  ))}
            </>
          )}
        </ColLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(CentorScreenAccordian);
