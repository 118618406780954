import React, { useState, FC } from "react";
import Table from "@mui/material/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Backdrop,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import SearchBar from "../Table/SearchBar";
import ConfirmationModal from "../Table/Modals/ConfirmationModal";
import ServiceTableHead from "./ServiceTableHead";

import TableToolbar from "../Table/TableToolbar";
import { useMutation, useQuery } from "react-query";

import AptChangeModal from "./Modals/AptChangeModal";
import { getServiceRepairs, postRepairStatus } from "../../../api/ServiceApi";
import useSessionState from "../../../hooks/useSessionState";
import { StyledCheckbox } from "../Table/Cells/StyledCheckbox";
import { ServiceRepairObj } from "../../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledRootDiv = styled("div")({
  width: "100%",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  position: "relative",
}));

const StyledViewQuote = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
});

const StyledTypography = styled(Typography)({
  display: "flex",
  justifyContent: "flex-start",
  padding: 10,
});

const StyledTypographyBold = styled(Typography)({
  display: "flex",
  color: "red",
  justifyContent: "flex-start",
  padding: 10,
  fontWeight: "bold",
});

const StyledBackdrop = styled(Backdrop)({
  position: "absolute",
  width: "100%",
  height: "50vh",
  zIndex: 10,
});

interface ServiceTableProps {
  viewSR: (row: ServiceRepairState) => void;
}

export interface ServiceRepairState extends ServiceRepairObj {
  id: string;
}

const ServiceTable: FC<ServiceTableProps> = ({ viewSR }) => {
  const [pageService, setPage] = useSessionState("pageService", 0);
  const [rowsPerPageService, setRowsPerPage] = useSessionState(
    "rowsPerPageService",
    15
  );

  const [isServiceSearch, setSearch] = useSessionState(
    "isServiceSearch",
    false
  );

  const [filterByService, setFilterBy] = useSessionState(
    "filterByService",
    "snNumber"
  );

  const [selected, setSelected] = useState<string[]>([]);

  const [searchTermService, setSearchTerm] = useSessionState(
    "searchTermService",
    ""
  );

  const { error, isFetching, data } = useQuery(
    [[isServiceSearch, "ServiceRepairs"], pageService, rowsPerPageService],
    async () => {
      if (
        searchTermService &&
        filterByService === "snNumber" &&
        isNaN(parseInt(searchTermService))
      )
        return "Must be Number";

      const response = await getServiceRepairs(
        pageService,
        rowsPerPageService,
        searchTermService,
        filterByService
      );

      return response.data;
    },
    { refetchOnWindowFocus: false }
  );

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const hasAdminAccess = role === "Admin" || role === "Inside Sales";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const term = value.toLowerCase();
    setSearchTerm(term);
  };

  const handleFilterByChange = (event: SelectChangeEvent<string>) =>
    setFilterBy(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== "Enter") return;
    setPage(0);
    setSearch(!isServiceSearch);
  };

  const handleSearchBtn = () => {
    setPage(0);
    setSearch((prev: boolean) => !prev);
  };

  const handleReset = () => {
    setSearchTerm("");
    setPage(0);
    setSearch(!isServiceSearch);
  };

  const mutation = useMutation(
    async (status: string) => {
      if (!status) return;
      return await postRepairStatus(selected, status);
    },
    {
      onSuccess: async () => {
        setSelected([]);
        handleCloseModal();
        setSearch(!isServiceSearch);
      },
      onError: (err) => console.log(err),
    }
  );

  const handleChangePage = (
    event: React.MouseEvent<any> | null,
    newPage: number
  ) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (!hasAdminAccess) {
      return null;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const unCheckAll = () => setSelected([]);

  const [hoverId, setHoverId] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const mouseEnter = (unitId: string) => {
    setHoverId(unitId);
  };

  const mouseLeave = () => {
    if (!selected.length) {
      setHoverId("");
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const [openApt, setOpenApt] = useState(false);

  const [aptId, setAptId] = useState("");

  const handleCloseApt = () => {
    setOpenApt(false);
    setAptId("");
  };

  const handleOpenApt = () => {
    setOpenApt(true);
    setAptId(selected[0] || "");
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  if (error)
    return <div>An error has occured, please try refreshing the page.</div>;

  return (
    <>
      {!isFetching && data ? (
        <SearchBar
          showTab="Service Repair"
          searchTerm={searchTermService}
          filterBy={filterByService}
          handleFilterByChange={handleFilterByChange}
          handleReset={handleReset}
          onKeyDown={onKeyDown}
          handleSearchBtn={handleSearchBtn}
          handleChange={handleChange}
        />
      ) : null}

      <StyledRootDiv>
        {!isFetching && data === "Must be Number" && (
          <StyledTypographyBold>
            Search Term must be a Number
          </StyledTypographyBold>
        )}

        <AptChangeModal
          open={openApt}
          handleClose={handleCloseApt}
          serviceId={aptId}
          setSearch={setSearch}
        />

        <ConfirmationModal
          mutation={mutation}
          handleClose={handleCloseModal}
          open={openModal}
          showTab="Service Repair"
        />

        <StyledPaper style={{ position: "relative" }}>
          {hasAdminAccess && (
            <TableToolbar
              unCheckAll={unCheckAll}
              handleOpenModal={handleOpenModal}
              showTab={"Service Repair"}
              numSelected={selected.length}
              handleOpenApt={handleOpenApt}
              searchTerm={searchTermService}
              searchTermFilter={filterByService}
              isSearch={isServiceSearch}
            />
          )}

          {isFetching ? (
            <StyledBackdrop open={isFetching}>
              <CircularProgress color="primary" />
            </StyledBackdrop>
          ) : (
            <>
              {data && data.currentPage && data.currentPage.length ? (
                <>
                  <TableContainer>
                    <Table
                      style={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={"small"}
                      aria-label="enhanced table"
                    >
                      <ServiceTableHead />

                      <TableBody>
                        {data.currentPage.map((row: ServiceRepairObj) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${row._id}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                              onMouseEnter={() => mouseEnter(row._id)}
                              onMouseLeave={mouseLeave}
                            >
                              {hasAdminAccess && (
                                <TableCell padding="checkbox">
                                  {row._id === hoverId || selected.length ? (
                                    <StyledCheckbox
                                      onClick={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleCheck(event, row._id)
                                      }
                                      checked={isItemSelected}
                                      color="primary"
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  ) : (
                                    <div style={{ width: 16, height: 16 }} />
                                  )}
                                </TableCell>
                              )}
                              <TableCell
                                onClick={() => viewSR(row as ServiceRepairState)}
                                padding="checkbox"
                              >
                                <StyledViewQuote variant="caption">
                                  View
                                </StyledViewQuote>
                              </TableCell>

                              <TableCell
                                align="center"
                                scope="row"
                                padding="none"
                              >
                                {row.createdAt || "N/A"}
                              </TableCell>
                              <TableCell
                                align="center"
                                scope="row"
                                padding="none"
                              >
                                {row.timeCreated || "N/A"}
                              </TableCell>

                              <TableCell align="center">
                                {row.customerName}
                              </TableCell>
                              {(hasAdminAccess || role === "Outside Sales") && (
                                <TableCell align="center">
                                  {row.dealer}
                                </TableCell>
                              )}
                              <TableCell align="center">
                                {row.snNumber}
                              </TableCell>
                              <TableCell align="center">
                                {row.poNumber || "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {row.soNumber || "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {row.appointment || "N/A"}
                              </TableCell>

                              {(hasAdminAccess || role === "Outside Sales") && (
                                <TableCell align="center">
                                  {row.team ? `Team ${row.team}` : "N/A"}
                                </TableCell>
                              )}

                              <TableCell align="center">{row.status}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[15, 20, 25]}
                    component="div"
                    count={data.totalResults}
                    rowsPerPage={rowsPerPageService}
                    page={pageService}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <StyledTypography>No Service Repairs Found</StyledTypography>
              )}
            </>
          )}
        </StyledPaper>
      </StyledRootDiv>
    </>
  );
};

export default ServiceTable;
