import React, { FC } from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import {
  bifoldCornerEVLeftImg,
  bifoldCornerEVRightImg,
} from "../../../Drawings/EPVCorner";
import { styles } from "../Styles";
import { UnitObj } from "../../../util/quoteCartStorage";

export interface EVCornerProps {
  item: UnitObj
  side: string
}

const EVCorner: FC<EVCornerProps> = ({ item, side }) => {
  const ev =
    side === "left"
      ? bifoldCornerEVLeftImg(item.exactConfig, item.primaryDoor || "")
      : bifoldCornerEVRightImg(item.cornerConfigRight || "", item.primaryDoor || "");

  const full = `${item.exactConfigFull} ${item.exactConfigFullRightCorner}`;

  return (
    <>
      {side === "left" ? (
        <>
          <Text
            style={[
              styles.text,
              { textDecoration: "underline", fontSize: 10, zIndex: 20 },
            ]}
          >
            All Images Not Drawn to Scale or Proportion
          </Text>

          <Text style={[styles.textBold, { fontSize: 11, zIndex: 20 }]}>
            Elevation View: Qty. {item.quantity} Bi-fold {item.cornerType} Corner {full} (
            {`Primary Door: ${item.primaryDoor}`})
          </Text>

          <View>
            {item.addDL && item.liteConfig !== '1 3/4"' ? (
              <Text style={styles.text}>
                As Viewed from Exterior (SDL not shown)
              </Text>
            ) : item.addDL && item.liteConfig === '1 3/4"' ? (
              <Text style={styles.text}>
                As Viewed from Exterior (TDL not shown)
              </Text>
            ) : (
              <Text style={styles.text}>As Viewed from Exterior</Text>
            )}
          </View>

          <View style={{ padding: 5 }}>
            <Text style={[styles.textBold, { fontSize: 11, zIndex: 20, paddingBottom: 10 }]}>
              Left Side of Corner
            </Text>
          </View>
        </>
      ) : (
        <Text />
      )}

      {side === "right" ? (
        <View style={{ padding: 5 }}>
          <Text style={[styles.textBold, { fontSize: 11, zIndex: 20, paddingBottom: 10 }]}>
            Right Side of Corner
          </Text>
        </View>
      ) : (
        <Text />
      )}

      <Image
        src={ev}
        style={[styles.elevationView, { width: 432, height: 'auto' }]}
      />
    </>
  );
};

export default EVCorner;
