import React, { FC } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import findJambWidth from "./tableData/jambCalc";
import calculateScreenPocket from "./tableData/pocketCalc";
import {
  pocketHeight,
  outerPocketHeight,
  outerPocketHeightLong,
  stileAdjusted,
  stileAdjusted2,
  stileWidth,
  checkRail,
  checkRailXX,
  checkRailXX2,
  checkRailXX3,
  checkRailWoodCentor
} from "./tableData/sliderJambLegCalc";
import { width1_8125, width2_25, width2_375, width1_75, width2_8125 } from "./tableData/sliderHeadJambCalc";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import { calculatePanelHeight } from "../../util/pdfFormulas/calculatePanelHeight";
import { getPivotSillWidth } from "../DealerDrawings/RefKey";
import setbackCalc from "./tableData/setbackCalc";
import { CutsheetItm } from "../../GlobalTypes";

function getLength(
  tableLength: string,
  length: number,
  panelHeight: number,
  fixedPanelHeight: number
) {
  let len = "";

  switch (tableLength) {
    case "width1_8125":
      len = width1_8125(length);
      break;
    case "width1_75":
      len = width1_75(length);
      break;
    case "width2_75":
      len = width2_375(length);
      break;
    case "width2_25":
      len = width2_25(length);
      break;
    case "width2_8125":
      len = width2_8125(length);
      break;
    case "panelHeight":
      len = convertDecimalToFraction(panelHeight);
      break;
    case "fixedPanelHeight":
      len = convertDecimalToFraction(fixedPanelHeight);
      break;
    default:
      len = convertDecimalToFraction(length);
  }

  return len;
}

const styles = StyleSheet.create({
  body: {
    padding: 10,
    width: 280,
  },
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader1: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeaderLS: {
    width: "40%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "16.67%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "16.67%",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontWeight: 500,
  },
  tableCell: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
  },
});

interface CutsheetTableProps {
  data: CutsheetItm[] | undefined;
  name: string;
  qty: number;
  wood: string;
  width: number;
  jamb: string;
  screen: string;
  screenPocket: string;
  doorType: string;
  length: number;
  dlo?: string;
  stile?: string;
  sill?: string;
  material: string;
  swingDirection: string;
  netHeight: number;
  exactConfig: string;
  screenType?: string;
  finSetback: number
}

const CutSheetTable: FC<CutsheetTableProps> = ({
  data,
  name,
  qty,
  wood,
  width,
  jamb,
  screen,
  screenType,
  screenPocket,
  doorType,
  length,
  stile,
  sill,
  material,
  swingDirection,
  netHeight,
  exactConfig,
  finSetback
}) => {
  const panelHeight = calculatePanelHeight(
    exactConfig,
    doorType,
    sill || "",
    netHeight,
    screenType || "",
    material,
    swingDirection
  );

  const getWood = (label: string) => {
    if (label !== "wood") return label;
    return wood;
  };

  const getQty = (tableQty: string | number) => {
    if (tableQty !== "inswingJambQty" && typeof tableQty === "number")
      return tableQty * qty;

    if (name === "JAMB LEGS") {
      const inswingJambQty = jamb.includes("7")
        ? 4
        : jamb.includes("7-15/16")
        ? 6
        : 2;

      return inswingJambQty * qty;
    } else {
      const inswingJambQty = jamb.includes("7")
        ? 2
        : jamb.includes("7-15/16")
        ? 3
        : 1;

      return inswingJambQty * qty;
    }
  };

  const getDimensions = (size: string) => {
    if (size === "sillWidth") {
      const jambString = jamb
        .replace(" Overall Jamb Width", "")
        .replace("Standard ", "")
        .replace("-", " ")
        .replace('"', "");

      const sillWidth = getPivotSillWidth(jambString);
      return convertDecimalToFraction(sillWidth);
    }else if (size.includes("jambCalc") || size === "jambSize") {
      return findJambWidth(size, jamb, screen, screenType || "", doorType);
    } else if (size.includes("pocketCalc")) {
      return calculateScreenPocket(width, screenPocket, size);
    }else if(size === 'pocketHeight'){
      return pocketHeight(width);
    }else if(size === 'outerPocketHeight'){
      return outerPocketHeight(width);
    }else if(size === 'outerPocketHeightLong'){
      return outerPocketHeightLong(width);
    }else if(size === 'stileAdjusted'){
      return stileAdjusted(stile || "");
    }else if(size === 'stileAdjusted2'){
      return stileAdjusted2(stile || "");
    }else if(size === 'stileWidth'){
      return stileWidth(stile || "");
    }else if(size === 'checkRail'){
      return checkRail(stile || "");
    }else if(size === 'checkRailXX'){
      return checkRailXX(stile || "");
    }else if(size === 'checkRailXX2'){
      return checkRailXX2(stile || "");
    }else if(size === 'checkRailXX3'){
      return checkRailXX3(stile || "");
    }else if(size === 'checkRailWoodCentor'){
      return checkRailWoodCentor(stile || "");
    }else if(size.includes("setbackCalc")){
      const setbackSub = Number(size.split("setbackCalc")[1].replace("_", "."))
      return setbackCalc(setbackSub, finSetback)
    }

    return size;
  };

  return (
    <View style={styles.body}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeader}>{name}</Text>
          </View>

          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeader}>SIZE</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>PART</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>QTY</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>SPECIES</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>WIDTH</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>HEIGHT</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>LENGTH</Text>
          </View>
        </View>

        {data?.map((row, i) => (
          <View key={i} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.part}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{getQty(row.qty)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{getWood(row.species)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{getDimensions(row.width)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{getDimensions(row.height)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{getLength(row['length'] || '', length, panelHeight, sill?.includes("Steel") ? panelHeight + .3125 : panelHeight + .375)}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default CutSheetTable;
