import React, { useEffect, useState, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/QuoteForm/Navbar";
import { Divider, Typography, Backdrop, CircularProgress, SelectChangeEvent } from "@mui/material";
import getParams from "../hooks/useParams";
import { useQuery } from "react-query";
import AdminDealerSearch from "../components/AdminDealerSearch";
import Header from "../components/QuoteForm/Header";
import RequiredMsgs from "../components/QuoteForm/RequiredMsgs";
import MatchingTaxResults from "../components/QuoteForm/MatchingTaxResults";
import DealerSalesTax from "../components/QuoteForm/DealerSalesTax";
import DeliveryInfo from "../components/QuoteForm/DeliveryInfo";
import ProjectName from "../components/QuoteForm/ProjectName";
import DealerInfo from "../components/QuoteForm/DealerInfo";
import CustomerInfo from "../components/QuoteForm/CustomerInfo";
import DeliveryChips from "../components/QuoteForm/DeliveryChips";
import CreateQuoteBtn from "../components/QuoteForm/CreateQuoteBtn";
import UpdateQuoteBtn from "../components/QuoteForm/UpdateQuoteBtn";
import Copyright from "../components/Copyright";
import CustomerHeard from "../components/QuoteForm/CustomerHeard";
import {
  getUser,
  getQuoteFormData,
  getUserFromEmail,
  postCreateQuote,
  getSalesTax,
  postEditForm,
} from "../api/QuoteFormApi";
import { blueGrey } from '@mui/material/colors';
import { UserObj } from "../GlobalTypes";
import { styled } from "@mui/material/styles";

export interface QuoteFormState {
  agSalesperson: string
  salesperson: string
  dealer: string
  address: string
  city: string
  state?: string
  zip: string
  email: string
  phone: string
  customerName: string
  jobName: string
  customerEmail: string
  customerPhone: string
  customerZip: string
  adminCOD: string
  checkedDealer: boolean,
  checkedJobsite: boolean,
  formNotes: string
  leadTime: boolean,
  customLeadTime: string
  customerCity: string
  heardAbout: string
  heardAboutOther: string
}

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 150,
  marginRight: 150,
  flexWrap: "wrap",
  backgroundColor: "white",
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StyledDivWrap = styled("div")({
  backgroundColor: blueGrey[50],
  // height: "100%",
  minHeight: "100vh",
  position: "relative",
});

const StyledDivTitle = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: 80,
  paddingLeft: 20,
  borderBottom: "2px solid grey",
  paddingBottom: 5,
});

const StyledTypography = styled(Typography)({
  position: "absolute",
  right: 40,
  top: 20,
  color: "#4b4b4b",
});

const StyledForm = styled("form")(({ theme }) => ({
  paddingTop: 30,
  paddingBottom: 30,
  width: "100%",
  "& > *": {
    margin: theme.spacing(1),
    width: "25ch",
  },
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  position: "relative",
}));

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const StyledCircularProgress = styled(CircularProgress)({
  position: "fixed",
  left: "50%",
  top: "50%",
});

const QuoteForm: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const id = getParams(location.pathname);

  const isQuoteCreated = location.state.isQuoteCreated || false;

  const {
    isLoading: isLoadingUser,
    error: userError,
    data: userData,
    isFetching: isFetchingUser,
  } = useQuery(
    ["User", { isQuoteCreated }],
    async () => {
      const response = await getUser(id);

      if (response.data.signInRole === "Production") {
        const user = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
        navigate(`/dealerZone/${user}`);
        return;
      }

      if (!isQuoteCreated) {
        const { role, agSalesRep, dealerRep, dealer, email, phone } =
          response.data;

        const hasDealerAccess =
          role === "Dealer" || role === "Manager" || role === "Company Manager";

        if (hasDealerAccess) {
          setState({
            ...state,
            agSalesperson: agSalesRep,
            salesperson: dealerRep,
            dealer,
            email,
            phone,
          });
        } else if (role === "Outside Sales") {
          setState({ ...state, agSalesperson: dealerRep });
        }

        !hasDealerAccess && handleOpen();
      }

      return response.data;
    },
    { refetchOnWindowFocus: false }
  );

  const { data, isFetching } = useQuery(
    ["Quote", { isQuoteCreated }],
    () => {
      if (isQuoteCreated) {
        return fetchQuote();
      }
    },
    { refetchOnWindowFocus: false }
  );

  const fetchQuote = async () => {
    try {
      const response = await getQuoteFormData(id);

      const { quote } = response.data;

      if (quote) {
        setState({
          agSalesperson: quote.agSalesperson,
          salesperson: quote.salesperson,
          dealer: quote.dealer,
          address: quote.address,
          city: quote.city,
          zip: quote.zip,
          email: quote.email,
          phone: quote.phone,
          customerName: quote.customerName,
          jobName: quote.jobName,
          customerEmail: quote.customerEmail,
          customerPhone: quote.customerPhone,
          customerZip: quote.customerZip,
          adminCOD: quote.adminCOD || "None",
          checkedDealer: quote.checkedDealer,
          checkedJobsite: quote.checkedJobsite,
          formNotes: quote.formNotes ? quote.formNotes : "",
          leadTime: quote.customLeadTime ? true : false,
          customLeadTime: quote.customLeadTime || "",
          customerCity: quote.customerCity || "",
          heardAbout: quote.heardAbout,
          heardAboutOther: quote.heardAboutOther,
        });

        return quote;
      }
    } catch (err) {
      return;
    }
  };

  const [err, setErr] = useState(false);

  const [state, setState] = useState<QuoteFormState>({
    agSalesperson: "",
    salesperson: "",
    dealer: "",
    address: "",
    city: "",
    zip: "",
    email: "",
    phone: "",
    customerName: "",
    jobName: "",
    customerEmail: "",
    customerPhone: "",
    customerZip: "",
    adminCOD: "None",
    checkedDealer: false,
    checkedJobsite: false,
    formNotes: "",
    leadTime: false,
    customLeadTime: "",
    customerCity: "",
    heardAbout: "",
    heardAboutOther: "",
  });

  const [requireds, setRequireds] = useState<string[]>([]);

  useEffect(() => {
    if (!state || isFetchingUser) return;
    const reqs: string[] = [];
    if (!state.jobName) reqs.push("Project Name");
    if (
      !state.customerName &&
      (userData.signInRole === "Dealer" ||
        userData.signInRole === "Manager" ||
        userData.signInRole === "Company Manager")
    )
      reqs.push("Customer Name");
    if (!state.agSalesperson) reqs.push("AG Sales Rep");
    if (!state.dealer) reqs.push("Dealer");
    if (!state.salesperson) reqs.push("Dealer Rep Name");
    if (!state.email) reqs.push("Dealer Rep Email");
    if (!state.checkedDealer && !state.checkedJobsite) reqs.push("Ship To");
    if (state.checkedDealer) {
      if (!state.address) reqs.push("Address");
      if (!state.city) reqs.push("City");
      if (!state.zip) reqs.push("Zip Code");
    }

    if (state.customerZip && !state.customerCity) reqs.push("Jobsite City");
    if (!state.customerZip && state.customerCity) reqs.push("Jobsite Zip Code");

    setRequireds(reqs);
  }, [state]);

  const handleAllChange = (event: React.ChangeEvent<any> | SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    let update = {
      ...state,
      [name]: 
       name !== "leadTime" && name !== "glass" 
        ? value 
        : "checked" in event.target 
        ? event.target.checked
        : ""
    }

    if (name === "heardAbout" && value !== "Other") {
      update["heardAboutOther"] = "";
    }

    setState(update);

    if (state.checkedDealer || state.checkedJobsite) {
      if (name === "customerZip" || name === "customerCity") {
        !hideCurrentTax && isQuoteCreated && setHCT(true);
        setTaxResults([]);
        setSalesTaxErr("");
      }
    }
  };

  const handleCheck = (delivery: string) => {
    const isNonAdmin =
      userData.role === "Dealer" ||
      userData.role === "Manager" ||
      userData.role === "Company Manager";

    if (delivery === "dealer") {
      if (state.checkedDealer === false) {
        isNonAdmin
          ? setState({
              ...state,
              address: userData.address,
              city: userData.city,
              zip: userData.zip,
              checkedDealer: !state.checkedDealer,
              checkedJobsite: false,
            })
          : setState({
              ...state,
              checkedDealer: !state.checkedDealer,
              checkedJobsite: false,
            });
      } else {
        isNonAdmin
          ? setState({
              ...state,
              address: "",
              city: "",
              zip: "",
              checkedDealer: !state.checkedDealer,
              checkedJobsite: false,
            })
          : setState({
              ...state,
              checkedDealer: !state.checkedDealer,
              checkedJobsite: false,
            });
      }
    } else if (delivery === "jobsite") {
      isNonAdmin
        ? setState({
            ...state,
            address: "",
            city: "",
            zip: "",
            checkedDealer: false,
            checkedJobsite: !state.checkedJobsite,
          })
        : setState({
            ...state,
            checkedDealer: false,
            checkedJobsite: !state.checkedJobsite,
          });
    }
  };

  const [salesTaxErr, setSalesTaxErr] = useState("");

  const handleDelete = () => console.info("You clicked the delete icon.");

  const handleReturn = () => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
    navigate(`/dealerZone/${loggedInUser}`);
  };

  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const checkForReqs = () => {
    if (!state.checkedDealer && !state.checkedJobsite) {
      setErr(true);
      return true;
    }

    if (
      !state.jobName ||
      !state.agSalesperson ||
      !state.dealer ||
      !state.salesperson ||
      !state.email ||
      (state.customerZip && !state.customerCity) ||
      (!state.customerZip && state.customerCity)
    ) {
      setErr(true);
      return true;
    }

    if (state.checkedDealer) {
      if (!state.address || !state.city || !state.zip) {
        setErr(true);
        return true;
      }
    }

    if (
      userData.signInRole === "Dealer" ||
      userData.signInRole === "Manager" ||
      userData.signInRole === "Company Manager"
    ) {
      if (!state.customerName) {
        setErr(true);
        return true;
      }
    }

    return false;
  };

  const [taxResults, setTaxResults] = useState([]);

  const handleCreateQuote = async () => {
    var requireds = checkForReqs();

    if (!requireds) {
      setIsBtnDisabled(true);
      setErr(false);
      localStorage.clear();

      const sTax = await handleSalesTax();

      if (sTax?.stErr) return setIsBtnDisabled(false);

      if (userData.role !== "Dealer") {
        const res = await getUserFromEmail(state.email.toLowerCase());

        if (res.data.error) return setIsBtnDisabled(false);

        const quoteID =
          res.data === "no user found with that email" ? id : res.data.id;

        createQuote(quoteID, sTax?.salesTax || null);
      } else {
        const res = await postCreateQuote(
          id,
          state,
          sTax?.salesTax || null,
        );

        if (!res) return setIsBtnDisabled(false);

        sessionStorage.setItem("quoteId", JSON.stringify(res.data.quoteId));
        sessionStorage.setItem(
          "quoteNumber",
          JSON.stringify(res.data.quoteNumber)
        );

        navigate(`/config/${id}`, {
          state: { isQuoteCreated: true, userId: id },
        });
      }
    }
  };

  const createQuote = async (userIdid: string, salesTax: number | null) => {
    const res = await postCreateQuote(
      userIdid,
      state,
      salesTax,
    );

    if (!res) return setIsBtnDisabled(false);

    sessionStorage.setItem("quoteId", JSON.stringify(res.data.quoteId));
    sessionStorage.setItem("quoteNumber", JSON.stringify(res.data.quoteNumber));

    navigate(`/config/${id}`, {
      state: { isQuoteCreated: true, userId: userIdid },
    });
  };

  const handleSalesTax = async () => {
    let salesTax = 0;
    let stErr = false;
    const zip = state.customerZip || null;
    const salesTaxCity = state.customerCity || null;

    if (taxResults.length && tax && zip) {
      salesTax = tax;
    } else if (zip) {
      const res = await getSalesTax(zip, salesTaxCity);

      if (res.data.error) {
        salesTax = 0;
        stErr = true;
        setSalesTaxErr(res.data.error);
        setTaxResults([]);
        return;
      }

      setSalesTaxErr("");
      if (res.data.results.length > 1) {
        setTaxResults(res.data.results);
        stErr = true;
      } else {
        salesTax = res.data.results[0];
        stErr = false;
        setTaxResults([]);
      }
    }

    return { salesTax, stErr };
  };

  const handleUpdateQuote = async () => {
    var requireds = checkForReqs();

    if (requireds) return;

    setIsBtnDisabled(true);
    setErr(false);

    const sTax = await handleSalesTax();

    if (sTax?.stErr) return setIsBtnDisabled(false);

    const quoteId = JSON.parse(sessionStorage.getItem("quoteId") || JSON.stringify(""));
    const res = await postEditForm(quoteId, state, sTax?.salesTax || null);

    if (res.data.data) {
      navigate(`/config/${id}`, {
        state: { isQuoteCreated: true, userId: id },
      });
      return;
    }

    setIsBtnDisabled(false);
  };

  const returnToConfig = () =>
    navigate(`/config/${id}`, {
      state: { isQuoteCreated: true, userId: id },
    });

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const adminFillFields = (dealer: UserObj) => {
    if (!dealer) return;

    setState({
      ...state,
      agSalesperson: dealer.agSalesRep || "",
      salesperson: dealer.dealerRep || "",
      dealer: dealer.dealer || "",
      email: dealer.email || "",
      phone: dealer.phone || "",
      address: dealer.address || "",
      city: dealer.city || "",
      state: dealer.state || "",
      zip: dealer.zip || "",
      adminCOD: dealer.codOrTerms || "",
      checkedDealer: true,
      checkedJobsite: false,
    });
    handleClose();
  };

  const [tax, setTax] = useState(0);

  const handleTaxChange = (e: SelectChangeEvent<string>) => setTax(Number(e.target.value));

  const [hideCurrentTax, setHCT] = useState(false);

  if (userError)
    return <div>An Error occured, please try refreshing the page.</div>;

  return (
    <>
      {!isFetchingUser && !isLoadingUser && !isFetching ? (
        <>
          <StyledDivWrap>
            <Navbar
              goBackFunction={handleReturn}
              btnText="Return to Dealer Zone"
              quoteNumber={data && data.quoteNumber ? data.quoteNumber : null}
              returnedPath="form"
              historyState={location?.state || null}
            />

            {userData.signInRole !== "Dealer" && !isQuoteCreated && (
              <AdminDealerSearch
                role={userData.signInRole}
                open={open}
                handleClose={handleClose}
                adminFillFields={adminFillFields}
              />
            )}

            <StyledDivTitle>
              <Typography style={{ fontWeight: "bold" }} variant="h3">
                Quote Information
              </Typography>
            </StyledDivTitle>

            <StyledDiv style={{ position: "relative" }}>
              {!isFetching && data && data.createdBy && (
                <StyledTypography variant="subtitle2">
                  Created By: <span>{data.createdBy}</span>
                </StyledTypography>
              )}

              <StyledForm noValidate autoComplete="off">
                <Header text="Job Details" />

                <ProjectName
                  handleAllChange={handleAllChange}
                  role={userData.role}
                  jobName={state.jobName}
                  formNotes={state.formNotes}
                  agSalesperson={state.agSalesperson}
                />

                <Header text="Dealer Information" />

                <DealerInfo
                  role={userData.role}
                  signInRole={userData.signInRole}
                  handleAllChange={handleAllChange}
                  state={state}
                  handleOpen={handleOpen}
                  isQuoteCreated={isQuoteCreated}
                />

                <Header text="Customer Information" />

                <CustomerInfo
                  handleAllChange={handleAllChange}
                  signInRole={userData.signInRole}
                  customerName={state.customerName}
                  customerEmail={state.customerEmail}
                  customerPhone={state.customerPhone}
                />

                <CustomerHeard
                  handleChange={handleAllChange}
                  heardAbout={state.heardAbout}
                  heardAboutOther={state.heardAboutOther}
                />

                <Header text="Shipping Information" />

                <StyledDivCol>
                  {state.checkedJobsite && (
                    <>
                      <Typography variant="caption">
                        * Upcharge Applies
                      </Typography>
                      <Typography variant="caption">
                        * Two-person minimum for hand unloading on Jobsite
                        deliveries.
                      </Typography>
                      <Typography
                        style={{ paddingBottom: 20, textAlign: "center" }}
                        variant="caption"
                      >
                        * Some areas may be outside our delivery zone; call
                        (805) 644-4494 if unsure.
                      </Typography>
                    </>
                  )}

                  <DeliveryChips
                    checkedDealer={state.checkedDealer}
                    checkedJobsite={state.checkedJobsite}
                    handleCheck={handleCheck}
                    handleDelete={handleDelete}
                  />

                  {state.checkedJobsite && (
                    <>
                      <Typography style={{ padding: 20 }}>
                        Jobsite address can be entered on the Order Summary page
                        prior to sign off.
                      </Typography>
                      <Divider
                        style={{ width: "50%", backgroundColor: "black" }}
                      />
                    </>
                  )}

                  {state.checkedDealer && (
                    <DeliveryInfo
                      handleAllChange={handleAllChange}
                      address={state.address}
                      city={state.city}
                      zip={state.zip}
                      dealerDelivery={
                        userData.role === "Dealer" ||
                        userData.role === "Company Manager" ||
                        userData.role === "Manager" ||
                        userData.role === "Dealer Manager"
                          ? true
                          : false
                      }
                    />
                  )}

                  {(state.checkedDealer || state.checkedJobsite) &&
                    !userData.dealerTax && (
                      <>
                        <DealerSalesTax
                          handleAllChange={handleAllChange}
                          customerCity={state.customerCity}
                          customerZip={state.customerZip}
                          currentSalesTax={
                            isQuoteCreated &&
                            !hideCurrentTax &&
                            !taxResults.length &&
                            !isFetching &&
                            data.salesTax
                              ? data.salesTax
                              : null
                          }
                        />

                        {taxResults.length ? (
                          <MatchingTaxResults
                            tax={tax}
                            handleTaxChange={handleTaxChange}
                            taxResults={taxResults}
                          />
                        ) : null}

                        {salesTaxErr && (
                          <Typography style={{ color: "red" }}>
                            {salesTaxErr}
                          </Typography>
                        )}
                      </>
                    )}
                </StyledDivCol>
              </StyledForm>
              {!isQuoteCreated ? (
                <CreateQuoteBtn
                  isBtnDisabled={isBtnDisabled}
                  handleCreateQuote={handleCreateQuote}
                />
              ) : (
                <UpdateQuoteBtn
                  isBtnDisabled={isBtnDisabled}
                  handleUpdateQuote={handleUpdateQuote}
                  returnToConfig={returnToConfig}
                />
              )}
              {err && requireds.length ? (
                <RequiredMsgs requireds={requireds} />
              ) : null}
            </StyledDiv>

            <Copyright position="right" />
          </StyledDivWrap>
        </>
      ) : (
        <Backdrop open={isFetchingUser || isLoadingUser}>
          <StyledCircularProgress size={60} color="primary" />
        </Backdrop>
      )}
    </>
  );
};

export default QuoteForm;
