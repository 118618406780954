import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import AppBar from "@mui/material/AppBar";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Toolbar,
  Typography,
  useMediaQuery,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LabelIcon from "@mui/icons-material/Label";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import BuildIcon from "@mui/icons-material/Build";
import AddIcon from "@mui/icons-material/Add";
import WindowIcon from "@mui/icons-material/Window";
import LeadTimes from "../components/dealerZone/LeadTimes";
import QuotesTable from "../components/dealerZone/Table/QuotesTable";
import StilesImgBanner from "../components/dealerZone/StilesImgBanner";
import { useQuery } from "react-query";
import AccountDropdown from "../components/AccountDropdown";
import getParams from "../hooks/useParams";
import { useLocation, useNavigate } from "react-router-dom";

import ServiceRepair from "../components/dealerZone/Service/ServiceRepair";
import ChartAndNewsTab from "../components/dealerZone/ChartAndNewsTab";

import LoseChangesModal from "../components/dealerZone/Service/Modals/LoseChangesModal";
import TechSupportTooltip from "../components/dealerZone/TechSupportTooltip";

import AgTipsModal from "../components/dealerZone/AgTipsModal";
import Copyright from "../components/Copyright";
import useSessionState from "../hooks/useSessionState";
import { useStore } from "../store/store";
import {
  searchAllQuotes,
  getAllQuotes,
  postHideTips,
  getLogo,
} from "../api/DzoneApi";
import { getServiceNumber } from "../api/ServiceApi";
import { styled } from "@mui/material/styles";

const StyledRootDiv = styled("div")({
  display: "flex",
  position: "relative",
  minHeight: "100vh",
  paddingBottom: "2.5rem",
  backgroundColor: "#fafafa",
});

const drawerWidth = 240;

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  height: 95,
  backgroundColor: "white",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const StyledFillerDiv = styled("div")(({ theme }) => ({
  width: 50,
  height: 50,
  backgroundColor: "white",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StyledImgLogo = styled("img")(({ theme }) => ({
  height: 80,
  width: 300,
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
}));

const StyledNav = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

const StyledMain = styled("main")(({ theme }) => ({
  flexGrow: 1,
  margin: 20,
  padding: theme.spacing(3),
  // paddingTop: 95,
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    margin: 0,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontWeight: "bold",
    fontSize: 30,
    fontFamily: "Arial",
    paddingTop: 10,
  },
}));

const StyledNewQuoteDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  margin: 20,
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    margin: 0,
  },
}));

const StyledNewQuoteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4dae4f",
  flexBasis: "25%",
  color: "white",
  [theme.breakpoints.down("md")]: {
    flexBasis: "100%",
    height: "100%",
  },
}));

const StyledHomeDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
}));

const StyledTopHomeDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "flex-start",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
  },
}));

const StyledQuotesDiv = styled("div")({
  display: "flex",
  height: 50,
  marginTop: 20,
  justifyContent: "space-between",
  position: "relative",
});

const CreateAccount = lazy(
  () => import("../components/dealerZone/CreateAccount")
);

const CustomLabels = lazy(
  () => import("../components/dealerZone/CustomLabels/CustomLabels")
);

const AdminCalendar = lazy(
  () => import("../components/dealerZone/Calendar/AdminCalendar")
);

const SalesLog = lazy(
  () => import("../components/dealerZone/SalesReport/SalesLog")
);

const GlassDetailsTable = lazy(
  () => import("../components/dealerZone/GlassDetails/GlassDetailsTable")
);

const CustomerListTable = lazy(
  () => import("../components/dealerZone/CustomerList/CustomerListTable")
);

const InsightsTab = lazy(
  () => import("../components/dealerZone/Insights/InsightsTab")
);

function isMobile() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

const bgColorFn = (showTab: string, tab: string) =>
  showTab === tab ? "#4b4b4b" : "";
const fontColorFn = (showTab: string, tab: string) =>
  showTab === tab ? "white" : "";

interface DealzerZoneTabProps {
  showTab: string;
  tab: string;
  handleTabChange: (tab: string) => void;
  children: React.ReactNode;
}

const DealerZoneTab: FC<DealzerZoneTabProps> = ({
  showTab,
  tab,
  handleTabChange,
  children,
}) => {
  const backgroundColor = bgColorFn(showTab, tab);
  const color = fontColorFn(showTab, tab);

  return (
    <ListItem
      button
      style={{
        backgroundColor,
      }}
      onClick={() => handleTabChange(tab)}
    >
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText style={{ color }} primary={tab} />
    </ListItem>
  );
};

export interface TableState {
  page: number;
  rowsPerPage: number;
  totalResults: number;
  searchTerm: string;
  filterBy: string;
}

interface DealerZoneProps {
  window?: (() => { document: { body: Element } }) | undefined;
}

export type RepairState = { status: string, id: string }

const DealerZone: FC<DealerZoneProps> = ({ window }) => {
  const resetQuote = useStore((state) => state.resetQuote);

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));
  const email = JSON.parse(sessionStorage.getItem("email") || JSON.stringify(""));

  useEffect(() => {
    localStorage.clear();
    resetQuote();

    if (role === "Production") {
      setOrdersTable({
        page: 0,
        rowsPerPage: 15,
        totalResults: 0,
        searchTerm: "",
        filterBy: "salesOrder",
      });
    }

    if (isMobile()) return;

    const showTips = JSON.parse(sessionStorage.getItem("showTips") || JSON.stringify(""));

    if (showTips) {
      handleOpenTips();
      sessionStorage.setItem("showTips", JSON.stringify(false));
    }
  }, []);

  const location = useLocation();
  const id = getParams(location.pathname);

  const navigate = useNavigate();
  const theme = useTheme();
  const mediaLg = useMediaQuery(theme.breakpoints.up("md"));

  const [mobileOpen, setMobileOpen] = useState(false);

  const [showTab, setShowTab] = useSessionState("showTab", "Home");

  const [quotesTable, setQuotesTable] = useSessionState("quotesTable", {
    page: 0,
    rowsPerPage: 15,
    totalResults: 0,
    searchTerm: "",
    filterBy: "quoteNumber",
  });

  const [ordersTable, setOrdersTable] = useSessionState("ordersTable", {
    page: 0,
    rowsPerPage: 15,
    totalResults: 0,
    searchTerm: "",
    filterBy: "quoteNumber",
  });

  const [isSearchQuotes, setIsSearchQ] = useSessionState(
    "isSearchQuotes",
    false
  );

  const [isSearchOrders, setIsSearchO] = useSessionState(
    "isSearchOrders",
    false
  );

  const [reSearchQuotes, setReSearchQ] = useState(false);
  const [reSearchOrders, setReSearchO] = useState(false);

  const [showArchive, setShowArchive] = useSessionState(
    "showArchive",
    "Quotes"
  );

  const handleArchive = (event: SelectChangeEvent<string>) => {
    setShowArchive(event.target.value);
  };

  const searchAll = async (
    status: string,
    table: TableState,
    setTotal: Dispatch<SetStateAction<TableState>>,
    archive?: boolean
  ) => {
    const term = table.searchTerm.toLowerCase();

    const res = await searchAllQuotes(
      term,
      status,
      table.filterBy,
      table.page,
      table.rowsPerPage,
      archive
    );

    if (res.data !== "No Quotes Found") {
      setTotal((prev) => ({
        ...prev,
        totalResults: res.data.totalResults,
      }));
    }

    return res.data;
  };

  const {
    isLoading: isLoadingQuotes,
    error: quotesError,
    data: quotesData,
    isFetching: isFetchingQuotes,
  } = useQuery(
    [
      "Quotes",
      isSearchQuotes,
      quotesTable.page,
      quotesTable.rowsPerPage,
      reSearchQuotes,
      showArchive,
    ],
    async () => {
      if (role === "Production") return;

      if (!isSearchQuotes) {
        const response = await getAllQuotes(
          quotesTable.page,
          quotesTable.rowsPerPage,
          "Quotes",
          showArchive
        );

        setQuotesTable((prev: TableState) => ({
          ...prev,
          totalResults: response.data.totalResults,
        }));
        return response.data;
      } else {
        const searchData = await searchAll(
          "Quotes",
          quotesTable,
          setQuotesTable,
          showArchive
        );
        return searchData;
      }
    }
  );

  const { isLoading, error, data, isFetching } = useQuery(
    [
      "Orders",
      isSearchOrders,
      ordersTable.page,
      ordersTable.rowsPerPage,
      reSearchOrders,
    ],
    async () => {
      if (!isSearchOrders) {
        const response = await getAllQuotes(
          ordersTable.page,
          ordersTable.rowsPerPage,
          "Orders"
        );

        setOrdersTable((prev: TableState) => ({
          ...prev,
          totalResults: response.data.totalResults,
        }));
        return response.data;
      } else {
        const searchData = await searchAll(
          "Orders",
          ordersTable,
          setOrdersTable
        );
        return searchData;
      }
    }
  );

  const handleTabChange = (tab: string) => {
    if (tab === showTab) return;

    if (showTab === "Service Repair" && snNumber) {
      handleOpen(tab);
      return;
    }
    setShowTab(tab);
  };

  const changeTab = () => {
    setSnNumber(0);
    setOpen(false);
    repair.status && setRepair({ status: "", id: "" });
    setShowTab(tabToSwitch);
  };

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleNewQuote = () =>
    navigate(`/form/${id}`, {
      state: { isQuoteCreated: false },
    });

  useEffect(() => {
    if (role === "Production") {
      setShowTab("Orders");
      return;
    }

    (async () => {
      const response = await getLogo();
      response.data && setLogo(response.data);
    })();
  }, []);

  const [logo, setLogo] = useState("");

  const [snNumber, setSnNumber] = useState(0);

  const getSN = async () => {
    const serviceNumber = await getServiceNumber();
    serviceNumber.data.snNumber && setSnNumber(serviceNumber.data.snNumber);
  };

  const [repair, setRepair] = useState<RepairState>({ status: "", id: "" });

  const [tabToSwitch, setTTS] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = (tab: string) => {
    setTTS(tab);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [openTips, setOpenTips] = useState(false);
  const handleOpenTips = () => setOpenTips(true);

  const handleCloseTips = async (dontShow?: boolean) => {
    if (dontShow) {
      const response = await postHideTips();
      response.data === "AG Tips Hidden" && setOpenTips(false);
      return;
    }
    setOpenTips(false);
  };

  const showInsights =
    role === "Admin" ||
    role === "Outside Sales" ||
    email === "laura@agmillworks.com" ||
    email === "erika@agmillworks.com"
      ? true
      : false;

      const drawer = (
        <div style={{ minHeight: "100vh", paddingBottom: "2.5rem" }}>
          <Toolbar />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!logo || logo === "No Logo Found" ? (
              <Typography
                style={{ color: "black", position: "relative", bottom: 30 }}
                variant="h5"
                noWrap
              >
                AG DEALER ZONE
              </Typography>
            ) : (
              <div style={{ padding: 15 }}>
                <img
                  style={{
                    width: 150,
                    height: 80,
                    position: "fixed",
                    left: 45,
                    top: 5,
                  }}
                  src={logo}
                  alt=""
                />
              </div>
            )}
          </div>
          <Divider />
          <List>
            {role !== "Production" ? (
              ["Home", "Quotes", "Orders", "Service Repair"].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  style={{ backgroundColor: showTab === text ? "#4b4b4b" : "" }}
                  onClick={() => handleTabChange(text)}
                >
                  <ListItemIcon>
                    {index === 0 ? (
                      <HomeIcon style={{ color: showTab === text ? "white" : "" }} />
                    ) : index === 1 ? (
                      <ReceiptIcon style={{ color: showTab === text ? "white" : "" }} />
                    ) : index === 2 ? (
                      <PermContactCalendarIcon
                        style={{ color: showTab === text ? "white" : "" }}
                      />
                    ) : (
                      <BuildIcon style={{ color: showTab === text ? "white" : "" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === text ? "white" : "" }}
                    primary={text}
                  />
                </ListItem>
              ))
            ) : (
              <>
                <ListItem
                  button
                  style={{ backgroundColor: showTab === "Orders" ? "#4b4b4b" : "" }}
                  onClick={() => handleTabChange("Orders")}
                >
                  <ListItemIcon>
                    <PermContactCalendarIcon
                      style={{ color: showTab === "Orders" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Orders" ? "white" : "" }}
                    primary="Orders"
                  />
                </ListItem>
    
                <ListItem
                  button
                  style={{
                    backgroundColor: showTab === "Custom Labels" ? "#4b4b4b" : "",
                  }}
                  onClick={() => handleTabChange("Custom Labels")}
                >
                  <ListItemIcon>
                    <LabelIcon
                      style={{ color: showTab === "Custom Labels" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Custom Labels" ? "white" : "" }}
                    primary={"Custom Labels"}
                  />
                </ListItem>
              </>
            )}
    
            {(role === "Outside Sales" ||
              role === "Admin" ||
              role === "Inside Sales") && (
              <>
                <ListItem
                  button
                  style={{
                    backgroundColor: showTab === "Calendar" ? "#4b4b4b" : "",
                  }}
                  onClick={() => handleTabChange("Calendar")}
                >
                  <ListItemIcon>
                    <CalendarIcon
                      style={{ color: showTab === "Calendar" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Calendar" ? "white" : "" }}
                    primary={"Calendar"}
                  />
                </ListItem>
    
                <ListItem
                  button
                  style={{
                    backgroundColor: showTab === "Sales Report" ? "#4b4b4b" : "",
                  }}
                  onClick={() => handleTabChange("Sales Report")}
                >
                  <ListItemIcon>
                    <AssignmentIcon
                      style={{ color: showTab === "Sales Report" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Sales Report" ? "white" : "" }}
                    primary={"Sales Report"}
                  />
                </ListItem>
              </>
            )}
    
            {role === "Admin" && (
              <>
                <ListItem
                  button
                  style={{
                    backgroundColor: showTab === "Create Account" ? "#4b4b4b" : "",
                  }}
                  onClick={() => handleTabChange("Create Account")}
                >
                  <ListItemIcon>
                    <AddBoxIcon
                      style={{ color: showTab === "Create Account" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Create Account" ? "white" : "" }}
                    primary={"Create Account"}
                  />
                </ListItem>
    
                <ListItem
                  button
                  style={{
                    backgroundColor: showTab === "Custom Labels" ? "#4b4b4b" : "",
                  }}
                  onClick={() => handleTabChange("Custom Labels")}
                >
                  <ListItemIcon>
                    <LabelIcon
                      style={{ color: showTab === "Custom Labels" ? "white" : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: showTab === "Custom Labels" ? "white" : "" }}
                    primary={"Custom Labels"}
                  />
                </ListItem>
              </>
            )}
    
            {(role === "Admin" ||
              role === "Production" ||
              role === "Inside Sales") && (
              <ListItem
                button
                style={{
                  backgroundColor: showTab === "Glass Details" ? "#4b4b4b" : "",
                }}
                onClick={() => handleTabChange("Glass Details")}
              >
                <ListItemIcon>
                  <WindowIcon
                    style={{ color: showTab === "Glass Details" ? "white" : "" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: showTab === "Glass Details" ? "white" : "" }}
                  primary={"Glass Details"}
                />
              </ListItem>
            )}
    
            {(role === "Outside Sales" ||
              role === "Admin" ||
              role === "Inside Sales") && (
              <>
              <DealerZoneTab 
                showTab={showTab}
                tab="Customer List"
                handleTabChange={handleTabChange}
              >
                <GroupIcon
                  style={{ color: fontColorFn(showTab, "Customer List") }}
                />
              </DealerZoneTab>
    
                {showInsights &&
                <DealerZoneTab 
                  showTab={showTab}
                  tab="Insights"
                  handleTabChange={handleTabChange}
                >
                  <AssessmentIcon
                    style={{ color: fontColorFn(showTab, "Insights") }}
                  />
                </DealerZoneTab>
                }
              </>
            )}
          </List>
    
          <Copyright position="center1" />
        </div>
      );
    
      const container =
        window !== undefined ? () => window().document.body : undefined;
    
      return (
        <StyledRootDiv>
          {role !== "Production" && (
            <AgTipsModal open={openTips} handleClose={handleCloseTips} />
          )}
    
          {showTab === "Home" && role !== "Production" && <TechSupportTooltip />}
    
          <StyledAppbar position="fixed">
            <StyledToolbar>
              <StyledIconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                size="large"
              >
                <MenuIcon style={{ color: "black" }} />
              </StyledIconButton>
    
              <StyledFillerDiv />
    
              <StyledImgLogo
                src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoBlack.png"
                alt="logo"
              />
    
              <AccountDropdown
                color="#4b4b4b"
                resumeLaterBtn={false}
                returnedPath="dealerZone"
              />
            </StyledToolbar>
          </StyledAppbar>
    
          <StyledNav aria-label="dealerZone tabs">
            {!mediaLg ? (
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": { width: drawerWidth },
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            ) : (
              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": { width: drawerWidth },
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            )}
          </StyledNav>
    
          {role !== "Production" ? (
            <StyledMain>
              <Toolbar style={{ position: "relative" }} />
    
              <div
                style={
                  showTab === "Quotes" ||
                  showTab === "Orders" ||
                  showTab === "Glass Details" ||
                  showTab === "Customer List"
                    ? {
                        display: "flex",
                        height: 50,
                        marginTop: 20,
                        justifyContent: "space-between",
                        position: "relative",
                      }
                    : {
                        display: "flex",
                        height: 50,
                        marginTop: 20,
                        justifyContent: "flex-end",
                        position: "relative",
                        [theme.breakpoints.down("xl")]: {
                          width: "100%",
                        },
                      }
                }
              >
                {showTab === "Quotes" && (
                  <div style={{ flexBasis: "70%" }}>
                    <StyledTypography variant="h2">Quotes</StyledTypography>
                  </div>
                )}
                {showTab === "Orders" && (
                  <div style={{ flexBasis: "70%" }}>
                    <StyledTypography variant="h2">Orders</StyledTypography>
                  </div>
                )}
                {showTab === "Glass Details" && (
                  <div style={{ flexBasis: "70%" }}>
                    <StyledTypography variant="h2">Glass Details</StyledTypography>
                  </div>
                )}
                {showTab === "Customer List" && (
                  <div style={{ flexBasis: "70%" }}>
                    <StyledTypography variant="h2">Customer List</StyledTypography>
                  </div>
                )}
    
                {showTab !== "Service Repair" &&
                  showTab !== "Calendar" &&
                  showTab !== "Custom Labels" &&
                  showTab !== "Sales Report" &&
                  showTab !== "Glass Details" &&
                  showTab !== "Customer List" &&
                  showTab !== "Insights" && (
                    <StyledNewQuoteDiv>
                      <StyledNewQuoteButton
                        onClick={handleNewQuote}
                        size="large"
                        variant="contained"
                        color="success"
                      >
                        <AddIcon /> New Quote
                      </StyledNewQuoteButton>
                    </StyledNewQuoteDiv>
                  )}
              </div>
              {showTab === "Quotes" && (
                <QuotesTable
                  isSearch={isSearchQuotes}
                  setIsSearch={setIsSearchQ}
                  totalLength={quotesTable.totalResults}
                  page={quotesTable.page}
                  rowsPerPage={quotesTable.rowsPerPage}
                  isFetching={isFetchingQuotes}
                  data={quotesData}
                  isLoading={isLoadingQuotes}
                  error={quotesError}
                  showTab={showTab}
                  userId={id}
                  filterBy={quotesTable.filterBy}
                  searchTerm={quotesTable.searchTerm}
                  setState={setQuotesTable}
                  setReSearch={setReSearchQ}
                  showArchive={showArchive}
                  handleArchive={handleArchive}
                />
              )}
              {showTab === "Orders" && (
                <QuotesTable
                  isSearch={isSearchOrders}
                  setIsSearch={setIsSearchO}
                  totalLength={ordersTable.totalResults}
                  page={ordersTable.page}
                  rowsPerPage={ordersTable.rowsPerPage}
                  isFetching={isFetching}
                  data={data}
                  isLoading={isLoading}
                  error={error}
                  showTab={showTab}
                  userId={id}
                  filterBy={ordersTable.filterBy}
                  searchTerm={ordersTable.searchTerm}
                  setState={setOrdersTable}
                  setReSearch={setReSearchO}
                />
              )}
    
              {showTab === "Home" && (
                <>
                  <StyledHomeDiv>
                    <StyledTopHomeDiv>
                      <LeadTimes />
    
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 5,
                        }}
                      >
                        <ChartAndNewsTab />
                      </div>
                    </StyledTopHomeDiv>
                    <StilesImgBanner />
                  </StyledHomeDiv>
                </>
              )}
    
              {showTab === "Glass Details" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <GlassDetailsTable />
                </Suspense>
              )}
    
              {showTab === "Customer List" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <CustomerListTable setShowTab={setShowTab} />
                </Suspense>
              )}
    
              {showTab === "Service Repair" && (
                <>
                  <LoseChangesModal
                    handleClose={handleClose}
                    open={open}
                    changeTab={changeTab}
                    modalText="Continuing Will Cause You to Lose Any Changes."
                  />
    
                  <ServiceRepair
                    id={id}
                    snNumber={snNumber}
                    setSnNumber={setSnNumber}
                    getSN={getSN}
                    repair={repair}
                    setRepair={setRepair}
                  />
                </>
              )}
    
              {(role === "Admin" ||
                role === "Inside Sales" ||
                role === "Outside Sales") &&
                showTab === "Calendar" && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AdminCalendar role={role} />
                  </Suspense>
                )}
    
              {role === "Admin" && showTab === "Create Account" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <CreateAccount />
                </Suspense>
              )}
    
              {role === "Admin" && showTab === "Custom Labels" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <CustomLabels />
                </Suspense>
              )}
    
              {showTab === "Sales Report" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <SalesLog role={role} />
                </Suspense>
              )}
    
              {showTab === "Insights" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <InsightsTab userId={id} />
                </Suspense>
              )}
            </StyledMain>
          ) : (
            <StyledMain>
              <Toolbar style={{ position: "relative" }} />
    
              {showTab === "Orders" ? (
                <>
                  <StyledQuotesDiv>
                    <div>
                      <StyledTypography variant="h2">Orders</StyledTypography>
                    </div>
                  </StyledQuotesDiv>
                  <QuotesTable
                    isSearch={isSearchOrders}
                    setIsSearch={setIsSearchO}
                    totalLength={ordersTable.totalResults}
                    page={ordersTable.page}
                    rowsPerPage={ordersTable.rowsPerPage}
                    isFetching={isFetching}
                    data={data}
                    isLoading={isLoading}
                    error={error}
                    showTab={"Orders"}
                    userId={id}
                    filterBy={ordersTable.filterBy}
                    searchTerm={ordersTable.searchTerm}
                    setState={setOrdersTable}
                    setReSearch={setReSearchO}
                  />
                </>
              ) : showTab === "Custom Labels" ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <CustomLabels />
                </Suspense>
              ) : showTab === "Glass Details" ? (
                <>
                  <div style={{ flexBasis: "70%" }}>
                    <StyledTypography variant="h2">Glass Details</StyledTypography>
                  </div>
    
                  <Suspense fallback={<div>Loading...</div>}>
                    <GlassDetailsTable />
                  </Suspense>
                </>
              ) : null}
            </StyledMain>
          )}
        </StyledRootDiv>
      );
};

export default DealerZone;
