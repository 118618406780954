import React, { useEffect, useMemo, memo, CSSProperties, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../../Choices";
import SlidingScreenAccordian from "../SlidingScreen/SlidingScreenAccordian";
import { Image, Transformation } from "cloudinary-react";
import SliderScreenPocketAccordian from "../SliderScreenPocketAccordian";
import ScreenTypeAccordian from "../ScreenType/ScreenTypeAccordian";
import ReusableAccordian from "../../ReusableAccordion";
import { useStore, useAccordion } from "../../../store/store";
import { useRequiredMsgs } from "../../../store/requiredMsgs";
import {
  screenColors,
  screenLayoutImages,
  sliderScreenOptions,
  group6,
  group7,
  screenType,
  ScreenObj,
  SliderScreenOption
} from "./defaults";
import {
  comboScreenLayout,
  shadeColors,
  // shadeLayout,
} from "../../../initialValues/screens";
import fractionToInt from "../../../util/fractionToInt";
import findScreenChoices from "./findScreenChoices";
import { Element } from "react-scroll";
import { RowLayout, ColLayout } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const slidingScreenConfigs = ["OA-XO", "OX-AO", "OX", "XO"];

interface ScreenLayoutChoicesProps {
  layout: ScreenObj[];
  handleClick: (pdfLabel: string, disabled: boolean) => void;
  screenLayout: string;
}

const ScreenLayoutChoices: FC<ScreenLayoutChoicesProps> = ({
  layout,
  handleClick,
  screenLayout,
}): any => {
  return layout.map((state) => (
    <div
      key={state.id}
      onClick={() => handleClick(state.pdfLabel || "", state.disabled || false)}
    >
      <Choices
        label={state.label}
        variant={state.pdfLabel === screenLayout ? "filled" : "outlined"}
        checkMark={state.pdfLabel === screenLayout}
        disabled={state.disabled || false}
      />
    </div>
  ));
};

interface UpdateObj {
  addScreen?: string;
  screenPocket?: string;
  screenLayout?: string;
}

function SliderScreenAccordian() {
  const theme = useTheme();

  const width = useStore((state) => state.quote.unitSize);
  const widthFraction = useStore((state) => state.quote.unitSizeFraction);

  const sliderOverallWidth = fractionToInt(width, widthFraction);

  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const sliderOverallHeight = fractionToInt(height, heightFraction);

  const addToQuote = useStore((state) => state.addToQuote);

  const sliderP = useStore((state) => state.quote.sliderPocket);
  const isPocketing = sliderP === "With Pocket";

  const chosenConfig = useStore((state) => state.quote.exactConfig);

  const screenTypeChoice = useStore((state) => state.quote.screenType);

  const screen = useStore((state) => state.quote.addScreen);
  const screenLayout = useStore((state) => state.quote.screenLayout);
  const slidingScr = useStore((state) => state.quote.addSlidingScreen);

  const sillStyle = useStore((state) => state.quote.sillStyle);

  const stiles = useStore((state) => state.quote.stilesAndRails);
  const isSlimline =
    stiles && (stiles.includes("Slimline") || stiles.includes("Lock"));

  const screenVal = screen
    ? screen.replace(" (upcharge applies)", "")
    : slidingScr
    ? slidingScr
    : "";

  const screenColor = useStore((state) => state.quote.screenColor);
  const shadeMeshColor = useStore((state) => state.quote.shadeMeshColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[25].expanded);
  const require = useAccordion((state) => state.expanded[25].require);

  const displayedImage = useMemo(() => {
    if (!screenVal) return "";

    if (screenVal.includes("Combo")) {
      if (screenLayout === "Left Shade/Right Screen") {
        return "Combo Screen Left";
      }

      if (screenLayout === "Right Shade/Left Screen") {
        return "Combo Screen Right";
      }

      return "";
    }

    if (screenVal === "Add Double Shade") {
      return "Double Shade";
    }

    if (screenVal === "Add Double Screen") {
      return "Double Screen";
    }

    if (screenVal === "Add Shade") {
      if (screenLayout === "Left Shade") {
        return "Shade Left";
      }

      if (screenLayout === "Right Shade") {
        return "Shade Right";
      }
      return "";
    }

    if (screenLayout) {
      return screenLayout;
    }
  }, [screenVal, screenLayout]);

  const screenOptions = useMemo(
    () => sliderScreenOptions(chosenConfig),
    [chosenConfig]
  );

  useEffect(() => {
    if (!screenTypeChoice) return;

    if (!slidingScreenConfigs.includes(chosenConfig)) {
      return addToQuote({ addSlidingScreen: "" });
    }

    if (screenTypeChoice !== "Sliding Screen") {
      addToQuote({ addSlidingScreen: "" });
    } else {
      if (findRequired("Screen Layout")) removeRequired("Screen Layout");

      addToQuote(
        {
          addScreen: "",
          screenLayout: "",
          screenPocket: "",
        },
        "addScreen"
      );
    }
  }, [screenTypeChoice]);

  const handleAddScreenClick = (label: string) => {
    const checked = label === screenVal;

    if (checked) {
      addToQuote({ addScreen: "" }, "addScreen");
    } else {
      let update: UpdateObj = { addScreen: label };
      if (label.includes("Double")) {
        if (!isPocketing && chosenConfig.includes("-")) {
          return addToQuote(update);
        }

        if (isPocketing || chosenConfig.includes("Primary")) {
          update["screenPocket"] = "Add Double Pocket";
        } else if (!isPocketing) {
          if (chosenConfig.slice(-1) === "X") {
            update["screenPocket"] = "Add Pocket Right";
          } else {
            update["screenPocket"] = "Add Pocket Left";
          }
        }
      } else {
        const defaultLayout =
          screenOptions && label === "Add Shade"
            ? screenOptions.shadeLayout.filter((item) => item.checkMark)
            : screenOptions?.layout.filter((item) => item.checkMark);

        if (defaultLayout?.length) {
          if (findRequired("Screen Layout")) removeRequired("Screen Layout");
          update["screenLayout"] = defaultLayout[0].pdfLabel;
        } else {
          update["screenLayout"] = "";
        }

        if (isPocketing) update["screenPocket"] = "Add Pocket";

        if (!isPocketing && !chosenConfig.includes("Primary"))
          update["screenPocket"] = "";
      }

      addToQuote(update);
    }
  };

  const handleLayoutClick = (pdfLabel: string, disabled: boolean) => {
    if (disabled) return;

    const checked = pdfLabel === screenLayout;

    if (checked) {
      addToQuote({ screenLayout: "" });
    } else {
      let update: UpdateObj = { screenLayout: pdfLabel };
      if (findRequired("Screen Layout")) removeRequired("Screen Layout");
      if (chosenConfig.includes("Primary")) {
        update["screenPocket"] = "Add Pocket";
      }

      addToQuote(update);
    }
  };

  const handleScreenColorClick = (label: string) => {
    const checked = label === screenColor;

    if (checked) {
      addToQuote({ screenColor: "" });
    } else {
      addToQuote({ screenColor: label });
      if (findRequired("Screen Extrusion Color"))
        removeRequired("Screen Extrusion Color");
    }
  };

  const handleShadeColorClick = (label: string) => {
    const checked = label === shadeMeshColor;

    if (checked) {
      addToQuote({ shadeMeshColor: "" });
    } else {
      addToQuote({ shadeMeshColor: label });
      if (findRequired("Shade Mesh Color")) removeRequired("Shade Mesh Color");
    }
  };

  const sliderScrConfigs = slidingScreenConfigs.includes(chosenConfig);

  const showSliding =
    ((chosenConfig === "OX" || chosenConfig === "XO") &&
      sliderOverallWidth <= 150) ||
    ((chosenConfig === "OA-XO" || chosenConfig === "OX-AO") &&
      sliderOverallWidth <= 300)
      ? true
      : false;

  const showSlidingScreen =
    showSliding && screenTypeChoice === "Sliding Screen" ? true : false;

  const hideScreenPocket =
    screenTypeChoice === "Centor Flat Screen/Shade"
      ? true
      : (group6.includes(chosenConfig) || group7.includes(chosenConfig)) &&
        sliderOverallWidth <= 192 &&
        sliderOverallWidth >= 187 &&
        screenVal === "Add Screen"
      ? true
      : false;

  const screenChoices = findScreenChoices(
    isPocketing,
    chosenConfig,
    screenTypeChoice || "",
    screenOptions as SliderScreenOption,
    sliderOverallWidth,
    sliderOverallHeight,
    sillStyle || ""
  );

  useEffect(() => {
    if (!showSliding) {
      return addToQuote({ addSlidingScreen: "" });
    }

    if (screenTypeChoice !== "Sliding Screen") {
      addToQuote({ addSlidingScreen: "" });
    } else {
      if (findRequired("Screen Layout")) removeRequired("Screen Layout");

      addToQuote(
        {
          addScreen: "",
          screenLayout: "",
          screenPocket: "",
        },
        "addScreen"
      );
    }
  }, [screenTypeChoice]);

  useEffect(() => {
    if (!showSliding && screenTypeChoice === "Sliding Screen") {
      addToQuote({ screenType: "", addSlidingScreen: "" });
    }
  }, [showSliding]);

  const chipArrangement2 = {
    marginBottom: 10,
    marginTop: 20,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  };

  const helper = {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  };

  const helper2 = {
    padding: theme.spacing(1, 2),
  };

  const hasShade =
    screenVal === "Add Shade" ||
    screenVal === "Add Double Shade" ||
    screenVal === "Add Combo Screen/Shade"
      ? true
      : false;

  let centorWidth = sliderOverallWidth - 1;

  if (isPocketing) {
    const doubleSided = chosenConfig.includes("-");
    centorWidth = doubleSided
      ? sliderOverallWidth + 8.3128
      : sliderOverallWidth + 6.8113;
  }

  let screenTypeOptions = showSliding
    ? screenType
    : screenType.filter((itm) => itm.label !== "Sliding Screen");

  if (centorWidth > 354) {
    screenTypeOptions = screenTypeOptions.filter(
      (itm) => !itm.label.includes("Centor")
    );
  }

  const showSliderScreens =
    (sliderOverallWidth < 384 && screenTypeChoice && sliderScrConfigs) ||
    (sliderOverallWidth < 384 &&
      !sliderScrConfigs &&
      !screenTypeChoice &&
      screenTypeOptions.length === 1) ||
    (sliderOverallWidth < 384 && !sliderScrConfigs && screenTypeChoice);

  if (!chosenConfig) return null;

  return (
    <>
      {chosenConfig && (
        <>
          <ScreenTypeAccordian
            screenTypeOptions={screenTypeOptions}
          />
          {showSliderScreens ? (
            <>
              <Element name="Screen Layout" />
              <Element name="Screen Extrusion Color" />
              <Element name="Shade Mesh Color" />

              {showSlidingScreen ? (
                <SlidingScreenAccordian />
              ) : (
                <>
                  <ReusableAccordian
                    expanded={expanded}
                    handleAccordClick={handleAccordClick}
                    accordionName="screen"
                    heading={`${
                      screenTypeChoice === "Centor Flat Screen/Shade"
                        ? "Centor"
                        : "Brio"
                    } Screen`}
                    secondaryHeading="Select Screen Option"
                    required={require ? true : false}
                  >
                    <ColLayout>
                      {isSlimline &&
                        screenTypeChoice !== "Centor Flat Screen/Shade" && (
                          <Typography variant="subtitle1">
                            *Pocketing Screen recommended for Slimline
                          </Typography>
                        )}

                      <Typography variant="subtitle1">
                        *upcharge applies to all screens/shades
                      </Typography>

                      <RowLayout>
                        {screenChoices.map((state, idx) => (
                          <div
                            key={idx}
                            onClick={() => handleAddScreenClick(state.label)}
                          >
                            <Choices
                              label={state.label}
                              variant={
                                state.label === screenVal
                                  ? "filled"
                                  : "outlined"
                              }
                              checkMark={state.label === screenVal}
                            />
                          </div>
                        ))}
                      </RowLayout>

                      <RowLayout>
                        {(screenVal === "Add Screen" ||
                          screenVal === "Add Combo Screen/Shade" ||
                          screenVal === "Add Shade") && (
                          <ColLayoutSpacing>
                            <Typography variant="subtitle1">
                              Screen Layout
                              <span style={{ color: "red", fontSize: 22 }}>
                                *
                              </span>
                            </Typography>
                            <RowLayout>
                              {screenVal === "Add Shade" ? (
                                <ScreenLayoutChoices
                                  layout={screenOptions?.shadeLayout || []}
                                  handleClick={handleLayoutClick}
                                  screenLayout={screenLayout || ""}
                                />
                              ) : screenVal === "Add Combo Screen/Shade" ? (
                                <ScreenLayoutChoices
                                  layout={comboScreenLayout}
                                  handleClick={handleLayoutClick}
                                  screenLayout={screenLayout || ""}
                                />
                              ) : (
                                <ScreenLayoutChoices
                                  layout={screenOptions?.layout || []}
                                  handleClick={handleLayoutClick}
                                  screenLayout={screenLayout || ""}
                                />
                              )}
                            </RowLayout>
                          </ColLayoutSpacing>
                        )}

                        {hasShade && (
                          <div
                            style={
                              screenVal === "Add Double Shade"
                                ? ({
                                    ...chipArrangement2,
                                    ...helper2,
                                  } as CSSProperties)
                                : ({
                                    ...chipArrangement2,
                                    ...helper,
                                  } as CSSProperties)
                            }
                          >
                            <Typography variant="subtitle1">
                              Shade Mesh Color
                              <span style={{ color: "red", fontSize: 22 }}>
                                *
                              </span>
                            </Typography>

                            <RowLayout>
                              {shadeColors.map((state) => (
                                <div
                                  key={state.id}
                                  onClick={() =>
                                    handleShadeColorClick(state.label)
                                  }
                                >
                                  <Choices
                                    label={state.label}
                                    variant={
                                      state.label === shadeMeshColor
                                        ? "filled"
                                        : "outlined"
                                    }
                                    checkMark={state.label === shadeMeshColor}
                                  />
                                </div>
                              ))}
                            </RowLayout>
                          </div>
                        )}

                        <div
                          style={
                            screenVal === "Add Screen" ||
                            screenVal === "Add Combo Screen/Shade" ||
                            screenVal === "Add Shade" ||
                            screenVal === "Add Double Shade"
                              ? ({
                                  ...chipArrangement2,
                                  ...helper,
                                } as CSSProperties)
                              : (chipArrangement2 as CSSProperties)
                          }
                        >
                          <Typography variant="subtitle1">
                            Screen Extrusion Color
                            {screenVal && (
                              <span style={{ color: "red", fontSize: 22 }}>
                                *
                              </span>
                            )}
                          </Typography>
                          <RowLayout>
                            {screenColors.map((state) => (
                              <div
                                key={state.id}
                                onClick={() =>
                                  handleScreenColorClick(state.label)
                                }
                              >
                                <Choices
                                  label={state.label}
                                  variant={
                                    state.label === screenColor
                                      ? "filled"
                                      : "outlined"
                                  }
                                  checkMark={state.label === screenColor}
                                />
                              </div>
                            ))}
                          </RowLayout>
                        </div>
                      </RowLayout>

                      {displayedImage &&
                        screenLayoutImages
                          .filter((image) => image.label === displayedImage)
                          .map((imageShown) => (
                            <RowLayout key={imageShown.label}>
                              <ColLayout
                                style={{
                                  marginRight: 40,
                                  marginTop: 10,
                                  marginBottom: 5,
                                }}
                              >
                                <Image
                                  cloudName="ag-millworks"
                                  secure="true"
                                  publicId={imageShown.img}
                                />
                                <Typography
                                  style={{ alignSelf: "center" }}
                                  variant="caption"
                                >
                                  *As viewed from exterior
                                </Typography>
                              </ColLayout>

                              {shadeMeshColor && hasShade && (
                                <div style={{ marginTop: -10 }}>
                                  <Image
                                    cloudName="ag-millworks"
                                    secure="true"
                                    publicId={`Shade_Colors/${shadeMeshColor
                                      .split(" ")
                                      .join("_")}_v2`}
                                  >
                                    <Transformation height="220" crop="scale" />
                                  </Image>
                                </div>
                              )}
                            </RowLayout>
                          ))}
                    </ColLayout>
                  </ReusableAccordian>

                  {hideScreenPocket ? null : screenVal && screenOptions ? (
                    <SliderScreenPocketAccordian
                      screenOptions={screenOptions}
                      isPocketing={isPocketing}
                    />
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default memo(SliderScreenAccordian);
