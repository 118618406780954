import React, { memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useAccordion, useStore } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import {
  addSdlChip,
  addTdlChip,
  addIRailChip,
  lites,
  lites2,
  litesIRail,
} from "./defaults";
import DLAccordian from "./DLAccordian";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledSpan = styled("span")({
  color: "red", 
  fontSize: 20
});

interface LiteProps {
  doorType: string
}

interface UpdateObj {
  addDL: string
  spacerColor?: string
  liteConfig?: string
  diffSideliteSdl?: boolean
}

const LiteAccordian: FC<LiteProps> = ({ doorType }) => {  
  const addToQuote = useStore((state) => state.addToQuote);

  const materialChoice = useStore((state) => state.quote.material);
  const stiles = useStore((state) => state.quote.stileCategory);

  const addDL = useStore((state) => state.quote.addDL);
  const liteConfig = useStore((state) => state.quote.liteConfig);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[23].expanded);
  const require = useAccordion((state) => state.expanded[23].require);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleLitesClick = (label: string) => {
    const checked = label === liteConfig;

    if (checked) {
      addToQuote({ liteConfig: "" });
    } else {
      if (findRequired("Divided Lites Size"))
        removeRequired("Divided Lites Size");

      addToQuote({ liteConfig: label });
    }
  };

  const handleClick = (label: string) => {
    const checked = label === addDL;

    if (checked) {
      addToQuote(
        {
          liteConfig: "",
          addDL: "",
        },
        "addDL"
      );
    } else {
      let update: UpdateObj = { addDL: label };

      if (label === "Add SDL") {
        update["spacerColor"] = "Black";
        if (findRequired("IG Spacer Color")) removeRequired("IG Spacer Color");
      }

      if (label === "Add TDL") update["liteConfig"] = '1 3/4"';

      if (doorType === "Pivot" && label === "Add Intermediate Rail") {
        const railSize = stiles === "Traditional" ? '5 1/4"' : '2 1/2"';
        update["liteConfig"] = railSize;
        update["diffSideliteSdl"] = false;
      }

      addToQuote(update);
    }
  };

  return (
    <>
      <ReusableAccordian
        expanded={expanded}
        handleAccordClick={handleAccordClick}
        accordionName="lite"
        heading="Lite Configuration"
        secondaryHeading="Select desired lite configuration"
        required={require ? true : false}
      >
        {materialChoice.includes("Clad") && (
          <ColLayout>
            <Typography variant="subtitle1">
              * Divided Lites will match Stickings/Stops profiles
            </Typography>
            <Typography variant="subtitle1">
              ** All shadow bars are available in black only
            </Typography>
            <RowLayout>
              <div onClick={() => handleClick(addSdlChip.label)}>
                <Choices
                  label={addSdlChip.label}
                  variant={addSdlChip.label === addDL ? "filled" : "outlined"}
                  checkMark={addSdlChip.label === addDL}
                  wide={true}
                />
              </div>
              {doorType === "Pivot" && (
                <div onClick={() => handleClick(addIRailChip.label)}>
                  <Choices
                    label={addIRailChip.label}
                    variant={
                      addIRailChip.label === addDL ? "filled" : "outlined"
                    }
                    checkMark={addIRailChip.label === addDL}
                    wide={true}
                  />
                </div>
              )}
            </RowLayout>

            {addDL === "Add SDL" && (
              <ColLayoutTopDivider>
                <Typography variant="subtitle1">
                  Simulated Divided Lites Size
                  <StyledSpan>*</StyledSpan>
                </Typography>
                <RowLayout>
                  {lites.map((state) => (
                    <div
                      key={state.id}
                      onClick={() => handleLitesClick(state.label)}
                    >
                      <Choices
                        label={state.label}
                        variant={
                          state.label === liteConfig ? "filled" : "outlined"
                        }
                        checkMark={state.label === liteConfig}
                        disabled={state.disabled || false}
                      />
                    </div>
                  ))}
                </RowLayout>
              </ColLayoutTopDivider>
            )}

            {doorType === "Pivot" && addDL === "Add Intermediate Rail" && (
              <ColLayoutTopDivider>
                <Typography variant="subtitle1">
                  Intermediate Rail Lites Size
                  <StyledSpan>*</StyledSpan>
                </Typography>
                <RowLayout>
                  {litesIRail
                    .filter((item) => item.label === liteConfig)
                    .map((state) => (
                      <div key={state.id}>
                        <Choices
                          label={state.label}
                          variant="filled"
                          checkMark={true}
                          disabled={true}
                        />
                      </div>
                    ))}
                </RowLayout>
              </ColLayoutTopDivider>
            )}
          </ColLayout>
        )}

        {materialChoice === "All Wood" && (
          <ColLayout>
            <Typography variant="subtitle1">
              * Divided Lites will match Stickings/Stops profiles
            </Typography>
            <Typography variant="subtitle1">
              ** All shadow bars are available in black only
            </Typography>
            <RowLayout>
              <div onClick={() => handleClick(addSdlChip.label)}>
                <Choices
                  label={addSdlChip.label}
                  variant={addSdlChip.label === addDL ? "filled" : "outlined"}
                  checkMark={addSdlChip.label === addDL}
                  wide={true}
                />
              </div>
              {doorType === "Pivot" ? (
                <div onClick={() => handleClick(addIRailChip.label)}>
                  <Choices
                    label={addIRailChip.label}
                    variant={
                      addIRailChip.label === addDL ? "filled" : "outlined"
                    }
                    checkMark={addIRailChip.label === addDL}
                    wide={true}
                  />
                </div>
              ) : (
                <div onClick={() => handleClick(addTdlChip.label)}>
                  <Choices
                    label={addTdlChip.label}
                    variant={
                      addTdlChip.label === addDL ? "filled" : "outlined"
                    }
                    checkMark={addTdlChip.label === addDL}
                    wide={true}
                  />
                </div>
              )}
            </RowLayout>
            <RowLayout>
              {addDL === "Add SDL" && (
                <ColLayoutTopDivider>
                  <Typography variant="subtitle1">
                    Simulated Divided Lites Size
                    <StyledSpan>*</StyledSpan>
                  </Typography>
                  <RowLayout>
                    {lites.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleLitesClick(state.label)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === liteConfig ? "filled" : "outlined"
                          }
                          checkMark={state.label === liteConfig}
                          disabled={state.disabled || false}
                        />
                      </div>
                    ))}
                  </RowLayout>
                </ColLayoutTopDivider>
              )}
              {addDL === "Add TDL" && (
                <ColLayoutTopDivider>
                  <Typography variant="subtitle1">
                    True Divided Lites Size(upcharge)
                    <StyledSpan>*</StyledSpan>
                  </Typography>
                  <RowLayout>
                    <Choices
                      label={lites2.label}
                      variant="filled"
                      checkMark={true}
                      disabled={true}
                    />
                  </RowLayout>
                </ColLayoutTopDivider>
              )}
              {doorType === "Pivot" && addDL === "Add Intermediate Rail" && (
                <ColLayoutTopDivider>
                  <Typography variant="subtitle1">
                    Intermediate Rail Lites Size
                    <StyledSpan>*</StyledSpan>
                  </Typography>
                  <RowLayout>
                    {litesIRail
                      .filter((item) => item.label === liteConfig)
                      .map((state) => (
                        <div key={state.id}>
                          <Choices
                            label={state.label}
                            variant={
                              state.label === liteConfig
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === liteConfig}
                            disabled={true}
                          />
                        </div>
                      ))}
                  </RowLayout>
                </ColLayoutTopDivider>
              )}
            </RowLayout>
          </ColLayout>
        )}
      </ReusableAccordian>

      {materialChoice && addDL && (
        <DLAccordian doorType={doorType} />
      )}
    </>
  );
}

export default memo(LiteAccordian);
