import { convertDecimalToFraction } from "../../../util/pdfFormulas/convertDecimalToFraction";
import fractionToInt from "../../../util/fractionToInt";

function getStandardJamb(doorType: string, hasScreen: string, screenType: string) {
  if (doorType === "Swing") {
    return !hasScreen ? 5.625 : screenType?.includes("Centor") ? 7.875 : 6.875;
  }

  return !hasScreen ? 5.625 : screenType?.includes("Centor") ? 9.875 : 8.5;
}

function jambCalc(
  jamb: string,
  screen: string,
  screenType: string,
  adder: number,
  doorType: string
) {
  const parseJamb = jamb.includes("Overall")
    ? jamb
        .replace("Standard", "")
        .replace("Overall Jamb Width", "")
        .replace("-", " ")
        .replace('"', "")
        .trim()
    : jamb;

  const standardJamb = getStandardJamb(doorType, screen, screenType);

  const jambWidth = fractionToInt(
    parseJamb.split(" ")[0],
    parseJamb.split(" ")[1]
  );
  const jambDiff = jambWidth - standardJamb;

  var cell = jambDiff + adder;

  var fraction = convertDecimalToFraction(cell);

  if (fraction === "0") fraction = convertDecimalToFraction(jambWidth);
  else if (fraction[0] === "0") fraction = fraction.split(" ")[1].trim();

  return fraction;
}

function findJambWidth(
  key: string,
  jamb: string,
  screen: string,
  screenType: string,
  doorType: string
) {
  if (!key || !jamb) return;

  if (key === "jambSize") {
    const parseJamb = jamb.includes("Overall")
      ? jamb
          .replace("Standard", "")
          .replace("Overall Jamb Width", "")
          .replace("-", " ")
          .replace('"', "")
          .trim()
      : jamb;

    return parseJamb;
  }

  const adderString = key.split("jambCalc")[1];
  const adderInt = parseFloat(adderString.replace("_", "."));

  const jambWidth = jambCalc(jamb, screen, screenType, adderInt, doorType);

  return jambWidth;
}

export default findJambWidth;
