import React, { useState, FC, Dispatch, SetStateAction } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragUpdate,
} from "react-beautiful-dnd";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import RemoveAndEditBtn from "./RemoveEditDuplicate/RemoveAndEditBtn";
import QuoteList from "./QuoteList";
import QuantityUpdate from "./QuantityUpdate";
import api from "../api/api";
import { useStore } from "../store/store";
import { Delete } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { deleteUnit } from "../api/SidebarApi";
import DeleteErrorModal from "./RemoveEditDuplicate/DeleteErrorModal";
import { ChangeQtyFn } from "./QuantityUpdate";
import { UnitObj } from "../util/quoteCartStorage";
import { styled } from "@mui/material/styles";

interface DragDropListProps {
  quoteId: string;
  status: string;
  units: UnitObj[];
  setUnits: Dispatch<SetStateAction<UnitObj[]>>;
  loadDuplicate: boolean;
  duplicateUnit: (unitId: string) => void;
  setWindowOrDoor: Dispatch<SetStateAction<string>>;
  resetAllValues: () => void;
  setEditQuote: Dispatch<SetStateAction<boolean>>;
  checkedPD: boolean;
  changeQuantity: ChangeQtyFn;
}

const StyledDeleteIcon = styled(Delete)({
  color: "red",
  position: "absolute",
  top: 10,
  left: 15,
  fontSize: 30,
});

const StyledCircularProgress = styled(CircularProgress)({
  color: "red",
  position: "absolute",
  top: 10,
  left: 15,
});

const StyledButton = styled(Button)({
  color: "white",
  position: "absolute",
  borderRadius: 5,
  top: 47,
  right: 50,
  width: 200,
  padding: 0,
});

export const reorder = (
  list: UnitObj[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (
  isDragging: boolean,
  dragStyle: any,
  editBtn: boolean | undefined,
  id: number | string
) => ({
  userSelect: "none",

  // change background colour if dragging
  position: "relative",
  backgroundColor: isDragging
    ? "lightgrey"
    : editBtn && JSON.parse(sessionStorage.getItem("unitId") || JSON.stringify("")) === id
    ? "darkgrey"
    : "",

  // styles we need to apply on draggables
  ...dragStyle,
});

const getListStyle = () => ({
  overflow: "auto",
  height: 0,
  flex: "1 0 auto",
});

const DragDropList: FC<DragDropListProps> = ({
  quoteId,
  status,
  units,
  setUnits,
  loadDuplicate,
  duplicateUnit,
  setWindowOrDoor,
  resetAllValues,
  setEditQuote,
  checkedPD,
  changeQuantity,
}) => {
  const queryClient = useQueryClient();

  const isEdit = useStore((state) => state.isEdit);

  const [dnd, setDnd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loadingDel, setLoadingDel] = useState(false);

  const [errorDel, setErrorDel] = useState(false);

  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const [showChecks, setShowChecks] = useState(false);

  const [hoverId, setHoverId] = useState("");

  const handleCloseModal = () => {
    setHoverId("");
    setErrorDel(false);
    setCheckedIds([]);
  };

  const onDragEnd = (result: DragUpdate) => {
    if (
      !result.destination ||
      !units.length ||
      result.source.index === result.destination.index
    )
      return;

    if (status !== "Quote") {
      alert("Can't rearrange units on Orders");
      return;
    }

    const newOrder = reorder(
      units,
      result.source.index,
      result.destination.index
    );

    setUnits(newOrder);
    setDnd(true);
  };

  const handleNewOrdering = async (unitOrder: UnitObj[], quoteId: string) => {
    if (status !== "Quote") {
      setLoading(false);
      return;
    }
    setLoading(true);

    const res = await api.post("/api/reorderUnits", {
      quoteId,
      unitOrder,
    });

    if (res.data.data) setDnd(false);

    setLoading(false);
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { checked } = event.target;
    if (checked) {
      if (!checkedIds.includes(id)) {
        setCheckedIds((prev) => [...prev, id]);
        setShowChecks(true);
      }
    } else {
      const cids = [...checkedIds];
      const filtCids = cids.filter((item) => item !== id);
      setCheckedIds(filtCids);
      if (!filtCids.length) {
        setShowChecks(false);
      }
    }
  };

  const removeUnits = async () => {
    setLoadingDel(true);
    const editId = JSON.parse(sessionStorage.getItem("unitId") || JSON.stringify(""));

    setLoading(true);

    if (checkedIds.length && checkedIds.includes(editId)) {
      resetAllValues();
    }

    const res = await deleteUnit(quoteId, checkedIds);

    if (res.data.quote) {
      setLoadingDel(false);
      setErrorDel(false);
      return queryClient.invalidateQueries("QuoteCart");
    }

    setErrorDel(true);
    setLoadingDel(false);
  };

  const mouseEnter = (unitId: string | undefined) => {
    setHoverId(unitId || "");
  };

  const mouseLeave = () => {
    if (!checkedIds.length) {
      setHoverId("");
    }
  };

  return (
    <>
      {checkedIds.length ? (
        <>
          {!loadingDel ? (
            <Tooltip
              title={`Delete ${checkedIds.length} ${
                checkedIds.length === 1 ? "Unit" : "Units"
              }`}
            >
              <StyledDeleteIcon onClick={removeUnits} />
            </Tooltip>
          ) : (
            <StyledCircularProgress size={30} />
          )}
        </>
      ) : null}
      {dnd && (
        <StyledButton
          size="small"
          disabled={loading}
          variant="contained"
          onClick={() => handleNewOrdering(units, quoteId)}
          color="primary"
        >
          {!loading ? "SAVE CART ARRANGEMENT" : "SAVING..."}
        </StyledButton>
      )}

      <DeleteErrorModal open={errorDel} handleClose={handleCloseModal} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="quoteCart">
          {(provided, snapshot) => (
            <List
              style={getListStyle()}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {units.map((text, index) => (
                <Draggable
                  key={text._id}
                  index={index}
                  draggableId={text._id + "_dragID"}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      onMouseEnter={() => mouseEnter(text._id)}
                      onMouseLeave={mouseLeave}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        isEdit,
                        text._id || ""
                      )}
                    >
                      <ListItemText
                        primary={
                          <RemoveAndEditBtn
                            loadDuplicate={loadDuplicate}
                            duplicateUnit={duplicateUnit}
                            index={index}
                            setWindowOrDoor={setWindowOrDoor}
                            setEditQuote={setEditQuote}
                            unit={text}
                            quoteId={quoteId}
                            handleDeleteCheck={handleCheck}
                            showChecks={showChecks}
                            hoverId={hoverId}
                            status={status}
                          />
                        }
                        secondary={
                          <QuoteList checked={checkedPD} text={text} />
                        }
                      />
                      <QuantityUpdate
                        changeQuantity={changeQuantity}
                        unitId={text._id || ""}
                        unitQuantity={text.quantity}
                      />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DragDropList;
