import React, { useEffect, useState, memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ReusableAccordion from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import {
  lockRailSlider,
  lockRail,
  lockrailEven,
  lockrailFixedPanel,
  lockRailFPSlider,
  lockRailFritsSwing,
} from "./defaults";
import { getStileImageLabels, getStilesImage } from "./findImages";
import LockStileChoices from "./LockStileChoices";
import { showStilesOptions } from "./showStilesOptions";
import { showStilesOptions2ndLevel } from "./showStilesOptionsSecondLevel";
import { pivotLimits } from "./panelWidthLimitations";
import fractionToInt from "../../util/fractionToInt";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";
import { calculatePanelWidth } from "../../util/pdfFormulas/calculatePanelWidth";

const StileLabel = styled(Typography)({
  position: "relative",
  zIndex: 10,
  top: 25,
  fontWeight: "bold",
});

const StyledDivImgContainer = styled("div")({
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  justifyContent: "center",
});

const RowLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
  // width: "100%",
  justifyContent: "center",
}));

const ColLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

interface StilesRailsProps {
  withPocket?: string | undefined
  doorType: string
  evenConfig?: boolean
}

interface UpdateObj {
  lockRail: string
  stileCategory: string
  liteConfig?: string
  stilesAndRails?: string
}

const StilesRailsAccordian: FC<StilesRailsProps> = ({
  withPocket,
  doorType,
  evenConfig,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const bifoldConfiguration = useStore((state) => state.quote.configuration);
  const chosenConfig = useStore((state) => state.quote.exactConfig);

  const sliderPocket = useStore((state) => state.quote.sliderPocket);

  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);

  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore(
    (state) => state.quote.swingPanelFraction
  );

  const addDL = useStore((state) => state.quote.addDL);

  const overallPW = !swingPanelWidth
    ? 0
    : fractionToInt(swingPanelWidth, swingPanelFraction);

  const woodSpecies = useStore((state) => state.quote.woodSpecies);

  const accoyaChosen = woodSpecies?.includes("Accoya");

  const material = useStore((state) => state.quote.material);

  const materialChoice = material.includes("Clad")
    ? "clad"
    : material === "All Wood"
    ? "wood"
    : "";

  const stileCategory = useStore((state) => state.quote.stileCategory);
  const stiles = useStore((state) => state.quote.stilesAndRails);

  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const overallWidth = fractionToInt(unitSize, unitSizeFraction);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[8].expanded);

  const [panelErr, setPanelErr] = useState<{ slimline?: string, euro?: string, euroTrad?: string }>({ slimline: "" });

  const { dsi, dsi2 } = getStilesImage(
    stiles || "",
    panelErr,
    material,
    accoyaChosen,
    doorType,
    evenConfig,
    doorType === "Pivot" && pivotHardware ? pivotHardware : chosenConfig,
    doorType === "Pivot" && pivotAction ? pivotAction : "",
    doorType !== "Slider" ? 0 : overallWidth,
    !height || doorType !== "Slider"
      ? 0
      : fractionToInt(height, heightFraction),
    chosenConfig
  );

  const { label1, label2 } = getStileImageLabels(
    stiles || "",
    doorType === "Slider" || chosenConfig === "Fixed Slider",
    doorType === "Pivot" && pivotHardware ? pivotHardware : "",
    doorType === "Pivot" && pivotAction ? pivotAction : ""
  );

  const showDisclaimerStiles =
    stileCategory === "Traditional" && doorType !== "Slider";

  const panelWidthSlimline =
    materialChoice !== "wood"
      ? calculatePanelWidth(
          "Slider",
          overallWidth,
          0,
          material,
          chosenConfig,
          "Slimline",
          null,
          "",
          "",
          sliderPocket
        )
      : 0;

  useEffect(() => {
    if(doorType !== "Slider") return

    if(panelWidthSlimline && panelWidthSlimline > 60){
      setPanelErr({
        slimline: "Slimline Stiles not available for this Width and Configuration Combination",
      });
    }else{
      setPanelErr({
        slimline: "",
      });
    }
  }, [panelWidthSlimline])

  useEffect(() => {
    if (doorType !== "Pivot") return;

    pivotLimits(
      overallPW,
      setPanelErr,
      resetStiles,
      pivotHardware || "",
      pivotAction || "",
      stiles || ""
    );
  }, [overallPW, pivotHardware, pivotAction]);

  const resetStiles = () => {
    addToQuote({
      stilesAndRails: "",
      stileCategory: "",
    });
  };

  const showSlimline =
    (doorType === "Bi-fold" &&
      evenConfig &&
      stileCategory?.includes("Slimline")) ||
    (doorType === "Bi-fold Window" &&
      stileCategory?.includes("Slimline") &&
      bifoldConfiguration !== "3L OR 3R" &&
      bifoldConfiguration !== "5L OR 5R") ||
    (doorType === "Bi-fold Zero Corner" &&
      evenConfig &&
      stileCategory?.includes("Slimline")) ||
    (doorType === "Fixed Panel" &&
      chosenConfig !== "Fixed Slider" &&
      stileCategory?.includes("Slimline"))
      ? true
      : false;

  const showTradBtms = stileCategory === "Traditional";

  const showLockRail =
    (stiles &&
      stiles.includes("Slimline") &&
      materialChoice === "clad" &&
      !panelErr.slimline) ||
    (!showSlimline &&
      stiles &&
      stiles.includes("Slimline") &&
      !panelErr.slimline)
      ? true
      : false;

  const handleSlimline = (checked: boolean, type: string) => {
    if (!checked) {
      addToQuote(
        {
          [type]: "",
          lockRail: "",
          stileCategory: "",
        },
        type
      );
      return;
    }
    let val;

    const slimTrad =
      (doorType === "Bi-fold" && evenConfig) ||
      (doorType === "Bi-fold Window" &&
        bifoldConfiguration !== "3L OR 3R" &&
        bifoldConfiguration !== "5L OR 5R") ||
      (doorType === "Bi-fold Zero Corner" && evenConfig) ||
      (doorType === "Fixed Panel" && chosenConfig !== "Fixed Slider")
        ? true
        : false;

    if (!slimTrad && findRequired("Stiles and Rails"))
      removeRequired("Stiles and Rails");

    !slimTrad ? (val = "Slimline") : (val = "");

    let lock = "";

    if (doorType !== "Slider") {
      lock = !slimTrad ? '2-1/2" Stiles and Rails with 3-7/16" Lock Stile' : "";
    } else {
      lock =
        '2-1/2" Stiles and Top Rails with 3-7/16" Lock Stile and Bottom Rails';
    }

    addToQuote(
      {
        [type]: val,
        lockRail: lock,
        stileCategory: "Slimline",
      },
      type
    );
  };

  const clearStiles = (type: string) => {
    addToQuote(
      {
        [type]: "",
        stileCategory: "",
      },
      type
    );
  };

  const handleStilesClick1stLevel = (label: string, type: string) => {
    const checked =
      label.split(" ")[0] === stileCategory ||
      label.split(" ")[0] === stileCategory?.split(" ")[0];

    if (checked) {
      if (label.includes("Slimline")) {
        handleSlimline(false, type);
      } else if (label.includes("Euro")) {
        clearStiles(type);
      } else if (label.includes("Traditional")) {
        clearStiles(type);
      }
    } else {
      if (label.includes("Slimline")) {
        handleSlimline(true, type);
        if (doorType === "Pivot" && addDL === "Add Intermediate Rail") {
          addToQuote({ liteConfig: '2 1/2"' });
        }
      } else if (label.includes("Euro")) {
        if (findRequired("Stiles and Rails"))
          removeRequired("Stiles and Rails");

        let updateObj: UpdateObj = {
          [type]: label,
          lockRail: "",
          stileCategory: "Euro",
        };

        if (doorType === "Pivot" && addDL === "Add Intermediate Rail") {
          updateObj["liteConfig"] = '2 1/2"';
        }

        addToQuote(updateObj, "stilesAndRails");
      } else if (label.includes("Traditional")) {
        let updateObj: UpdateObj = {
          stilesAndRails: "",
          lockRail: "",
          stileCategory: "Traditional",
        };

        if (doorType === "Pivot" && addDL === "Add Intermediate Rail") {
          updateObj["liteConfig"] = '5 1/4"';
        }

        addToQuote(updateObj, "stilesAndRails");
      }
    }
  };

  const handleStilesClick2ndLevel = (label: string, type: string) => {
    const checked = label === stiles;

    if (checked) {
      if (label.includes("Slimline")) {
        handleSlimline(false, type);
      } else if (label.includes("Euro")) {
        clearStiles(type);
      } else if (label.includes("Traditional")) {
        clearStiles(type);
      }
    } else {
      if (label.includes("Slimline")) {
        let category = !label.includes("Traditional")
          ? "Slimline"
          : "Slimline Traditional";
        let rail = !label.includes("Traditional") ? '2-1/2"' : '4-3/4"';

        if (findRequired("Stiles and Rails")) {
          removeRequired("Stiles and Rails");
        }

        addToQuote({
          [type]: label,
          stileCategory: category,
          lockRail: `2-1/2" Stiles and Rails with ${rail} Lock Stile`,
        });
      } else if (label.includes("Bottom")) {
        if (findRequired("Stiles and Rails")) {
          removeRequired("Stiles and Rails");
        }

        addToQuote({ [type]: label }, `${type}`);
      }
    }
  };

  const [showLabels, setShowLabels] = useState(false);

  const showImageLabels = () => setShowLabels(true);

  return (
    <ReusableAccordion
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="stilesRails"
      heading="Stiles and Rails"
      secondaryHeading="Select desired railing"
      colOrRow="col"
    >
      {showDisclaimerStiles &&
        materialChoice === "clad" &&
        doorType === "Swing" && (
          <Typography variant="subtitle1">
            * I89 must be added to meet Title 24
          </Typography>
        )}
      {(doorType === "Slider" || doorType === "Pivot") && panelErr.slimline && (
        <Alert severity="error" style={{ marginBottom: 10 }}>
          {panelErr.slimline}
        </Alert>
      )}
      {doorType === "Pivot" && panelErr.euro && (
        <Alert severity="error" style={{ marginBottom: 10 }}>
          {panelErr.euro}
        </Alert>
      )}

      <RowLayout>
        {showStilesOptions(
          doorType,
          materialChoice,
          stileCategory || "",
          handleStilesClick1stLevel,
          chosenConfig,
          panelErr
        )}
      </RowLayout>

      {showSlimline &&
      (materialChoice === "clad" || doorType === "Bi-fold Window") ? (
        <RowLayoutTopDivider>
          {showStilesOptions2ndLevel(
            doorType,
            materialChoice,
            "slim",
            handleStilesClick2ndLevel,
            stiles || ""
          )}
        </RowLayoutTopDivider>
      ) : showTradBtms && stileCategory ? (
        <RowLayoutTopDivider>
          {showStilesOptions2ndLevel(
            doorType,
            materialChoice,
            "trad",
            handleStilesClick2ndLevel,
            stiles || ""
          )}
        </RowLayoutTopDivider>
      ) : null}

      {showLockRail &&
        ((materialChoice !== "wood" &&
          !accoyaChosen &&
          !evenConfig &&
          doorType !== "Bi-fold Window") ||
          (doorType === "Bi-fold Window" &&
            (bifoldConfiguration === "3L OR 3R" ||
              bifoldConfiguration === "5L OR 5R"))) && (
          <ColLayoutTopDivider>
            {(stiles === "Slimline Traditional" || materialChoice === "wood") &&
              chosenConfig !== "Fixed Full Bound" &&
              !doorType.includes("Bi-fold") && (
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  * Title 24 numbers not available
                </Typography>
              )}

            <RowLayout>
              {doorType === "Pivot" &&
              pivotHardware === "Fritsjurgens" &&
              pivotAction === "Free Swing" ? (
                <LockStileChoices label={lockRailFritsSwing.label} />
              ) : // : doorType === "Pivot" && pivotHardware === "Fritsjurgens" && pivotAction === "Self Closing" ? (
              //   <LockStileChoices label={lockRailFritsSelfClosing.label} />
              // )
              doorType !== "Slider" && chosenConfig !== "Fixed Slider" ? (
                <LockStileChoices label={lockRail.label} />
              ) : !["PX", "XP", "PX-AP", "PA-XP"].includes(chosenConfig) ? (
                <LockStileChoices label={lockRailSlider.label} />
              ) : null}
            </RowLayout>
          </ColLayoutTopDivider>
        )}

      {showLockRail &&
        ((materialChoice !== "wood" &&
          !accoyaChosen &&
          evenConfig &&
          doorType !== "Bi-fold Window") ||
          (doorType === "Bi-fold Window" &&
            bifoldConfiguration !== "3L OR 3R" &&
            bifoldConfiguration !== "5L OR 5R")) && (
          <ColLayoutTopDivider>
            {doorType !== "Bi-fold Window" && doorType !== "Fixed Panel" && (
              <Typography variant="subtitle1">
                {lockrailEven.label} is required with even configuration
              </Typography>
            )}

            {(stiles === "Slimline Traditional" || materialChoice === "wood") &&
              chosenConfig !== "Fixed Full Bound" &&
              !doorType.includes("Bi-fold") && (
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  * Title 24 numbers not available
                </Typography>
              )}

            <RowLayout>
              {chosenConfig === "Fixed Slider" ? (
                <LockStileChoices label={lockRailFPSlider.label} />
              ) : doorType === "Fixed Panel" ? (
                <LockStileChoices label={lockrailFixedPanel.label} />
              ) : (
                <LockStileChoices label={lockrailEven.label} />
              )}
            </RowLayout>
          </ColLayoutTopDivider>
        )}

      {stiles && dsi && (
        <RowLayout style={{ marginTop: -20 }}>
          <ColLayout>
            {showLabels && <StileLabel variant="body2">{label1}</StileLabel>}
            <StyledDivImgContainer>
              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId={`StilesAndRails/${dsi}`}
                onLoad={showImageLabels}
              />
            </StyledDivImgContainer>
          </ColLayout>

          {dsi2 && (
            <ColLayout>
              {showLabels && <StileLabel variant="body2">{label2}</StileLabel>}
              <StyledDivImgContainer>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={`StilesAndRails/${dsi2}`}
                />
              </StyledDivImgContainer>
            </ColLayout>
          )}
        </RowLayout>
      )}
    </ReusableAccordion>
  );
}

export default memo(StilesRailsAccordian);
