const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/CutSheets";

const groupP1 = ["PX", "XP", "PX-AP", "PA-XP"];

function getConfigLabel(config: string) {
  let label = "";

  if (config.includes("O")) {
    if (config.includes("-")) {
      label = config.replace("A", "X").replace("-", "_");
      return "_" + label;
    }
    const configArray = config.replace("O", "X").split("");
    if (config.slice(0, 1) === "O") {
      configArray.splice(-1, 1, "O");
      label = `${config}_${configArray.join("")}`;
    } else {
      configArray.splice(0, 1, "O");
      label = `${configArray.join("")}_${config}`;
    }
  } else if (config.includes("Primary")) {
    label = config.split(" ")[0].trim();
    return "_" + label;
  } else if (config.includes("P")) {
    if (config.includes("-")) {
      label = config.replace("A", "X").replace("-", "_");
      return "_" + label;
    }
    const configArray = config.replace("P", "X").split("");
    if (config.slice(0, 1) === "P") {
      configArray.splice(-1, 1, "P");
      label = `${config}_${configArray.join("")}`;
    } else {
      configArray.splice(0, 1, "P");
      label = `${configArray.join("")}_${config}`;
    }
  }

  return "_" + label;
}

function getPocketJamb(
  config: string,
  jamb: string,
  material?: string,
  sill?: string
) {
  return !groupP1.includes(config)
    ? ""
    : material && !material.includes("Clad") && sill?.includes("Steel")
    ? "_TRACK"
    : jamb === "3 7/8"
    ? "_3.875"
    : jamb === "4 1/8"
    ? "_4.125"
    : jamb === "5 3/4"
    ? "_5.75"
    : "_6";
}

function headJambImagesSlider(
  doorMaterial: string,
  screenType: string,
  screen: string,
  slidingScreen: string,
  config: string,
  handle: string,
  jamb: string
) {
  const isCentor = screen && screenType?.includes("Centor") ? true : false;

  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const screenLabel =
    !screen && !slidingScreen
      ? ""
      : slidingScreen
      ? "_SLIDING_SCREEN"
      : isCentor
      ? "_CENTOR_SCREEN"
      : screen
      ? "_SCREEN"
      : "";

  const configLabel = getConfigLabel(config);

  const handleLabel =
    !screen || !config.includes("O")
      ? ""
      : isCentor
      ? ""
      : handle && handle.includes("Truth")
      ? "_TRUTH"
      : "_HOPPE";

  const pocketJamb = 
    isCentor 
      ? "" 
      : doorMaterial.includes("Clad")
      ? getPocketJamb(config, jamb)
      : "";

  const cloudinaryID =
    `${material}${screenLabel}${configLabel}${handleLabel}${pocketJamb}`.trim();

  return `${CLOUDINARY_URL}/headJamb/Slider/${cloudinaryID}.jpg`;
}

function sillImagesSlider(
  doorMaterial: string,
  sill: string,
  screenType: string,
  screen: string,
  slidingScreen: string,
  config: string,
  handle: string,
  isPocketing: boolean,
  jamb: string
) {
  const isCentor = screen && screenType?.includes("Centor") ? true : false;

  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  const screenLabel =
    !screen && !slidingScreen
      ? ""
      : slidingScreen
      ? "_SLIDING_SCREEN"
      : isCentor
      ? "_CENTOR_SCREEN"
      : screen
      ? "_SCREEN"
      : "";

  const configLabel = getConfigLabel(config);

  const sillLabel = sill.includes("Steel") ? "_TRACK" : "";

  const handleLabel =
    !screen || isPocketing
      ? ""
      : isCentor
      ? ""
      : handle && handle.includes("Truth")
      ? "_TRUTH"
      : "_HOPPE";

  const pocketJamb = 
    isCentor 
    ? "" 
    : doorMaterial.includes("Clad")
    ? getPocketJamb(config, jamb)
    : "";

  const cloudinaryID =
    `${material}${screenLabel}${configLabel}${sillLabel}${handleLabel}${pocketJamb}`.trim();

  return `${CLOUDINARY_URL}/sill/Slider/${cloudinaryID}.jpg`;
}

function jambLegImagesSlider(
  doorMaterial: string,
  screenType: string,
  screen: string,
  slidingScreen: string,
  screenLayout: string,
  screenPocket: string,
  config: string,
  handle: string,
  stiles: string,
  jamb: string,
  sill: string
) {
  const doubleSided =
    config.includes("-") ||
    config === "OX" ||
    config === "XO" ||
    config === "PX" ||
    config === "XP"
      ? true
      : false;

  const isCentor = screen && screenType?.includes("Centor") ? true : false;

  const material = doorMaterial.includes("Clad") ? "CLAD" : "WOOD";

  let slimline = !isCentor && stiles.includes("Slimline") ? "_SLIMLINE" : "";

  if (
    config === "PX" ||
    config === "XP" ||
    config === "PX-AP" ||
    config === "PA-XP"
  ) {
    slimline = "";
  }

  const screenLabel =
    !screen && !slidingScreen
      ? ""
      : slidingScreen
      ? "_SLIDING_SCREEN"
      : isCentor
      ? "_CENTOR_SCREEN"
      : screen &&
        screen.includes("Double") &&
        screenPocket &&
        screenPocket.includes("Double") &&
        doubleSided
      ? "_SCREEN_PKT_DBL"
      : screen &&
        screen.includes("Double") &&
        screenPocket &&
        screenPocket.includes("Double") &&
        !doubleSided
      ? "_DBL_SCREEN_PKT_DBL"
      : screen &&
        screen.includes("Double") &&
        screenPocket &&
        screenPocket.includes("Left") &&
        !doubleSided
      ? "_DBL_SCREEN_PKT_LEFT"
      : screen &&
        screen.includes("Double") &&
        screenPocket &&
        screenPocket.includes("Right") &&
        !doubleSided
      ? "_DBL_SCREEN_PKT_RIGHT"
      : screen && !screenPocket
      ? "_SCREEN"
      : screen && screenLayout && screenLayout.includes("Left") && screenPocket
      ? "_SCREEN_PKT_LEFT"
      : screen && screenLayout && screenLayout.includes("Right") && screenPocket
      ? "_SCREEN_PKT_RIGHT"
      : "";

  let configLabel = `_${config.replace("-", "_")}`;

  if (config.includes("Primary")) {
    let lbl: string | string[] = config.split(" ")[0].split("");

    if (config.includes("Left")) {
      lbl.splice(0, 1, "A");
      lbl = lbl.join("");
    } else {
      lbl.splice(-1, 1, "A");
      lbl = lbl.join("");
    }

    configLabel = `_${lbl}`;
  }

  const handleLabel = !config.includes("O")
    ? ""
    : handle && handle.includes("Truth")
    ? "_TRUTH"
    : "_HOPPE";

  const pocketJamb = 
    isCentor
      ? "" 
      : getPocketJamb(config, jamb, doorMaterial, sill);

  const cloudinaryID =
    `${material}${slimline}${screenLabel}${configLabel}${handleLabel}${pocketJamb}`.trim();

  return `${CLOUDINARY_URL}/jamb/Slider/${cloudinaryID}.jpg`;
}

export { headJambImagesSlider, sillImagesSlider, jambLegImagesSlider };
