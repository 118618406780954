import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { roundTo4 } from "./StoryPole";
import "../fontRegister"

const styles = StyleSheet.create({
  body: {
    padding: 10,
    width: 280,
  },
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeaderLS: {
    width: "40%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "16.67%",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontWeight: 500,
  },
  tableCell: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
  },
  tableCellBifoldOpening: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontFamily: "Roboto-Bold",
    fontWeight: 'bold'
  },
});

interface LockStileProps {
  panelWidth?: number
  panelHeight?: number
  qty: number
  glassOpening: number
  glassOpeningHeight: number
  isBifold?: boolean
  isIRailFrame?: boolean
  isIRail?: boolean
}

const LockStileTable: FC<LockStileProps> = ({
  panelWidth,
  panelHeight,
  qty,
  glassOpening,
  glassOpeningHeight,
  isBifold,
  isIRailFrame,
  isIRail
}) => {
  return (
    <View style={[styles.body, { paddingLeft: 0 }]}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableColHeaderLS, { width: "20%" }]}>
            <Text style={styles.tableCellHeader}>{qty}x</Text>
          </View>
          <View style={styles.tableColHeaderLS}>
            <Text style={styles.tableCellHeader}>WIDTH</Text>
          </View>
          <View style={styles.tableColHeaderLS}>
            <Text style={styles.tableCellHeader}>HEIGHT</Text>
          </View>
        </View>

        {!isIRail ?
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}>DOOR</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}>{panelWidth}</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}>{panelHeight}</Text>
          </View>
        </View>
        :
        <Text />
        }

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}>OPENING</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={isBifold ? styles.tableCellBifoldOpening : styles.tableCell}>{glassOpening}</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}>{glassOpeningHeight}</Text>
          </View>
        </View>

        {!isIRailFrame ?
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}>GLASS</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}>{Math.floor(roundTo4(glassOpening - .125) * 16)/16}</Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}>{Math.floor(roundTo4(glassOpeningHeight - .125) * 16)/16}</Text>
          </View>
        </View>
        :
        <Text />
        }
      </View>
    </View>
  );
};

export default LockStileTable;
