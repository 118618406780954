import React, { FC } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";

import JambLegSection from "./JambLegSection";
import HeadSillSection from "./HeadSillSection";
import RefKey from "./RefKey";
import Lite from "./Lite";
import EVandPV from "./EVandPV";
import {
  getBifoldKerfImage,
  getSwingKerfImage,
  getSliderKerfImage,
} from "./Lite";
import { styles } from "./Styles";
import { QuoteObj } from "../../GlobalTypes";
import { UnitObj } from "../../util/quoteCartStorage";

interface HeaderProps {
  form: QuoteObj;
  today?: string;
  time?: string;
  isDealer?: boolean;
  isProduction?: boolean;
  salesOrder?: string;
}

export const Header: FC<HeaderProps> = ({
  form,
  today,
  time,
  isDealer,
  isProduction,
  salesOrder,
}) => {
  return (
    <View style={styles.header}>
      <View style={styles.company}>
        <Image
          style={styles.logo}
          src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoBlack.png"
        />
        <View style={[styles.row, { paddingLeft: 20 }]}>
          <View style={[styles.column, { paddingRight: 10 }]}>
            <Text style={styles.address}>3111 Golf Course Dr</Text>
            <Text style={styles.address}>Ventura, CA 93003</Text>
          </View>
          <View style={[styles.column, { paddingLeft: 10 }]}>
            <Text style={styles.address}>T: 1 (800) 559-9995</Text>
            <Text style={styles.address}>F: (805) 644-4493</Text>
          </View>
        </View>
        <View style={styles.column}>
          <Text
            style={styles.pageAndDate}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />

          {!form.dateOrdered ? (
            <Text style={styles.pageAndDate}>
              Time/Date: {today} {time}
            </Text>
          ) : form.dateOrdered && !form.depositReceivedDate ? (
            <View style={styles.rowStart}>
              <Text style={styles.pageAndDate}>
                Time/Date: {today} {time}
              </Text>
              <Text style={[styles.pageAndDate, { paddingLeft: 10 }]}>
                Sign-Off Date:{" "}
                {new Date(form.dateOrdered).toLocaleDateString("en-US")}
              </Text>
            </View>
          ) : form.dateOrdered && form.depositReceivedDate ? (
            <View style={styles.rowStart}>
              <Text style={styles.pageAndDate}>
                Time/Date: {today} {time}
              </Text>
              <Text style={[styles.pageAndDate, { paddingLeft: 10 }]}>
                Deposit Received:{" "}
                {new Date(form.depositReceivedDate).toLocaleDateString(
                  "en-US",
                  { timeZone: "UTC" }
                )}
              </Text>
            </View>
          ) : (
            <Text></Text>
          )}
        </View>
      </View>
      <View style={styles.dealerInfo}>
        {isDealer && !isProduction ? (
          <Text style={styles.dealer}>DEALER DRAWINGS</Text>
        ) : isDealer && isProduction ? (
          <Text style={styles.dealer}>PRODUCTION ORDER</Text>
        ) : (
          <View style={styles.customerQDiv}>
            <Text style={styles.customer}>CUSTOMER DRAWINGS</Text>
          </View>
        )}

        {!salesOrder ? (
          <Text style={styles.quoteNumber}>Quote #: PC-{form.quoteNumber}</Text>
        ) : (
          <Text style={styles.quoteNumber}>Sales Order #: {salesOrder}</Text>
        )}

        <Text style={[styles.text, { fontSize: 10 }]}>
          Dealer: {form.dealer}
        </Text>
        <Text style={[styles.text, { fontSize: 10 }]}>
          Dealer Sales Rep: {form.salesperson}
        </Text>
      </View>
    </View>
  );
};

interface DealerDrawingsProps extends HeaderProps {
  item: UnitObj;
  unit: string;
}

const DealerDrawings: FC<DealerDrawingsProps> = ({
  isDealer,
  form,
  item,
  unit,
  salesOrder,
  isProduction,
}) => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed).toLocaleDateString();
  const time = new Date(timeElapsed).toLocaleTimeString();

  const signline = "${signfield:1:y:______}";
  const dateSigned = "${datefield:1:y:Date_Signed}";

  const withDividedLites = item.addDL && item.dlLayout ? true : false;
  const withSideliteDividedLites =
    item.doorType === "Swing" &&
    item.addDL &&
    item.diffSideliteSdl &&
    item.dlLayoutSidelite
      ? true
      : false;

  return (
    <>
      <Page>
        <Header
          form={form}
          salesOrder={salesOrder}
          today={today}
          time={time}
          isDealer={isDealer}
          isProduction={isProduction}
        />

        <View style={[styles.column, { width: "100%" }]}>
          <View style={styles.rowStart2}>
            <View style={styles.unitNum}>
              <Text style={styles.unitNumTxt}>Unit #: {unit}</Text>
            </View>

            <View style={styles.evpvCol}>
              <EVandPV item={item} isProduction={isProduction} />
            </View>

            <View style={[styles.column, { width: "30%" }]}>
              <RefKey item={item} />

              <Lite item={item} />
            </View>
          </View>

          <View style={styles.rowSpacing}>
            <View style={[styles.column, { alignItems: "center" }]}>
              <Text style={[styles.textBold, { fontSize: 12 }]}>
                Jamb Leg Section View:
              </Text>

              <JambLegSection item={item} />
            </View>
            <View style={[styles.column, { alignItems: "center" }]}>
              <Text style={[styles.textBold, { fontSize: 12 }]}>
                Head/Sill Section View:
              </Text>

              <HeadSillSection item={item} />
            </View>
          </View>
        </View>

        {isDealer ? (
          <View style={styles.fixedBox}>
            <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
            <View style={{ position: "relative" }}>
              <Text style={styles.dateSigned}>{dateSigned}</Text>
            </View>
          </View>
        ) : (
          <View style={styles.initialsBox}>
            <Text style={styles.textBold}>Initials: __________</Text>
          </View>
        )}
      </Page>

      {(withDividedLites || withSideliteDividedLites) && item.kerf ? (
        <Page>
          <Header
            form={form}
            salesOrder={salesOrder}
            today={today}
            time={time}
            isDealer={isDealer}
            isProduction={isProduction}
          />

          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.unitNum}>
              <Text style={styles.unitNumTxt}>Unit #: {unit}</Text>
            </View>
          </View>

          <View
            style={[styles.column, { alignItems: "center", marginTop: 50 }]}
          >
            <Text style={[styles.textBold, { fontSize: 12 }]}>
              Kerf Option:
            </Text>

            {item.kerfConfig !== "Custom Setback" ? (
              <Text style={[styles.textBold, { fontSize: 12 }]}>
                KF = {item.kerfConfig?.replace("Standard", "")}
              </Text>
            ) : item.kerfConfig === "Custom Setback" ? (
              <Text style={[styles.textBold, { fontSize: 12 }]}>
                KF = {item.kerfSetback}" Setback
              </Text>
            ) : (
              <Text style={[styles.textBold, { fontSize: 12 }]}>
                N/A (No Kerf)
              </Text>
            )}

            <Image
              src={
                item.doorType.includes("Bi-fold")
                  ? getBifoldKerfImage(item.addScreen || "", "")
                  : item.doorType === "Pivot"
                  ? getSwingKerfImage("Inswing", "", "")
                  : item.doorType === "Swing"
                  ? getSwingKerfImage(item.swingDirection || "", item.addScreen || "", "")
                  : getSliderKerfImage(item.addScreen || "")
              }
              style={{ height: 180, width: 144 }}
            />
          </View>

          {isDealer ? (
            <View style={styles.fixedBox2}>
              <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
              <View style={{ position: "relative" }}>
                <Text style={styles.dateSigned}>{dateSigned}</Text>
              </View>
            </View>
          ) : (
            <View style={styles.fixedBox2}>
              <Text style={styles.textBold}>Initials: __________</Text>
            </View>
          )}
        </Page>
      ) : null}
    </>
  );
};

export default DealerDrawings;
