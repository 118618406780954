import React, { useEffect, memo, useMemo, FC } from "react";
import {
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  Typography,
} from "@mui/material";
import ReusableAccordian from "../ReusableAccordion";
import DLselection from "../lites/DLselection";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import { findStopsImage } from "./findStopsImage";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
});

interface DLProps {
  doorType: string
}

const DLAccordian: FC<DLProps> = ({ doorType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const addDL = useStore((state) => state.quote.addDL);

  const liteConfig = useStore((state) => state.quote.liteConfig);

  const sLite = useStore((state) => state.quote.sideLite);
  const swingSidelite =
    (doorType === "Swing" || doorType === "Pivot") && sLite !== "none";

  const equalPanels = useStore((state) => state.quote.equalPanels);
  const equalPanelsChecked = equalPanels ? true : false;

  const material = useStore((state) => state.quote.material);

  const cladSdlShape = useStore((state) => state.quote.stickingStops);

  const woodInt = useStore((state) => state.quote.stickingStopsInterior);
  const woodExt = useStore((state) => state.quote.stickingStopsExterior);

  const materialChoice = useStore((state) => state.quote.material);

  const daylightOpening = useStore((state) => state.quote.daylightOpening);

  const dlLayout = useStore((state) => state.quote.dlLayout);
  const dlLayoutSidelite = useStore((state) => state.quote.dlLayoutSidelite);

  const sideliteSdl = useStore((state) => state.quote.diffSideliteSdl);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[24].expanded);
  const require = useAccordion((state) => state.expanded[24].require);

  const isSDL = addDL === "Add SDL";

  const text = isSDL
    ? "Simulated Divided Lites"
    : doorType === "Pivot"
    ? "Intermediate Rail"
    : "True Divided Lites";

  const displayedDl = useMemo(() => {
    const image = findStopsImage(
      doorType,
      material,
      isSDL,
      cladSdlShape || "",
      woodInt || "",
      woodExt || "",
      liteConfig || ""
    );

    return image;
  }, [woodInt, woodExt, isSDL, cladSdlShape, liteConfig]);

  const handleCheck = (e: React.ChangeEvent<{ checked: boolean }>, name: string) => {
    const { checked } = e.target;

    addToQuote({
      diffSideliteSdl: name === "no" && checked ? true : false,
    });

    if (findRequired("Sidelite Divided Lites Configuration"))
      removeRequired("Sidelite Divided Lites Configuration");
  };

  useEffect(() => {
    if (!swingSidelite || sideliteSdl === null) return;
    if (equalPanelsChecked) addToQuote({ diffSideliteSdl: false });
  }, [swingSidelite, equalPanels]);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="dl"
      heading={text}
      secondaryHeading={`Select ${text} shape`}
      colOrRow="col"
      required={require ? true : false}
    >
      <RowLayout>
        <DLselection
          layout={dlLayout}
          dlo={daylightOpening}
          isSDL={isSDL}
          swingSidelite={false}
          doorType={doorType}
          panelLayout=""
        />

        <ColLayout>
          {materialChoice.includes("Clad") && displayedDl && (
            <ColLayout style={{ marginLeft: 20, marginBottom: 60 }}>
              <Typography variant="caption">
                * Lites shape will match Stickings and Stops profiles
              </Typography>
              <div style={{ marginTop: 20 }}>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={displayedDl}
                />
              </div>
            </ColLayout>
          )}
          {materialChoice === "All Wood" && displayedDl && (
            <ColLayout style={{ marginLeft: 20, marginBottom: 60 }}>
              <Typography variant="caption">
                * Wood Divided Lites shape will match Stickings and Stops
                profiles
              </Typography>
              <div style={{ marginTop: 20 }}>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={displayedDl}
                />
              </div>
            </ColLayout>
          )}
          {!displayedDl && (
            <RowLayout>
              <Typography variant="body2">
                *Select a Stickings/Stops Profile to view your {text} shape
              </Typography>
            </RowLayout>
          )}
        </ColLayout>
      </RowLayout>

      {swingSidelite &&
        !equalPanelsChecked &&
        addDL !== "Add Intermediate Rail" && (
          <ColLayout>
            <StyledFormControl>
              <FormLabel style={{ color: "black" }} component="legend">
                Would you like the Sidelite SDL Configuration to match?
                <span style={{ color: "red", fontSize: 22 }}>*</span>
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      disabled={
                        swingSidelite && dlLayout === "9 Lite Marginal"
                          ? true
                          : false
                      }
                      checked={
                        sideliteSdl === null || sideliteSdl ? false : true
                      }
                      onChange={(e) => handleCheck(e, "yes")}
                    />
                  }
                  label="Yes"
                  labelPlacement="end"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      disabled={
                        swingSidelite && dlLayout === "9 Lite Marginal"
                          ? true
                          : false
                      }
                      checked={
                        sideliteSdl === null || !sideliteSdl ? false : true
                      }
                      onChange={(e) => handleCheck(e, "no")}
                    />
                  }
                  label="No"
                  labelPlacement="end"
                />
              </FormGroup>
            </StyledFormControl>

            {sideliteSdl && sideliteSdl !== null && (
              <>
                <Divider style={{ width: "120%", height: 3 }} />

                <Typography style={{ paddingTop: 20 }}>
                  Select a Sidelite SDL Configuration:
                </Typography>

                <DLselection
                  panelLayout={dlLayout || ""}
                  layout={dlLayoutSidelite}
                  isSDL={isSDL}
                  swingSidelite={true}
                  doorType={doorType}
                />
              </>
            )}
          </ColLayout>
        )}
    </ReusableAccordian>
  );
}

export default memo(DLAccordian);
