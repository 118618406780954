const baseURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

function swingCloudinaryImg(
  swingDirection: string,
  swingType: string,
  sidelite: string,
  equalPanels: boolean,
  hinge: string,
  sideliteSide: string
) {
  var ev;
  var pv;

  const direction =
    swingDirection === "Outswing"
      ? "OS"
      : swingDirection === "Inswing"
      ? "IS"
      : "";

  if (!equalPanels) {
    const type =
      swingType === "Pair Swing"
        ? "PAIR"
        : swingType === "Single Swing"
        ? "SINGLE"
        : "";

    const evHinge =
      swingType === "Pair Swing"
        ? ""
        : swingType === "Single Swing" && hinge
        ? `_${hinge}`
        : "";

    let sl =
      sidelite === "Add Double Sidelite"
        ? "_2xSL"
        : sidelite === "Add Single Sidelite" && hinge === "LH"
        ? "_LEFT_SL"
        : sidelite === "Add Single Sidelite" && hinge === "RH"
        ? "_RIGHT_SL"
        : "";

    const singleWithSingleSidelite =
      swingType === "Single Swing" &&
      sidelite === "Add Single Sidelite" &&
      sideliteSide === "LOCK"
        ? true
        : false;

    if (singleWithSingleSidelite) {
      sl = hinge === "LH" ? "_RIGHT_SL" : "_LEFT_SL";
    }

    ev = `${baseURL}/elevationView/swings/${type}${evHinge}${sl}_EV.jpg`;
    pv = `${baseURL}/planView/swings/${direction}_${type}_${hinge}${sl}_PLAN.png`;
  } else {
    const type =
      swingType === "Pair Swing"
        ? "_PAIR"
        : swingType === "Single Swing"
        ? "_SINGLE"
        : "";

    let sl =
      sidelite === "Add Double Sidelite"
        ? "_2xSL"
        : sidelite === "Add Single Sidelite"
        ? "_1xSL"
        : "";

    const singleWithSingleSidelite =
      swingType === "Single Swing" &&
      sidelite === "Add Single Sidelite" &&
      sideliteSide === "LOCK"
        ? "_LOCK"
        : "";

    ev = `${baseURL}/elevationView/swings/${hinge}${type}${sl}_EVEN${singleWithSingleSidelite}.jpg`;
    pv = `${baseURL}/planView/swings/${direction}_${hinge}${type}${sl}_EVEN${singleWithSingleSidelite}.png`;
  }

  return {
    ev,
    pv,
  };
}

function swingProdCloudinaryImg(
  swingDirection: string,
  swingType: string,
  sidelite: string,
  equalPanels: boolean,
  hinge: string,
  sideliteSide: string,
  stiles: string
) {
  var ev;

  const direction =
    swingDirection === "Outswing"
      ? "OS"
      : swingDirection === "Inswing"
      ? "IS"
      : "";

  const type =
    swingType === "Pair Swing"
      ? "PAIR"
      : swingType === "Single Swing"
      ? "SINGLE"
      : "";

  let sl =
    sidelite === "Add Double Sidelite"
      ? "_2xSL"
      : sidelite === "Add Single Sidelite" && hinge === "LH"
      ? "_LEFT_SL"
      : sidelite === "Add Single Sidelite" && hinge === "RH"
      ? "_RIGHT_SL"
      : "";

  const singleWithSingleSidelite =
    swingType === "Single Swing" &&
    sidelite === "Add Single Sidelite" &&
    sideliteSide === "LOCK"
      ? true
      : false;

  if (singleWithSingleSidelite) {
    sl = hinge === "LH" ? "_RIGHT_SL" : "_LEFT_SL";
  }

  if (!equalPanels) {
    const evHinge =
      swingType === "Pair Swing"
        ? ""
        : swingType === "Single Swing" && hinge
        ? `_${hinge}`
        : "";

    ev = `${baseURL}/elevationView/swings/${type}${evHinge}${sl}_EV.jpg`;
  } else {
    const typeE =
      swingType === "Pair Swing"
        ? "_PAIR"
        : swingType === "Single Swing"
        ? "_SINGLE"
        : "";

    let slE =
      sidelite === "Add Double Sidelite"
        ? "_2xSL"
        : sidelite === "Add Single Sidelite"
        ? "_1xSL"
        : "";

    const singleWithSingleSidelite =
      swingType === "Single Swing" &&
      sidelite === "Add Single Sidelite" &&
      sideliteSide === "LOCK"
        ? "_LOCK"
        : "";

    ev = `${baseURL}/elevationView/swings/${hinge}${typeE}${slE}_EVEN${singleWithSingleSidelite}.jpg`;
  }

  const stilesFolder = stiles.includes("Slimline") ? "Slim" : "EuroTrad";

  var pv = `${baseURL}/planView/swings/production/${stilesFolder}/${direction}_${type}_${hinge}${sl}.png`;

  return {
    ev,
    pv,
  };
}

export { swingCloudinaryImg, swingProdCloudinaryImg };
