import React, { useMemo, useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grow from "@mui/material/Grow";
import { useStore } from "../store/store";
import { Image, Transformation } from "cloudinary-react";
import findTitle24 from "../util/findTitle24";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTable = styled(Table)({
  width: 200,
  border: "1px solid black",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface DisplayTitle24Args {
  ufactor: string,
  shgc: string,
  cpd?: string
}

const Title24: FC = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const ufactor = useStore((state) => state.quote.ufactor);
  const shgc = useStore((state) => state.quote.shgc);

  const exactConfig = useStore((state) => state.quote.exactConfig);
  const stilesAndRails = useStore((state) => state.quote.stilesAndRails);
  const lockRail = useStore((state) => state.quote.lockRail);
  const material = useStore((state) => state.quote.material);
  const glassType = useStore((state) => state.quote.glassType);
  const sillStyle = useStore((state) => state.quote.sillStyle);
  const doorType = useStore((state) => state.quote.doorType);
  const i89coating = useStore((state) => state.quote.i89coating);
  const addDL = useStore((state) => state.quote.addDL);
  const liteConfig = useStore((state) => state.quote.liteConfig);
  const breatherTubes = useStore((state) => state.quote.breatherTubes);
  const swingDirection = useStore((state) => state.quote.swingDirection);
  const woodSpecies = useStore((state) => state.quote.woodSpecies);
  const addScreen = useStore((state) => state.quote.addScreen);
  const stileCategory = useStore((state) => state.quote.stileCategory);
  const customGlass = useStore((state) => state.quote.customGlass);

  const text =
    "* Select Stiles, Material, Sill, and Glass to view U-Factor and SHGC numbers here";

  const intUfactor = parseFloat(ufactor || "");
  const intShgc = parseFloat(shgc || "");

  const title24Compliant = intUfactor <= 0.3 && intShgc <= 0.23;

  useEffect(() => {
    async function handletitle24() {
      if(customGlass) {
        return displayTitle24()
      }

      let stiles =
        stilesAndRails === "Slimline" ||
        stilesAndRails === "Slimline Traditional"
          ? lockRail
          : stilesAndRails;

      const nonSliderFetchT24 =
        (material &&
          glassType &&
          stiles &&
          sillStyle &&
          sillStyle !== "Raised Sill" &&
          doorType !== "Slider") ||
        (doorType === "Fixed Panel" && material && glassType && stiles) ||
        (doorType === "Pivot" && material && glassType && stiles)
          ? true
          : false;

      const sliderFetchT24 =
        material && glassType && stiles && doorType === "Slider" ? true : false;

      if (!nonSliderFetchT24 && !sliderFetchT24) return;

      if (nonSliderFetchT24) {
        const title24 = await findTitle24(
          doorType,
          material,
          glassType,
          i89coating,
          stilesAndRails?.includes("Slimline") && material === "All Wood"
            ? "Slimline"
            : stiles,
          stileCategory,
          liteConfig,
          breatherTubes,
          woodSpecies,
          addScreen,
          swingDirection,
          sillStyle,
          addDL,
          exactConfig
        );

        if (title24 !== "Error") displayTitle24(title24);
      } else if (sliderFetchT24) {
        const title24 = await findTitle24(
          doorType,
          material,
          glassType,
          i89coating,
          stiles,
          stileCategory,
          liteConfig,
          breatherTubes,
          woodSpecies,
          addScreen
        );

        if (title24 !== "Error") displayTitle24(title24);
      }
    }

    handletitle24();
  }, [
    material,
    woodSpecies,
    glassType,
    stilesAndRails,
    sillStyle,
    doorType,
    breatherTubes,
    addScreen,
    liteConfig,
    lockRail,
    i89coating,
    exactConfig,
  ]);

  const displayTitle24 = (title24?: DisplayTitle24Args) => {
    if (!title24) {
      return addToQuote({
        ufactor: "0.53",
        shgc: "0.53",
        cpd: "",
      });
    }

    let update: DisplayTitle24Args = {
      ufactor: title24.ufactor,
      shgc: title24.shgc,
    };
    if (title24.cpd) {
      update["cpd"] = title24.cpd;
    }

    addToQuote(update);
  };

  const table = useMemo(() => {
    return (
      <Row>
        {ufactor && shgc ? (
          <TableContainer>
            <StyledTable size="small" aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    U-Factor
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {ufactor === "0.53" ? (
                      <span style={{ color: "red" }}>{ufactor} *</span>
                    ) : (
                      ufactor
                    )}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    SHGC
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {shgc === "0.53" ? (
                      <span style={{ color: "red" }}>{shgc} *</span>
                    ) : (
                      shgc
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        ) : (
          <Typography variant="body1">{text}</Typography>
        )}
        {title24Compliant && ufactor && shgc && (
          <div style={{ marginLeft: 20 }}>
            <Grow in={true}>
              <div>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId="title24"
                >
                  <Transformation width="100" height="100" crop="scale" />
                </Image>
              </div>
            </Grow>
          </div>
        )}
      </Row>
    );
  }, [intUfactor, intShgc]);

  return (
    <>
      {table}
      {customGlass ? 
       <Typography style={{ color: "red" }} variant="subtitle1">
        * NOTE: This unit has custom glass
        </Typography>
      :
      <>
        {(intUfactor === 0.53 || intShgc === 0.53) && (
          <Typography style={{ color: "red" }} variant="subtitle1">
            * NOTE: Does not meet title 24
          </Typography>
        )}
      </>
      }
    </>
  );
};

export default Title24;
