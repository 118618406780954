import React, { memo, FC } from "react";
import { Typography } from "@mui/material";
import usePersistedState from "../../hooks/usePersistedState";
import SwingWidthAccordian from "../SwingConfiguration/SwingWidthAccordian";
import SwingConfigs from "../SwingConfiguration/SwingConfigs";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { sideLite } from "./defaults";
import Choices from "../Choices";
import EqualPanels from "../SwingConfiguration/EqualPanels";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const RowLayoutLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledDivRowStart = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
});

const StyledDivRowStart2 = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  width: 410,
  paddingTop: 20,
});

interface PivotConfigProps {
  editQuote: boolean;
  submitPressed: boolean;
}

interface UpdateObj {
  equalPanels?: boolean;
  sideLite: string;
  exactConfigImg: string;
  exactConfig: string;
}

const PivotConfigAccordian: FC<PivotConfigProps> = ({
  editQuote,
  submitPressed,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);
  const editBtn = useStore((state) => state.isEdit);
  const sLite = useStore((state) => state.quote.sideLite);

  const handleErrMsgChange = useStore((state) => state.handleError);
  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[7].expanded);

  const handleClickSLite = (label: string) => {
    const checked = label === sLite;

    if (checked) return;

    let update: UpdateObj = {
      exactConfig: "",
      exactConfigImg: "",
      sideLite: label === "None" ? "none" : `Add ${label}`,
    };

    if (label === "None") {
      update["equalPanels"] = false;
    }

    addToQuote(update);
    handleErrMsgChange("sideliteWidthErr", "");
  };

  const [widthMeasureImg, setWidthMeasureImg] = usePersistedState(
    "widthMeasureImg",
    "",
    submitPressed,
    editQuote
  );

  return (
    <>
      <ReusableAccordian
        expanded={expanded}
        handleAccordClick={handleAccordClick}
        accordionName="swingDirection"
        heading="Configuration"
        secondaryHeading="Select Configuration"
        colOrRow="col"
        required={false}
        required5={true}
      >
        <Typography variant="caption">
          PLEASE NOTE: No warranty for water penetration, wind penetration, or
          airborne particles
        </Typography>

        <RowLayout style={{ justifyContent: "flex-start", width: "100%" }}>
          <ColLayout
            style={{ width: "50%", alignItems: "flex-end", paddingRight: 80 }}
          >
            <StyledDivRowStart>
              {sideLite.map((state) => (
                <div
                  key={state.id}
                  onClick={() => handleClickSLite(state.label)}
                >
                  <Choices
                    label={state.label}
                    variant={
                      state.label.toLowerCase() ===
                      sLite?.replace("Add ", "").toLowerCase()
                        ? "filled"
                        : "outlined"
                    }
                    checkMark={
                      state.label.toLowerCase() ===
                      sLite?.replace("Add ", "").toLowerCase()
                    }
                  />
                </div>
              ))}
            </StyledDivRowStart>

            {sLite && sLite !== "none" && (
              <StyledDivRowStart2>
                <EqualPanels isPivot={true} />
              </StyledDivRowStart2>
            )}
          </ColLayout>

          <RowLayoutLeftDivide style={{ paddingLeft: 80 }}>
            <SwingConfigs
              setWidthMeasureImg={setWidthMeasureImg}
              sType="Single Swing"
              sLite={sLite || ""}
              swingDir="Inswing"
              isPivot={true}
            />
          </RowLayoutLeftDivide>
        </RowLayout>
      </ReusableAccordian>

      <SwingWidthAccordian
        widthMeasureImg={widthMeasureImg}
        editBtn={editBtn}
        sideLites={sLite || ""}
        direction="Inswing"
        swingType="Single Swing"
        isPivot={true}
      />
    </>
  );
};

export default memo(PivotConfigAccordian);
