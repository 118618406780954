import React, { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { calcWithAdminCharge } from "../../../../util/calcWithAdminCharge";
import StatusTooltip from "./StatusTooltip";
import QuotePdf from "./pdf/QuotePdf";
import OrderPdf from "./pdf/OrderPdf";
import ProductionPdf from "./pdf/ProductionPdf";
import { StyledCheckbox } from "./StyledCheckbox";
import { TableCellsProps } from "../RoleTableCells";
import { CutsheetDataObj } from "../../../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledViewQuote = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
});

const StyledTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 200,
});

interface AdminInsideCellsProps extends TableCellsProps {
  loadingCutsheet: boolean
  cutsheetData: CutsheetDataObj[]
}

const AdminInsideSalesCells: FC<AdminInsideCellsProps> = ({
  row,
  tab,
  handleViewClick,
  viewPdfId,
  loadingCutsheet,
  dealer,
  isItemSelected,
  handleCheck,
  cutsheetData,
  handleViewPdf,
  numSelected,
  hoverId,
}) => {
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const isOrder =
    row.status !== "Quote" && row.status !== "Archived" ? true : false;

  return (
    <>
      {tab === "Insights" ? (
        <TableCell />
      ) : (
        <TableCell padding="checkbox">
          {(numSelected && numSelected > 0) || hoverId === row._id ? (
            <StyledCheckbox
              color="primary"
              onClick={(event: React.MouseEvent<any>) => handleCheck(event, row)}
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          ) : (
            <div style={{ width: 16, height: 16 }} />
          )}
        </TableCell>
      )}

      <TableCell onClick={() => handleViewClick && handleViewClick(row)} padding="checkbox">
        <StyledViewQuote variant="caption">View</StyledViewQuote>
      </TableCell>

      {tab === "Quotes" && (
        <QuotePdf
          row={row}
          viewPdfId={viewPdfId || ""}
          dealer={dealer || null} 
          handleViewPdf={handleViewPdf || null}
        />
      )}

      {tab === "Insights" ? (
        <>
          <TableCell align="center" scope="row" padding="none">
            {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}
          </TableCell>

          <TableCell align="center" scope="row" padding="none">
            {row.dateOrdered
              ? new Date(row.dateOrdered).toLocaleDateString("en-US")
              : "N/A"}
          </TableCell>
        </>
      ) : tab === "Quotes" ? (
        <>
          <TableCell align="center" scope="row" padding="none">
            {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}
          </TableCell>
          <TableCell align="center" scope="row" padding="none">
            {row.updatedAt
              ? new Date(row.updatedAt).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "N/A"}
          </TableCell>
        </>
      ) : (
        <TableCell align="center" scope="row" padding="none">
          {row.dateOrdered
            ? new Date(row.dateOrdered).toLocaleDateString("en-US")
            : "N/A"}
        </TableCell>
      )}

      {tab === "Insights" && isOrder ? (
        <OrderPdf 
          handleViewPdf={handleViewPdf || null}
          row={row}
          dealer={dealer || null}
          viewPdfId={viewPdfId || ""}
        />
      ) : tab === "Insights" && !isOrder ? (
        <QuotePdf
          row={row}
          viewPdfId={viewPdfId || ""}
          dealer={dealer || null}
          handleViewPdf={handleViewPdf || null}
        />
      ) : (
        tab === "Orders" && (
          <OrderPdf
            handleViewPdf={handleViewPdf || null}
            row={row}
            dealer={dealer || null}
            viewPdfId={viewPdfId || ""}
          />
        )
      )}

      <StyledTableCell align="center">
        {row.customerName && row.customerName.trim()
          ? row.customerName
          : "None"}
      </StyledTableCell>
      <TableCell align="center">{row.quoteNumber}</TableCell>

      {tab === "Orders" && (
        <ProductionPdf
          row={row}
          handleViewPdf={handleViewPdf || null}
          dealer={dealer || null}
          viewPdfId={viewPdfId || ""}
          loadingCutsheet={loadingCutsheet}
          cutsheetData={cutsheetData}
        />
      )}

      <TableCell align="center">{row.dealer}</TableCell>

      <TableCell align="center">{row.salesperson}</TableCell>

      <TableCell align="center">{row.poNumber || "None"}</TableCell>

      {(tab === "Orders" || tab === "Insights") && (
        <TableCell align="center">{row.salesOrder || "N/A"}</TableCell>
      )}

      {(tab === "Orders" || tab === "Insights") && (
        <TableCell align="center">{row.invoiceNumber || "N/A"}</TableCell>
      )}

      <TableCell align="center">
        {row.grandTotal && !row.adminCharge
          ? row.grandTotal
          : row.grandTotal && row.adminCharge
          ? calcWithAdminCharge(row.grandTotal, row.adminCharge)
          : "N/A"}
      </TableCell>

      <TableCell align="center">{row.totalUnits || "None"}</TableCell>

      {tab === "Insights" && (
        <TableCell align="center">
          {row.daysToClose ? row.daysToClose + " days" : "N/A"}
        </TableCell>
      )}

      <StatusTooltip role="Admin" row={row} tab={tab} />
    </>
  );
};

export default AdminInsideSalesCells;
