import axios from 'axios'
import { createBrowserHistory } from 'history'
import { checkForExpiredToken } from './Authentication';

const instance = axios.create();
const history = createBrowserHistory()

instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('auth-token') || "";
    const tokenNotExpired = checkForExpiredToken(token);
    if(!tokenNotExpired){
        history.push("/logout")
        window.location.reload()
        return config
    }
    config.headers['x-auth-token'] = token;
    return config;
}, (err) => {
    return Promise.reject(err)
});

export default instance;