import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "./Styles";
import round from "../../util/round";
import { calcWithAdminCharge } from "../../util/calcWithAdminCharge";
import priceWithZeros from "../../util/priceWithZeros";
import formatIntWithCommas from "../../util/formatIntWithCommas";
import { calcSuggestedRetail } from "./calcSuggestedRetail";
import {
  calcSalesTaxDollar,
  calcDealerTaxDollar,
} from "../../util/calcSalesTaxDollar";
import { UnitObj } from "../../util/quoteCartStorage";
import { QuoteObj } from "../../GlobalTypes";
import { AddCostObj } from "../../GlobalTypes";

const handleCustomerGrand = (gt: string) => {
  if (gt && gt.includes(".") && gt.split(".")[1].length === 1) {
    return `${gt}0`;
  } else if (gt && !gt.includes(".")) {
    return `${gt}.00`;
  }

  return gt;
};

const signline = "${signfield:1:y:______}";
const dateSigned = "${datefield:1:y:Date_Signed}";

var customPaint = (unit: UnitObj) =>
  unit.customPaintOrStain && unit.customPaintOrStain !== "";

interface SummaryDetailsProps {
  isCustomer?: boolean;
  data: QuoteObj;
  orderedUnits: UnitObj[];
  suggestedRetail: string;
  factor?: number;
  codOrTerms: string;
  grandTotal: string;
  addCosts: AddCostObj[];
  salesTax: number;
  margin: number;
  withMarkup: boolean | undefined;
  customClad: boolean;
  deliveryMarkup: number;
  dealerTax?: number | null;
}

const SummaryDetails: FC<SummaryDetailsProps> = ({
  isCustomer,
  data,
  orderedUnits,
  suggestedRetail,
  factor,
  codOrTerms,
  grandTotal,
  addCosts,
  salesTax,
  margin,
  withMarkup,
  customClad,
  deliveryMarkup,
  dealerTax,
}) => {
  const deliveryCost =
    data.checkedJobsite && data.deliveryCharge && !deliveryMarkup
      ? data.deliveryCharge
      : data.checkedJobsite &&
        data.deliveryCharge &&
        deliveryMarkup &&
        !withMarkup
      ? round(data.deliveryCharge / margin)
      : data.checkedJobsite &&
        data.deliveryCharge &&
        deliveryMarkup &&
        withMarkup
      ? round(data.deliveryCharge * (1 - margin + 1))
      : null;

  const customerTotal =
    isCustomer &&
    calcSuggestedRetail(suggestedRetail, margin, withMarkup || false);

  return (
    <>
      {!isCustomer ? (
        <>
          <View style={styles.dateSigned2}>
            <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
            <Text style={styles.ds}>{dateSigned}</Text>
          </View>

          <View style={[styles.column, { paddingRight: 100 }]}>
            <Text style={styles.textBold}>Factor:</Text>

            <Text style={styles.textBold}>Total:</Text>

            {data.adminCharge && data.adminChargeLabel ? (
              <Text style={styles.textBold}>{data.adminChargeLabel}:</Text>
            ) : (
              <Text></Text>
            )}

            {customClad && data.customCladPrice ? (
              <Text style={styles.textBold}>Custom Clad Color:</Text>
            ) : (
              <Text></Text>
            )}

            {dealerTax ? (
              <Text style={styles.textBold}>Sales Tax:</Text>
            ) : (
              <Text />
            )}

            {data.checkedJobsite ? (
              <Text style={styles.textBold}>Jobsite Delivery:</Text>
            ) : data.checkedDealer ? (
              <Text style={styles.textBold}>Dealer Delivery:</Text>
            ) : (
              <Text style={styles.textBold}>Delivery:</Text>
            )}

            <View style={styles.summaryDisclaim}>
              <Text style={[styles.textBold, { fontSize: 14 }]}>
                Dealer Net Cost:
              </Text>
              {data.status === "Quote" || data.status === "Archived" ? (
                <Text style={[styles.textBold, { fontSize: 8 }]}>
                  Price valid for 14 days
                </Text>
              ) : (
                <Text style={[styles.textBold, { fontSize: 8 }]}>Terms:</Text>
              )}
              {orderedUnits.some(customPaint) ? (
                <Text
                  style={[
                    styles.textBold,
                    { fontSize: 8, position: "absolute", bottom: -10 },
                  ]}
                >
                  Custom Paint/Stain may affect price
                </Text>
              ) : (
                <Text></Text>
              )}
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.column}>
              <Text style={styles.text}>{factor}</Text>

              <Text style={styles.text}>${suggestedRetail}</Text>

              {data.adminCharge && data.adminChargeLabel ? (
                <Text style={styles.text}>
                  {data.adminCharge.toString().includes("-") && "-"} $
                  {formatIntWithCommas(
                    priceWithZeros(Math.abs(data.adminCharge))
                  )}
                </Text>
              ) : (
                <Text />
              )}

              {customClad && data.customCladPrice ? (
                <Text style={styles.text}>
                  ${formatIntWithCommas(data.customCladPrice)}.00
                </Text>
              ) : (
                <Text></Text>
              )}

              {dealerTax ? (
                <Text style={styles.text}>
                  {dealerTax}% (
                  {calcDealerTaxDollar(
                    dealerTax,
                    suggestedRetail,
                    data.customCladPrice || 0,
                    data.adminCharge || 0
                  )}
                  )
                </Text>
              ) : (
                <Text />
              )}

              {data.checkedJobsite && data.deliveryCharge ? (
                <Text style={styles.text}>${data.deliveryCharge}.00</Text>
              ) : (
                <Text style={styles.text}>$0</Text>
              )}

              <View style={{ marginTop: 10 }}>
                {(!data.adminCharge && !data.adminChargeLabel) || dealerTax ? (
                  <Text style={[styles.textBold, { fontSize: 14 }]}>
                    ${grandTotal || "$"}
                  </Text>
                ) : (
                  <Text style={[styles.textBold, { fontSize: 14 }]}>
                    ${calcWithAdminCharge(grandTotal, data.adminCharge || 0)}
                  </Text>
                )}

                {codOrTerms.includes("COD") ? (
                  <Text
                    style={[styles.textBold, { fontSize: 8, paddingRight: 10 }]}
                  >
                    COD (Sign-Off and 50% Deposit is required to Start Order)
                  </Text>
                ) : codOrTerms &&
                  (data.status === "Quote" || data.status === "Archived") ? (
                  <Text style={[styles.textBold, { fontSize: 8 }]}>
                    Terms: {codOrTerms}
                  </Text>
                ) : codOrTerms ? (
                  <Text style={[styles.textBold, { fontSize: 8 }]}>
                    {codOrTerms}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </View>
            </View>
          </View>
        </>
      ) : (
        <>
          <View style={styles.signature}>
            <Text style={[styles.textBold, { paddingBottom: 5 }]}>
              Signature: ______________________________________
            </Text>
            <Text style={styles.text}>Date: ______________</Text>
          </View>

          <View style={[styles.column, { paddingRight: 100 }]}>
            <Text style={styles.textBold}>Total:</Text>

            {customClad && data.customCladPrice ? (
              <Text style={styles.textBold}>Custom Clad Color:</Text>
            ) : (
              <Text></Text>
            )}

            {addCosts.map((cost, idx) => (
              <View key={idx}>
                {!cost.taxable ? (
                  <Text />
                ) : cost.title ? (
                  <Text style={styles.textBold}>{cost.title}:</Text>
                ) : (
                  <Text style={styles.textBold}>Additional Charges:</Text>
                )}
              </View>
            ))}

            {data.adminCharge &&
            data.adminChargeLabel &&
            data.adminChargeMargined ? (
              <Text style={styles.textBold}>{data.adminChargeLabel}:</Text>
            ) : (
              <Text />
            )}

            <View style={{ marginTop: 10 }}>
              {!dealerTax ? (
                <Text style={styles.textBold}>Sales Tax:</Text>
              ) : (
                <Text />
              )}

              {addCosts.map((cost, idx) => (
                <View key={idx}>
                  {cost.taxable ? (
                    <Text />
                  ) : cost.title ? (
                    <Text style={styles.textBold}>{cost.title}:</Text>
                  ) : (
                    <Text style={styles.textBold}>Additional Charges:</Text>
                  )}
                </View>
              ))}

              {data.checkedJobsite ? (
                <Text style={styles.textBold}>Jobsite Delivery:</Text>
              ) : (
                <Text />
              )}

              <View style={{ borderTop: "1px solid black", width: "350%" }}>
                <Text style={[styles.textBold, { fontSize: 14 }]}>
                  Grand Total:
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.column}>
              <Text style={styles.text}>${customerTotal}</Text>

              {customClad && data.customCladPrice && !withMarkup ? (
                <Text style={styles.text}>
                  $
                  {formatIntWithCommas(
                    priceWithZeros(round(data.customCladPrice / margin))
                  )}
                </Text>
              ) : customClad && data.customCladPrice && withMarkup ? (
                <Text style={styles.text}>
                  $
                  {formatIntWithCommas(
                    priceWithZeros(
                      round(data.customCladPrice * (1 - margin + 1))
                    )
                  )}
                </Text>
              ) : (
                <Text></Text>
              )}

              {addCosts.map((cost, idx) => (
                <View key={idx}>
                  {!cost.taxable ? (
                    <Text />
                  ) : cost.price ? (
                    <Text style={styles.text}>
                      ${formatIntWithCommas(priceWithZeros(cost.price))}
                    </Text>
                  ) : (
                    <Text style={styles.textBold}>$0</Text>
                  )}
                </View>
              ))}

              {data.adminCharge &&
              data.adminChargeLabel &&
              data.adminChargeMargined ? (
                <Text style={styles.text}>
                  {data.adminCharge.toString().includes("-") && "-"} $
                  {formatIntWithCommas(
                    priceWithZeros(Math.abs(data.adminCharge))
                  )}
                </Text>
              ) : (
                <Text />
              )}

              <View style={{ marginTop: 10 }}>
                {!dealerTax ?
                <Text style={styles.text}>
                  {salesTax !== 0
                    ? `${(salesTax * 100).toFixed(3)}% (${calcSalesTaxDollar(
                      salesTax,
                      suggestedRetail,
                      addCosts,
                      margin,
                      withMarkup ? true : false,
                      data.adminChargeMargined && data.adminCharge ? data.adminCharge : 0,
                      customClad ? data.customCladPrice : 0
                    )})`
                    : "Zip Code Not Entered"}
                </Text>
                : 
                <Text />
                }

                {addCosts.map((cost, idx) => (
                  <View key={idx}>
                    {cost.taxable ? (
                      <Text />
                    ) : cost.price ? (
                      <Text style={styles.text}>
                        ${formatIntWithCommas(priceWithZeros(cost.price))}
                      </Text>
                    ) : (
                      <Text style={styles.textBold}>$0</Text>
                    )}
                  </View>
                ))}

                {data.checkedJobsite && data.deliveryCharge ? (
                  <Text style={styles.text}>
                    ${formatIntWithCommas(priceWithZeros(deliveryCost || 0))}
                  </Text>
                ) : (
                  <Text />
                )}

                <View>
                  <Text style={[styles.textBold, { fontSize: 14 }]}>
                    ${handleCustomerGrand(grandTotal)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </>
      )}
    </>
  );
};

export default SummaryDetails;
