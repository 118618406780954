import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import "../fontRegister"

const styles = StyleSheet.create({
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader1: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontWeight: 500,
  },
  tableCellHeaderBold: {
    margin: "auto",
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    padding: 2,
    fontSize: 8,
  },
  body: {
    padding: 10,
    width: 180,
  },
});

export const roundTo4 = (num: number) => {
  var m = Number((Math.abs(num) * 10000).toPrecision(15));
  return (Math.round(m) / 10000) * Math.sign(num);
};

interface StoryPoleProps {
  height: number
  pocket: string
}

const StoryPole: FC<StoryPoleProps> = ({ height, pocket }) => {
  const storypole = roundTo4(height - 3.4375);

  return (
    <View style={styles.body}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeaderBold}>STORY POLE</Text>
          </View>

          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeader}>{storypole}</Text>
          </View>
        </View>

        {pocket ? (
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader1}>
              <Text style={styles.tableCellHeaderBold}>SCREEN POCKET</Text>
            </View>

            <View style={styles.tableColHeader1}>
              <Text style={styles.tableCellHeader}>{pocket}</Text>
            </View>
          </View>
        ) : (
          <Text></Text>
        )}
      </View>
    </View>
  );
};

export default StoryPole;
