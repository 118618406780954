import { calculatePanelHeight } from "../../util/pdfFormulas/calculatePanelHeight";
import { calculatePanelWidth } from "../../util/pdfFormulas/calculatePanelWidth";

function getStilesImage(
  stiles: string,
  panelErr: { slimline?: string; euro?: string; euroTrad?: string },
  material: string,
  isAccoya: boolean,
  door: string,
  evenConfig: boolean | undefined,
  config: string,
  pivotMovement: string,
  width: number,
  height: number,
  exactConfig: string
) {
  let dsi = "";
  let dsi2 = "";

  if (!stiles) return { dsi, dsi2 };

  if (stiles.includes("Slimline")) {
    if (panelErr?.slimline) return { dsi: "", dsi2: "" };

    if (stiles.includes("Traditional")) {
      //Slimline Traditional
      if (material === "All Wood" && door && door.includes("Window")) {
        dsi = isAccoya ? "accoyaSlimlineTrad" : "woodSlimlineTrad";
      } else {
        dsi = "euro_2-1";
      }
    } else {
      //Slimline
      if (material === "All Wood" && door && door.includes("Window")) {
        dsi = isAccoya ? "accoyaSlimline" : "woodSlimline";
      } else {
        dsi = "slimline-square";
      }

      if (door && config === "Fixed Slider") dsi2 = "euro_3-7";
      else if (
        door &&
        !door.includes("Window") &&
        !evenConfig &&
        door !== "Fixed Panel"
      )
        dsi2 = "euro_3-7";
    }
  } else if (stiles.includes("Euro")) {
    if (door === "Slider" && width && height) {
      const panelWidth = calculatePanelWidth(
        "Slider",
        width,
        0,
        material,
        exactConfig,
        stiles,
        null,
        "",
        "",
        exactConfig.includes("P") && !exactConfig.includes("Primary")
          ? "With Pocket"
          : "No Pocket"
      );

      const panelHeight = calculatePanelHeight(
        exactConfig,
        "Slider",
        "Raised SILLution",
        height,
        "",
        material,
        ""
      );

      const approxSqFt = panelWidth
        ? Math.round((panelWidth * panelHeight) / 144)
        : 0;
      if (approxSqFt > 50) {
        if (material === "All Wood") {
          dsi2 = !isAccoya ? "woodTradTop" : "accoyaTradTop";
        } else {
          dsi2 = "euroTrad_v2";
        }
      }
    }

    //Euro
    if (material === "All Wood") {
      dsi = !isAccoya && door !== "Pivot" ? "woodEuro_v2" : "accoyaEuro_v2";
    } else {
      dsi = "euroClad";
    }

    if (
      door === "Pivot" &&
      config === "Fritsjurgens" &&
      pivotMovement === "Self Closing"
    ) {
      dsi2 = material === "All Wood" ? "accoyaTradTop" : "euroTrad_v2";
    }
  } else if (stiles.includes("Bottom")) {
    //Traditional
    if (material === "All Wood") {
      dsi = !isAccoya && door !== "Pivot" ? "woodTradTop" : "accoyaTradTop";
    } else {
      dsi = "trad_top";
    }

    if (stiles.includes("7")) {
      if (material === "All Wood") {
        dsi2 = !isAccoya && door !== "Pivot" ? "woodTradBtm" : "accoyaTradBtm";
      } else {
        dsi2 = "trad_btm";
      }
    }
  }

  return { dsi, dsi2 };
}

function getStileImageLabels(
  stiles: string,
  isSlider: boolean,
  pivotHardware?: string,
  pivotMovement?: string
) {
  let label1 = "";
  let label2 = "";

  if (!stiles) return { label1, label2 };

  if (stiles.includes("Slimline")) {
    if (pivotHardware === "Fritsjurgens" && pivotMovement === "Free Swing") {
      label1 = "Stiles and Bottom Rail";
      label2 = "Lock Stile and Top Rail";
    } else if (isSlider) {
      label1 = "Stiles and Top Rails";
      label2 = "Lock Stile and Bottom Rails";
    } else {
      label1 = "All Stiles and Rails";
      label2 = "Lock Stile";
    }
  } else if (stiles.includes("Euro")) {
    if (pivotHardware === "Fritsjurgens" && pivotMovement === "Self Closing") {
      label1 = "Stiles and Rails";
      label2 = "Bottom Rail";
    } else {
      label1 = "Stiles and Rails";
      label2 = "Bottom Rail"; //only used for large sliders
    }
  } else if (stiles.includes("Bottom")) {
    label1 = pivotHardware ? "Stiles and Top Rail" : "Stiles and Top Rails";
    label2 = pivotHardware ? "Bottom Rail" : "Bottom Rails";
  }

  return { label1, label2 };
}

export { getStileImageLabels, getStilesImage };
