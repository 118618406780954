import React, { memo, FC } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { handleHeight } from './defaults';
import { RowLayout } from "../../util/accordianStyles";

interface HandleHeightProps {
  doorType: string
}

const HandleHeightAccordian: FC<HandleHeightProps> = ({ doorType }) => {
  const addToQuote = useStore(state => state.addToQuote);

  const handleHeightChoice = useStore(state => state.quote.handleHeight);

  const findRequired = useRequiredMsgs(state => state.findRequired);
  const removeRequired = useRequiredMsgs(state => state.removeRequired);

  const handleAccordClick = useAccordion(state => state.handleAccordClick);
  const expanded = useAccordion(state => state.expanded[17].expanded);

  const handleClick = (label: string) => {
    const checked = label === handleHeightChoice;

    if(checked){
      addToQuote({ handleHeight: "" }, "handleHeight")
    }else{
      if(doorType === "Pivot"){
        if(findRequired('Thumb Turn Height')) removeRequired("Thumb Turn Height");
      }else{
        if(findRequired('Handle Height')) removeRequired("Handle Height");
      }
      addToQuote({ handleHeight: label })
    }
  };

  const heading = 
   doorType === "Bi-fold" 
   ? "Handle/Shoot-bolt Height" 
   : doorType === "Pivot" 
   ? "Thumb Turn Height" 
   : "Handle Height";

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="handleHeight"
      heading={heading}
      secondaryHeading={`Select desired ${heading.toLowerCase()}`}
    >
      <RowLayout>
        {handleHeight.map(state => (
           <div key={state.id} onClick={() => handleClick(state.label)}>
           <Choices 
             wide={true}
             label={state.label}
             variant={state.label === handleHeightChoice ? 'filled' : 'outlined'}
             checkMark={state.label === handleHeightChoice}
           />
         </div>
        ))}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default  memo(HandleHeightAccordian);