import { ScreenObj, SliderScreenOption } from "./defaults";

function findScreenChoices(
  isPocketing: boolean,
  chosenConfig: string,
  screenType: string,
  screenOptions: SliderScreenOption,
  width: number,
  height: number,
  sillStyle: string
) {
  if (!chosenConfig) return [];

  const centorHeight = sillStyle?.includes("Steel")
    ? height + 1.1792
    : height - 0.5625;

  let centorWidth = width;

  if (isPocketing) {
    const doubleSided = chosenConfig.includes("-");
    centorWidth = doubleSided ? width + 8.3128 : width + 6.8113;
  } else {
    centorWidth = width - 1;
  }

  let options: ScreenObj[] = [];

  if (screenType === "Centor Flat Screen/Shade") {
    if (centorWidth > 354) {
      return [];
    }

    if (centorWidth <= 165) {
      options = [
        ...screenOptions.addScreen,
        {
          id: 3,
          type: "addScreen",
          label: "Add Combo Screen/Shade",
          variant: "outlined",
          checkMark: false,
          disabled: false
        },
        {
          id: 4,
          type: "addScreen",
          label: "Add Shade",
          variant: "outlined",
          checkMark: false,
          disabled: false
        },
        {
          id: 5,
          type: "addScreen",
          label: "Add Double Shade",
          variant: "outlined",
          checkMark: false,
          disabled: false
        },
      ];
    } else if (centorWidth <= 167) {
      options = [
        ...screenOptions.addScreen,
        {
          id: 3,
          type: "addScreen",
          label: "Add Combo Screen/Shade",
          variant: "outlined",
          checkMark: false,
          disabled: false
        },
        {
          id: 4,
          type: "addScreen",
          label: "Add Double Shade",
          variant: "outlined",
          checkMark: false,
          disabled: false
        },
      ];
    } else {
      const screenKey = "addScreenDbl";

      if (centorWidth > 322 && screenOptions[screenKey as keyof SliderScreenOption]) {
        options = screenOptions[screenKey as keyof SliderScreenOption] as ScreenObj[];
      } else if (centorWidth > 181 && screenOptions[screenKey as keyof SliderScreenOption]) {
        options = [
          ...screenOptions[screenKey as keyof SliderScreenOption] as ScreenObj[],
          {
            id: 3,
            type: "addScreen",
            label: "Add Double Shade",
            variant: "outlined",
          checkMark: false,
          disabled: false
          },
        ];
      } else {
        options = screenOptions.addScreen;
      }
    }
  } else {
    if (
      chosenConfig.includes("P") &&
      width > 187 &&
      screenOptions.addScreenDbl
    ) {
      options = screenOptions.addScreenDbl;
    } else if (
      !chosenConfig.includes("P") &&
      width > 192 &&
      screenOptions.addScreenDbl
    ) {
      options = screenOptions.addScreenDbl;
    } else {
      options = screenOptions.addScreen;
    }
  }

  if (centorHeight > 114) {
    return options.filter(
      (itm) => !itm.label.includes("Shade") && !itm.label.includes("Combo")
    );
  }

  return options;
}

export default findScreenChoices;
