import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getModalStyle from "../../../../util/getModalStyle";
import { StyledModalDiv } from "../../../../util/accordianStyles";

interface LoseChangesProps {
  open: boolean;
  handleClose: () => void;
  modalText: string;
  changeTab: () => void;
}

const LoseChangesModal: FC<LoseChangesProps> = ({
  open,
  handleClose,
  modalText,
  changeTab,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3 id="simple-modal-description">{modalText}</h3>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button onClick={changeTab} color="primary" variant="contained">
          OK
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default LoseChangesModal;
