import React, { FC } from "react";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { SelectChangeEvent } from "@mui/material";
import JobsiteModal from "./modals/JobsiteModal";
import PoModal from "./modals/PoModal";
import { styled } from "@mui/material/styles";

const StyledDivContainer = styled("div")({
  marginBottom: 10,
  border: "2px solid grey",
  padding: 5,
  textAlign: "center",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
});

const StyledDivWrap = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: "green",
  fontSize: 20,
  paddingLeft: 5,
});

const StyledErrIcon = styled(ErrorOutlineIcon)({
  color: "#d0302e",
  fontSize: 20,
  paddingLeft: 5,
});

const StyledButton = styled(Button)({
  position: "relative",
  color: "black",
});

type Jobsite = {
  contactName: string;
  contactNumber: string;
  address: string;
  city: string;
  zip: string;
};

interface PoJobsiteProps {
  disableChanges: boolean;
  po: string;
  jobsite: Jobsite;
  handleJobsiteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePoChangeModal: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleModalDetails: () => void;
  openJobsite: boolean;
  openPO: boolean;
  handleCloseJobsite: () => void;
  handleOpenJobsite: () => void;
  handleClosePO: () => void;
  handleOpenPO: () => void;
  loadJobsite: boolean;
  err: string;
  taxResults: number[];
  handleTaxChange: (event: SelectChangeEvent<string>) => void;
  chosenTax: string | number;
}

const PoJobsite: FC<PoJobsiteProps> = ({
  disableChanges,
  po,
  jobsite,
  handleJobsiteChange,
  handlePoChangeModal,
  handleModalDetails,
  openJobsite,
  openPO,
  handleCloseJobsite,
  handleOpenJobsite,
  handleClosePO,
  handleOpenPO,
  loadJobsite,
  err,
  taxResults,
  handleTaxChange,
  chosenTax,
}) => {
  const hasAllJobsiteValues =
    jobsite.contactName &&
    jobsite.contactNumber &&
    jobsite.address &&
    jobsite.city &&
    jobsite.zip
      ? true
      : false;

  return (
    <StyledDivContainer>
      <PoModal
        open={openPO}
        handleClose={handleClosePO}
        handlePoChangeModal={handlePoChangeModal}
        po={po}
        disableChanges={disableChanges}
      />
      <JobsiteModal
        open={openJobsite}
        handleClose={handleCloseJobsite}
        handleJobsiteChange={handleJobsiteChange}
        jobsite={jobsite}
        handleModalDetails={handleModalDetails}
        loadJobsite={loadJobsite}
        err={err}
        disableChanges={disableChanges}
        taxResults={taxResults}
        handleTaxChange={handleTaxChange}
        chosenTax={chosenTax}
      />

      <StyledDivWrap>
        <StyledButton
          style={{ marginRight: 10 }}
          onClick={handleOpenPO}
          variant="outlined"
          size="small"
        >
          PO#
          {po ? <StyledCheckCircleIcon /> : <StyledErrIcon />}
        </StyledButton>

        <StyledButton
          onClick={handleOpenJobsite}
          variant="outlined"
          size="small"
        >
          Jobsite Details
          {hasAllJobsiteValues ? <StyledCheckCircleIcon /> : <StyledErrIcon />}
        </StyledButton>
      </StyledDivWrap>
    </StyledDivContainer>
  );
};

export default PoJobsite;
