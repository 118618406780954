import React, { FC } from "react";
import Choices from "../../Choices";
import ReusableAccordian from "../../ReusableAccordion";
import { useStore, useAccordion } from "../../../store/store";
import { Typography, Badge } from "@mui/material";
import { Image } from "cloudinary-react";
import { styled } from "@mui/material/styles";
import LearnMore from "../../LearnMore";

const StyledImgDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingTop: 40,
});

const StyledDiv70 = styled("div")({
  width: "70%",
  paddingBottom: 20,
  paddingTop: 20,
  textAlign: "center",
});

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingTop: 10,
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: "#f0be07",
  },
});

function findCentorSectionViews(material: string, sill: string) {
  const folder = "CENTOR_SECTION_VIEW/sliders";
  const materialLabel = material === "All Wood" ? "WOOD" : "CLAD";
  const sillLabel = sill.includes("Steel") ? "TRACKS" : "SILL";

  return {
    headSill: `${folder}/${materialLabel}_${sillLabel}`,
    jambLeg: `${folder}/${materialLabel}_LEG_JAMB`,
  };
}

interface ScreenTypeProps {
  screenTypeOptions: any
}

const ScreenTypeAccordian: FC<ScreenTypeProps> = ({ screenTypeOptions }) => {  
  const addToQuote = useStore((state) => state.addToQuote);

  const screenTypeChoice = useStore((state) => state.quote.screenType);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[31].expanded);

  const material = useStore((state) => state.quote.material);

  const sillStyle = useStore((state) => state.quote.sillStyle);

  const isCentor = screenTypeChoice?.includes("Centor");

  const isBrio = screenTypeChoice?.includes("Brio");

  const centorImgs = isCentor
    ? findCentorSectionViews(material, sillStyle || "")
    : null;

  const handleClick = (label: string) => {
    const checked = label === screenTypeChoice;

    if (checked) {
      addToQuote(
        {
          screenType: "",
          addScreen: "",
          screenLayout: "",
          screenPocket: "",
          screenColor: "",
        },
        "addScreen"
      );
    } else {
      addToQuote(
        {
          screenType: label,
          addScreen: "",
          screenLayout: "",
          screenPocket: "",
          screenColor: "",
        },
        "addSlidingScreen"
      );
    }
  };

  const brioOnlyOption = screenTypeOptions.length === 1 ? true : false;

  if (brioOnlyOption) return null;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="screenType"
      heading="Screen Type"
      secondaryHeading="Select a Screen Type"
      required={false}
      colOrRow="col"
    >
      {isCentor && (
        <Typography>
          Featuring a flat material that rolls up and provides the option for
          either screen mesh or shade.
        </Typography>
      )}

      {isBrio && (
        <Typography>
          Featuring an accordion-style material that folds up
        </Typography>
      )}

      {screenTypeChoice && (
        <LearnMore href="https://agmillworks.com/retractable-screens/" />
      )}

      <StyledDiv>
        {screenTypeOptions.map((item: any) => (
          <div key={item.id} onClick={() => handleClick(item.label)}>
            {item.id === 2 ? (
              <StyledBadge
                badgeContent={"New!"}
                style={{
                  marginLeft: screenTypeOptions?.length > 2 ? 10 : "",
                  marginRight: screenTypeOptions?.length > 2 ? 10 : "",
                }}
              >
                <Choices
                  label={item.label}
                  variant={
                    item.label === screenTypeChoice ? "filled" : "outlined"
                  }
                  checkMark={item.label === screenTypeChoice}
                />
              </StyledBadge>
            ) : (
              <Choices
                label={item.label}
                variant={
                  item.label === screenTypeChoice ? "filled" : "outlined"
                }
                checkMark={item.label === screenTypeChoice}
              />
            )}
          </div>
        ))}
      </StyledDiv>

      {isCentor && centorImgs && (
        <StyledImgDiv>
          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId={centorImgs.jambLeg}
            style={{ paddingRight: 30 }}
          />
          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId={centorImgs.headSill}
            style={{ paddingLeft: 30 }}
          />
        </StyledImgDiv>
      )}

      {isCentor && (
        <StyledDiv70>
          <Typography variant="subtitle1">
            <span style={{ fontWeight: "bold" }}>Important Note:</span> To
            simplify installation, we have integrated the Centor Screen framing
            into our frame and added wood blocking to fill any gaps. This design
            requires only one rough opening through the jamb. The Net Frame
            Width (NFW) and Net Frame Height (NFH) measurements include the
            screen frame.
          </Typography>
        </StyledDiv70>
      )}
    </ReusableAccordian>
  );
};

export default ScreenTypeAccordian;
