import api from "./api";

async function signOffStatusChange() {
  const quoteId = JSON.parse(sessionStorage.getItem("quoteId") || JSON.stringify(""));

  if (!quoteId) {
    console.log("no quote id");
    return;
  }

  let today = new Date();

  return await api.put("/api/changeQuoteStatusAfterSignOff", {
    quoteId,
    dateOrdered: today,
  });
}

async function getFolderData(folderID: string) {
  return await api.post("/api/getFolderData", { folderID });
}

async function downloadEsignDoc(folderID: string) {
  return await api.post(
    "/api/downloadEsignDoc",
    { folderID },
    { responseType: "arraybuffer" }
  );
}

export { signOffStatusChange, getFolderData, downloadEsignDoc };
