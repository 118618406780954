import React, { FC } from "react";
import {
  Switch,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { useStore } from "../../store/store";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "95%",
});

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

interface EqualPanelsProp {
  isPivot?: boolean;
}

interface UpdateObj {
  equalPanels: boolean;
  exactConfigImg?: string;
}

const EqualPanels: FC<EqualPanelsProp> = ({ isPivot }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const equalPanels = useStore((state) => state.quote.equalPanels);

  const exactConfigImg = useStore((state) => state.quote.exactConfigImg);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const checked = equalPanels ? true : false;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    let updateObj: UpdateObj = { equalPanels: checked };

    if (isPivot && exactConfigImg) {
      const configIMG =
        checked && !exactConfigImg.includes("LEVER")
          ? `${exactConfigImg.split(".jpg")[0]}_EVEN.jpg`
          : checked && exactConfigImg.includes("LEVER")
          ? `${exactConfigImg.split("_LEVER")[0]}_EVEN_LEVER.jpg`
          : exactConfigImg.replace("_EVEN", "");

      updateObj["exactConfigImg"] = configIMG;
    }

    handleErrMsgChange("swingWidthErr", "");
    addToQuote(updateObj);
  };

  return (
    <StyledDiv>
      <StyledFormControl variant="standard">
        <FormLabel style={{ color: "black" }}>
          <span style={{ fontWeight: "bold" }}>Optional:</span> Make Panel and
          Sidelite Widths Equal Size
        </FormLabel>
        <FormControlLabel
          control={
            <Switch color="primary" onChange={handleChange} checked={checked} />
          }
          label={checked ? "Yes" : "No"}
        />
      </StyledFormControl>
    </StyledDiv>
  );
};

export default EqualPanels;
