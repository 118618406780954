import React, { useState, FC } from "react";
import getModalStyle from "../../../util/getModalStyle";
import { Typography, Button, Modal, SelectChangeEvent } from "@mui/material";
import MatchingTaxResults from "../../QuoteForm/MatchingTaxResults";
import { StyledModalDiv } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledDivInput = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginBottom: 5,
  width: "100%",
  marginRight: 20,
  padding: 5,
});

const StyledDivInputTitle = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
});

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

type Jobsite = {
  contactName: string;
  contactNumber: string;
  address: string;
  city: string;
  zip: string;
};

interface JobsiteModalProps {
  disableChanges: boolean;
  jobsite: Jobsite;
  handleJobsiteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleModalDetails: () => void;
  loadJobsite: boolean;
  err: string;
  taxResults: number[];
  handleTaxChange: (event: SelectChangeEvent<string>) => void;
  chosenTax: number | string;
  open: boolean;
  handleClose: () => void;
}

const JobsiteModal: FC<JobsiteModalProps> = ({
  open,
  handleClose,
  handleJobsiteChange,
  jobsite,
  handleModalDetails,
  loadJobsite,
  err,
  disableChanges,
  taxResults,
  handleTaxChange,
  chosenTax,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const hasAdminAccess = role === "Admin" || role === "Inside Sales";

  const body = (
    <StyledModalDiv style={modalStyle}>
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", paddingBottom: 20 }}
      >
        Jobsite Information:
      </Typography>

      <StyledDivCol>
        <Typography style={{ paddingBottom: 5 }} variant="subtitle2">
          * Minimum of 2 people is required for hand unloading for jobsite
          deliveries, and only curbside delivery is available.
        </Typography>
        <Typography style={{ textAlign: "center" }} variant="subtitle2">
          * Some areas may be outside our delivery zone; call
        </Typography>
        <Typography
          style={{ paddingBottom: 10, textAlign: "center" }}
          variant="subtitle2"
        >
          (805) 644-4494 if unsure.
        </Typography>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Jobsite Contact Name:{" "}
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={20}
            style={{ width: 150, marginLeft: 10 }}
            value={jobsite.contactName}
            onChange={(e) => handleJobsiteChange(e)}
            name="contactName"
            type="text"
            disabled={hasAdminAccess ? false : disableChanges}
          />
        </StyledDivInput>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Jobsite Contact #:
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={20}
            style={{ width: 150, marginLeft: 10 }}
            value={jobsite.contactNumber}
            onChange={(e) => handleJobsiteChange(e)}
            name="contactNumber"
            type="text"
            disabled={hasAdminAccess ? false : disableChanges}
          />
        </StyledDivInput>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Address:
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={30}
            style={{ width: 150, marginLeft: 10 }}
            value={jobsite.address}
            onChange={(e) => handleJobsiteChange(e)}
            name="address"
            type="text"
            disabled={disableChanges}
          />
        </StyledDivInput>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              City:
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={25}
            style={{ width: 150, marginLeft: 10 }}
            value={jobsite.city}
            onChange={(e) => handleJobsiteChange(e)}
            name="city"
            type="text"
            disabled={disableChanges}
          />
        </StyledDivInput>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              State:
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={20}
            disabled={true}
            style={{ width: 150, marginLeft: 10 }}
            value="CA"
            type="text"
          />
        </StyledDivInput>

        <StyledDivInput>
          <StyledDivInputTitle>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Zip:
            </Typography>
          </StyledDivInputTitle>

          <input
            maxLength={6}
            style={{ width: 150, marginLeft: 10 }}
            value={jobsite.zip}
            onChange={(e) => handleJobsiteChange(e)}
            name="zip"
            type="text"
            disabled={disableChanges}
          />
        </StyledDivInput>
      </StyledDivCol>

      {err && (
        <StyledDivCol style={{ paddingTop: 20 }}>
          <Typography variant="subtitle1" style={{ color: "red" }}>
            {err}
          </Typography>
        </StyledDivCol>
      )}

      {taxResults.length ? (
        <MatchingTaxResults
          tax={chosenTax}
          handleTaxChange={handleTaxChange}
          taxResults={taxResults}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: 40,
        }}
      >
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleModalDetails}
          color="primary"
          variant="contained"
          disabled={
            loadJobsite ? true : hasAdminAccess ? false : disableChanges
          }
        >
          Confirm
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default JobsiteModal;
