import React, { useEffect, FC } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useAccordion, useStore } from "../../store/store";
import fractionToInt from "../../util/fractionToInt";
import taggedCornerConfigImages from "../../taggedImages/taggedCornerConfigImages";
import { initialCornerOptions } from "../../initialValues";
import {
  Typography,
  Select,
  MenuItem,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { calculateCornerPanelWidth } from "../../util/pdfFormulas/calculateCornerPanelWidth";
import noAccess from "../../util/noAccess";
import { styled } from "@mui/material/styles";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

const StyledTypography = styled(Typography)({
  fontSize: 20,
  fontWeight: "bold",
  paddingRight: 20,
});

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: " center",
  width: "100%",
  paddingTop: 20,
});

function findNumberOfPanels(config: string) {
  const panels = config.split("").find((item) => !isNaN(Number(item)));
  const numOfPanels = Number(panels);
  return numOfPanels;
}

function findBifoldImage(option: string) {
  if (!option) return "";

  var match = taggedCornerConfigImages.find((img) => img.config === option);
  return match;
}

function findFrameWidth(
  netWidth: number,
  cornerType: string,
  side: string,
  primaryDoor: string
) {
  let frameWidth;

  const isPrimaryDoor = primaryDoor === side;

  if (cornerType === "Inside") {
    const addPrimary = 2;
    const addSecondary = 4.375;

    frameWidth = isPrimaryDoor
      ? netWidth + addPrimary
      : netWidth + addSecondary;
  } else {
    const subPrimary = 4.375;
    const subSecondary = 2.125;

    frameWidth = isPrimaryDoor
      ? netWidth - subPrimary
      : netWidth - subSecondary;
  }

  return frameWidth;
}

function isLargePanel(
  config: string,
  frameWidth: number,
  primaryDoor: string,
  material: string
) {
  if (!frameWidth || !config) return false;

  const cornerSide = config.split("")[1];

  const isPrimaryDoor =
    (primaryDoor === "Left" && cornerSide === "L") ||
    (primaryDoor === "Right" && cornerSide === "R");

  const numOfPanels = findNumberOfPanels(config);
  const panelWidth = calculateCornerPanelWidth(
    numOfPanels,
    material,
    frameWidth,
    isPrimaryDoor
  );

  if (panelWidth) {
    if (panelWidth > 48 || panelWidth < 18) return false;
    if (panelWidth >= 42) return `${config} (Wider Panels)`;
  }

  return config;
}

function filterMap(
  options: string[],
  overall: number,
  primaryDoor: string,
  material: string
) {
  if (!options || !options.length) return;

  let arr: string[] = [];

  options.forEach((itm) => {
    const panel = isLargePanel(itm, overall, primaryDoor, material);

    if (panel) arr.push(panel);
  });

  return arr;
}

const odds = ["1L", "1R", "3L", "3R", "5L", "5R", "7L", "7R"];

interface BifoldCornerConfigProps {
  hideHandlesWithEvenConfigs: (hide: boolean) => void;
}

const BifoldCornerConfigAccordian: FC<BifoldCornerConfigProps> = ({
  hideHandlesWithEvenConfigs,
}) => {
  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[4].expanded);

  const addToQuote = useStore((state) => state.addToQuote);
  const material = useStore((state) => state.quote.material);
  const cornerType = useStore((state) => state.quote.cornerType);
  const cornerConfigLeft = useStore((state) => state.quote.exactConfig);
  const cornerConfigRight = useStore((state) => state.quote.cornerConfigRight);

  const isLeftOdd = odds.includes(cornerConfigLeft);
  const isRightOdd = odds.includes(cornerConfigRight || "");
  const oddEven = (isLeftOdd && !isRightOdd) || (!isLeftOdd && isRightOdd);

  const leftWidth = useStore((state) => state.quote.unitSize);
  const leftFraction = useStore((state) => state.quote.unitSizeFraction);

  const rightWidth = useStore((state) => state.quote.cornerWidthRight);
  const rightFraction = useStore(
    (state) => state.quote.cornerWidthFractionRight
  );

  const primaryDoor = useStore((state) => state.quote.primaryDoor);
  const handleErrMsgChange = useStore((state) => state.handleError);
  const error = useStore((state) => state.swingWidthErr);

  const overallLeft = findFrameWidth(
    fractionToInt(leftWidth, leftFraction),
    cornerType || "",
    "Left",
    primaryDoor || ""
  );
  const overallRight = findFrameWidth(
    fractionToInt(rightWidth || "", rightFraction),
    cornerType || "",
    "Right",
    primaryDoor || ""
  );

  useEffect(() => {
    addToQuote({ cornerFrameWidthLeft: overallLeft });
  }, [overallLeft]);

  useEffect(() => {
    addToQuote({ cornerFrameWidthRight: overallRight });
  }, [overallRight]);

  const configObjLeft = initialCornerOptions.find(
    (item) => item.min <= overallLeft && item.max >= overallLeft
  );

  const configObjRight = initialCornerOptions.find(
    (item) => item.min <= overallRight && item.max >= overallRight
  );

  const cornerOptionsLeft = filterMap(
    configObjLeft ? configObjLeft.left : [],
    overallLeft,
    primaryDoor || "",
    material
  );

  const cornerOptionsRight = filterMap(
    configObjRight ? configObjRight.right : [],
    overallRight,
    primaryDoor || "",
    material
  );

  useEffect(() => {
    if (!cornerOptionsLeft?.length) return;
    if (
      !cornerOptionsLeft.includes(cornerConfigLeft) &&
      !cornerOptionsLeft.includes(`${cornerConfigLeft} (Wider Panels)`)
    ) {
      addToQuote({ exactConfig: "" });
    }
  }, [cornerOptionsLeft]);

  useEffect(() => {
    if (!cornerOptionsRight?.length) return;
    if (
      !cornerOptionsRight.includes(cornerConfigRight || "") &&
      !cornerOptionsRight.includes(`${cornerConfigRight} (Wider Panels)`)
    ) {
      addToQuote({ cornerConfigRight: "" });
    }
  }, [cornerOptionsRight]);

  useEffect(() => {
    if (!cornerType) return;

    if (cornerConfigLeft && cornerConfigRight && oddEven) {
      const primarySide = isLeftOdd ? "Left" : "Right";
      addToQuote({ primaryDoor: primarySide });
    }

    const bothSidesOdd =
      odds.includes(cornerConfigLeft) && odds.includes(cornerConfigRight || "")
        ? true
        : false;

    if (cornerType === "Outside" && bothSidesOdd) {
      return handleErrMsgChange(
        "swingWidthErr",
        "Outside Corner Units cannot have two odd numbered sides"
      );
    }

    return handleErrMsgChange("swingWidthErr", "");
  }, [cornerType, cornerConfigLeft, cornerConfigRight]);

  const imageLeft = findBifoldImage(cornerConfigLeft);
  const imageRight = findBifoldImage(cornerConfigRight || "");

  const handleChangeLeft = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({
      exactConfig: value,
      stilesAndRails: "",
      stileCategory: "",
    });
    const hideHandles =
      noAccess.includes(value) && noAccess.includes(cornerConfigRight || "");
    hideHandlesWithEvenConfigs(hideHandles);
  };

  const handleChangeRight = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({
      cornerConfigRight: value,
      stilesAndRails: "",
      stileCategory: "",
    });
    const hideHandles =
      noAccess.includes(value) && noAccess.includes(cornerConfigLeft);
    hideHandlesWithEvenConfigs(hideHandles);
  };

  const handleChangePrimary = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    addToQuote({ primaryDoor: value });
    handleErrMsgChange("primaryDoorErr", "");
  };

  useEffect(() => {
    if (!imageLeft || !imageRight) return;

    const imgL =
      primaryDoor === "Right" && imageLeft.image2
        ? imageLeft.image2
        : imageLeft.image;

    const imgR =
      primaryDoor === "Left" && imageRight.image2
        ? imageRight.image2
        : imageRight.image;

    addToQuote({
      exactConfigImg: imgL,
      exactConfigImgRightCorner: imgR,
    });
  }, [imageLeft, imageRight, primaryDoor]);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="configuration"
      heading="Configuration"
      secondaryHeading="Select a configuration"
      required={false}
      required5={true}
      colOrRow="col"
    >
      <StyledDivCol>
        <Typography style={{ fontWeight: "bold", paddingRight: 40 }}>
          Primary Door:
          <span style={{ color: "red", fontSize: 22 }}>*</span>
        </Typography>
        <FormControl component="fieldset">
          <FormControlLabel
            value="end"
            control={
              <Radio
                color="primary"
                checked={primaryDoor === "Left"}
                onChange={handleChangePrimary}
                value="Left"
                disabled={oddEven}
              />
            }
            label="Left"
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={
              <Radio
                color="primary"
                checked={primaryDoor === "Right"}
                onChange={handleChangePrimary}
                value="Right"
                disabled={oddEven}
              />
            }
            label="Right"
            labelPlacement="end"
          />
        </FormControl>
      </StyledDivCol>

      {primaryDoor && (
        <>
          <RowLayout style={{ marginTop: -20 }}>
            <ColLayout>
              <RowLayout>
                <StyledTypography>Left Configuration:</StyledTypography>
                <Select
                  value={cornerConfigLeft}
                  onChange={handleChangeLeft}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  style={{ marginRight: 60, backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cornerOptionsLeft?.map((itm) => (
                    <MenuItem
                      key={itm}
                      value={itm.replace(" (Wider Panels)", "")}
                    >
                      {itm}
                    </MenuItem>
                  ))}
                </Select>
              </RowLayout>

              {imageLeft && cornerConfigLeft ? (
                <Paper
                  elevation={3}
                  style={{
                    marginRight: 60,
                    backgroundColor: "white",
                    padding: 10,
                  }}
                >
                  <img
                    src={
                      primaryDoor === "Right" && imageLeft.image2
                        ? imageLeft.image2
                        : imageLeft.image
                    }
                    alt="corner left"
                  />
                </Paper>
              ) : (
                <div style={{ width: 300, height: 232, marginRight: 60 }} />
              )}
            </ColLayout>

            <ColLayout>
              <RowLayout>
                <StyledTypography style={{ marginLeft: 60 }}>
                  Right Configuration:
                </StyledTypography>
                <Select
                  value={cornerConfigRight}
                  onChange={handleChangeRight}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cornerOptionsRight?.map((itm) => (
                    <MenuItem
                      key={itm}
                      value={itm.replace(" (Wider Panels)", "")}
                    >
                      {itm}
                    </MenuItem>
                  ))}
                </Select>
              </RowLayout>

              {imageRight && cornerConfigRight ? (
                <Paper
                  elevation={3}
                  style={{
                    marginLeft: 60,
                    backgroundColor: "white",
                    padding: 10,
                  }}
                >
                  <img
                    src={
                      primaryDoor === "Left" && imageRight.image2
                        ? imageRight.image2
                        : imageRight.image
                    }
                    alt="corner right"
                  />
                </Paper>
              ) : (
                <div style={{ width: 300, height: 232, marginLeft: 60 }} />
              )}
            </ColLayout>
          </RowLayout>

          {oddEven && (
            <StyledDivCol>
              <Typography>
                * Primary Door defaults to side with odd configuration
              </Typography>
            </StyledDivCol>
          )}

          {error && (
            <StyledDivCol>
              <Typography style={{ color: "red" }}>{error}</Typography>
            </StyledDivCol>
          )}
        </>
      )}
    </ReusableAccordian>
  );
};

export default BifoldCornerConfigAccordian;
