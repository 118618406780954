import React, { useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { useStore } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import fractionToInt from "../../util/fractionToInt";
import NumberFormatCustom from "../NumberFormatCustom";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 100,
  position: "relative",
}));

const StyledForm = styled("form")(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(1),
    width: "25ch",
  },
}));

interface JambInputProps {
  doorType: string;
  fraction: string;
  jamb: string;
  hasScreen: boolean;
  hasKerf: boolean;
  screenType: string;
}

const JambInput: FC<JambInputProps> = ({
  doorType,
  fraction,
  jamb,
  hasScreen,
  hasKerf,
  screenType,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const exactConfig = useStore((state) => state.quote.exactConfig);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleErrMsgChange = useStore((state) => state.handleError);
  const jambErr = useStore((state) => state.jambErr);

  useEffect(() => {
    if (!jamb) return;

    const overall = fractionToInt(jamb, fraction);

    let min: { num: number; msg: string } = { num: 0, msg: "" };
    let max: { num: number; msg: string } = { num: 0, msg: "" };

    if (exactConfig === "Fixed Full Bound") {
      min = { num: 4, msg: '4"' };
      max = { num: 8.5, msg: '8 1/2"' };
    } else if (exactConfig === "Fixed Slider") {
      min = { num: 4.125, msg: '4 1/8"' };
      max = { num: 8.5, msg: '8 1/2"' };
    } else if (doorType === "Pivot") {
      min = { num: 5, msg: '5"' };
      max = { num: 9.5, msg: '9 1/2"' };
    } else if (doorType === "Swing") {
      if (hasScreen && screenType === "Centor Flat Screen/Shade") {
        min = { num: 7.875, msg: '7 7/8"' };
        max = { num: 10.875, msg: '10 7/8"' };
      } else {
        min = hasScreen ? { num: 6.375, msg: '6 3/8"' } : { num: 4, msg: '4"' };
        max = hasScreen
          ? { num: 10.875, msg: '10 7/8"' }
          : { num: 8, msg: '8"' };
      }
    } else {
      if (hasScreen) {
        if (screenType === "Centor Flat Screen/Shade") {
          min = hasKerf
            ? { num: 10.25, msg: '10 1/4"' }
            : { num: 9.875, msg: '9 7/8"' };
          max = hasKerf ? { num: 11, msg: '11"' } : { num: 10, msg: '10"' };
        } else {
          min = hasKerf
            ? { num: 8.875, msg: '8 7/8"' }
            : { num: 8.5, msg: '8 1/2"' };
          max = { num: 10, msg: '10"' };
        }
      } else {
        min = hasKerf ? { num: 6, msg: '6"' } : { num: 5.625, msg: '5 5/8"' };
        max = { num: 8.5, msg: '8 1/2"' };
      }
    }

    const errMessage = `Must be between ${min.msg} - ${max.msg}`;

    if (!hasScreen && overall >= min.num && overall <= max.num) {
      handleErrMsgChange("jambErr", "");
    } else if (!hasScreen && (overall < min.num || overall > max.num)) {
      handleErrMsgChange("jambErr", errMessage);
    } else if (hasScreen && overall >= min.num && overall <= max.num) {
      handleErrMsgChange("jambErr", "");
    } else if (hasScreen && (overall < min.num || overall > max.num)) {
      handleErrMsgChange("jambErr", errMessage);
    }
  }, [fraction, jamb, hasScreen, doorType, hasKerf, exactConfig]);

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;

    if (value) {
      const jambString = fraction ? `${value} ${fraction}` : value;
      addToQuote({ jamb: jambString }, "jamb");
    } else {
      const jambString = fraction ? ` ${fraction}` : "";
      addToQuote({ jamb: jambString }, "jamb");
    }

    if (findRequired("Jamb Width"))
      removeRequired("Jamb Width", "Custom Overall Jamb Width");
  };

  const handleChangeFraction = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (value) addToQuote({ jamb: `${jamb} ${value}` });
    else addToQuote({ jamb: `${jamb}` });
  };

  const upcharge =
    exactConfig === "Fixed Slider"
      ? '6-5/8"'
      : doorType === "Swing" && !hasScreen
      ? '5-5/8"'
      : doorType === "Swing" &&
        hasScreen &&
        screenType === "Centor Flat Screen/Shade"
      ? '7-7/8"'
      : doorType === "Swing" && hasScreen
      ? '6-7/8"'
      : screenType === "Centor Flat Screen/Shade" && hasScreen && !hasKerf
      ? '9-7/8"'
      : screenType === "Centor Flat Screen/Shade" && hasScreen && hasKerf
      ? '10-1/4"'
      : !hasScreen && !hasKerf
      ? '5-5/8"'
      : !hasScreen && hasKerf
      ? '6"'
      : hasScreen && !hasKerf
      ? '8-1/2"'
      : hasScreen && hasKerf
      ? '8-7/8"'
      : null;

  return (
    <>
      <ColLayout>
        {upcharge && (
          <Typography variant="caption">
            * Upcharge if wider than {upcharge}
          </Typography>
        )}

        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
          }}
          noValidate
          autoComplete="off"
        >
          <ColLayout>
            <TextField
              variant="outlined"
              label="Enter Overall Width"
              value={jamb}
              error={jambErr ? true : false}
              helperText={jambErr || ""}
              onChange={handleChange}
              name="jamb"
              id="formatted-numberformat-input"
              style={{ width: 200 }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </ColLayout>
        </StyledForm>
      </ColLayout>

      <RowLayout style={{ alignSelf: jambErr ? "center" : "flex-end" }}>
        <StyledFormControl>
          <InputLabel>fraction</InputLabel>
          <Select
            value={fraction}
            onChange={handleChangeFraction}
            name="fractionValue"
            label="fraction"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"1/16"}>1/16</MenuItem>
            <MenuItem value={"1/8"}>1/8</MenuItem>
            <MenuItem value={"3/16"}>3/16</MenuItem>
            <MenuItem value={"1/4"}>1/4</MenuItem>
            <MenuItem value={"5/16"}>5/16</MenuItem>
            <MenuItem value={"3/8"}>3/8</MenuItem>
            <MenuItem value={"7/16"}>7/16</MenuItem>
            <MenuItem value={"1/2"}>1/2</MenuItem>
            <MenuItem value={"9/16"}>9/16</MenuItem>
            <MenuItem value={"5/8"}>5/8</MenuItem>
            <MenuItem value={"11/16"}>11/16</MenuItem>
            <MenuItem value={"3/4"}>3/4</MenuItem>
            <MenuItem value={"13/16"}>13/16</MenuItem>
            <MenuItem value={"7/8"}>7/8</MenuItem>
            <MenuItem value={"15/16"}>15/16</MenuItem>
          </Select>
        </StyledFormControl>
      </RowLayout>
    </>
  );
};

export default JambInput;
