import React, { useState, useEffect, FC } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

const StylesDiv = styled("div")(({ theme }) => ({
  width: "25%",
  position: "fixed",
  right: 10,
  bottom: 20,
  zIndex: 50,
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const InfoAlert: FC = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    let timeout: any;

    timeout = setTimeout(() => {
      setOpen(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <StylesDiv>
      <Collapse in={open}>
        <Alert
          severity="info"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Info</AlertTitle>
          <strong>Double Asterisk (**) — Required for Quick Quote Price</strong>
        </Alert>
      </Collapse>
    </StylesDiv>
  );
};

export default InfoAlert;
