import React, { useState, useEffect, useMemo, memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Choices from "../Choices";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material";
import Select from "@mui/material/Select";
import ReusableAccordian from "../ReusableAccordion";
import { useStore, useAccordion } from "../../store/store";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import {
  withKey,
  specialOrder,
  nonPocketHandles,
  handles,
  withHandle,
  sliderImgs,
  handleImages,
  specialAtlanta,
  special,
  normalHandleColorDallas,
  sliderTruthMountColors,
  veronaSliderColor,
  normalHandleColor,
  handleColors,
} from "./defaults";
import Video from "../Video";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  paddingBottom: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 200,
  marginRight: 50,
}));

interface HandleProps {
  doorType: string
}

const  HandleAccordian: FC<HandleProps> = ({ doorType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const sliderPocketing = useStore((state) => state.quote.sliderPocket);
  const chosenConfig = useStore((state) => state.quote.exactConfig);

  const isPocketing =
    sliderPocketing === "With Pocket" ||
    (chosenConfig && chosenConfig.includes("Primary"))
      ? true
      : false;

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[16].expanded);

  const withHandleChoice = useStore((state) => state.quote.withHandle);
  const withKeyChoice = useStore((state) => state.quote.withKey);

  const handleStyle = useStore((state) => state.quote.handleStyle);
  const finish = useStore((state) => state.quote.handleColor);

  const [open, setOpen] = useState<boolean>(false);

  const [handleColor, setHandleColor] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (value) {
      addToQuote({ handleColor: value }, "handleColor");
      if (findRequired("Handle Finish")) removeRequired("Handle Finish");
    } else {
      addToQuote({ handleColor: "" }, "handleColor");
    }
  };

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (doorType !== "Slider" || !isPocketing) return;
    if ((handleStyle === "Verona") || (handleStyle === "Dallas")) {
      setHandleColor([]);
      addToQuote({
        handleStyle: "",
        handleColor: "",
      });
    }
  }, [isPocketing, doorType]);

  useEffect(() => {
    if (!doorType || !handleStyle) return;

    if (
      doorType === "Slider" &&
      handleStyle === "One Piece Truth (Flush Mount)"
    ) {
      return setHandleColor(sliderTruthMountColors);
    }

    if (handleStyle === "Dallas") {
      setHandleColor(normalHandleColorDallas);
    } else if (handleStyle === "Verona") {
      doorType !== "Sliders"
        ? setHandleColor(normalHandleColor)
        : setHandleColor(veronaSliderColor);
    } else if (handleStyle === "Atlanta *") {
      setHandleColor(specialAtlanta);
    } else {
      setHandleColor(special);
    }
  }, [doorType, handleStyle]);

  const handleClickWithHandle = (label: string) => {
    const checked = label === withHandleChoice;

    if (checked) {
      addToQuote({ withHandle: "" });
    } else {
      addToQuote({ withHandle: label });
      if (findRequired("Handle Set")) removeRequired("Handle Set");
    }
  };

  const handleClickWithKey = (label: string) => {
    const checked = label === withKeyChoice;

    if (checked) {
      addToQuote({ withKey: "" });
    } else {
      addToQuote({ withKey: label });

      if (findRequired("Handle Key option")) {
        removeRequired("Handle Key option");
      }
    }
  };

  const handleClickHandleStyle = (label: string) => {
    if (!label) return;

    const checked = label === handleStyle;

    if (checked) {
      addToQuote({ handleStyle: "" }, "handleStyle");
    } else {
      addToQuote({ handleStyle: label }, "handleStyle");
      if (findRequired("Handle Style")) {
        removeRequired("Handle Style");
      }
    }
  };

  const handleImg = useMemo(() => {
    if (!handleStyle) return;

    const images = doorType === "Slider" ? sliderImgs : handleImages;

    return images.find((img) => img.label === handleStyle);
  }, [handleStyle, doorType]);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="handle"
      heading="Handle Set"
      secondaryHeading="Select handle combination"
      colOrRow="col"
    >
      {withHandleChoice === "No Handle Set" && (
        <ColLayout>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Guts Only
          </Typography>
          <Typography variant="subtitle1">
            Slimline and Euro Stiles Setback is 35mm
          </Typography>
          <Typography variant="subtitle1">
            Traditional Stiles Setback is 45mm
          </Typography>
          <br />
          <Typography variant="subtitle1">
            Works with: Emtek Configuration 5
          </Typography>
        </ColLayout>
      )}

      {(withHandleChoice === "No Handle Set" || !withHandleChoice) &&
      doorType !== "Slider" ? (
        <RowLayout>
          {withHandle.map((state) => (
            <div
              key={state.id}
              onClick={() => handleClickWithHandle(state.label)}
            >
              <Choices
                label={state.label}
                variant={
                  state.label === withHandleChoice ? "filled" : "outlined"
                }
                checkMark={state.label === withHandleChoice}
              />
            </div>
          ))}
        </RowLayout>
      ) : (
        <>
          {doorType !== "Slider" && (
            <ColLayout>
              <RowLayout>
                {withHandle.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickWithHandle(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === withHandleChoice
                          ? "filled"
                          : "outlined"
                      }
                      checkMark={state.label === withHandleChoice}
                    />
                  </div>
                ))}
              </RowLayout>
              <Divider style={{ width: "150%", margin: 10 }} />
            </ColLayout>
          )}

          <Typography variant="subtitle1">
            * Satin Nickel finish recommended for jobsite within 3 miles of
            coast
          </Typography>
          <RowLayout>
            <ColLayoutSpacing>
              <Typography variant="subtitle1">
                With key?<span style={{ color: "red", fontSize: 22 }}>*</span>
              </Typography>
              <RowLayout>
                {withKey.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClickWithKey(state.label)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === withKeyChoice ? "filled" : "outlined"
                      }
                      checkMark={state.label === withKeyChoice}
                    />
                  </div>
                ))}
              </RowLayout>
            </ColLayoutSpacing>
            <ColLayoutLeftDivider>
              <Typography style={{ margin: "auto" }} variant="subtitle1">
                Select a Handle Style
                <span style={{ color: "red", fontSize: 22 }}>*</span>
              </Typography>

              <RowLayout>
                {doorType === "Slider"
                  ? nonPocketHandles.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleClickHandleStyle(state.label)}
                      >
                        {!isPocketing ? (
                          <Choices
                            label={state.label}
                            variant={
                              state.label === handleStyle
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === handleStyle}
                          />
                        ) : state.label.includes("Truth") ? (
                          <Choices
                            label={state.label}
                            variant={
                              state.label === handleStyle
                                ? "filled"
                                : "outlined"
                            }
                            checkMark={state.label === handleStyle}
                          />
                        ) : null}
                      </div>
                    ))
                  : handles.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleClickHandleStyle(state.label)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === handleStyle ? "filled" : "outlined"
                          }
                          checkMark={state.label === handleStyle}
                        />
                      </div>
                    ))}
              </RowLayout>
            </ColLayoutLeftDivider>

            {doorType === "Slider" ? null : (
              <ColLayoutLeftDivider>
                <Typography variant="subtitle1">Special Orders</Typography>
                <Typography variant="caption">
                  (* Up-charge applies to all special orders)
                </Typography>
                <RowLayout>
                  {specialOrder.map((state) => (
                    <div
                      key={state.id}
                      onClick={() => handleClickHandleStyle(state.label)}
                    >
                      <Choices
                        label={state.label}
                        variant={
                          state.label === handleStyle ? "filled" : "outlined"
                        }
                        checkMark={state.label === handleStyle}
                      />
                    </div>
                  ))}
                </RowLayout>
              </ColLayoutLeftDivider>
            )}
          </RowLayout>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {handleStyle && handleImg?.label && (
              <ColLayout style={{ marginRight: 20 }}>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={handleImg.imgLabel}
                />

                <Typography style={{ paddingTop: 10 }} variant="subtitle1">
                  * All images are depicted without exterior key or handle
                  finish
                </Typography>
              </ColLayout>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledFormControl variant="outlined">
                <InputLabel id="demo-controlled-open-select-label">
                  Select Finish<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  style={{ width: 250, height: 50 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Select Finish"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={finish}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {handleColor.length ? (
                    handleColor.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value=""></MenuItem>
                  )}
                </Select>
              </StyledFormControl>

              {finish &&
                handleColors
                  .filter((color) => color.label === finish)
                  .map((item) => (
                    <Image
                      key={item.label}
                      style={{ marginLeft: 40 }}
                      cloudName="ag-millworks"
                      secure="true"
                      publicId={item.img}
                    />
                  ))}
            </div>
          </div>
        </>
      )}

      {doorType?.includes("Bi-fold") &&
        withHandleChoice === "Add Handle Set" && (
          <Video src="https://www.youtube.com/watch?v=rPALQ4sW8MA" />
        )}
    </ReusableAccordian>
  );
}

export default memo(HandleAccordian);
