const cloudinaryUrl = "https://res.cloudinary.com/ag-millworks/image/upload";

const pdfScreens = {
  rightScr: `${cloudinaryUrl}/Screens/right_screen.jpg`,
  leftScr: `${cloudinaryUrl}/Screens/left_screen.jpg`,
  doubleScr: `${cloudinaryUrl}/Screens/double_screen.jpg`,
  slidingScrRight: `${cloudinaryUrl}/Screens/DURANGO_RIGHT.jpg`,
  slidingScrLeft: `${cloudinaryUrl}/Screens/DURANGO_LEFT.jpg`,
  slidingScrDouble: `${cloudinaryUrl}/Screens/DURANGO_DOUBLE.jpg`,
  rightPkt: `${cloudinaryUrl}/ScreenPockets/rightPocket.jpg`,
  leftPkt: `${cloudinaryUrl}/ScreenPockets/leftPocket.jpg`,
  doublePkt: `${cloudinaryUrl}/ScreenPockets/doubleBothPockets.jpg`,
  doubleLeftPkt: `${cloudinaryUrl}/ScreenPockets/doubleLeftPocket.jpg`,
  doubleRightPkt: `${cloudinaryUrl}/ScreenPockets/doubleRightPocket.jpg`,
  comboRight: `${cloudinaryUrl}/Screens/combo_shade_right.jpg`,
  comboLeft: `${cloudinaryUrl}/Screens/combo_shade_left.jpg`,
  rightShade: `${cloudinaryUrl}/Screens/right_shade.jpg`,
  leftShade: `${cloudinaryUrl}/Screens/left_shade.jpg`,
  doubleShade: `${cloudinaryUrl}/Screens/double_shade.jpg`,
};

const pdfScreensCorner = {
  scrOutside: `${cloudinaryUrl}/Screens/OUTSIDE_CORNER.jpg`,
  scrInside: `${cloudinaryUrl}/Screens/INSIDE_CORNER.jpg`,
  shadeOutside: `${cloudinaryUrl}/Screens/OUTSIDE_CORNER_SHADE.jpg`,
  shadeInside: `${cloudinaryUrl}/Screens/INSIDE_CORNER_SHADE.jpg`,
  rightPktOutside: `${cloudinaryUrl}/ScreenPockets/OUTSIDE_CORNER_RIGHT_PKT_SCR.jpg`,
  leftPktOutside: `${cloudinaryUrl}/ScreenPockets/OUTSIDE_CORNER_LEFT_PKT_SCR.jpg`,
  doublePktOutside: `${cloudinaryUrl}/ScreenPockets/OUTSIDE_CORNER_DBL_PKT_SCR.jpg`,
  rightPktInside: `${cloudinaryUrl}/ScreenPockets/INSIDE_CORNER_RIGHT_PKT_SCR.jpg`,
  leftPktInside: `${cloudinaryUrl}/ScreenPockets/INSIDE_CORNER_LEFT_PKT_SCR.jpg`,
  doublePktInside: `${cloudinaryUrl}/ScreenPockets/INSIDE_CORNER_DBL_PKT_SCR.jpg`,
};

export function findCornerScreenImg(
  screen: string,
  pocket?: string,
  cornerType?: string
) {
  let key = "";

  if (!screen || !cornerType) return key;

  if (screen.includes("Shade")) {
    return pdfScreensCorner[
      `shade${cornerType}` as keyof typeof pdfScreensCorner
    ];
  }

  if (!pocket) key = `scr${cornerType}`;

  if (!key) return "";

  return pdfScreensCorner[key as keyof typeof pdfScreensCorner];
}

export function findCornerPocketImg(
  screen: string,
  pocket?: string,
  cornerType?: string
) {
  let key = "";

  if (!screen || !pocket || !cornerType) return key;

  if (pocket.includes("Double")) {
    key = "doublePkt";
  }

  if (pocket.includes("Left")) {
    key = "leftPkt";
  }

  if (pocket.includes("Right")) {
    key = "rightPkt";
  }

  if (!key) return "";

  return pdfScreensCorner[
    `${key}${cornerType}` as keyof typeof pdfScreensCorner
  ];
}

export function findScreenImg(
  screen: string,
  pocket?: string,
  layout?: string
) {
  let key = "";

  if (!screen) return key;

  if (screen.includes("Combo")) {
    if (layout && layout.includes("Right Shade")) {
      key = "comboRight";
    }

    if (layout && layout.includes("Left Shade")) {
      key = "comboLeft";
    }
  } else if (screen.includes("Double Shade") && !pocket) {
    key = "doubleShade";
  } else if (
    screen.includes("Shade") &&
    !pocket &&
    layout &&
    layout.includes("Right")
  ) {
    key = "rightShade";
  } else if (
    screen.includes("Shade") &&
    !pocket &&
    layout &&
    layout.includes("Left")
  ) {
    key = "leftShade";
  } else if (screen.includes("Double Screen") && !pocket) {
    key = "doubleScr";
  } else if (
    !screen.includes("Double") &&
    !pocket &&
    layout &&
    layout.includes("Right")
  ) {
    key = "rightScr";
  } else if (
    !screen.includes("Double") &&
    !pocket &&
    layout &&
    layout.includes("Left")
  ) {
    key = "leftScr";
  }

  if (!key) return "";

  return pdfScreens[key as keyof typeof pdfScreens];
}

export function findPocketImg(
  screen: string,
  pocket?: string,
  layout?: string
) {
  let key = "";

  if (!screen || !pocket) return key;

  if (screen.includes("Double")) {
    if (pocket.includes("Double")) {
      key = "doublePkt";
    }

    if (pocket.includes("Left")) {
      key = "doubleLeftPkt";
    }

    if (pocket.includes("Right")) {
      key = "doubleRightPkt";
    }
  } else {
    if (layout && layout.includes("Right")) {
      key = "rightPkt";
    }

    if (layout && layout.includes("Left")) {
      key = "leftPkt";
    }
  }

  if (!key) return "";

  return pdfScreens[key as keyof typeof pdfScreens];
}

export function findSlidingScreenImg(screen: string, exactConfig: string) {
  if (!screen) return "";

  const slidingScrConfigs = ["XO", "OX", "OX-AO", "OA-XO"];

  let key = "";

  if (slidingScrConfigs.includes(exactConfig)) {
    if (screen.includes("Left")) {
      key = "slidingScrRight";
    }

    if (screen.includes("Right")) {
      key = "slidingScrLeft";
    }

    if (screen.includes("Double")) {
      key = "slidingScrDouble";
    }
  }

  if (!key) return "";

  return pdfScreens[key as keyof typeof pdfScreens];
}
