import React, { useState, useEffect, FC } from "react";
import {
  Button,
  Typography,
  Modal,
  Checkbox,
  Divider,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import getModalStyle from "../../../../util/getModalStyle";
import api from "../../../../api/api";
import { StyledModalDiv } from "../../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: 10,
});

const StyledDivCol2 = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 10,
});

const StyledDivColEnd = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  flexDirection: "column",
  padding: 10,
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
});

const StyledDivRowStart = styled(Typography)({
  alignSelf: "flex-start",
  paddingTop: 10,
});

const StyledDivRowCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: 10,
});

const StyledDivToFrom = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  width: 50,
});

interface ExportFilterModalProps {
  handleClose: () => void
  open: boolean
  showTab: string
  searchTerm: string
  searchTermFilter: string
  isSearch: boolean
  showArchive?: string
  showActive?: string
}

const ExportFilterModal: FC<ExportFilterModalProps> = ({
  handleClose,
  open,
  showTab,
  searchTerm,
  searchTermFilter,
  isSearch,
  showArchive,
  showActive,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [abortController, setAbortController] = useState<AbortController | {}>({});

  const [loadingCsv, setLoadCsv] = useState(false);

  const [error, setError] = useState(false);

  const [checked, setChecked] = useState(false);

  const [includeCompleted, setIncludeCompleted] = useState(false); //for service export

  const [sort, setSort] = useState<"Descending" | "Ascending">("Descending"); //ascending or descending

  const [sortBy, setSortBy] = useState("date"); //date, quote number, grandTotal, dealer (admin)

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const isDealer =
    role === "Dealer" || role === "Manager" || role === "Company Manager";

  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1;
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  var month2 = month.toString().length === 1 ? `0${month}` : month;
  var day2 = day.toString().length === 1 ? `0${day}` : day;

  var newdate = `${year}-${month2}-${day2}`;

  useEffect(() => {
    if (open) return;
    const abortControllerVar = new AbortController();
    setAbortController(abortControllerVar);

    setChecked(false);
    setSort("Descending");
    setIncludeCompleted(false);
    if (showTab === "Customer List") {
      setSortBy("dealer");
    } else {
      setSortBy("date");
    }
    setDate({ startDate: "", endDate: "" });
    setLoadCsv(false);
    setError(false);
    return () => Object.keys(abortController).length && abortController instanceof AbortController ? abortController.abort() : undefined;
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDate((prevDate) => ({ ...prevDate, [name]: value }));
  };

  const handleSortClick = (sortedOrder: "Ascending" | "Descending") => setSort(sortedOrder);

  const handleSortByChange = (event: SelectChangeEvent<string>) => setSortBy(event.target.value);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const handleCheckCompleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeCompleted(checked);
  };

  const getExportData = async () => {
    setLoadCsv(true);
    let startDate;
    let endDate;

    if (checked) {
      startDate = new Date(date.startDate);
      const offset = startDate.getTimezoneOffset();
      startDate.setMinutes(startDate.getMinutes() + offset);

      endDate = new Date(date.endDate);
      endDate.setMinutes(endDate.getMinutes() + offset);
    }

    var params = !isSearch
      ? {
          startDate,
          endDate,
          status: showTab,
          sortDirection: sort,
          sortBy,
          filter: checked,
          showArchive,
        }
      : {
          startDate,
          endDate,
          status: showTab,
          sortDirection: sort,
          sortBy,
          filter: checked,
          searchTerm,
          searchTermFilter,
          showArchive,
        };

    const res = await api.get("/exportToCsv", {
      signal: abortController instanceof AbortController ? abortController.signal : undefined,
      params,
      responseType: "arraybuffer",
    });

    if (res.data.byteLength === 2) {
      setLoadCsv(false);
      setError(true);
      return false;
    }

    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = showTab === "Orders" ? "orders.xlsx" : "quotes.xlsx";
    link.click();

    if (res.data.error) {
      setLoadCsv(false);
      setError(true);
      return false;
    }

    setError(false);
    setLoadCsv(false);
  };

  const getSalesReportExportData = async () => {
    setLoadCsv(true);
    let startDate;
    let endDate;

    if (checked) {
      startDate = new Date(date.startDate);
      const offset = startDate.getTimezoneOffset();
      startDate.setMinutes(startDate.getMinutes() + offset);

      endDate = new Date(date.endDate);
      endDate.setMinutes(endDate.getMinutes() + offset);
    }

    var params = !isSearch
      ? {
          startDate,
          endDate,
          sortDirection: sort,
          sortBy,
          filter: checked,
        }
      : {
          startDate,
          endDate,
          sortDirection: sort,
          sortBy,
          filter: checked,
          searchTerm,
          searchTermFilter,
        };

    const res = await api.get("/exportSalesReports", {
      signal: abortController instanceof AbortController ? abortController.signal : undefined,
      params,
      responseType: "arraybuffer",
    });

    if (res.data.byteLength === 2) {
      setLoadCsv(false);
      setError(true);
      return false;
    }

    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "sales_report.xlsx";
    link.click();

    if (res.data.error) {
      setLoadCsv(false);
      setError(true);
      return false;
    }

    setError(false);
    setLoadCsv(false);
  };

  const handleCustomerExport = async () => {
    setLoadCsv(true);
    try {
      const params = {
        filterBy: searchTermFilter,
        searchTerm,
        activeStatus: showActive,
        sortBy,
        sortDirection: sort,
      };

      const res = await api.get("/exportCustomerList", {
        signal: abortController instanceof AbortController ? abortController.signal : undefined,
        params,
        responseType: "arraybuffer",
      });

      if (res.data.byteLength === 2) {
        setLoadCsv(false);
        setError(true);
        return false;
      }

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "customer_list.xlsx";
      link.click();

      if (res.data.error) {
        setLoadCsv(false);
        setError(true);
        return false;
      }

      setError(false);
      setLoadCsv(false);
    } catch (err) {
      setLoadCsv(false);
      setError(true);
      console.log(err);
    }
  };

  const handleServiceExport = async () => {
    setLoadCsv(true);
    try {
      const params = {
        searchBy: searchTermFilter,
        searchTerm,
        sortBy,
        sortDirection: sort,
        filter: checked,
        startDate: date.startDate,
        endDate: date.endDate,
        includeCompleted,
      };

      const res = await api.get("/exportService", {
        signal: abortController instanceof AbortController ? abortController.signal : undefined,
        params,
        responseType: "arraybuffer",
      });

      if (res.data.byteLength === 2) {
        setLoadCsv(false);
        setError(true);
        return false;
      }

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "service.xlsx";
      link.click();

      if (res.data.error) {
        setLoadCsv(false);
        setError(true);
        return false;
      }

      setError(false);
      setLoadCsv(false);
    } catch (err) {
      setLoadCsv(false);
      setError(true);
      console.log(err);
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle}>
      <StyledDivCol>
        <div style={{ borderBottom: "solid black 2px" }}>
          <h2 style={{ fontWeight: "bold" }}>Export Table to Excel</h2>
        </div>

        <StyledDivRowStart>Sort</StyledDivRowStart>

        <StyledDivCol>
          <StyledDivRow style={{ padding: 5 }}>
            <Button
              size="small"
              variant={sort === "Ascending" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleSortClick("Ascending")}
            >
              <ArrowUpIcon style={{ fontSize: 14 }} />
              Ascending
            </Button>
            <Button
              size="small"
              variant={sort === "Descending" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleSortClick("Descending")}
            >
              <ArrowDownIcon style={{ fontSize: 14 }} />
              Descending
            </Button>
          </StyledDivRow>

          <StyledDivRow style={{ padding: 10 }}>
            <Typography style={{ fontWeight: "bold" }}>Sort By:</Typography>
            {showTab === "Sales Reports" ? (
              <Select
                onChange={handleSortByChange}
                value={sortBy}
                variant="outlined"
                margin="dense"
                size="small"
                style={{ width: 180 }}
              >
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="dealer">Dealer</MenuItem>
              </Select>
            ) : showTab === "Customer List" ? (
              <Select
                onChange={handleSortByChange}
                value={sortBy}
                variant="outlined"
                margin="dense"
                size="small"
                style={{ width: 180 }}
              >
                <MenuItem value="dealer">Dealer</MenuItem>
                <MenuItem value="factor">Factor</MenuItem>
                <MenuItem value="role">Role</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </Select>
            ) : showTab === "Service Repair" ? (
              <Select
                onChange={handleSortByChange}
                value={sortBy}
                variant="outlined"
                margin="dense"
                size="small"
                style={{ width: 180 }}
              >
                <MenuItem value="dealer">Dealer</MenuItem>
                <MenuItem value="date">Date Created</MenuItem>
              </Select>
            ) : (
              <Select
                onChange={handleSortByChange}
                value={sortBy}
                variant="outlined"
                margin="dense"
                size="small"
                style={{ width: 180 }}
              >
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="quoteNumber">Quote Number</MenuItem>
                <MenuItem value="grandTotal">Dealer Cost</MenuItem>
                {!isDealer && <MenuItem value="dealer">Dealer</MenuItem>}
              </Select>
            )}
          </StyledDivRow>

          {showTab === "Service Repair" && (
            <StyledDivRowCenter>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleCheckCompleted}
                      checked={includeCompleted}
                    />
                  }
                  label="Include Completed Service?"
                />
              </FormGroup>
            </StyledDivRowCenter>
          )}
        </StyledDivCol>
      </StyledDivCol>

      <Divider style={{ width: "100%" }} />

      {showTab !== "Customer List" && (
        <StyledDivCol2>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheck}
                  checked={checked}
                />
              }
              label="Filter By Date"
            />
          </FormGroup>
          {checked && (
            <>
              <StyledDivColEnd>
                <StyledDivRow style={{ padding: 10, width: "100%" }}>
                  <StyledDivToFrom>
                    <Typography style={{ fontWeight: "bold" }}>
                      From:
                    </Typography>
                  </StyledDivToFrom>
                  <input
                    type="date"
                    name="startDate"
                    value={date.startDate}
                    onChange={handleChange}
                    max={newdate}
                    style={{ marginLeft: 10 }}
                  />
                </StyledDivRow>
                <StyledDivRow style={{ padding: 10, width: "100%" }}>
                  <StyledDivToFrom>
                    <Typography style={{ fontWeight: "bold" }}>To:</Typography>
                  </StyledDivToFrom>
                  <input
                    type="date"
                    name="endDate"
                    value={date.endDate}
                    onChange={handleChange}
                    min={date.startDate || ""}
                    max={newdate}
                    style={{ marginLeft: 10 }}
                  />
                </StyledDivRow>
              </StyledDivColEnd>
            </>
          )}
        </StyledDivCol2>
      )}

      {error && (
        <StyledDivRowCenter>
          <Typography style={{ color: "red", fontWeight: "bold" }}>
            No Results Found
          </Typography>
        </StyledDivRowCenter>
      )}

      <StyledDivRowCenter>
        <Button style={{marginRight: 20}} onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>

        {!loadingCsv ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "#478d4b", color: "white", marginLeft: 20 }}
            onClick={
              showTab === "Sales Reports"
                ? getSalesReportExportData
                : showTab === "Customer List"
                ? handleCustomerExport
                : showTab === "Service Repair"
                ? handleServiceExport
                : getExportData
            }
            disabled={loadingCsv}
          >
            Export
          </Button>
        ) : (
          <Typography>Generating...</Typography>
        )}
      </StyledDivRowCenter>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default ExportFilterModal;
