import { convertDecimalToFraction } from "../../../util/pdfFormulas/convertDecimalToFraction";

function calculateScreenPocket(netWidth: number, pocket: string, key: string){ 
    let A;
    let delta;

    const width = 
      !pocket?.includes("Double Pocket")
        ? netWidth 
        : netWidth / 2;

    //07/31/24 peer Antonio: if unit has a pocket and width is 120 for example go to next category

    if(pocket){
        if(width < 48){
            A = 3.5
        } else if(width < 72){
            A = 4.25
        }else if(width < 96){
            A = 4.75
        }else if(width < 120){
            A = 5
        }else if(width < 144){
            A = 5.75
        }else if(width < 168){
            A = 6.25
        }else{
            A = 6.75
        }
    }else{
        if(width <= 48){
            A = 3.5
        } else if(width <= 72){
            A = 4.25
        }else if(width <= 96){
            A = 4.75
        }else if(width <= 120){
            A = 5
        }else if(width <= 144){
            A = 5.75
        }else if(width <= 168){
            A = 6.25
        }else{
            A = 6.75
        }
    }

    switch(key){
        case 'pocketCalc1':
            // A -= 1;
            break;
        case 'pocketCalc_875':
            A += .875;
            break;
        case 'pocketCalc_9375':
            A -= .9375;
            break;
        case 'pocketCalc1_5625':
            A += 1.5625;
            break;
        case 'pocketCalc1_5':
            A += 1.5;
            break;
        default: 
            A += 0
    }
  
    delta = convertDecimalToFraction(A)
    
    return delta
};

export default calculateScreenPocket